import PropTypes from 'prop-types';
import { useState } from 'react';
// icons
// @mui
import { Button, CardHeader, Divider, Popover, Stack, Typography } from '@mui/material';
// components
import { IconButtonAnimate } from '../../components/animate';
import Iconify from '../../components/Iconify';
import NavSection from '../../components/NavSection';
import { PROFILE_NAV_CONFIG } from '../menuConfig';
import { useSelector } from "react-redux";
import { useTheme } from '@mui/system';
import { Link as RouterLink } from "react-router-dom";
import { isLoggedIn } from '../../utils/auth';
import styled from '@emotion/styled';

// ----------------------------------------------------------------------

AccountPopover.propTypes = {
  sx: PropTypes.object,
};

export default function AccountPopover({ sx, lightMode }) {
  const { user, app } = useSelector((state) => state);
  const { langLibrary } = app;
  const [open, setOpen] = useState(false);
  const { profile } = user ? user : {};
  const theme = useTheme()

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate color="inherit" onClick={handleOpen} sx={sx}>
        <Iconify icon={'iconoir:user'} sx={{ width: 30, height: 30, fontWeight: "bold", color: lightMode ? theme.palette.text.secondary : "#fff" }} />
      </IconButtonAnimate>

      <Popover
        open={Boolean(open)}
        onClose={handleClose}
        anchorEl={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 300 },
        }}
      >
        <>
          {
            !isLoggedIn() ?
              <Stack direction="row" alignItems="center" spacing={2} p={2}>

                <BtnStyle
                  to="/auth/login"
                  sx={{ background: "#fff !important", }}
                  variant="contained"
                  component={RouterLink}
                >
                  {langLibrary.key_7}
                </BtnStyle>
                <BtnStyle
                  to="/auth/register"
                  color="primary"
                  variant="contained"
                  component={RouterLink}

                >
                  {langLibrary.key_6}
                </BtnStyle>
              </Stack>
              : <>
                <Stack padding="1rem 1.5rem">
                  <CardHeader title={profile?.displayName} subheader={<Typography>{profile?.email}</Typography>} />
                </Stack>
                <Divider />
                <NavSection navConfig={PROFILE_NAV_CONFIG} />
              </>
          }
        </>
      </Popover>

    </>
  );
}
const BtnStyle = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  width: "150px",
}));
