import { Stack, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { MotionViewport } from "../components/animate";
import Page from "../components/Page";
import { APP_BAR_DESKTOP } from "../layouts/app";
import Header from "../layouts/header/Header";
import MainMenu from "../layouts/header/MainMenu";
import Banner from "../sections/homepage/Banner";
import FooterBanner from "../sections/homepage/FooterBanner";
import Introduce from "../sections/homepage/Introduce";
import Media from "../sections/homepage/Media";
import Partners from "../sections/homepage/Partners";
import Procedure from "../sections/homepage/Procedure";
import Products from "../sections/homepage/Products";
import WhyChoose from "../sections/homepage/WhyChoose";
import TrackVisibility from "react-on-screen";
import Solution from "../sections/homepage/Solution";
import StakingPool from "../sections/homepage/StakingPool";
// ----------------------------------------------------------------------

export default function Homepage() {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const animation = `
  @keyframes title-animation {
    0%   { width: 0px}
    100% {width:100px}
  }`;

  return (
    <>
      <style children={animation} />
      <Header offsetTop={window.innerHeight - APP_BAR_DESKTOP} />
      <Page title={langLibrary.key_195}>
        <ContainerStyle >
          <Banner />
          <MotionViewport>
            <StakingPool />
          </MotionViewport>
          <MotionViewport>
            <Solution />
          </MotionViewport>

          <MotionViewport>
            <TrackVisibility offset={0} partialVisibility>
              {
                ({ isVisible }) => <Introduce isVisible={isVisible} />
              }
            </TrackVisibility>
          </MotionViewport>
          <MotionViewport>
            <Products />
          </MotionViewport>
          <MotionViewport>
            <WhyChoose />
          </MotionViewport>
          <MotionViewport>
            <Partners />
          </MotionViewport>
          {/* <TrackVisibility offset={0} partialVisibility>
            {
              ({ isVisible }) => <Procedure isVisible={isVisible} />
            }
          </TrackVisibility> */}
          <MotionViewport >
            <Media />
          </MotionViewport>
          <FooterBanner />
        </ContainerStyle>
      </Page>
    </>
  );
}


// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
  marginTop: `-${APP_BAR_DESKTOP}px`,
  background: "#0D0D0D",
  "& *": {
    fontFamily: 'conthrax !important'
  }
}));