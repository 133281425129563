const ko = {
  key_1: "코인을 선택합니다",
  key_2: "현재 이 페이지를 구축하기 위해 열심히 노력하고 있습니다.",
  key_3: "복사됨",
  key_4: "찾을 수 없습니다",
  key_5: "오타를 확인하거나 완전한 단어를 사용해 보세요.",
  key_6: "회원가입",
  key_7: "로그인",
  key_8: "Google 서비스에 대한 비밀번호 및 환경설정",
  key_9: "본인 확인 및 웹 설정 방법",
  key_10: "계정 설정",
  key_11: "영어",
  key_12: "한국어",
  key_13: "일본어",
  key_14: "수익",
  key_15: "부터",
  key_16: "까지",
  key_17: "커미션을 받기 위해 스테이킹해보세요.",
  key_18:
    "귀하의 어필리에이트 풀은 제한되어 있습니다. 커미션을 계속 받으려면 더 많이 스테이킹해야 합니다.",
  key_19: "친구 초대하기",
  key_20: "까지",
  key_21: "요구 사항",
  key_22: "커미션",
  key_23: "추천 커미션",
  key_24: "랭킹 커미션",
  key_25: "랭킹 보너스",
  key_26: "매칭 커미션",
  key_27: "총 수익",
  key_28: "팀",
  key_29: "펀딩",
  key_30: "입금 및 출금",
  key_31: "교환",
  key_32: "로그인",
  key_33: "계정이 없으신가요?",
  key_34: "시작하기",
  key_35: "404 - 페이지를 찾을 수 없습니다",
  key_36: "죄송합니다. 페이지를 찾을 수 없습니다!",
  key_37:
    "죄송합니다. 찾으시는 페이지를 찾을 수 없습니다. URL을 잘못 입력하셨을 수도 있습니다. 맞춤법을 재확인해주세요.",
  key_38: "홈으로 이동",
  key_39: "프로모션",
  key_40: "회원가입",
  key_41: "비밀번호 재설정",
  key_42: "스테이킹",
  key_43: "스테이킹",
  key_44: "수익",
  key_45: "회원",
  key_46: "이메일은 유효한 이메일 주소여야 합니다",
  key_47: "이메일은 필수 항목입니다",
  key_48: "비밀번호은 필수 항목입니다",
  key_49: "Google OTP 코드는 필수 항목입니다",
  key_50: "Google OTP 코드는 6자리여야 합니다",
  key_51: "이메일 주소",
  key_52: "비밀번호",
  key_53: "비밀번호를 잊으셨나요?",
  key_54: "Google OTP 코드를 입력해주세요",
  key_55: "Google OTP 코드",
  key_56: "Google OTP - 6자리",
  key_57: "확인",
  key_58: "비밀번호 확인은 필수 항목입니다",
  key_59: "비밀번호가 일치해야 합니다",
  key_60: "이메일 코드는 필수 항목입니다",
  key_61: "이미 계정이 있으신가요?",
  key_62: "비밀번호 확인",
  key_63: "이메일을 확인하여 OTP 코드 6자리를 받으세요",
  key_64: "OTP 코드",
  key_65: "회원가입 완료",
  key_66: "다음으로 로그인 페이지로 자동으로 리디렉션됩니다.",
  key_67: "NFT 구매 성공!",
  key_68: "OTP는 필수 항목입니다",
  key_69: "추천코드는 필수 항목입니다",
  key_70: "추천코드",
  key_71: "비밀번호를 잊으셨나요?",
  key_72:
    "계정과 연결된 이메일 주소를 입력하시면 비밀번호를 재설정할 수 있는 링크를 이메일로 보내드립니다.",
  key_73: "요청 보내기",
  key_74: "새 비밀번호 설정",
  key_75: "OTP가 이메일로 전송되었습니다. 이메일을 확인하세요.",
  key_76: "새 비밀번호",
  key_77: "재설정 성공!",
  key_78: "또는",
  key_79: "스테이킹",
  key_80: "F1 투자자",
  key_81: "총 판매",
  key_82: "표시 이름은 필수 항목입니다 ",
  key_83: "업데이트 성공!",
  key_84: "이름 입력",
  key_85: "표시 이름",
  key_86: "안녕",
  key_87: "취소",
  key_88: "업데이트",
  key_89: "수익 스테이킹",
  key_90: "매칭",
  key_91: "다이렉트",
  key_92: "랭킹",
  key_93: "날짜",
  key_94: "입금",
  key_95: "주소",
  key_96: "입금 시 주소와 메모는 필수 항목입니다",
  key_97: "성공",
  key_98: "확인",
  key_99: "트랜잭션 해시",
  key_100: "액수",
  key_101: "수수료",
  key_102: "시간",
  key_103: "상태",
  key_104: "유형",
  key_105: "이름을 업데이트하세요.",
  key_106: "이 이름은 프로필 및 랭킹에 표시됩니다.",
  key_107: "만든 시간",
  key_108: "레벨",
  key_109: "랭크",
  key_110: "가입 시간",
  key_111: "회원",
  key_112: "핫 월렛",
  key_113: "Google Authenticator (GA)",
  key_114: "2단계 인증으로 계정 보호",
  key_115: "구글 인증",
  key_116: "다음",
  key_117: "1. 이 키 백업:",
  key_118:
    "2. Google Play 또는 AppStore에서 [Authenticator App]을 다운로드하세요.",
  key_119: "3. 앱에서 [Set up account]을 선택합니다.",
  key_120: "4. [Scan a barcode]을 선택합니다.",
  key_121: "이 코드 스캔",
  key_122: "끄기",
  key_123: "켜기",
  key_124: "코드를 입력주세요·",
  key_125: "Google OTP 코드",
  key_126: "인증자 설정",
  key_127: "아이디",
  key_128: "이메일",
  key_129: "기존 비밀번호는 필수 항목입니다 ",
  key_130: "새 비밀번호는 필수 항목입니다 ",
  key_131: "안전한 비밀번호는 계정을 보호하는 데 도움이 됩니다",
  key_132: "비밀번호를 변경합니다",
  key_133: "강력한 비밀번호를 선택하고 다른 계정에 재사용하지 마세요.",
  key_134: "기존 비밀번호",
  key_135: "강한 비밀번호",
  key_136:
    "8자 이상을 사용해주세요. 다른 사이트의 비밀번호나 애완동물의 이름과 같이 너무 뻔한 비밀번호를 사용하지 마세요.",
  key_137: "비밀번호 확인",
  key_138: "금액은 필수 항목입니다",
  key_139: "추가 성공!",
  key_140: "스테이킹 정보",
  key_141: "프로세스",
  key_142: "일",
  key_143: "재투자",
  key_144: "가용",
  key_145: "모두",
  key_146: "추가",
  key_147: "스테이킹 추가",
  key_148: "추가하시겠습니까?",
  key_149: "액수는 이상이어야 합니다.",
  key_150: "스테이킹 성공!",
  key_151: "최소",
  key_152: "스테이킹",
  key_153: "추정",
  key_154: "기간",
  key_155: "이자",
  key_156: "매월",
  key_157: "만료일",
  key_158: "스테이킹을 확인함으로써 귀하는 동의합니다",
  key_159: "이용 약관",
  key_160: "처음 30일 동안 자본 인출하시면 수수료는 10%입니다",
  key_161: "30일 이후에는 수수료가 5%입니다.",
  key_162:
    "1180일 동안 인출하는 경우에는 이전에 받은 수입과 커미션이 공제됩니다",
  key_163: "MANAGER 등급 이상의 사용자는 출금이 불가능합니다.",
  key_164: "붕괴",
  key_165: "결제",
  key_166: "언스테이크",
  key_167: "어필리에이트  잠금",
  key_168: "일",
  key_169: "언스테이크 성공!",
  key_170: "공제",
  key_171: "받음",
  key_172: "귀하의  자본이익률이 공제됩니다",
  key_173: "이용 약관에 따라",
  key_174: "잠금 성공!",
  key_175: "교환 성공!",
  key_176: "비율",
  key_177: "최대",
  key_178: "최소",
  key_179: "교환하시겠습니까?",
  key_180: "추천 아이디는 필수 항목입니다",
  key_181: "추천 코드.",
  key_182: "첫 로그인에 필요한 추천 코드를 입력해주세요.",
  key_183: "계정 변경",
  key_184: "출금",
  key_185: "출금이 일시적으로 비활성화됩니다.",
  key_186: "정보를 확인해주세요!",
  key_187: "주소로",
  key_188: "옵션 선택",
  key_189: "대시보드",
  key_190: "설정",
  key_191: "로그 아웃",
  key_192: "지갑",
  key_193: "패키지",
  key_194: "내 패키지",
  key_195: "홈",
  key_196: "기다려주세요",
  key_197: "이 봇은 시작하는 중입니다.",
  key_198: "이 봇은 중지 중입니다.",
  key_199: "이 봇은 옵션이 변경되고 있습니다.",
  key_200: "요청이 거부되었습니다",
  key_201: "메모",
  key_202: "옵션 저장 ",
  key_203: "알림:",
  key_204: "통화 쌍",
  key_205: "트레일링 스톱",
  key_206: "입금이 일시적으로 비활성화되었습니다.",
  key_207: "메모 없이 출금하시겠습니까?",
  key_208: "지원",
  key_209: "철금 성공...!",
  key_210: "비밀번호는 최소 길이가 8인 문자열이어야 합니다.",
  key_211: "계정",
  key_212:
    "귀하의 요청이 성공적으로 전송되었습니다. 이 요청에 대한 세부 정보가 포함된 이메일을 보내 드렸습니다.",
  key_213: "지원으로 돌아가기",
  key_214: "문제가 있는 경우 문제를 제출하여 해결할 수 있습니다.",
  key_215: "죄송합니다. 이 케이스는 아직 지원되지 않습니다.",
  key_216: "유효한 이메일을 입력해주세요.",
  key_217: "수신자의 이메일을 입력해주세요.",
  key_218: "액수를 입력해주세요.",
  key_219: "Tx 해시를 입력해주세요.",
  key_220: "수신자(이메일)",
  key_221: "보내기",
  key_222: "이메일을 입력해주세요.",
  key_223: "이미지를 입력해주세요.",
  key_224: "수수료 유형을 입력해주세요.",
  key_225: "날짜를 입력해주세요.",
  key_226: "스폰서 이메일",
  key_227: "F1 이메일",
  key_228: "커미션 유형",
  key_229: "누락된 날짜",
  key_230: "다이렉트",
  key_231: "랭킹",
  key_232: "매칭",
  key_233: "GA 검증",
  key_234: "Tx 해시가 유효하지 않습니다.",
  key_235: "앱 다운로드",
  key_236: "보너스",
  key_237: "시장 진입점",
  key_238: "클레임",
  key_239: "클레임하시겠습니까?",
  key_240: "클레임 성공!",
  key_241: "날짜 | 상태",
  key_242: "모두 클레임",
  key_243: "자동",
  key_244: "Ubot",
  key_245: "Ubot - AI 기반 Forex 거래 시스템으로 매월 약 5-15%의 수익 창출",
  key_246: "완벽한 투자 솔루션",
  key_247:
    "Ubot은 3년간 운영해온 AI 기술 기반의 Forex 트레이딩 상품입니다. Fibonacci에 따라 자동으로 작동하며 인공지능(AI)의 우수성을 결합합니다. AI는 시장 상황에 맞춰 다양한 분석 알고리즘과 지속적인 업데이트로 인공지능을 활용합니다. 100% 이상/년 (약 5-15%/월) 수익을 창출합니다. 따라서 Ubot은 경제적 자유로 가는 가장 빠른 지름길이라고 할 수 있습니다.",
  key_248: "CFD는 100개 이상의 국가에서 널리 사용되는 금융 거래 분야입니다",
  key_249:
    "CFD 브로커는 전문 트레이더와 초보 트레이더 모두에게 현실적인 기대치를 모두 충족하도록 설계된 우수한 거래 조건을 제공합니다. 업적과 경험을 바탕으로 Ubot는 최첨단 거래 기술과 권위 있는 파트너십을 포함한 안전한 거래 네트워크로 거래추세를 만들어 왔습니다. 이에 저희는 안전하고 투명하며 신뢰할 수 있는 브로커가 되었습니다. ",
  key_250:
    "회사 오리엔테이션: CFD, Crypto, Usdt, Payment Coint 분야에서 세계 최고의 Ubot이 되기 위해...",
  key_251:
    "UBot은 투자자의 수익을 최우선에 두고 투자 현금 흐름의 투명하고 명확한 요소에도 중점을 둘 것입니다. 현재 저희는 CFD 분야에서 지속적으로 발전하고 있지만 가까운 미래에  가치를 가지고 있는 커뮤니티 요소를 가져올 새로운 방향을 제시합니다. ",
  key_252:
    "파트너와 함께 운영해 온 EI BOT 거래를 통해 Ubot도 금융 플랫폼의 투자자에게 안전한 거래 체험을 제공하고자 합니다. 다른 회사가 할 수 없는 가장 낮은 위험을 보장합니다.  너무 오래 걸리는 전통 도구가 필요없이 지속가능 수익을 내는 것을 자랑스럽게 여깁니다.",
  key_253: "봇 거래",
  key_254: "제품",
  key_255:
    "BOT TRADE의 추세 분석 도구는 매우 효과적인 솔루션이며 투자자에게 확실한 수익을 제공합니다. 수백 개의 서로 다른 계정을 쉽게 관리할 수 있지만 선택한 자본에 따라 수익 성능은 동일합니다. 당사의 BOT Trade는 종종 Accelerator Oscillator, Bollinger Bands, Averge Directinal Index, Moving Avergae Channel, Stochastic RIS, Williams Alligator 등과 같은 거래 분석 도구를 사용합니다.",
  key_256: "왜 Ubot인가?",
  key_257:
    "거래 논리는 시장의 오랜 경험을 가진 전문가들에 의해 BOT에 적용됩니다.",
  key_258:
    "몇 번의 클릭만으로 봇 전략을 설정합니다.  거래를 간단하게 자동화합니다. 지표를 사용하여 모멘텀을 포착하거나 추세 따라 잡습니다.",
  key_259: "투자자에게 매월 5%에서 15%의 수익을 냅니다.",
  key_260:
    "24/5 자동 주문 알림 및 계정 상태 업데이트를 통해 고객을 지원합니다.",
  key_261: "활성 장치는 가용  멀티미디어와 통합됩니다.",
  key_262: "저희는 펀드 조직에 투자하기로 결정했습니다.",
  key_263: "EI BOT",
  key_264: "쉬운 자동화",
  key_265: "수동 소득",
  key_266: "고객센터",
  key_267: "멀티미디어",
  key_268: "조직",
  key_269: "연락",
  key_270: "패키지",
  key_271: " 활성된 패키지 있음>= 6개월 ",
  key_272: "F1에서 F5까지",
  key_273: "최소 ...개월 패키지 구매해야함",
  key_274: "있음",
  key_275: "다른 지점의 수준",
  key_276: "레벨로 검색",
  key_277: "순위로 검색",
  key_278: "추천 ID",
  key_279: "추천 링크",
  key_280: "커미션 트랜잭션",
  key_281: "쉐어 수익 총",
  key_282: "봇 설정",
  key_283: "봇 옵션",
  key_284: "최대 매수 주문",
  key_285: "최대 매도 주문",
  key_286: "보텀 또는 탑의 유형",
  key_287: "FX 사용자 이름",
  key_288: "FX 비밀번호",
  key_289: "FX 서버",
  key_290: "First distance",
  key_291: "Next distance",
  key_292: "월 익절",
  key_293: "월 손절",
  key_294: " 요청 메모",
  key_295: "요청이 실행 중입니다.",
  key_296: "요청",
  key_297: "옵션 변경",
  key_298: "봇 시작",
  key_299: "봇 중지",
  key_300: "봇 ID",
  key_301: "봇 제목",
  key_302:
    "EA(Expert Advisor)라고도 하는 Forex Bot Trade는 일종의 소프트웨어입니다.",
  key_303:
    "표시 이름은 이름이나 닉네임 또는 Ubot에서 다른 사람들이 알고 싶어하는 다른 이름이 될 수 있습니다.",
  key_304: "페이지 크기",
  key_305: "연장 성공!",
  key_306: "패키지 이름",
  key_307: "기한",
  key_308: "구매 시간",
  key_309: "연장",
  key_310: "연장 통화 선택",
  key_311: "패키지 종류",
  key_312: " 연장 가격",
  key_313: "패키지 기간",
  key_314: "월",
  key_315: "제목",
  key_316: "사이드",
  key_317: "설정",
  key_318: "봇",
  key_319: "봇 목록",
  key_320: "연장 확인",
  key_321: "구매 성공!",
  key_322: "봇",
  key_323: "구매",
  key_324: "패키지 선택",
  key_325: "구매 통화 선택",
  key_326: "수량 선택",
  key_327: "패키지 가격",
  key_328: "수량",
  key_329: "총 가격",
  key_330: "구매 확인",
  key_331: "패키지 기간",
  UNKNOWN: "알수없는 오류.",
  OPERATION_FAIL: "작업 실패",
  UNAUTHORIZED: "미승인",
  INVALID_PARAMETERS: "잘못된 매개변수입니다.",
  INVALID_OPERATION: "잘못된 작업입니다.",
  PERMISSION_DENIED: "권한이 거부되었습니다.",
  SYSTEM_BUSY: "시스템이 사용 중입니다.",
  TOO_MANY_REQUESTS: "요청이 너무 많습니다.",
  RECORD_NOTFOUND: "레코드를 찾을 수 없습니다.",
  TOKEN_INVALID: "토큰이 유효하지 않습니다.",
  TOKEN_WRONG: "토큰이 잘못되었습니다.",
  TOKEN_EXPIRED: "토큰이 만료되었습니다.",
  OTP_INVALID: "OTP가 유효하지 않습니다.",
  OTP_WRONG: "OTP가 잘못되었습니다.",
  OTP_EXPIRED: "OTP가 만료되었습니다.",
  PASSWORD_WRONG: "비밀번호 또는 이메일이 잘못되었습니다.",
  GACODE_REQUIRED: "GaCode가 필요합니다.",
  GACODE_WRONG: "GaCode가 잘못되었습니다.",
  WRONG_CREDENTIALS: "잘못된 자격 증명입니다.",
  USER_INACTIVE: "귀하의 계정은 비활성 상태입니다.",
  INSUFFICIENT_FUNDS: "자금이 부족합니다.",
  EMAIL_EXIST: "이메일이 존재합니다.",
  SOLD_OUT: "매진",
  OWNER_ERROR: "오너 오류",
  FORBIDDEN: "금지.",
  key_332: "인텔리전스 트레이딩",
  key_333: "신뢰 파트너",
  key_334: "면책 서면",
  key_335: "내 봇",
  key_336: "옵션이 업데이트 중입니다.",
  key_337: "로그 요청 ",
  key_338: "형태",
  key_339: "이메일로 검색",
  key_340: "구독 확인",
  key_341: "통화 선택",
  key_342: "구독 ",
  key_343: "구독 성공!",
  key_344:
    "거래 시 인터넷 연결이 안정적인지 확인해주세요. 인터넷 연결이 끊기면 주문이 전송되지 않습니다.",
  key_345:
    "정전, 기계 고장과 같은 드문 경우의 위험에 대비하기 위해 거래 통제를 고려해주세요. Ubot의 민감성 때문에 봇은 때때로 시장의 잘못된 정보에 의해 영향을 받을 수 있습니다.",
  key_346:
    "광고뉴스와 프로젝트에 참여하지 않은 사람들의 판단을 따라하지 마십시오. 특히 Ubot 프로젝트에 소속되어 있다고 자임하는 관리자들의 말입니다. ",
  key_347:
    "수익은 회원님이 소유하고 있는  BOT 패키지에 따라 달라지므로 원하시는 수익을 얻기 위해 BOT 패키지를 적절히 선택하세요.",
  key_348: "월 평균 수익률은 5~15%로 추정됩니다.",
  key_349: "관리자가 먼저 메시지를 보내지 않습니다.\n",
  key_350:
    "지원이 필요하시거나 문의 사항이 있으시면 즉시 Ubot 지원 부서로 연락해 주세요. 월요일부터 금요일까지 23시간 동안 지속적으로 지원하도록 하겠습니다.",
  key_351:
    "BOT의 운용 시간과 업무 시간 외에 어떠한 거래 위험에 대해 책임지지 않습니다.",
  key_352: "검증되지 않은 투자 조언을 절대 따르지 마십시오. ",
  key_353: "정당한 사유 없이 비밀번호를 변경하시면 안됩니다.",
  key_354:
    "계정에 대한 무단 액세스로 인한 어떠한 손해에 대해서는 책임지지 않습니다.",
  key_355: "입금만 해주세요",
  key_356: "이 주소로",
  key_357: "입금이 비활성화되었습니다.",
  key_358: "에서",
  key_359: "주소/이메일",
  key_360: "주소 또는 사용자 이메일은 필수 항목입니다.",
  key_361: "1. 거래 기술만을 제공합니다. Uot은 투자 신탁 서비스가 아닙니다.",
  key_362:
    "2. 거래 시 인터넷 연결이 안정적이지51 확인해주세요. 인터넷 연결이 끊기면 주문이 전송되지 않습니다.",
  key_363:
    "3. 정전, 기계 고장과 같은 드문 경우의 위험에 대비하기 위해 거래 통제를 고려해주세요. Ubot의 민감성 때문에 봇은 때때로 시장의 잘못된 정보에 의해 영향을 받을 수 있습니다.",
  key_364:
    "4. 광고뉴스와 프로젝트에 참여하지 않은 사람들의 판단을 따라하지 마십시오. 특히 Ubot 프로젝트에 소속되어 있다고 자임하는 관리자들의 말입니다.",
  key_365: "5. 월 평균 수익률은 5~15%로 추정됩니다.",
  key_366: "6. 관리자가 먼저 메시지를 보내지 않습니다.",
  key_367:
    "7. 지원이 필요하시거나 문의 사항이 있으시면 즉시 Ubot 지원 부서로 연락해 주세요. 월요일부터 금요일까지 23시간 동안 지속적으로 지원하도록 하겠습니다.",
  key_368:
    "8. Bot의 운용 시간과 업무 시간 외에 어떠한 거래 위험에 대해 책임지지 않습니다.",
  key_369: "9. 검증되지 않은 투자 조언을 절대 따르지 마십시오.",
  key_370:
    "10. Bot을 설치한 후 MT4 비밀번호를 변경하시면 안 됩니다. 비밀번호가 변경된 경우 봇은 작동을 중지할 것입니다.",
  key_371: "11. 봇이 거래할 수 있으려면 거래소에서 KYC를 완료해야 합니다.",
  key_372:
    "12. 계정에 대한 무단 액세스로 인한 어떠한 손해에 대해서는 책임지지 않습니다.",
  key_373: "이용약관",
  key_374:
    "하이리스크 하이리턴 BOT 사용 패키지 / 시장상황에 따라 최초 투자금액의 최대 100%까지 추가 입금을 염두해둬야함",
  key_375: "Invest in",
  key_376: "with",
  key_377: "symbol",
  key_378: "최소 운영자금",
  key_379: "월 수익",
  key_380: "봇",
  key_381: "서포터 ",
  key_382: "투자 시장",
  key_383: "BOT 수",
  key_384: "서포터 수",
  key_385: "읽었으며 이에 동의합니다",
  key_386: "이용약관",
  key_387: "MT4 ID",
  key_388: "MT4 비밀번호",
  key_389:
    "Ubot은 주어진 시나리오에 근거하여 가능한 최고의 거래 서비스를 제공할 것을 보증할 뿐입니다. 사용자는 자신만의 시나리오를 설치할 수 있지만 권장하는 시나리오를 사용하지 않을 경우 Ubot는 어떠한 거래 손실에 대해 책임지지 않습니다. 또한 다른 회사의 상품이나 서비스에 대해 책임을 지지 않습니다. Ubot을 사용하기로 결정하기 전에 위험을 잘 이해하시고 모든 규정과 지침을 준수하는 것을 확실하십시오.",
  key_390:
    "Bot을 설치한 후 MT4 비밀번호를 변경하시면 안 됩니다. 비밀번호가 변경된 경우 봇은 작동을 중지할 것입니다",
  key_391: "주소/이메일로",
  key_392: "회사소개",
  key_393: "Automatic Intelligence Trading",
  key_394:
    "2019년부터 뛰어난 거래 시스템 및 투자 생태계와 결합된 매력적인 거래 조건을 제공하여 각각의 모든 금융 여정을 강화하기 위해 노력했습니다.",
  key_395: "Ubot이 다음과 같이 중요한 이점을 제공합니다",
  key_396: "경험",
  key_397: "2000명 이상의 사용자로 3년 동안 검증되고 운영하는 상품",
  key_398: "23/5 자동 거래",
  key_399: "FOMO 제거, 인간이 가진 감정 리스크 제거",
  key_400: "EA BOT",
  key_401:
    "Bot에 적용된 거래 논리는 금융 분야에서 다년간의 경험을 가진 전문가들로부터 학습됨",
  key_402: "효과적인 자본 관리 - 안정적인 수익",
  key_403: " 투자자에게 월 약 5-15%의  수동적 수익을 창출하기",
  key_404: "고객 지원",
  key_405:
    "연중무휴 자동 주문 알림 및 계정 상태 업데이트를 통해 고객을 지원하기",
  key_406: "신뢰",
  key_407: "전 세계에서 평판이 좋은 파트너의 신뢰",
  key_408: "Ubot BOT이 보증드립니다.",
  key_409: "안전 및 신뢰",
  key_410:
    "글로벌 기업인 Ubot은 고객에게 믿음직하고 신뢰할 수 있는 공급자였습니다. CFD 시장에서 7년 이상의 경험을 가진 기술 회사인 BIGWAVE가 만든 AI 기반 자동 Forex 거래 상품에 중점을 둡니다. Ubot을 통해 고객은 강력한 금융 보안 조치 및 다양한 고객 관리 정책을 이용할 수 있습니다.",
  key_411: "Ubot의 차이점",
  key_412: "통합",
  key_413: "거래, 투자 및 AI 자동 거래를 통합합니다",
  key_414: "단순화",
  key_415:
    "돈을 관리하는 방법과 거래 및 투자에 대해 더 많이 배울 수 있는 방법을 단순화",
  key_416: "연결",
  key_417: "금융 분야의 최고 전문가들과 세계를 연결하는 양방향 브리지",
  key_418: "금융교육을 지원합니다",
  key_419:
    "고객에게 거래와 투자를 넘어가는 서비스를 제공합니다. 금융 세계는 어렵지만 올바른 교육 지원을 통해 고객은 현명한 결정을 내릴 수 있습니다. 거래 및 투자 분야의 초보자이든 일일 트렌드를 파악하기를 좋아하는 해당 분야의 전문가이든 상관없이 Ubot는 신중하게 선별된 온라인 과정, 세미나, 웨비나, 거래 팟캐스트, 교육 기사를 통해 지식을 제공합니다.",
  key_420:
    "또한 고객들에게 매일의 투자 분석과 뉴스를 업데이트하여 현명하게 거래할 수 있도록 합니다 . Ebooks에서 일일 거래 및 투자 뉴스, 비디오 자습서, FX 과정 등을 배울 수 있습니다.",
  key_421: "시장을 선도하는 솔루션을 제공합니다",
  key_422: "시장",
  key_423:
    "Ubot은 투자자에게 없어서는 안 될 파트너이자 소중한 도구가 될 지향점을 가지고 CFD, Crypto, USDT, Payment Coin 등의 분야에 Bot Trade를 적용하는 선두 기업이 되는 것을 목표로 합니다. 뿐만 아니라 AI 인공지능으로 매일 개선되는 정교하고 효과적인 분석과 로직을 기반으로 안전한 투자 솔루션을 제공합니다. Ubot는 Fibonacci에 따라 AI의 힘으로 자동 생성되는 24/5 운영하여 월 평균 5~15%의 안정적인 수익을 창출합니다.",
  key_424: "거래자와 투자자는 다음과 같은 이유로 Ubot를 선택합니다",
  key_425: "배우기",
  key_426:
    "전문가와 함께하는 무료 웨비나 및 실시간 거래 세션, 포괄적인 Forex 과정 등을 통해 CFD 거래 및 주식 시장 투자에 대해 알아봅니다.",
  key_427: "벌기",
  key_428: "월 최소 5~15%의 수익으로 안정적인 수익을 창출합니다.",
  key_429: "투자하기",
  key_430:
    "CFD로 고전적이고 다각화된 투자 방식을 선호하시면 투자 시 이점을 얻기 위해 수천 개의 도구중에 첨단 기술을 사용할 수 있습니다",
  key_431: "Ubot은 어느 시간대에 작동하나요?",
  key_432:
    "Ubot는 23/5 연속 작동합니다. 즉, BOT는 월요일부터 금요일까지 23시간 동안 연속 작동합니다.",
  key_433: "Ubot은 주말에도 작동하나요?",
  key_434:
    "Ubot는 외환시장과 유사한 특성을 가지고 있기 때문에 월요일부터 금요일까지만 연속적으로 운영되며, 주말에는 중앙은행이 휴무이므로 외환거래가 이루어지지 않습니다.",
  key_435:
    "다만, 외국과 베트남의 시간대가 완전히 다르기 때문에 계속해서 거래가 이루어질 것 같으니 너무 걱정하지 않으셔도 됩니다. 이 거래 세션이 종료되면 다른 세션이 열리며 동시에 거래 세션이 열리므로 거래자에게 좋은 거래 기회가 제공됩니다.",
  key_436: "Ubot 의 작동 메커니즘은 무엇인가요?",
  key_437:
    "BOT 패키지를 구매한 후 사용자는 계정에 돈을 입금하기만 하면 되며 차트를 보는 데 많은 시간을 할애할 필요가 전혀 없습니다. 거래 논리는 BOT에 의해 적용되며 시장의 오랜 전문가의 경험에서 배웁니다.",
  key_438:
    "또한 자동화 메커니즘을 통해 사용자는 몇 번의 클릭만으로 봇 전략을 설정할 수 있습니다. 사용자는 거래 시 익절과 손절을 수동으로 선택할 수 있으며, Ubot는 가격에 영향을 미치는 이벤트를 기다리며 시장을 면밀히 모니터링합니다. BOT는 사용자가 설정한 시나리오에 따라 신호를 보내거나 자체적으로 결정하고 매수 또는 매도 주문을 실행합니다.",
  key_439: "출금 후 얼마 정도 후에 돈을 받을 수 있나요?",
  key_440:
    "언제든지 출금할 수 있으며 완전히 무료이며 24-36시간 이내에 돈을 받을 것입니다.",
  key_441: "Ubot에 입금은 어떻게 하나요?",
  key_442: "입금 방법 알아보기",
  key_443: "여기",
  key_444: "출금 방법 알아보기",
  key_445: "Ubot에서 출금은 어떻게 하나요?",
  key_446: "Ubot 에 입금 가능한 최소 및 최대 금액은 얼마인가요?",
  key_447:
    "한 달에 $350만 내면 Ubot에 완전히 가입하고 체험할 수 있습니다. 입금에 대한 최대 금액은 정해져 있지 않으며 자금력에 맞는 BOT 패키지를 선택할 수 있습니다.",
  key_448: "Ubot의 예상 수익은 얼마인가요?",
  key_449:
    "때때로 시장이 약간의 영향을 받고 가격이 변동하기 때문에 저희는 회원님에게 수익 수준을 보장하지 않습니다. 그러나 Ubot이 보장할 수 있는 예상 수익은 월 5-15%이지만 이 수익은 소유하고 있는 BOT 패키지에 따라 다릅니다.",
  key_450: "봇을 어떻게 갱신하나요?",
  key_451:
    "BOT 갱신에 매우 유리한 가격이 많습니다. 기간이 12개월 미만인 갱신 패키지의 경우 BOT 가격은 원가로 계산됩니다(할인 없음). 기간이 12개월 이상인 갱신 패키지의 경우 BOT 갱신 가격은 원가의 50%로 계산됩니다.",
  key_452: "Ubot에서 어떤 통화쌍을 거래해야 하나요?",
  key_453:
    "Forex가 얼마나 많은 통화쌍을 가지고 있느냐는 것은 투자자들이 자주 묻는 가장 일반적인 질문 중 하나입니다. 세계에는 200개 이상의 국가와 지역이 있으므로 다양한 통화가 있습니다. 그러나 경제 규모, 무역 및 기타 특성 때문에 일부 통화와 주요 통화 쌍만 외환 시장에서 빈번하게 거래됩니다. Ubot에서는 고객의 수익을 최우선에 두기 때문에 다음과 같은 특징을 가진 AUD-CAD 거래 통화 쌍을 선택합니다:",
  key_454: "안정적이고 시장에 비해 변동성이 크지 않습니다.",
  key_455: "전쟁이나 군비 경쟁에 참여하지 않는 온건한 두 나라의 통화 쌍입니다.",
  key_456: "Ubot에 가입하면 돈을 잃게 되나요?",
  key_457:
    "Ubot에 가입하시면 Ubot의 리스크와 수익 창출 방법에 대해 공유하게 됩니다. 따라서 수년간 사용된 거의 1000개 이상의 봇들은 돈을 잃지 않았습니다.",
  key_458: "거래하기 가장 좋은 시간 프레임은 무엇인가요?",
  key_459:
    "일반적으로 월요일에는 시장이 상당히 조용할 것이고 뉴스는 다른 날만큼 쇄도하지 않습니다.",
  key_460:
    "월요일과는 반대로 화요일, 수요일, 목요일에서는 시장은 흥미진진하고 변동이 심할 것입니다. 뉴스와 정보는 주로 이 3일 동안 나타납니다. 투자자는 이 3일 동안 자신 있게 거래에 참여할 수 있습니다. 시장은 금요일 아침에도 여전히 활기차지만 오후에는 다시 조용해질 것입니다.",
  key_461: "투자 지식이 없는데 가입할 수 있나요?",
  key_462:
    "Ubot은 1개월 체험에 $350에 초보자를 위한 BOT 패키지를 지원합니다. 그러나 이 BOT 패키지를 사용하면 1개의 BOT만 소유하게 됩니다.",
  key_463: "사용자에게 가장 편리한 봇 패키지는 무엇인가요?",
  key_464:
    "Pro-safe 패키지를 구매하는 것을 추천합니다. 이 패키지를 구매하면 5개의 거래 AI BOT과 3개의 거래 재무 고문을 소유하고 한 달에 약 5-15%의 수익을 얻을 수 있습니다.",
  key_465: "Ubot은 월 5-10%의 수익을 보장하나요?",
  key_466:
    "사업이나 투자에는 수익이 보장된다는 개념이 없지만 항상 안전과 지속가능한 수익을 창출하는 것을 공유하고 지향합니다. 지난 수년 동안 수익은 매달 5-10 %에 꾸준히 도달해왔습니다.",
  key_467: "내 MT4 계정에 어떻게 Bot을 설치할 수 있나요?",
  key_468:
    "Ubot이 설치를 도와주고 계정에 대한 시나리오를 생성하도록 안내해드리겠습니다.",
  key_469: "거래소 파트너",
  key_470: "계정 개설",
  key_471: "패키지 구독",
  key_472: "Fxprimus 등록",
  key_473: "Fxprimus 지갑에 입금",
  key_474: "BOT 옵션 설정",
  key_475: "봇 사용 시작",
  key_476: "GA 인증 켜기",
  key_477: "GA 인증 끄기 ",
  key_478: "로 구독",
  key_479: "추천 커미션 없음",
  key_480: "최소",
  key_481: "개월 패키지 구매해야함",
  key_482: "추천 커미션",
  key_483: "일반직급자의 쉐어 커미션 수익",
  key_484: "일반직급자의 거래소 수수료 수익",
  key_485: "상위직급자의 쉐어 커미션 수익",
  key_486: "상위직급자의 거래소 수수료 수익",
  key_487: "약",
  key_488: "이용약관을 읽었으며 이에 동의합니다",
  key_489: "에서 이 주소로만",
  key_490: "를 입금해주세요.",
  key_491: "커미션",
  key_492: "통화 쌍으로",
  key_493: "시장에 투자",
  key_494: "MANAGER급 이상에 대한 요건",
  key_495: "GOLD급 이상에 대한 요건",
  key_496: "DIAMOND급 이상에 대한 요건",
  key_497: "MASTER급 이상에 대한 요건",
  key_498: "미디어",
  key_499: "Forex",
  key_500: "Crypto",
  key_501: "Stock",
  key_502: "곧 출시",
  key_503: "구독하려시면 로그인하세요",
  key_516: "용어 사전",
  key_517: "설명",
  key_518: "Ubot",
  key_519:
    "Ubot는 CFD 시장에서 7년 이상의 경험을 가진 기술 회사가 만든  AI 기술을 기반으로 반 자동 Forex 거래 상품입니다. Ubot은 매월 평균 5-15%의 안정적인 수익으로 Fibonacci에 따라 평일 자동으로 작동합니다.",
  key_520: "FXPrimus",
  key_521:
    "영국, 호주, 싱가포르, 중국, 베트남 등 13년 이상 세계에서 존재해 온 외환, 금속, 에너지, 증권 시장을 전문으로 하는 선물사입니다",
  key_522: "EA (Expert Advisor)",
  key_523:
    "MT4 또는 MT5 거래 플랫폼을 기반으로 사용 가능한 프로그래밍 프로그램에 따라 금융 거래 신호를 제공하거나 거래 주문(주문 종결, 손실 중지, 이익실현 등)을 실행하는 소프트웨어 유형입니다.",
  key_524: "MT4 (MetaTrader4)",
  key_525:
    '간단하고 직관적인 인터페이스를 갖춘 인기있는 온라인 거래 플랫폼입니다. 트레이더는 MT4로 자신만의 자동 트레이딩 전략을 쉽게 만들  수 있습니다. (참고: 금융 업계에서 널리 사용되는 거래 플랫폼인 MetaTrader 4로도 알려진 "MT4")',
  key_526: "BOT 사용 패키지",
  key_527: "사용자가 Bot을 사용하기 위해 지불해야 하는 비용입니다.",
  key_528: "운영자금",
  key_529: "Bot이 거래를 시작하기 위해 사용자가 입금해야 하는 금액입니다",
  key_530:
    "시장이 크게 변동할 때 사용자가 포지션을 유지하기 위해 예치해야 하는 금액입니다.",
  key_531: "예상 수익 ",
  key_532:
    "설치된 거래 시나리오에 따라 Ubot을 사용하는 동안 얻은 예상 수익 값입니다. 예상 수익은 거래 시나리오에 따라 다를 수 있습니다.",
  key_533: "거래 시나리오",
  key_534:
    " 사용자가 자신의 Bot에 적용하도록 선택할 수 있는 시나리오입니다. 봇은 이 시나리오를 사용하여 거래를 진행합니다. 사용자가 선택한 시나리오에 따라 수익과 위험이 달라집니다.",
  key_535: "사용자 설정에 따른 시나리오 제목입니다",
  key_536: "EA 유형",
  key_537:
    "Bot이 거래를 수행할 유형입니다. Buy와 Sell을 모두 선택하는 것을 권장합니다",
  key_538: "사용자가 선택한 통화 쌍입니다. AUD/CAD를 선택하는 것을 권장합니다",
  key_539:
    "최대 매수 주문. Ubot은 사용자에게 10개의 주문을 설정하는 것을 권장합니다. ",
  key_540:
    "최대 매도 주문. Ubot은 사용자에게 10개의 주문을 설정하는 것을 권장합니다. ",
  key_541:
    "Enter market은 시장 진입점이다. 이 란에서 사용자는 도구를 선택할 수 있으며 Bot은 선택한 도구의 신호를 통해 주문 입력을 진행한다. 도구에는 RSI, MACD, Stoch, 상단 및 하단 도구가 포함된다.",
  key_542:
    "Period는 시간 프레임이다. 사용자가 거래 전략에 맞게 선택할 수 있는 시간 프레임이 있다 ( M5 - M15 - M30 - H1 - H4 ).",
  key_543:
    "Ubot은 사용자에게 Next distance를 20핍으로 설정하는 것을 권장합니다.",
  key_544:
    'Profit permonth는 월 익절입니다. 이 란에서 사용자는 월 단위로 기대하는 수익 가치를 선택할 수 있습니다. 월 익절에 도달하면 Bot은 자동으로 주문을 중지합니다. Ubot은 사용자에게 초기 최소 운영 자금의 약 3배에 해당하는 "월 익절"을 설치하도록 권장합니다. 예를 들어, 초기 최소 운영 자금은 $2000면 설치할 "월 익절"은 약 $6000입니다.',
  key_545:
    'Loss permonth는 월 손절입니다. 이 란에서 사용자는 매월 버릴 수 있는 금액을 선택할 수 있습니다. 월 손절에 도달하면 Bot은 자동으로 주문을 중지합니다. Ubot은 사용자에게 초기 최소 운영 자금의 약 3배에 해당하는 "월 손절"을 설치하도록 권장합니다. 예를 들어, 초기 최소 운영 자금은 $2000면 설치할 "월 손절"은 약 $6000입니다.',
  key_546:
    "시장의 하락/상승 추세에 따라 자동으로 조정되는 매수/매도 주문 가격으로 투자자가 예상 범위 내에서 최적의 가격을 달성할 수 있도록 도와주는 매수/매도 주문입니다. Ubot은 사용자에게 트레일링 스탑을 40%로 설정할 것을 권장합니다. 이것은 Ubot 연구에 따르면 안전한 수준입니다.",
  key_547: "이익 실현",
  key_548:
    "이익실현은 실제로 이익을 거둘 수 있는 시점이 되어 거래를 종료한다는 뜻입니다. 특정 가격에 도달했을 때 매도(롱 전략의 경우) 또는 매수(숏 전략의 경우) 주문을 합니다",
  key_549: "시나리오 코드",
  key_550:
    "시나리오 코드. Bot이 거래하는 각 시나리오마다 다른 코드가 부여됩니다.",
  key_551: "핍",
  key_552:
    "핍은 금융 상품의 변동성을 측정하는 단위입니다. 대부분의 통화 쌍은 소수점 이하 4자리이며 pip는 소수점 이하 4번째에 해당합니다. 예를 들어 가격이 14.0000에서 14,0001로 변경되면 가격이 1핍 증가했음을 의미합니다.",
  key_553: "봇 사용 가이드",
  key_554: "패키지를 보시려면 로그인주세요",
  key_555: "Ubot",
  key_556: "가이드",
  key_557: "스폰서",
  key_558: "유효기간",
  key_559: "Secret Formula",
  key_560: "MT4로 봇 연결",
  key_561: "확인 대기",
  key_562: "정보 변경",
  key_563: "봇 선택",
  key_564: " 봇 ID를 선택주세요. 선택된 봇의 수는 5의 배수입니다 (1,5,10).",
  key_565: " 적용 성공",
  key_566: "패키지 디테일",
  key_567: "적용",
  key_568: "문의하기",
  key_569: "개인 계정",
  key_570: "추천 목록",
  key_571: "코드 입력",
  key_572: "Ubot",
  key_573: "저자본, 고수익",
  key_574: "스마트폰만 필요한 투자",
  key_575: "시간 자유, 경제적 자유",
  key_576: "소개",
  key_577:
    "Ubot은 3년간 운영해온 AI 기술 기반의 Forex 트레이딩 상품입니다. Fibonacci에 따라 자동으로 작동하며 인공지능(AI)의 우수성을 결합합니다. AI는 시장 상황에 맞춰 다양한 분석 알고리즘과 지속적인 업데이트로 인공지능을 활용합니다. 100% 이상/년 (약 5-15%/월) 수익을 창출합니다. 따라서 Ubot은 경제적 자유로 가는 가장 빠른 지름길이라고 할 수 있습니다.",
  key_578: " 면책 사항",
  key_579: "지원 센터",
  key_580: "support@ubot.finance",
  key_581: "회사소개",
  key_582: "시장",
  key_583: "교육",
  key_584: " 7년",
  key_585: "금융 업계에서 안정적인 자리를 찾는",
  key_586:
    "Ubot의 발전 팀은 지난 7년 동안 투자자를 위한 안전하고 안정한 거래 상품을 찾는 데 전념했습니다. 발전 팀은 시장 동향을 지속적으로 분석하고 업데이트하는 고급 AI 기술과 정교한 알고리즘을 구현했습니다. 또한 손실을 최소화하고 투자자의 자금을 보호하기 위해 엄격한 위험 관리 시스템을 만들었습니다. Ubot은 개발자들과 많은 투자자들이 오랜 시간동안 찾아 헤매던 결과입니다.",
  key_587: "기회",
  key_588:
    "외환은 전 세계 통화 교환을 위한 탈 중앙화 시장입니다. 매일 수조 달러가 거래되는 세계 최대의 금융 시장입니다. 외환 시장을 통해 개인, 은행, 기업 및 정부는 여행, 무역 및 투자와 같은 다양한 목적으로 통화를 교환할 수 있습니다. 외환 거래는 환율 변동으로부터 수익을 얻기 위해 하나의 통화를 구입하고 다른 통화를 판매하는 것을 포함합니다. 외환 시장은 주말과 공휴일을 제외하고 연중무휴로 운영됩니다. 또한 외환 시장의 일일 거래량은 약 6조 달러로 암호화폐 시장과 나스닥 시장을 합친 것보다 훨씬 큽니다. 이러한 규모와 유동성은 환율 변동에서 수익을 얻을 기회를 찾는 트레이더와 투자자에게 외환 시장을 매우 매력적으로 만듭니다.",
  key_589: "경험",
  key_590:
    "금융시장에서 상당한 경험을 쌓은 Ubot은 3년 넘게 자사 상품을 시험해 왔으며 2000명 이상의 투자자가 상품을 신뢰하고 사용하고 있습니다. 사용자들의 긍정적인 피드백은 Ubot팀이 상업적으로 높은 기준을 충족하기 위해 자사의 상품을 개선하도록 하는 원동력이 되었습니다.",
  key_591:
    "신뢰할 수 있고 효율적인 거래 도구로 떠오르며 지속적인 수동적 수입원을 제공하는 Ubot은 지속적으로 연간 100% 이상의 수익을 창출하고 있습니다. Ubot의 고급 AI 기술과 정교한 알고리즘은 끊임없이 변화하는 시장 상황에 적응하기 위해 지속적으로 업데이트되어 투자자에게 보다 안전하고 안정한 거래 도구를 제공합니다.",
  key_592:
    "Ubot의 성공은 주로 고객 만족을 보장하고 고품질 거래 상품을 제공하겠다는 확고한 약속에서 비롯됩니다.",
  key_593: "23/5 -  FOMO 제거",
  key_594:
    "Ubot의 Auto Trade 23/5는 금융 시장의 다른 거래 상품과 차별화되는 고유한 기능입니다. Auto Trade 23/5를 사용하면 트레이더는 더 이상 FOMO 요소나 감정적 실수에 대해 걱정할 필요가 없습니다. 이 기능을 통해 Ubot은 사람의 개입 없이 지속적으로 작동하여 투자자에게 원활하고 안정적인 거래 체험을 제공합니다. Ubot의 Auto Trade 23/5는 첨단 AI 기술과 복잡한 알고리즘을 사용하여 거래자들이 놓치거나 감정에 영향을 받는 것을 두려워하지 않고 모든 거래 기회를 활용할 수 있도록 보장합니다.",
  key_595: "자본 관리",
  key_596:
    "효과적인 자본 관리는 금융 시장에서 안정적인 수익을 달성하는 Ubot의 성공 요인입니다. Ubot의 우수한 자본 관리 시스템은 투자자가 매월 약 5-15%의 안정적인 수동적 수익을 올릴 수 있도록 보장합니다. 이는 엄격한 위험 관리 정책을 구현하고 고급 알고리즘을 사용하여 시장 동향을 실시간으로 분석하고 조정함으로써 달성됩니다. Ubot의 자금 관리 시스템을 통해 투자자들은 자신의 돈이 잘 관리되고 있다고 믿을 수 있으며 투자로부터 안정적인 수입원을 누릴 수 있습니다.",
  key_597: "신뢰",
  key_598:
    "Ubot은 전 세계적으로 평판이 좋은 업체과의 파트너십을 맺고 금융 시장에서 신뢰성으로 명성을 얻었습니다.",
  key_599:
    "고객센터에 연락하는 가장 편리한 방식을 선택해주세요. 이메일이나 온라인 채팅을 통해 문의해도 됩니다. 경험이 풍부한 전문가가 플랫폼에 대한 질문에 답변해 드립니다. 지원 시스템은 연중무휴(연중 공휴일 제외) 이용 가능합니다.",
  key_600: "회원가입 ",
  key_601: "금속",
  key_602: "주식 거래",
  key_603: "선물",
  key_604: "왜 Ubot인가?",
  key_605: "자료",
  key_606: "거래 통계",
  key_607: "파트너 되기",
  key_608: "자주 묻는 질문",
  key_609: "입금 및 출금",
  key_610: "프로모션 및 콘테스트",
  key_611: "친구 추천",
  key_612: "자산",
  key_613: "초보자를 위한 가이드",
  key_614: "Ubot 사전",
  key_615: "시장 분석",
  key_616: "트레이딩 교육",
  key_617: "이용약관",
  key_618: "도움 받기",
  key_619:
    "Ubot은 CFD 시장에서 7년 이상의 경험을 가진 기술 회사가 만든  AI 기술을 기반으로 자동 Forex 거래 상품이다. Ubot은 매월 평균 5-15%의 안정적인 수익으로 Fibonacci에 따라 평일 자동으로 작동한다.",
  key_620: "FPMarkets",
  key_621:
    "FPMarkets는 2005년부터 설립되고 규제받고 있는 Forex 및 CFD 브로커이다. 총 40회 이상 글로벌 수상을 한만큼 안정성과 신용도를 인정받은 회사이기도 한다. 뿐만아니라 20년도 호주 탑 포렉스 브로커로 선정되기도 했다.",
  key_622: "FXPrimus",
  key_623:
    "FXPrimus는 영국, 호주, 싱가포르, 중국, 베트남 등 13년 이상 세계에서 존재해 온 외환, 금속, 에너지, 증권 시장을 전문으로 하는 거래소이다",
  key_624: "EA (Expert Advisor)",
  key_625:
    "MT4 또는 MT5 거래 플랫폼을 기반으로 사용 가능한 프로그래밍 프로그램에 따라 금융 거래 신호를 제공하거나 거래 주문(주문 종결, 손실 중지, 이익실현 등)을 실행하는 소프트웨어 유형이다.",
  key_626:
    "간단하고 직관적인 인터페이스를 갖춘 인기있는 온라인 거래 플랫폼이다. 트레이더는 MT4로 자신만의 자동 트레이딩 전략을 쉽게 생성할 수 있다.",
  key_627: "BOT 비용",
  key_628: "사용자가 Bot을 사용하기 위해 지불해야 하는 비용이다.",
  key_629: "투자자금",
  key_630: "Bot이 거래를 시작하기 위해 사용자가 입금해야 하는 금액이다",
  key_631:
    "시장이 크게 변동할 때 사용자가 포지션을 유지하기 위해 예치해야 하는 금액이다.",
  key_632:
    "설치된 거래 시나리오에 따라 Ubot을 사용하는 동안 얻은 예상 수익 값이다. 예상 수익은 거래 시나리오에 따라 다를 수 있다.",
  key_633: "거래 시나리오",
  key_634:
    "사용자가 자신의 Bot에 적용하도록 선택할 수 있는 시나리오이다. 봇은 이 시나리오를 사용하여 거래를 진행한다.",
  key_635: "Bot이 거래를 수행할 유형이다.",
  key_636: "사용자가 거래하기로 선택한 통화 쌍이다.",
  key_637: "최대 매수 주문이다",
  key_638: " 최대 매도 주문이다",
  key_639: "트레일링 스탑",
  key_640:
    "시장의 하락/상승 추세에 따라 자동으로 조정되는 매수/매도 주문 가격으로 투자자가 예상 범위 내에서 최적의 가격을 달성할 수 있도록 도와주는 매수/매도 주문이다.",
  key_641: "1. 거래 기술만을 제공합니다. Uot은 투자 신탁 서비스가 아닙니다.",
  key_642:
    "2. 거래 시 인터넷 연결이 안정적이지51 확인해주세요. 인터넷 연결이 끊기면 주문이 전송되지 않습니다.",
  key_643:
    "3. 정전, 기계 고장과 같은 드문 경우의 위험에 대비하기 위해 거래 통제를 고려해주세요. Ubot의 민감성 때문에 봇은 때때로 시장의 잘못된 정보에 의해 영향을 받을 수 있습니다.",
  key_644:
    "4. 광고뉴스와 프로젝트에 참여하지 않은 사람들의 판단을 따라하지 마십시오. 특히 Ubot 프로젝트에 소속되어 있다고 자임하는 관리자들의 말입니다.",
  key_645: "5. 월 평균 수익률은 5~15%로 추정됩니다.",
  key_646: "6. 관리자가 먼저 메시지를 보내지 않습니다.",
  key_647:
    "7. 지원이 필요하시거나 문의 사항이 있으시면 즉시 Ubot 지원 부서로 연락해 주세요. 월요일부터 금요일까지 23시간 동안 지속적으로 지원하도록 하겠습니다.",
  key_648:
    "8. Bot의 운용 시간과 업무 시간 외에 어떠한 거래 위험에 대해 책임지지 않습니다.",
  key_649: "9. 검증되지 않은 투자 조언을 절대 따르지 마십시오.",
  key_650:
    "10. Bot을 설치한 후 MT4 비밀번호를 변경하시면 안 됩니다. 비밀번호가 변경된 경우 봇은 작동을 중지할 것입니다.",
  key_651: "11. 봇이 거래할 수 있으려면 거래소에서 KYC를 완료해야 합니다.",
  key_652:
    "12. 계정에 대한 무단 액세스로 인한 어떠한 손해에 대해서는 책임지지 않습니다.",
  key_653: "트랜스퍼",
  key_654: "수신자 이메일을 입력해주세요",
  key_655: "트랜스퍼 확인",
  key_656: "트랜스퍼 성공",
  key_657: "이메일로",
  key_658: "봇 바로 구매",
  key_659: "PitchDeck English",
  key_660: "PitchDeck Korean",
  key_661: "PitchDeck Vietnamese",
  key_662: "PitchDeck Chinese",
  key_663:
    "계정 확인은 8-24시간이 소요되며, 알림 이메일을 수신하신 후 다시 돌아오시기 바랍니다.",
  key_664:
    "Ubot은 MetaTrade 4(MT4) 거래 플랫폼에서 고객에게 거래 소프트웨어를 제공하는 금융 서비스 회사입니다. 투자 신탁 플랫폼이 아닙니다. 외환 시장에 진입하기로 결정하기 전에 투자 목표, 경험 수준 및 위험 허용 수준을 신중하게 고려해야 합니다. 가장 중요한 것은 잃을 수 없는 돈을 투자하지 않는 것입니다. 완전한 민사 능력을 갖춘 성인만이 이 웹사이트에서 제공하는 거래를 수행할 수 있습니다.",
  key_665: "양수 값이어야 합니다.",
  key_666: "수신자",
  key_667: "트랜스퍼 시간",
  key_668: "수신자 아이디",
  key_669: "수신자 이메일",
  key_670: "봇 트랜스퍼 내역으로 이동",
  key_671: "검색 ",
  key_672: "봇 트랜스퍼 내역",
  key_673: "필터 표시",
  key_674: "전화 번호",
  key_675: "발신자",
  key_676: "외환 거래 시작",
  key_677: "여러 통화 쌍, USD, 금속... 등으로 외환 거래 시작",
  key_678: "AUD/CAD 통화 쌍으로 거래 시작",
  key_679: "GBP/USD 통화 쌍 테스트",
  key_680: "BW BOT 시작",
  key_681: "Fxprimus 거래소와 제휴",
  key_682: "Ubot 알파 버전",
  key_683: "GBP/USD 통화 쌍 테스트",
  key_684: "글로벌 시장에서 Ubot.at 출시",
  key_685: "GBP/USD 통화 쌍으로 거래 시작",
  key_686: "FPMarkets 거래소와 제휴",
  key_687: "새로운 거래 통화 쌍 베타 버전 테스트",
  key_688: "다른 거래소와 제휴",
  key_689: "Ubot 토큰, NFT, 백서 출시",
  key_690: "Ubot 토큰 상장, NFT 판매 계획",
  key_691: "모바일 앱 ver1 출시",
  key_692: "새로운 통화 쌍 및 새로운 패키지 적용",
  key_693: "AI 기반 크립토 거래 서비스 및 더 많은 신상품 출시",
  key_694: "로드",
  key_695: "맵",
  key_696: "지원을 위해 QR 코드를 스캔하여 텔레그램 그룹에 가입해주세요.",
  key_697: "베트남어",
  key_698:
    "Bot을 설치한 후 MT4 비밀번호를 변경하시면 안 됩니다. 비밀번호가 변경된 경우 봇은 작동을 중지할 것입니다",
  key_699: "비밀번호 변경",
  key_700: "이름",
  key_701: "전송 성공",
  key_702: "토큰",
  key_703: "유연",
  key_704: "고정",
  key_705: "스테이킹 내역",
  key_706: "수익 받기 성공",
  key_707: "수익 받기 실패",
  key_708: "언스테이킹 성공",
  key_709: "언스테이킹 실패",
  key_710: "개월",
  key_711: "적립",
  key_712: "락업 기간",
  key_713: "숨기",
  key_714: "디테일",
  key_715: "스테이킹 금액",
  key_716: "가입 시간",
  key_717: "종료 시간",
  key_718: "현재 수익",
  key_719: "수익 받기",
  key_720: "언스테이킹",
  key_721: "스테이킹 금액",
  key_722: "현재 수익",
  key_723: "수익 받기",
  key_724: "수익으로 언스테이킹",
  key_725: "수익 받기",
  key_726: "수익 받기 성공",
  key_727: "언스테이킹 실패",
  key_728: "예상 수익 ",
  key_729: "연간 수익률",
  key_730: "스테이커",
  key_731: "USDT 가격",
  key_732: "스테이킹 풀",
};

export default ko;
