import { Avatar, Card, CardHeader, Collapse, Container, IconButton, Stack, Typography, } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/bitcoin-trading-bot.json'
import Image from '../../components/Image';
import Title from './Title';
import TrackVisibility from "react-on-screen";

// ----------------------------------------------------------------------

export default function Partners({ ...other }) {
    const theme = useTheme()
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const [showMore, setShowMore] = useState(false)

    return (<ContainerStyle {...other} py='10rem !important'>
        <Container>
            <TrackVisibility offset={100} partialVisibility>
                {
                    ({ isVisible }) => <Title isVisible={isVisible} title={langLibrary.key_333} />
                }
            </TrackVisibility>
        </Container>
        <Container sx={{ position: 'relative', zIndex: 1, mt: 5 }}>
            <Stack direction={'row'} spacing={20} justifyContent='center' alignItems='center'>
                <Image
                    onClick={() => { window.open("https://portal.fpmarkets.com/int-EN/register?fpm-affiliate-utm-source=IB&fpm-affiliate-agt=21234") }}
                    src='/images/logo_BW.png'
                    sx={{ height: '100px', width: 'auto', maxWidth: 300, cursor: "pointer" }}
                    objectFit="contain" />
                <Image
                    onClick={() => { window.open("https://clients.fxprimus.com/en/register?ref=50013221") }}
                    src='/images/logo_FX.png'
                    sx={{ height: '50px', width: 'auto', maxWidth: 300, cursor: "pointer" }}
                    objectFit="contain" />
            </Stack>
        </Container>
    </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    [theme.breakpoints.down('md')]: {
        '& h3': {
            fontSize: '1.2rem'
        }
    }
}));