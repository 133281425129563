// @mui
import {
  Card,
  Divider,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// icon
import moment from "moment";
import { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import SearchNotFound from "../../components/SearchNotFound";
import { useResponsive } from "../../hooks";
import { post } from "../../utils/api";
import { fCurrency, fDate } from "../../utils/format";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
import Label from "../../components/Label";

const CommissionTypeDes = {
  DIRECT_COMMISSION: "key_482",
  NORMAL_SHARED_REVENUE_INCOME: "key_483",
  NORMAL_SHARED_VOLUME_INCOME: "key_484",
  SENIOR_SHARED_REVENUE_INCOME: "key_485",
  SENIOR_SHARED_VOLUME_INCOME: "key_486"
}

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  marginTop: '2rem'
}));

// ----------------------------------------------------------------------

export default function CommissionList({ ...other }) {
  const isDesktop = useResponsive("up", "sm");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;


  const TABLE_HEAD_DESKTOP = [
    {
      label: "key_93", //Date
      alignRight: false,
      render: (row) => moment(row.createdTime).format("YYYY-MM-DD HH:mm"),
    },
    {
      label: "key_491", //Amount
      alignRight: false,
      render: (row) => `${fCurrency(row.amount, 2)} ${row.currency}`,
    },
    {
      label: "key_15",
      alignRight: false,
      render: (row) => <RenderFromRef data={row} />,
    },
    {
      label: "key_104", //Direct
      alignRight: false,
      render: (row) => langLibrary[CommissionTypeDes[row.type]],
    },
  ];

  const TABLE_HEAD_MOBILE = [
    {
      render: (row, langLibrary) => (
        <Stack>
          <Typography sx={{ opacity: 0.6 }}>{fDate(row.createdTime)}</Typography>
          <Typography>{langLibrary[CommissionTypeDes[row.type]]}</Typography>
          <Stack direction='row' sx={{ opacity: 0.6 }}>

          </Stack>
        </Stack>
      )
    },
    {
      render: (row) => <Stack>

        <Typography textAlign='right' sx={{ fontSize: '1rem !important' }}>{fCurrency(row.amount, 2)} </Typography>
        <Typography textAlign='right' sx={{ opacity: 0.6 }}>{row.currency}</Typography>
      </Stack>
    },
    {
      sx: {
        gridArea: "end !important",
      },
      render: (row) => <Stack direction={"row"}>
        <Typography mr={0.5}>{langLibrary.key_15}: </Typography>
        <Typography sx={{ opacity: 0.6 }} textAlign='right' ></Typography>
        <RenderFromRef data={row} />
      </Stack>
    }
  ];

  const handleGetHistory = () => {
    let params = {
      page: page + 1,
      pageSize: pageSize,
      search: "",
      orderBy: "",
      isDesc: true,
      returnStatistic: false,
      filters: {
        // CreatedTimeFrom: fromDate ? moment(fromDate).utc().unix() * 1000 : 0,
        // CreatedTimeTo: toDate ? moment(toDate).utc().unix() * 1000 : 0,
      },
    };
    setLoading(true);
    post(
      `/affiliate/my-com-txs`,
      params,
      (result) => {
        setData(result);
        setLoading(false);
      },
      () => {
        setLoading(false);
        setData({ items: [] });
      }
    );
  };

  useEffect(() => {
    handleGetHistory();
  }, [page, pageSize]);

  useEffect(() => {
    if (moment(fromDate).isValid()) {
      handleGetHistory();
    }
  }, [fromDate]);

  useEffect(() => {
    if (moment(toDate).isValid()) {
      handleGetHistory();
    }
  }, [toDate]);

  const TABLE_HEAD = isDesktop ? TABLE_HEAD_DESKTOP : TABLE_HEAD_MOBILE;

  return (
    <CardStyle>
      <Typography variant="h6" textAlign={{ xs: "center", sm: "left" }}
        padding={{ xs: "1rem 0px", sm: "1rem 1.5rem" }}>{langLibrary.key_280}</Typography>
      <Divider />
      <Stack padding={{ xs: "1rem", md: 0 }}>
        <CustomTable
          loading={loading}
          tableHead={
            <TableRow>
              {TABLE_HEAD.map((item, index) => (
                <TableCell key={index} sx={item.sx}>
                  {langLibrary[item.label]}
                </TableCell>
              ))}
            </TableRow>
          }
          tableBody={
            <>
              {data.items &&
                data.items.map((item, index) => (
                  <TableRow key={index}>
                    {TABLE_HEAD.map((e, j) => (
                      <TableCell key={j} sx={e.sx}>
                        {e.render
                          ? e.render(item, langLibrary)
                          : item[e.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {!loading && data.items && data.items.length === 0 && (
                <TableRow sx={{ '&.MuiTableRow-root': { display: !isDesktop ? "flex" : "", justifyContent: "center" } }}>
                  <TableCell align="center" colSpan={20} sx={{ py: 15 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              )}
            </>
          }
          tablePagination={
            data && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                labelRowsPerPage={langLibrary.key_304}
                component="div"
                count={data.itemCount ? data.itemCount : 0}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, newPage) => {
                  setPage(newPage);
                }}
                onRowsPerPageChange={(e) => setPageSize(e.target.value)}
              />
            )
          }
        />
      </Stack>
    </CardStyle >
  );
}

const RenderFromRef = ({ data }) => {
  return data.type === "DIRECT_COMMISSION" ? <Stack direction='row' alignItems='center' spacing={1}>
    <Label>F{data.downlineLevel}</Label>
    <Typography sx={{
      maxWidth: 240,
      overflow: "hidden",
      textOverflow: "hidden",
      whiteSpace: "nowrap"
    }}>{data.downlineEmail}</Typography>
  </Stack> : '--'
}
