import { LoadingButton } from '@mui/lab';
import { Avatar, Button, Card, CardHeader, Collapse, Divider, Grid, Link, styled, TableCell, TablePagination, TableRow, Typography, useTheme } from '@mui/material';
import { Stack, } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Iconify from '../../components/Iconify';
import Label from '../../components/Label';
import Popup from '../../components/Popup';
import { CoinImage } from '../../settings/constants';
import { get, post } from '../../utils/api';
import { fCurrency, fDateTime, fDateTimeSuffix } from '../../utils/format';
import { Link as RouterLink } from "react-router-dom";
import CustomTable from '../../components/CustomTable';
import { useResponsive } from '../../hooks';
import SearchNotFound from '../../components/SearchNotFound';





// ----------------------------------------------------------------------


export default function StackList() {
    const { app, user } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const [products, setProducts] = useState([])
    const [showHistory, setShowHistory] = useState(false)
    const handleGetProduct = () => {
        const params = {
            "page": 1,
            "pageSize": 100,
        }
        post('/staking/txs', params, result => {
            setProducts(result.items)
        })
    }
    useEffect(() => {
        handleGetProduct()
    }, [])

    return (
        <Stack spacing={2} mt={5} justifyContent='center' alignItems={'center'}>
            <Stack direction={'row'} spacing={1} alignItems='center' justifyContent={'flex-end'} width={'90vw'} maxWidth="930px">
                <Button onClick={() => setShowHistory(true)} sx={{ px: 2, color: "#fff" }} startIcon={<Iconify icon="icon-park-solid:receive" />}>{langLibrary.key_705}</Button>
            </Stack>
            {
                products.map((item, index) => <CustomCard key={index} >
                    <CardContent data={item} onSuccess={handleGetProduct} />
                </CustomCard>)
            }
            <Popup open={showHistory} title={langLibrary.key_705} contentStyle={{ width: "85vw", maxWidth: 900 }} onClose={() => setShowHistory(false)}>
                <RedeemHistory />
            </Popup>
        </Stack>
    );
}

const CardContent = ({ data, onSuccess }) => {
    const theme = useTheme()
    const { app } = useSelector((state) => state);
    const { langLibrary } = app;
    const [showDetail, setShowDetail] = useState(false)
    const [showRedeemConfirm, setShowRedeemConfirm] = useState(false)
    const [showUnstakeConfirm, setShowUnstakeConfirm] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const isMobile = useResponsive('down', 'md')

    const handleRedeem = (callback) => {
        setShowRedeemConfirm(false)
        setSubmitting(true)
        const params = {
            "stakingTxId": data.id
        }
        post('/staking/redeem', params, () => {
            toast.success(langLibrary.key_706)
            setSubmitting(false)
            onSuccess()
            if (callback) callback()
        }, () => {
            toast.error(langLibrary.key_707)
            setSubmitting(false)
        })
    }

    const handleUnStack = () => {
        setShowUnstakeConfirm(false)
        handleRedeem(() => {
            setSubmitting(true)
            post(`/staking/un-stake?stakingId=${data.id}`, null, () => {
                toast.success(langLibrary.key_708)
                setSubmitting(false)
                onSuccess()
            }, () => {
                toast.error(langLibrary.key_709)
                setSubmitting(false)
            })
        })
    }

    return <>
        <Stack direction={isMobile ? 'column' : 'row'} width={'100%'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'}>
            <Stack width={'100%'} direction={isMobile ? 'column' : 'row'} flexGrow={1} spacing={4} alignItems='center'>
                <Stack width={isMobile ? '100%' : "20%"} spacing={isMobile ? 2 : 0} justifyContent={isMobile ? 'flex-end' : 'center'} direction={isMobile ? 'row-reverse' : 'column'}>
                    <Stack direction={'row'} spacing={1} alignItems='center'>
                        <Label sx={{ width: 'fit-content', opacity: 0.6 }}>{data.productType}</Label>
                        <Label color={data.status === "STAKING" ? 'success' : 'error'} sx={{ width: 'fit-content', opacity: 0.6 }}>{data.status}</Label>
                    </Stack>
                    <Typography variant='h6'>{data.expiredMonth} {langLibrary.key_710} #{data.id}</Typography>
                </Stack>
                <Stack width={'100%'} direction={'row'} alignItems="flex-start" justifyContent={isMobile ? 'space-between' : 'space-around'}>
                    <CardHeader
                        sx={{ width: isMobile ? '100%' : '15%' }}
                        title={<Typography sx={{ opacity: 0.6 }}>{langLibrary.key_711}</Typography>}
                        subheader={<Typography variant='h6'>{fCurrency(data.totalProfit, 2)} USDT</Typography>}
                    />
                    <CardHeader
                        sx={{ width: isMobile ? '100%' : '15%' }}
                        title={<Typography sx={{ opacity: 0.6 }}>APR</Typography>}
                        subheader={<Typography variant='h6'>{fCurrency(data.apr, 2)}%</Typography>}
                    />
                    <CardHeader
                        sx={{ width: isMobile ? '100%' : '15%' }}
                        title={<Typography noWrap sx={{ opacity: 0.6 }}>{langLibrary.key_712}</Typography>}
                        subheader={<Typography variant='h6'>{fCurrency(data.expiredMonth, 2)} {langLibrary.key_710}</Typography>}
                    />
                </Stack>
            </Stack>
            {
                <Button onClick={() => setShowDetail(!showDetail)} sx={{ width: isMobile ? 0 : 100, color: showDetail ? 'red' : theme.palette.text.default, position: isMobile ? 'absolute' : 'relative', top: 10, right: 5 }} endIcon={<Iconify sx={{ width: 30, height: 30 }} icon={showDetail ? 'material-symbols:arrow-drop-up' : 'material-symbols:arrow-drop-down'} />}>{isMobile ? "" : (showDetail ? langLibrary.key_713 : langLibrary.key_714)}</Button>
            }
        </Stack>
        <Collapse in={showDetail}>
            <Divider sx={{ mt: 2 }} />
            <Grid container mt={2}>
                <Grid item xs={6}>
                    <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <Typography sx={{ opacity: 0.6 }}>{langLibrary.key_715}</Typography>
                        <Typography >{fCurrency(data.amount, 2)} USDT</Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <Typography sx={{ opacity: 0.6 }}>{langLibrary.key_716}</Typography>
                        <Typography >{fDateTime(data.startTime)}</Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <Typography sx={{ opacity: 0.6 }}>{langLibrary.key_717}</Typography>
                        <Typography >{fDateTime(data.expiredTime)}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6} >
                    <Stack alignItems={'flex-end'}>
                        <CardHeader
                            title={<Typography noWrap textAlign={'right'} sx={{ opacity: 0.6 }}>{langLibrary.key_718}</Typography>}
                            subheader={<Typography noWrap textAlign={'right'} variant='h6'>{fCurrency(data.currProfit, 2)} {langLibrary.key_710}</Typography>}
                        />
                        <Stack spacing={2} mt={1} direction={'row'} alignItems='center'>
                            {
                                data.allowReceiveProfit && <LoadingButton loading={submitting} onClick={() => setShowRedeemConfirm(true)} variant='contained' sx={{ background: '#d9d9d9' }}>
                                    {langLibrary.key_719}
                                </LoadingButton>
                            }
                            {
                                data.allowUnstake && <LoadingButton loading={submitting} onClick={() => setShowUnstakeConfirm(true)} variant='contained' sx={{ background: '#d9d9d9' }}>
                                    {langLibrary.key_720}
                                </LoadingButton>
                            }
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Collapse>
        <RedeemConfirming data={data} open={showRedeemConfirm} onClose={() => setShowRedeemConfirm(false)} onSubmit={handleRedeem} />
        <UnstakeConfirming data={data} open={showUnstakeConfirm} onClose={() => setShowUnstakeConfirm(false)} onSubmit={handleUnStack} />

    </>
}

const RedeemConfirming = ({ open, onClose, data, onSubmit }) => {
    const { app } = useSelector((state) => state);
    const { langLibrary } = app;
    return <>
        <Popup open={open} onClose={onClose}>
            {
                data && <Stack sx={{ minWidth: 400 }} spacing={1}>
                    <Typography textAlign={'center'} sx={{ opacity: 0.6 }}>{langLibrary.key_721}</Typography>
                    <Stack direction={'row'} spacing={1} alignItems='center' justifyContent={'center'}>
                        <Avatar src={CoinImage.USDT} sx={{ width: 25, height: 25 }} />
                        <Typography textAlign={'center'} variant='h5'>{fCurrency(data.amount, 2)} USDT</Typography>
                    </Stack>
                    <Typography textAlign={'center'} sx={{ opacity: 0.6 }} pt={2}>{langLibrary.key_722}</Typography>
                    <Stack direction={'row'} spacing={1} alignItems='center' justifyContent={'center'}>
                        <Avatar src={CoinImage.USDT} sx={{ width: 30, height: 30 }} />
                        <Typography textAlign={'center'} variant='h4' sx={{ color: theme => theme.palette.text.default }}>{fCurrency(data.currProfit, 2)} USDT</Typography>
                    </Stack>
                    <Typography textAlign={'center'} sx={{ opacity: 0.6 }} pt={2}>{langLibrary.key_102}: {fDateTime(data.startTime)} - {fDateTime(data.expiredTime)}</Typography>
                </Stack>
            }
            <Button onClick={onSubmit} variant="contained" fullWidth height={30} sx={{ fontSize: '1.3rem', mt: 3, background: '#d9d9d9' }}>{langLibrary.key_723}</Button>
        </Popup>
    </>
}
const UnstakeConfirming = ({ open, onClose, data, onSubmit }) => {
    const { app } = useSelector((state) => state);
    const { langLibrary } = app;
    return <>
        <Popup open={open} onClose={onClose}>
            {
                data && <Stack sx={{ minWidth: 400 }} spacing={1}>
                    <Typography textAlign={'center'} sx={{ opacity: 0.6 }}>{langLibrary.key_721}</Typography>
                    <Stack direction={'row'} spacing={1} alignItems='center' justifyContent={'center'}>
                        <Avatar src={CoinImage.USDT} sx={{ width: 25, height: 25 }} />
                        <Typography textAlign={'center'} variant='h5'>{fCurrency(data.amount, 2)} USDT</Typography>
                    </Stack>
                    <Typography textAlign={'center'} sx={{ opacity: 0.6 }} pt={2}>{langLibrary.key_722}</Typography>
                    <Stack direction={'row'} spacing={1} alignItems='center' justifyContent={'center'}>
                        <Avatar src={CoinImage.USDT} sx={{ width: 30, height: 30 }} />
                        <Typography textAlign={'center'} variant='h4' sx={{ color: theme => theme.palette.text.default }}>{fCurrency(data.currProfit, 2)} USDT</Typography>
                    </Stack>
                    <Typography textAlign={'center'} sx={{ opacity: 0.6 }} pt={2}>{langLibrary.key_102}: {fDateTime(data.startTime)} - {fDateTime(data.expiredTime)}</Typography>
                </Stack>
            }
            <Button onClick={onSubmit} variant="contained" fullWidth height={30} sx={{ fontSize: '1.3rem', mt: 3, background: '#d9d9d9' }}>{langLibrary.key_724}</Button>
        </Popup>
    </>
}

const RedeemHistory = () => {
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const [data, setData] = useState(null)
    const [statistic, setStatistic] = useState(null)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [loading, setLoading] = useState(false)
    const isMobile = useResponsive('down', 'sm')
    const handleGetData = () => {
        const params = {
            "page": page + 1,
            "pageSize": pageSize,
            "search": "",
            "orderBy": "",
            "isDesc": true,
            "returnStatistic": false,
            "filters": {
            }
        }
        post('/staking/redeem/history', params, result => {
            setData(result.items)
            setStatistic({ itemCount: result.itemCount })
        })
    }
    useEffect(() => {
        handleGetData()
    }, [])
    const TABLE_HEAD_DESKTOP = [
        {
            label: "ID", render: row => `#${row.id}`
        },
        {
            label: langLibrary.key_725, render: row => `${fCurrency(row.receivedProfit, 2)} USDT`
        },
        {
            label: langLibrary.key_102, render: row => `${fDateTime(row.createdTime)}`
        },
    ];
    const TABLE_HEAD_MOBILE = [
        {
            sx: { gridArea: 'end' },
            render: row => (
                <Stack direction='row' py={1} justifyContent='space-between'>
                    <Stack>
                        <Stack direction={"row"}><Label>#{row.id}</Label></Stack>
                        <Typography variant='caption' >{fDateTime(row.createdTime)}</Typography>
                    </Stack>
                    <Stack>
                        <Typography textAlign={'right'} variant='caption' sx={{ opacity: 0.6 }}>{fDateTime(row.createdTime)}</Typography>
                        <Typography
                            textAlign={'right'}
                            variant='caption' >{fCurrency(row.receivedProfit, 2)} USDT</Typography>
                    </Stack>
                </Stack>
            )
        },
    ]
    const TABLE_HEAD_SUB = isMobile ? TABLE_HEAD_MOBILE : TABLE_HEAD_DESKTOP

    return (data ? <CustomTable
        loading={loading}
        tableHead={
            <TableRow>
                {TABLE_HEAD_SUB.map((item, index) => (
                    <TableCell sx={{ ...item.sx }} key={index}>{item.label}</TableCell>
                ))}
            </TableRow>
        }
        minHeight={110}
        minWidth={500}
        smMinWidth={500}
        tableBody={
            <>

                {data &&
                    data.map((item, index) => (
                        <TableRow key={index}>
                            {TABLE_HEAD_SUB.map((e, j) => (
                                <TableCell key={j} sx={{ ...e.sx }}>
                                    {
                                        e.render
                                            ? e.render(item, e.label)
                                            : item[e.id]
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                {!loading && data.length === 0 && (
                    <TableRow sx={{ '&.MuiTableRow-root': { display: isMobile ? "flex" : "", justifyContent: "center" } }}>
                        <TableCell align="center" colSpan={20} sx={{ py: 3 }}>
                            <SearchNotFound />
                        </TableCell>
                    </TableRow>
                )}
            </>
        }
        tablePagination={
            statistic && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={statistic.itemCount ? statistic.itemCount : 0}
                    rowsPerPage={pageSize}
                    labelRowsPerPage={langLibrary.key_304}
                    page={page}
                    onPageChange={(e, newPage) => {
                        setPage(newPage);
                    }}
                    onRowsPerPageChange={(e) => setPageSize(e.target.value)}
                />
            )
        }
    /> : null)
}
const CustomCard = styled(Card)(({ theme }) => ({
    background: theme.palette.background.paper,
    boxShadow: '0 1rem 1rem -0.75rem rgb(0 0 0 / 5%)',
    borderRadius: '15px',
    height: "100%",
    width: '100%',
    maxWidth: '900px',
    margin: "auto",
    padding: '1rem',
    [theme.breakpoints.down('lg')]: {
        marginTop: '1rem'
    }
}));
