// @mui
import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MotionViewport } from "../components/animate";
import Iconify from "../components/Iconify";
import Page from "../components/Page";
import DownLineList from "../sections/profile/DownLineList";

// ----------------------------------------------------------------------

export default function Team() {
  const { user } = useSelector((state) => state);
  const { profile } = user ? user : {};
  const [totalMember, setTotalMember] = useState(0);
  const [showFilter, setShowFilter] = useState(true);
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;

  if (!profile) return null;

  return (
    <MotionViewport>
      <Page title={langLibrary.key_28}>
        <Grid container width="100%">
          <Grid item xs={12}>
            <Stack
              spacing={2}
              mb={10}
            >
              <Typography sx={{ flexGrow: 1, fontSize: '2rem', color: "#fff" }}>
                {langLibrary.key_28}
              </Typography>
              <Typography sx={{ marginTop: '-0.5rem !important', color: "#fff" }}>
                {langLibrary.key_45}: {totalMember}
              </Typography>
            </Stack>
            <DownLineList
              showFilter={showFilter}
              downlineId={profile.id}
              onLoaded={(totalMember) => setTotalMember(totalMember)}
            />
          </Grid>
        </Grid>
      </Page>
    </MotionViewport>
  );
}
