import * as Yup from "yup";
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../components/Popup';
import { _triggerPartnerForm } from '../store/actions/appActions';
import { useFormik, Form, FormikProvider } from "formik";
import { useState } from "react";
import { TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { post } from "../utils/api";
import { toast } from "react-toastify";
import { useResponsive } from "../hooks";

// ----------------------------------------------------------------------


export default function PartnerForm() {
    const dispatch = useDispatch()
    const { app } = useSelector(state => state)
    const { triggerPartnerForm, langLibrary } = app ? app : {}
    const [isSubmitting, setIsSubmitting] = useState(false)
    const isMobile = useResponsive('down', 'sm')

    const PartnerSchema = Yup.object().shape({
        name: Yup.string().required().trim(),
        phone: Yup.number().required(),
        email: Yup.string().email(langLibrary.key_46).required(langLibrary.key_47).trim(),
        note: Yup.string().nullable()
    })
    const formik = useFormik({
        initialValues: {
            name: "",
            phone: "",
            email: "",
            note: "",
        },
        validationSchema: PartnerSchema,
        onSubmit: () => {
            handleSubmitPartner(formik.values);
        },
    })
    const handleSubmitPartner = (values) => {
        setIsSubmitting(true);
        const params = {
            name: values.name.trim(),
            phone: values.phone.trim(),
            email: values.email.trim(),
            note: values.note.trim()
        }
        post(`/partner/request`, params,
            () => {
                setIsSubmitting(false)
                toast.success(langLibrary.key_701)
                formik.resetForm()
                handleClose()
            },
            () => {
                setIsSubmitting(false)
                formik.resetForm()
            }
        )

    }

    const handleClose = () => {
        dispatch(_triggerPartnerForm(false))
    }
    const { errors, touched, handleSubmit, getFieldProps } = formik;

    return (
        <Popup open={Boolean(triggerPartnerForm)} onClose={handleClose} noBorderTop>
            <Stack spacing={2}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={4} width={isMobile ? 240 : 300}>
                            <Typography variant={"h4"}>{langLibrary.key_607}</Typography>
                            <TextField
                                fullWidth
                                autoComplete="name"
                                type="text"
                                label={langLibrary.key_700}
                                {...getFieldProps("name")}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                            <TextField
                                fullWidth
                                autoComplete="phone"
                                type="text"
                                label={langLibrary.key_674}
                                {...getFieldProps("phone")}
                                error={Boolean(touched.phone && errors.phone)}
                                helperText={touched.phone && errors.phone}
                            />
                            <TextField
                                fullWidth
                                autoComplete="email"
                                type="email"
                                label={langLibrary.key_51}
                                {...getFieldProps("email")}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                            <TextField
                                fullWidth
                                autoComplete="note"
                                type="text"
                                multiline
                                label={langLibrary.key_201}
                                {...getFieldProps("note")}
                                error={Boolean(touched.note && errors.note)}
                                helperText={touched.note && errors.note}
                            />
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                {langLibrary.key_221}
                            </LoadingButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Stack>
        </Popup>
    );
}