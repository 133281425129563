import { useParams } from "react-router-dom";
// @mui
import { Container, Grid, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
// hooks
// components
import Page from "../components/Page";
// sections
import { useEffect, useState } from "react";
import { MotionViewport } from "../components/animate";
import Image from "../components/Image";
import RegisterForm from "../sections/auth/register/RegisterForm";
import { LOCAL_KEY_REFERRAL_ID } from "../settings/constants";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(12, 0),
  maxHeight: "calc(100vh - 60px)",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(22, 0),
  },
}));
const CustomBox = styled(Stack)(({ theme }) => ({
  padding: "2rem",
  textAlign: "left",
  borderRadius: theme.shape.borderRadius,
  // boxShadow: theme.customShadows.z24,
  background: "#202020",
  alignSelf: "center",
  maxWidth: "500px",
  border: '1px solid #43E9F6',
  margin: "0px auto",
  [theme.breakpoints.down("md")]: {
    // padding: "0px",
    textAlign: "center",
    boxShadow: "none",
  },
}));
// ----------------------------------------------------------------------

export default function Register() {
  const [isSuccess, setIsSuccess] = useState(false);
  const { referralId } = useParams();
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;

  useEffect(() => {
    if (referralId && referralId.length > 0) {
      window.localStorage.setItem(
        LOCAL_KEY_REFERRAL_ID,
        JSON.stringify({ referralId })
      );
    }
  }, [referralId]);

  return (
    <Page title={langLibrary.key_40}>
      <RootStyle>
        <Container>
          <Grid
            container
            spacing={isSuccess ? 0 : 5}
            justifyContent="center"
          >
            <Grid item xs={12} md={isSuccess ? 11 : 5} lg={isSuccess ? 11 : 5}>
              <CustomBox sx={{ boxShadow: isSuccess ? "none" : "" }}>
                <RegisterForm onSuccess={() => setIsSuccess(true)} />
              </CustomBox>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
}
