import { Form, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";

// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  IconButton,
  InputAdornment,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// component

// icon
import { IconBan, IconChevronRight, IconCircleCheck } from "@tabler/icons";
import Iconify from "../../components/Iconify";
//
import { useEffect, useState } from "react";
import CopyContent from "../../components/CopyContent";
import CustomDrawer from "../../components/CustomDrawer";
import { _getNewProfile } from "../../store/actions/userActions";
import { get, put } from "../../utils/api";

// ----------------------------------------------------------------------
const PasswordContentStyle = styled(Card)(({ theme }) => ({
  minHeight: 200,
  borderRadius: '15px',
  boxShadow: '0 1rem 1rem -0.75rem rgb(0 0 0 / 5%)',
}));
// ----------------------------------------------------------------------

export default function GaSetting() {
  const theme = useTheme()
  const { user, app } = useSelector((state) => state);
  const { langLibrary, langCode } = app ? app : {};
  const dispatch = useDispatch();
  const { profile } = user ? user : {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [gaConfig, setGaConfig] = useState({});

  const ReferralSchema = Yup.object().shape({
    password: Yup.string().required(langLibrary.key_82),
    gaCode: Yup.string().required(langLibrary.key_82),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      gaCode: "",
    },
    validationSchema: ReferralSchema,
    onSubmit: () => {
      handleUpdate();
    },
  });

  useEffect(() => {
    if (open === true) {
      if (profile) {
        if (profile.gaEnable === false) {
          handleGetQR();
        } else {
          setStep(2);
        }
      }
    }
  }, [open]);

  const handleGetQR = () => {
    get("/user/ga", (result) => {
      setGaConfig(result);
    });
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const { gaEnable } = profile ? profile : {};

  const handleClose = () => {
    setOpen(false);
    setStep(1);
    formik.setValues({
      password: "",
      gaCode: "",
    });
    setTimeout(() => {
      formik.setErrors({
        password: "",
        gaCode: "",
      });
    }, 200);
  };

  const handleUpdate = () => {
    setIsSubmitting(true);
    const params = {
      password: formik.values.password,
      gaCode: formik.values.gaCode,
      enable: gaEnable ? false : true,
    };
    put(
      "/user/ga",
      params,
      () => {
        toast.success(langLibrary.key_83);
        setIsSubmitting(false);
        dispatch(_getNewProfile());
        handleClose();
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  return (
    <>
      <PasswordContentStyle>
        <CardHeader
          sx={{ padding: "1.5rem 1.5rem 0.5rem 1.5rem" }}
          title={langLibrary.key_113}
          subheader={<Typography>{langLibrary.key_114}</Typography>}
        />
        <ListItemButton
          disableRipple
          sx={{
            padding: "0.5rem 1.5rem", ':hover': {
              '.MuiTypography-root': {
                color: "#fff"
              },
              "svg": {
                opacity: 1
              }
            }
          }}
          onClick={() => setOpen(true)}
        >
          <Stack
            direction={"row"}
            width="100%"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <CardHeader
              sx={{ padding: 0 }}
              title={
                <Chip
                  color={gaEnable ? "success" : "default"}
                  sx={{ color: gaEnable ? "#fff" : "#454F5B" }}
                  icon={
                    gaEnable ? (
                      <IconCircleCheck stroke={2} size={"1.2rem"} />
                    ) : (
                      <IconBan stroke={2} size={"1.2rem"} />
                    )
                  }
                  label={gaEnable ? "ON" : "OFF"}
                  style={{ marginBottom: "0.5rem" }}
                />
              }
              subheader={
                <Typography>
                  {gaEnable ? (
                    <div>
                      {langCode === "en" ? `${langLibrary.key_122} ${langLibrary.key_233}` : `${langLibrary.key_477}`}
                    </div>
                  ) : (
                    <div>
                      {langCode === "en" ? `${langLibrary.key_123} ${langLibrary.key_233}` : `${langLibrary.key_476}`}
                    </div>
                  )}
                </Typography>
              }
            />
            <Iconify
              icon="carbon:chevron-right"
              sx={{ width: 25, height: 25, opacity: 0.5 }}
            />
          </Stack>
        </ListItemButton>
      </PasswordContentStyle>
      <CustomDrawer
        title={<Typography variant="h5">{langLibrary.key_115}</Typography>}
        anchor="right"
        parent={"#profile-layout"}
        open={open}
        onClose={() =>
          step == 2 && gaEnable === false ? setStep(1) : setOpen(false)
        }
        paperStyle={{ maxWidth: "500px", width: "100vw" }}
        actions={
          <Stack
            padding="1rem"
            width={"100%"}
            direction={"row"}
            justifyContent="flex-end"
            alignItems={"center"}
          >
            {step === 1 && (
              <Button onClick={() => setStep(2)}>
                {langLibrary.key_116}{" "}
                <IconChevronRight style={{ marginLeft: "5px" }} />
              </Button>
            )}
          </Stack>
        }
      >
        {step === 1 && gaConfig && (
          <Stack padding={{ xs: 2, sm: 4 }}>
            <img
              alt=""
              style={{ margin: "auto" }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Google_Authenticator_for_Android_icon.svg/1200px-Google_Authenticator_for_Android_icon.svg.png"
              width={100}
            />
            <CardHeader
              title={langLibrary.key_126}
              subheader={
                <Box>
                  <Typography variant="body2">{langLibrary.key_117}</Typography>
                  <Chip
                    label={<CopyContent text={gaConfig.manualEntryKey} />}
                    style={{
                      margin: "1rem 0px",
                      width: "100%",
                      minHeight: "50px",
                    }}
                  />
                  <Typography variant="body2">{langLibrary.key_118}</Typography>
                  <Typography variant="body2">{langLibrary.key_119}</Typography>
                  <Typography variant="body2">{langLibrary.key_120}</Typography>
                </Box>
              }
            />

            <img
              alt=""
              src={gaConfig.qrCodeSetupImageUrl}
              style={{ margin: " 2rem auto 0px", borderRadius: "15px" }}
              width={200}
            />
            <Typography textAlign={"center"}>{langLibrary.key_121}</Typography>
          </Stack>
        )}
        {step === 2 && (
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3} padding={{ xs: 2, sm: 4 }}>
                <CardHeader
                  title={`${gaEnable ? langLibrary.key_122 : langLibrary.key_123
                    } ${langLibrary.key_115}`}
                  subheader={
                    <Box>
                      <Typography variant="body2">
                        {langLibrary.key_124}
                      </Typography>
                    </Box>
                  }
                />
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="password"
                    type={showPassword ? "text" : "password"}
                    label={langLibrary.key_52}
                    {...getFieldProps("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <TextField
                    fullWidth
                    autoComplete="gaCode"
                    type={"text"}
                    label={langLibrary.key_125}
                    {...getFieldProps("gaCode")}
                    error={Boolean(touched.gaCode && errors.gaCode)}
                    helperText={touched.gaCode && errors.gaCode}
                  />
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    size="large"
                    type="submit"
                    fullWidth
                  >
                    {gaEnable ? langLibrary.key_122 : langLibrary.key_123}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          </FormikProvider>
        )}
      </CustomDrawer>
    </>
  );
}
