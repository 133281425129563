import { useSelector } from "react-redux";
// @mui
import {
  Alert,
  Button,
  CircularProgress,
  Hidden,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";

// icon

import $ from "jquery";
import CoinSelector from "../../components/CoinSelector";
import CustomDrawer from "../../components/CustomDrawer";
import QRCode from "react-qr-code";
import { get } from "../../utils/api";
import CopyContent from "../../components/CopyContent";
import { useResponsive } from "../../hooks";

// ----------------------------------------------------------------------

const QRBox = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.paperRadius,
  padding: "1.5rem",
  alignItems: "center",
  minHeight: "200px",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    background: "transparent",
    padding: "1.5rem 0px",
  },
}));

const CustomLabel = styled(Box)(({ theme }) => ({
  background: theme.palette.background.neutral,
  borderRadius: "100px",
  padding: "2px 2px 2px 20px",
  width: "100%",
  display: "flex",
  alignItems: "center",
}));
const LoaderBox = styled(Box)(() => ({
  position: "absolute",
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
}));
const AlertBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
  background: `${alpha(theme.palette.background.paper, 0.9)}`,
  paddingTop: "4rem",
}));

const QAContainer = styled(Stack)(({ theme }) => ({
  marginTop: "1.5rem",
  background: theme.palette.background.neutral,
  padding: "1.5rem",
  borderRadius: "10px",
  // border: "1px solid #A7B8C9",
  [theme.breakpoints.down("sm")]: {
    background: "#fff",
    marginTop: "1rem",
  },
}));
const ButtonConfirm = styled(Button)(({ theme }) => ({
  width: "20%",
  marginTop: "5px",
  background: "#ED6C02",
  "&:hover": {
    background: "#ED6C02",
  },
}));
const TextAlert = styled(Typography)(({ theme }) => ({
  color: "#B78103",
  [theme.breakpoints.down("sm")]: {
    color: "unset",
  },
}));

export default function Deposit({ onSuccess, ...other }) {
  const { user } = useSelector((state) => state);
  const { funding } = user ? user : {};
  const [showForm, setShowForm] = useState(false);
  const [coinSelected, setCoinSelected] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [address, setAddress] = useState(null);
  const [memo, setMemo] = useState(null);
  const [showMemoAlert, setShowMemoAlert] = useState(false);
  const isMobile = useResponsive("down", "sm");
  const { app } = useSelector((state) => state);
  const { langLibrary, langCode } = app;

  const handleClose = () => {
    setShowForm(false);
    setCoinSelected(null);
    setAddress(null);
    setMemo(null);
    setShowMemoAlert(false);
  };
  const handleGetDepositAddress = (network) => {
    setIsSubmitting(true);
    get(
      `/wallet/deposit-address?network=${network}`,
      (result) => {
        setAddress(result.address);
        if (result.memo) {
          setMemo(result.memo);
          setShowMemoAlert(true);
        }
        setIsSubmitting(false);
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  useEffect(() => {
    const { funding } = user;
    if (funding && coinSelected) {
      handleGetDepositAddress(coinSelected?.network);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinSelected]);

  if (!user || !user.profile) return null;

  return (
    <>
      <Button
        size={"large"}
        onClick={() => setShowForm(true)}
        color="primary"
        variant="outlined"
        sx={{ width: "100%" }}
      >
        {langLibrary.key_94}
      </Button>
      <CustomDrawer
        anchor={"right"}
        paperStyle={{ maxWidth: "500px", width: "100vw" }}
        title={langLibrary.key_94}
        open={Boolean(showForm)}
        parent={$("#root")}
        onClose={() => handleClose(false)}
      >
        <Stack padding="1rem">
          <Hidden smDown>
            <img
              alt=""
              src="/images/deposit-image.png"
              height={230}
              style={{ objectFit: "contain" }}
            />
          </Hidden>
          <Box
            sx={{
              borderRadius: "7px",
              marginTop: { xs: "2rem", md: "1rem" },
            }}
          >
            <CoinSelector
              options={funding}
              onSelect={(option) => {
                if (option.asset !== coinSelected?.asset) {
                  setMemo(null);
                  setAddress(null);
                  setShowMemoAlert(false);
                  setCoinSelected(option);
                }
              }}
              sx={{ width: "100% !important" }}
            />
          </Box>
          {coinSelected && (
            <>
              {!coinSelected.depositEnable ? (
                <Typography
                  variant="body2"
                  sx={{
                    padding: "0.5rem 1rem",
                    color: "#d85f5f !important",
                    background: "#ffeded",
                    margin: "1rem 0px",
                    borderRadius: "7px",
                  }}
                >
                  {langLibrary.key_357} <span style={{ color: "#d85f5f !important", fontWeight: "bold" }}> {coinSelected.asset} </span>
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    padding: "0.5rem 1rem",
                    color: "#d85f5f !important",
                    background: "#ffeded",
                    margin: "1rem 0px",
                    borderRadius: "7px",
                  }}
                >
                  {langCode === "en" ? (
                    <>
                      {langLibrary.key_355}
                      <span style={{ color: "#d85f5f !important", fontWeight: "bold" }}> {coinSelected.asset} </span>
                      {langLibrary.key_356} {langLibrary.key_358} <span style={{ color: "#d85f5f !important", fontWeight: "bold" }}> {coinSelected.networkName} </span>
                    </>
                  ) : (
                    <>
                      <span style={{ color: "#d85f5f !important", fontWeight: "bold" }}> {coinSelected.networkName} </span>
                      {langLibrary.key_489}
                      <span style={{ color: "#d85f5f !important", fontWeight: "bold" }}> {coinSelected.asset} </span>
                      {langLibrary.key_490}
                    </>
                  )}
                </Typography>
              )}

            </>
          )}


          <QRBox>
            {address && coinSelected && coinSelected.depositEnable && (
              <>
                <QAContainer>
                  <QRCode value={address} size={isMobile ? 100 : 150} />
                </QAContainer>
                <Stack mt={"1.5rem"} width={"100%"} alignItems="center">
                  <CustomLabel
                    sx={{
                      flexWrap: "wrap",
                      borderRadius: "10px",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      sx={{ opacity: "0.6" }}
                    >
                      {langLibrary.key_95}
                    </Typography>
                    <CopyContent text={address} />
                  </CustomLabel>
                </Stack>
                {memo && (
                  <>
                    <Stack mt={"1rem"} width={"100%"} alignItems="center">
                      <CustomLabel
                        sx={{
                          flexWrap: "wrap",
                          borderRadius: "10px",
                          paddingTop: "0.5rem",
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={"bold"}
                          sx={{ opacity: "0.6" }}
                        >
                          Memo
                        </Typography>
                        <CopyContent text={memo} />
                      </CustomLabel>
                    </Stack>
                    {showMemoAlert && (
                      <AlertBox>
                        <Alert
                          sx={{ width: "100%", textAlign: "center" }}
                          severity="warning"
                          icon={false}
                        >
                          <TextAlert variant="body2">
                            {langLibrary.key_96} {coinSelected?.network}{" "}
                            {langLibrary.key_97}
                          </TextAlert>
                          <ButtonConfirm
                            onClick={() => setShowMemoAlert(false)}
                            color="primary"
                            variant="contained"
                          >
                            {langLibrary.key_98}
                          </ButtonConfirm>
                        </Alert>
                      </AlertBox>
                    )}
                  </>
                )}
              </>
            )}

            {isSubmitting && (
              <LoaderBox>
                <CircularProgress />
              </LoaderBox>
            )}
          </QRBox>
        </Stack>
      </CustomDrawer>
    </>
  );
}
