import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../components/Popup';
import { _triggerPolicy } from '../store/actions/appActions';


// ----------------------------------------------------------------------


export default function Policy() {
    const dispatch = useDispatch()
    const { app } = useSelector(state => state)
    const { triggerPolicy, langLibrary } = app ? app : {}

    const handleClose = () => {
        dispatch(_triggerPolicy(false))
    }

    return (
        <Popup open={Boolean(triggerPolicy)} onClose={handleClose}>
            <Stack px={4} spacing={2}>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_641}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_642}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_643}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_644}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_645}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_646}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_647}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_648}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_649}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_650}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_651}</Typography>
                <Typography variant='body1' fontFamily="Exo,sans-serif" textAlign={'justify'}>{langLibrary.key_652}</Typography>

            </Stack>
        </Popup>
    );
}