import {
  FETCH_USER,
  FETCH_FUNDING,
  FETCH_AFFILIATE_PROFILE,
} from "../constants";
const initialState = {
  profile: null,
  funding: [],
  affiliateProfile: {},
};

export const UserReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER:
      return {
        ...state,
        profile: payload,
      };
    case FETCH_FUNDING:
      return {
        ...state,
        funding: payload,
      };
    case FETCH_AFFILIATE_PROFILE:
      return {
        ...state,
        affiliateProfile: payload,
      };
    default:
      return { ...state };
  }
};
