import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../components/Popup';
import { _triggerTelegramQRCode } from '../store/actions/appActions';


// ----------------------------------------------------------------------


export default function TelegramQRCode() {
    const dispatch = useDispatch()
    const { app } = useSelector(state => state)
    const { triggerTelegramQRCode, langLibrary } = app ? app : {}
    const handleClose = () => {
        dispatch(_triggerTelegramQRCode(false))
    }

    return (
        <Popup open={Boolean(triggerTelegramQRCode)} onClose={handleClose}>
            <Stack px={4} spacing={2} justifyContent={"center"} alignItems={"center"}>
                <Typography variant='h6'>Scan to join Telegram group for support</Typography>
                <img alt="" src='/images/social/telegram_qr_code.png' style={{ width: "30vh", height: "30vh" }} />

            </Stack>
        </Popup>
    );
}