import PropTypes from "prop-types";
// material
import { Stack, Typography } from "@mui/material";

// icons
import { IconFaceIdError } from "@tabler/icons";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = "", ...other }) {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  return (
    <Stack
      justifyItems={"center"}
      className="search-not-found"
      alignItems={"center"}
      {...other}
    >
      <IconFaceIdError />
      <Typography align="center" variant="subtitle1">
        {langLibrary.key_4}
      </Typography>
    </Stack>
  );
}
