import { Card, Container, Grid, Hidden, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/robot.json'
import Image from '../../components/Image';
import { alpha, Box, useTheme } from '@mui/system';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function Difference({ ...other }) {
    const { app } = useSelector(state => state)
    const theme = useTheme()
    const { langLibrary } = app ? app : {}
    return (<ContainerStyle {...other}>
        <ServiceContent container justifyContent='space-between'>
            <Grid item xs={12} p={2}>
                <Typography textAlign={'center'} variant='h2'>{langLibrary.key_589}</Typography>
            </Grid>
            <Grid item md={7} sm={12} p={2}>
                <Typography mt={3} variant='body3' textAlign={'left'} >{langLibrary.key_590}</Typography>
                <Typography mt={3} variant='body3' textAlign={'left'} >{langLibrary.key_591}</Typography>
                <Typography mt={3} variant='body3' textAlign={'left'} >{langLibrary.key_592}</Typography>
            </Grid>
            <Grid item md={4} sm={12} p={2}>
                <Stack direction={'row'} width="100%" height="100%" alignItems='center' justifyContent={'center'}>
                    <img src="/images/Group421.png" style={{ width: "100%", margin: "auto", maxWidth: 400 }} />
                </Stack>
            </Grid>
            <Hidden mdDown>
                <Grid item xs={12} sx={{ height: 200 }}></Grid>
            </Hidden>
            <Grid item md={4} sm={12} p={2}>
                <Stack direction={'row'} width="100%" height="100%" alignItems='center' >
                    <Typography textAlign={'left'} variant='h4'>{langLibrary.key_593}</Typography>
                </Stack>
            </Grid>
            <Grid item md={8} sm={12} p={2} mt={{ xs: '-3rem', sm: '-3rem', md: '0px' }}>
                <Typography mt={3} variant='body3' textAlign={'justify'} >{langLibrary.key_594}</Typography>
            </Grid>
            <Grid item md={4} sm={12} p={2}>
                <Stack direction={'row'} width="100%" height="100%" alignItems='center' >
                    <Typography textAlign={'left'} variant='h4'>{langLibrary.key_595}</Typography>
                </Stack>
            </Grid>
            <Grid item md={8} sm={12} p={2} mt={{ xs: '-3rem', sm: '-3rem', md: '0px' }}>
                <Typography mt={3} variant='body3' textAlign={'justify'} >{langLibrary.key_596}</Typography>
            </Grid>
        </ServiceContent>
    </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    alignItems: 'center',
    paddingTop: "50px",
    paddingBottom: '50px',
    minHeight: '50vh',
}));
const ServiceContent = styled(Grid)(({ theme }) => ({
    padding: '3rem',
    position: "relative",
    height: 'fit-content',
    zIndex: 10,
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
        padding: '1rem 0px',
    }
}));