import { Alert, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import Iconify from "../../../components/Iconify";
import { checkRankingCommission } from "../../../utils/utils";

// ----------------------------------------------------------------------

export default function UserRank() {
  const { user, app } = useSelector((state) => state);
  const { langLibrary, langCode } = app;
  const { affiliateProfile } = user ? user : {};

  if (!affiliateProfile) return null;

  const checker = checkRankingCommission(affiliateProfile, langCode, langLibrary);

  return (
    <Stack spacing={1.5}>
      <RenderStatus
        status={checker.revenue.status}
        title={langLibrary.key_27}
        text={checker.revenue.text}
        subTitle={langLibrary.key_272}
      />
      <RenderStatus
        status={checker.havePackage.status}
        title={langLibrary.key_270}
        subTitle={`${checker.havePackage.text}`}
      />
      <RenderStatus
        status={checker.f1Investor.status}
        title={langLibrary.key_80}
        subTitle={langLibrary.key_271}
        text={checker.f1Investor.text}
      />
    </Stack>
  );
}

const RenderStatus = ({ status, title, text, subTitle }) => {

  return (
    <Alert
      severity={status ? "success" : "info"}
      action={text}
      icon={
        status ? (
          <Iconify
            sx={{ width: 25, height: 25, color: "#0CD66E" }}
            icon="line-md:confirm-circle"
          />
        ) : (
          <Iconify
            sx={{ width: 25, height: 25, opacity: 0.6 }}
            icon="line-md:close-circle"
          />
        )
      }
    >
      <strong>{title}</strong>
      {
        subTitle && <p>{subTitle}</p>
      }
    </Alert>
  );
};
