import { Card, Collapse, Divider, Hidden, IconButton, Stack, TableCell, TablePagination, TableRow, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomTable from '../../components/CustomTable';
import Iconify from '../../components/Iconify';
import Label from '../../components/Label';
import Page from '../../components/Page';
import SearchNotFound from '../../components/SearchNotFound';
import { useResponsive } from '../../hooks';
import { post } from '../../utils/api';
import { fDate, fDateTime } from '../../utils/format';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
import { IconButtonAnimate } from '../../components/animate';
import { LoadingButton } from '@mui/lab';


export const BotTransferHistory = () => {
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [loading, setLoading] = useState(false)
    const [transferData, setTransferData] = useState([])
    const [statistic, setStatistic] = useState(null)
    const isMobile = useResponsive('down', 'sm')
    const isMobileSmall = useResponsive('between', null, 0, 500)
    const { app } = useSelector((state) => state);
    const [filter, setFilter] = useState(isMobile ? false : true)
    const { langLibrary } = app;
    const theme = useTheme()
    const handleGetHistory = () => {
        const params = {
            "page": page + 1,
            "pageSize": pageSize,
            "search": "",
            "orderBy": "",
            "isDesc": true,
            "returnStatistic": false,
            "filters": {
                BotId: formik.values.botId,
            }
        }
        setLoading(true)
        post('/package/bot/transfer-history', params, result => {
            setLoading(false)
            setTransferData(result.items)
            setStatistic({ itemCount: result.itemCount })
        }, () => {
            setLoading(false)
            setTransferData([])
        })
    }

    useEffect(() => {
        handleGetHistory()
    }, [page, pageSize])

    const TABLE_HEAD_DESKTOP = [
        {
            label: "key_300", render: row => `#${row.botId}` //Bot ID
        },
        {
            label: "key_675", render: row => (   //sender
                <Stack>
                    <Stack>
                        #{row.senderId}
                    </Stack>
                    <Stack>
                        {row.senderEmail}
                    </Stack>
                </Stack>
            )
        },
        {
            label: "key_666", render: row => (   //receiver
                <Stack>
                    <Stack>
                        #{row.receiverId}
                    </Stack>
                    <Stack>
                        {row.receiverEmail}
                    </Stack>
                </Stack>
            )
        },
        // {
        //     label: "key_668",   //receiver ID
        //     alignRight: false,
        //     render: row =>
        //         `#${row.receiverId}`

        // }, {
        //     label: "key_669",   //receiver email
        //     alignRight: false,
        //     render: row =>
        //         row.receiverEmail
        // },
        {
            label: "key_667",    //Transfer time
            render: (row) => fDateTime(row.createdTime)
        },
    ];
    const TABLE_HEAD_MOBILE = [
        {
            sx: { gridArea: 'end', minWidth: '100%' },
            render: row => (
                <Stack direction='row' p={1} justifyContent='space-between'>
                    <Stack spacing={0.5} width={"100%"}>
                        {/* <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack direction={"row"}><Typography variant='caption' sx={{ marginRight: "2px" }}>{langLibrary.key_300}:</Typography> <Label>#{row.botId}</Label></Stack>
                            <Typography textAlign={'right'} variant='caption' sx={{ opacity: 0.6 }}>{fDateTime(row.createdTime)}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography variant='caption' sx={{ marginRight: "2px" }}>Receiver:</Typography>
                            <Label sx={{ minWidth: 60 }}>#{row.receiverId}</Label>
                            <Divider orientation='vertical' flexItem sx={{ border: `1px solid rgba(255,255,255,0.4) `, margin: "0 0.5rem" }} />
                            <Typography variant='caption'>{row.receiverEmail}</Typography>
                        </Stack> */}
                        <Stack direction={"row"}>
                            <Stack direction={"row"}><Typography variant='caption' sx={{ marginRight: "2px" }}>{langLibrary.key_300}:</Typography> <Label>#{row.botId}</Label></Stack>
                            <Divider orientation='vertical' flexItem sx={{ border: `1px solid rgba(255,255,255,0.4) `, margin: "0 0.5rem" }} />
                            <Typography textAlign={'right'} variant='caption' sx={{ opacity: 0.6 }}>{fDateTime(row.createdTime)}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} flexWrap={"wrap"} spacing={2} rowGap={1}>
                            {isMobileSmall && <Typography variant='body2' color={"primary"}>{langLibrary.key_15}</Typography>}
                            <Stack>
                                <Typography variant='caption' sx={{ opacity: 0.6 }}>#{row.senderId}</Typography>
                                <Typography variant='body2'>{row.senderEmail}</Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                {!isMobileSmall && (
                                    <Stack sx={{ width: 30 }}>
                                        <Iconify icon={"material-symbols:arrow-right-alt-rounded"} sx={{ width: 30, height: 30, color: theme.palette.primary.main }} />
                                    </Stack>
                                )}
                                {isMobileSmall && <Typography variant='body2' color={"primary"}>{langLibrary.key_16}</Typography>}
                                <Stack>
                                    <Typography variant='caption' sx={{ opacity: 0.6 }}>#{row.receiverId}</Typography>
                                    <Typography variant='body2'>{row.receiverEmail}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack >
            )
        },
    ]
    const TABLE_HEAD_SUB = isMobile ? TABLE_HEAD_MOBILE : TABLE_HEAD_DESKTOP
    //formik
    const formFilterSchema = Yup.object().shape({
        botId: Yup.number().positive("Must be a positive value"),
        receiverId: Yup.number().positive("Must be a positive value"),
    });
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: formFilterSchema,
        onSubmit: () => {
            if (page === 0) {
                handleGetHistory()
            } else {
                setPage(0)
            }
        },
    });
    const { errors, touched, handleSubmit, getFieldProps } = formik;
    return <>
        <Page title={langLibrary.key_672} sx={{ position: "relative" }}>
            <Stack spacing={5} width={"100%"} minHeight={130} mb={2}>
                <Typography sx={{ flexGrow: 1, fontSize: '2rem', color: "#fff" }}>
                    {langLibrary.key_672}
                </Typography>
            </Stack>
            <Card>
                {isMobile && (
                    <Stack direction={"row"} p={"0.5rem 0.5rem 0rem 0.5rem"} alignItems={"center"} onClick={() => setFilter(!filter)}>
                        <Typography color={"primary"}>{langLibrary.key_673}</Typography>
                        <IconButton color='primary' sx={{ width: 30, height: 30, padding: 0 }} >
                            <Iconify icon={filter ? "material-symbols:arrow-drop-up" : "material-symbols:arrow-drop-down"} sx={{ width: "100%", height: "100%" }} />
                        </IconButton>
                    </Stack>
                )}
                <Collapse in={isMobile ? filter : true}>
                    <Stack p={{ xs: 1, sm: 2 }}>
                        <FormikProvider value={formik}>
                            <Form>
                                <Hidden smDown>
                                    <Stack spacing={{ sm: 0, md: 2 }} direction={"row"} flexWrap={"nowrap"} gap={1} width={"100%"}>
                                        <TextField
                                            size="small"
                                            label={langLibrary.key_300}
                                            variant="outlined"
                                            {...getFieldProps("botId")}
                                            // sx={{ width: "80vw", maxWidth: 200, }}
                                            error={Boolean(touched.botId && errors.botId)}
                                            helperText={touched.botId && errors.botId}
                                            InputProps={{
                                                endAdornment: <IconButtonAnimate sx={{ mr: -1.5 }}>
                                                    <Iconify icon='tabler:search' />
                                                </IconButtonAnimate>,
                                            }}
                                        />
                                        {/* <TextField
                                            size="small"
                                            label={"Sender ID"}
                                            variant="outlined"
                                            {...getFieldProps("senderId")}
                                            sx={{ width: "80vw", maxWidth: 200 }}
                                            InputProps={{
                                                endAdornment: <IconButtonAnimate sx={{ mr: -1.5 }}>
                                                    <Iconify icon='tabler:search' />
                                                </IconButtonAnimate>,
                                            }}
                                        /> */}
                                        {/* <TextField
                                            size="small"
                                            label={langLibrary.key_668}
                                            variant="outlined"
                                            {...getFieldProps("receiverId")}
                                            error={Boolean(touched.receiverId && errors.receiverId)}
                                            helperText={touched.receiverId && errors.receiverId}
                                            sx={{ width: "80vw", maxWidth: 200 }}
                                            InputProps={{
                                                endAdornment: <IconButtonAnimate sx={{ mr: -1.5 }}>
                                                    <Iconify icon='tabler:search' />
                                                </IconButtonAnimate>,
                                            }}
                                        /> */}

                                        <LoadingButton
                                            variant='contained'
                                            // sx={{ width: "80vw", maxWidth: 200, height: "40px" }}
                                            type='submit'
                                            // disabled={!formik.values.botId && !formik.values.senderId && !formik.values.receiverId}
                                            loading={loading}
                                            disabled={errors.botId || errors.receiverId}
                                        >
                                            {langLibrary.key_671}
                                        </LoadingButton>
                                    </Stack>
                                </Hidden>
                                <Hidden smUp>
                                    <Stack spacing={1}>
                                        <Stack spacing={1} direction={"row"}>
                                            <TextField
                                                size="small"
                                                label={langLibrary.key_300}
                                                variant="outlined"
                                                {...getFieldProps("botId")}
                                                error={Boolean(touched.botId && errors.botId)}
                                                helperText={touched.botId && errors.botId}
                                                // sx={{ width: "50%" }}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <IconButtonAnimate sx={{ mr: -1.5 }}>
                                                        <Iconify icon='tabler:search' />
                                                    </IconButtonAnimate>,
                                                }}
                                            />
                                            {/* <TextField
                                                size="small"
                                                label={langLibrary.key_668}
                                                variant="outlined"
                                                sx={{ width: "50%" }}
                                                error={Boolean(touched.receiverId && errors.receiverId)}
                                                helperText={touched.receiverId && errors.receiverId}
                                                {...getFieldProps("receiverId")}
                                                InputProps={{
                                                    endAdornment: <IconButtonAnimate sx={{ mr: -1.5 }}>
                                                        <Iconify icon='tabler:search' />
                                                    </IconButtonAnimate>,
                                                }}
                                            /> */}
                                            {/* <TextField
                                                size="small"
                                                label={"Sender ID"}
                                                variant="outlined"
                                                {...getFieldProps("senderId")}
                                                sx={{ width: "50%" }}
                                                InputProps={{
                                                    endAdornment: <IconButtonAnimate sx={{ mr: -1.5 }}>
                                                        <Iconify icon='tabler:search' />
                                                    </IconButtonAnimate>,
                                                }}
                                            /> */}
                                        </Stack>
                                        <Stack spacing={1} direction={"row"}>
                                            <LoadingButton
                                                variant='contained'
                                                type='submit'
                                                fullWidth
                                                disabled={errors.botId || errors.receiverId}
                                                loading={loading}
                                            >
                                                {langLibrary.key_671}
                                            </LoadingButton>
                                        </Stack>
                                    </Stack>
                                    <Divider sx={{ marginTop: "1rem !important" }} />
                                </Hidden>
                            </Form>
                        </FormikProvider>
                    </Stack>
                </Collapse>
                <CustomTable
                    loading={loading}
                    tableHead={
                        <TableRow>
                            {TABLE_HEAD_SUB.map((item, index) => (
                                <TableCell sx={{ ...item.sx }} key={index}>{langLibrary[item.label]}</TableCell>
                            ))}
                        </TableRow>
                    }
                    minHeight={110}
                    smMinWidth={800}
                    tableBody={
                        <>

                            {transferData &&
                                transferData.map((item, index) => (
                                    <TableRow key={index}>
                                        {TABLE_HEAD_SUB.map((e, j) => (
                                            <TableCell key={j} sx={{ ...e.sx }}>
                                                {
                                                    e.render
                                                        ? e.render(item, e.label)
                                                        : item[e.id]
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            {!loading && transferData.length === 0 && (
                                <TableRow sx={{ '&.MuiTableRow-root': { display: isMobile ? "flex" : "", justifyContent: "center" } }}>
                                    <TableCell align="center" colSpan={20} sx={{ py: 3 }}>
                                        <SearchNotFound />
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    }
                    tablePagination={
                        statistic && (
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={statistic.itemCount ? statistic.itemCount : 0}
                                rowsPerPage={pageSize}
                                labelRowsPerPage={langLibrary.key_304}
                                page={page}
                                onPageChange={(e, newPage) => {
                                    setPage(newPage);
                                }}
                                onRowsPerPageChange={(e) => setPageSize(e.target.value)}
                            />
                        )
                    }
                />
            </Card>
        </Page >
    </>
}