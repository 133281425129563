import { toast } from "react-toastify";

import { Typography } from "@mui/material";
import { fCurrency } from "./format";
export const getLocalStorage = (key) => {
  var output = {};
  let localString = window.localStorage.getItem(key);
  if (localString && localString.length > 0 && localString != "undefined") {
    output = JSON.parse(localString);
  }
  return output;
};

export const CustomToast = (type, content, showMsg) => {
  const langLibraryObj = localStorage.getItem("langLibrary");
  const langLibrary = JSON.parse(langLibraryObj);
  if (type === "error") {
    toast.error(
      <div
        style={{
          display: "flex",
          alignContent: "center",
        }}
      >
        <Typography variant="body2">
          {showMsg ? content : langLibrary[content]}
        </Typography>
      </div>
    );
  } else {
    toast.success(
      <div
        style={{
          display: "flex",
          alignContent: "center",
        }}
      >
        <Typography variant="body2">{content}.</Typography>
      </div>
    );
  }
};

export function randomNumber(limit) {
  return Math.floor(Math.random() * limit) + 1;
}

export const getErrorMessage = (code) => {
  switch (code) {
    case "WRONG_CREDENTIALS":
      return toast.error("Incorrect user account or password");

      return toast.error(code);
  }
};

export const objFromUrlParams = (search) => {
  if (!search) return {};

  search = search.replace("?", "");

  return JSON.parse(
    '{"' +
    decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  );
};

export const capitalizeFirstLetter = (str) => {
  let isStr = str;
  if (str === "staking") isStr = "renting";
  return isStr.charAt(0).toUpperCase() + isStr.slice(1);
};

export const formatRankName = (string) => {
  if (!string) string = "";
  return string.split("_").join(" ");
};

export const checkRankingCommission = (profile, langCode, langLibrary) => {
  if (!profile) return false;
  const require = profile.requirements;
  if (!require) return false;

  const havePackage = {
    text: langCode === "en" ? `${langLibrary.key_273} ${require.minActiveBotMonths} months` : `${langLibrary.key_480} ${require.minActiveBotMonths}${langLibrary.key_481}`,
    status: profile.activeBotMonths >= require.minActiveBotMonths,
    need: require.minActiveBotMonths - profile.activeBotMonths,
  };

  const balance = {
    text: `$${fCurrency(profile.totalUsdBalance, 2)} / $${fCurrency(
      require.minUsdBalance,
      2
    )}`,
    status: profile.totalUsdBalance >= require.minUsdBalance,
    need: require.minUsdBalance - profile.totalUsdBalance,
  };

  const f1Investor = {
    text: `${profile.f1Count} / ${require.f1CountRequired}`,
    status: profile.f1Count >= require.f1CountRequired,
    need: `${require.f1CountRequired} ${require.requiredRank}`,
  };

  const revenue = {
    text: `$${fCurrency(profile.totalRevenueUsd, 2)} / $${fCurrency(
      require.revenueRequired,
      2
    )}`,
    status: profile.totalRevenueUsd >= require.revenueRequired,
    need: require.revenueRequired - profile.totalRevenueUsd,
  };

  const member = {
    text: `${profile.memberPrevRankCount} / ${require.prevRankRequired}`,
    status: profile.memberPrevRankCount >= require.prevRankRequired,
    need: `${require?.prevRankRequired} ${require.requiredRank}`,
  };

  var output = {
    havePackage: havePackage,
    balance: balance,
    f1Investor: f1Investor,
    revenue: revenue,
    member: member,
  };

  return output;
};

function getExifOrientation(file, callback) {
  // Suggestion from http://code.flickr.net/2012/06/01/parsing-exif-client-side-using-javascript-2/:
  if (file.slice) {
    file = file.slice(0, 131072);
  } else if (file.webkitSlice) {
    file = file.webkitSlice(0, 131072);
  }

  var reader = new FileReader();
  reader.onload = function (e) {
    var view = new DataView(e.target.result);
    if (view.getUint16(0, false) != 0xffd8) {
      callback(-2);
      return;
    }
    var length = view.byteLength,
      offset = 2;
    while (offset < length) {
      var marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) {
          callback(-1);
          return;
        }
        var little = view.getUint16((offset += 6), false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;
        for (var i = 0; i < tags; i++)
          if (view.getUint16(offset + i * 12, little) == 0x0112) {
            callback(view.getUint16(offset + i * 12 + 8, little));
            return;
          }
      } else if ((marker & 0xff00) != 0xff00) break;
      else offset += view.getUint16(offset, false);
    }
    callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

function imgToCanvasWithOrientation(img, rawWidth, rawHeight, orientation) {
  var canvas = document.createElement("canvas");
  if (orientation > 4) {
    canvas.width = rawHeight;
    canvas.height = rawWidth;
  } else {
    canvas.width = rawWidth;
    canvas.height = rawHeight;
  }

  if (orientation > 1) {
    console.log("EXIF orientation = " + orientation + ", rotating picture");
  }

  var ctx = canvas.getContext("2d");
  switch (orientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, rawWidth, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, rawHeight);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, rawHeight, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, rawHeight, rawWidth);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, rawWidth);
      break;
  }
  ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
  return canvas;
}

export const reduceFileSize = (
  file,
  acceptFileSize,
  maxWidth,
  maxHeight,
  quality,
  callback
) => {
  if (file.size <= acceptFileSize) {
    callback(file);
    return;
  }
  var img = new Image();
  img.onerror = function () {
    URL.revokeObjectURL(this.src);
    callback(file);
  };
  img.onload = function () {
    URL.revokeObjectURL(this.src);
    getExifOrientation(file, function (orientation) {
      var w = img.width,
        h = img.height;
      var scale =
        orientation > 4
          ? Math.min(maxHeight / w, maxWidth / h, 1)
          : Math.min(maxWidth / w, maxHeight / h, 1);
      h = Math.round(h * scale);
      w = Math.round(w * scale);

      var canvas = imgToCanvasWithOrientation(img, w, h, orientation);
      canvas.toBlob(
        function (blob) {
          console.log(
            "Resized image to " + w + "x" + h + ", " + (blob.size >> 10) + "kB"
          );
          var outputFile = new File([blob], file.name, {
            type: file.type,
          });
          callback(outputFile);
        },
        "image/jpeg",
        quality
      );
    });
  };
  img.src = URL.createObjectURL(file);
};