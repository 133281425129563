// @mui
import {
  Card,
  Divider,
  Grid,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import CopyContent from "../components/CopyContent";
import Page from "../components/Page";
import { useResponsive } from "../hooks";
import CommissionList from "../sections/profile/CommissionList";
import DownLineList from "../sections/profile/DownLineList";
import { MyBots } from "../sections/profile/MyBots";
import DiamondRank from "../sections/profile/RankCommission/DiamondRank";
import GoldRank from "../sections/profile/RankCommission/GoldRank";
import ManagerRank from "../sections/profile/RankCommission/ManagerRank";
import UserRank from "../sections/profile/RankCommission/UserRank";
import UpdateReferral from "../sections/profile/UpdateReferral";
import { formatRankName } from "../utils/utils";

// ----------------------------------------------------------------------

const CustomLabel = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: "10px",
  padding: "0.3rem 1rem",
  width: "100%",
  marginTop: '0.5rem',
  maxWidth: 300,
  width: "calc(100vw - 180px)"
}));


const QRBox = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.paperRadius,
  margin: "0px",
  padding: "1rem",
}));

const customConfig = {
  hideTitle: true,
  hideSymbol: true,
  hideAction: true,
  pageTitle: "Dashboard"
}
export default function Dashboard() {
  const isDesktop = useResponsive("up", "sm");
  const { user, app } = useSelector((state) => state);
  const { profile, affiliateProfile } = user ? user : {};
  const { langLibrary } = app;
  const { config } = app ? app : {};
  const { decimals } = config ? config : {};
  if (!profile || !affiliateProfile) return null;

  const RankCondition = {
    USER: <UserRank />,
    MANAGER: <ManagerRank />,
    GOLD: <GoldRank />,
    DIAMOND: <DiamondRank />,
  };
  const nextRankData = {
    MANAGER: "key_494",
    GOLD: "key_495",
    DIAMOND: "key_496",
    MASTER: "key_497"
  }

  const isMax =
    RankCondition[affiliateProfile.currentRank] === undefined ? true : false;


  return (
    <Page title={langLibrary.key_189}>
      <Grid container m={0} width="100%" justifyContent="space-between">
        <Grid item xs={12} sx={{ minHeight: 150, marginBottom: '1rem' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='flex-end' flexWrap="wrap" >
            {/* <Stack direction={'row'} alignItems="center" spacing={2} marginBottom={{ xs: "1rem", lg: "0rem" }}>
              <Stack>
                <Stack justifyContent={"flex-end"}>
                  <Typography variant="body2" textAlign={{ lg: 'left' }} sx={{ color: "#fff" }}>{langLibrary.key_557} {"ID"}</Typography>
                  <Typography variant="h4" mt={-1} textAlign={{ lg: 'left' }} sx={{ color: "#fff" }}>{profile.referralId ? profile.referralId : "-/-"}</Typography>
                </Stack>
                <Stack>
                  <CustomLabel sx={{ background: "transparent" }}>
                    <CopyContent
                      text={profile.referralEmail}
                    />
                  </CustomLabel>

                </Stack>
              </Stack>
              <UpdateReferral />
            </Stack> */}
            <Stack alignItems="flex-start">
              <Stack direction={"row"} spacing={1}>
                <Typography sx={{ color: "#fff", fontSize: '1.5rem', marginRight: 0.5 }}>{langLibrary.key_557}: </Typography>
                <Typography sx={{ color: "#fff", fontSize: '1.5rem' }}>{profile.referralId ? profile.referralId : "-/-"}</Typography>
                <UpdateReferral />
              </Stack>
              <Stack direction={"row"}>
                <CopyContent
                  text={langLibrary.key_580}
                  sxText={{ color: "#fff", fontSize: "1.5rem" }}
                  notSlice={true}
                  sizeIconCopy={"1.5rem"}
                  iconColor={"#fff"}
                />
              </Stack>

            </Stack>

            <Stack direction={{ xs: 'row-reverse', lg: 'row' }} marginTop={{ xs: 2, lg: 0 }} alignItems={'flex-end'} justifyContent={'flex-end'} spacing={2} width={{ xs: "100%", lg: '50%' }}>
              <Stack justifyContent={'flex-end'}>
                <Typography variant="body2" textAlign={{ xs: 'left', lg: 'right' }} sx={{ color: "#fff" }}>{langLibrary.key_278}</Typography>
                <Typography variant="h4" mt={-1} textAlign={{ xs: 'left', lg: 'right' }} sx={{ color: "#fff" }}>{profile?.id}</Typography>
                <CustomLabel>
                  <CopyContent
                    text={`${window.location.origin}/auth/register/${profile.id}`}
                  />
                </CustomLabel>
              </Stack>
              <QRBox>
                <QRCode
                  value={`${window.location.origin}/auth/register/${profile.id}`}
                  size={100}
                />
              </QRBox>
            </Stack>
          </Stack>
        </Grid>
        {RankCondition[affiliateProfile.currentRank] && (
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            alignItems="center"
            padding={{
              xs: "0px 0px 1rem 0px",
              lg: "0px 1rem 0px 0px",
            }}
          >
            <CustomCard width="100%">
              <Typography
                variant="h6"
                textAlign={{ xs: "center", sm: "left" }}
                padding={{ xs: "1rem 0px", sm: "1rem 1.5rem" }}
              >
                {langLibrary[nextRankData[affiliateProfile.nextRank]]}
              </Typography>
              <Divider />
              <Stack padding={{ xs: "1rem", sm: "1rem 1.5rem 1.5rem" }}>
                {RankCondition[affiliateProfile.currentRank]}
              </Stack>
            </CustomCard>
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={6} pt={2} pb={{ xs: 2, lg: 0 }} pr={{ xs: 0, lg: 2 }}>
          <MyBots justGetTable={true} />
        </Grid>
        <Grid item xs={12} md={12} lg={6} pt={2} pl={{ lg: 2 }} width={{ xs: 300, sm: "unset" }}>
          <DownLineList
            downlineId={profile.id}
          />
        </Grid>
        <Grid item xs={12} sx={{ minHeight: 150, marginBottom: '1rem', }}>
          <Stack direction='row' justifyContent='space-between' alignItems='flex-end' flexWrap="wrap" >
            <CommissionList />
          </Stack>
        </Grid>
      </Grid>

    </Page>
  );
}


const RenderRank = ({ currentRank }) => {
  const rankColor = {
    USER: "#7DDF87",
    MANAGER: "#AF7BF2",
    GOLD: "#F39839",
    DIAMOND: "#7CA7F3",
    MASTER: "#EA6086"
  }
  if (!currentRank) return null
  return <RankLabel sx={{ background: rankColor[currentRank] }}>
    <Stack sx={{ background: "#fff", borderRadius: '7px' }} direction='row' alignItems={'center'} pl={0.5}>
      <svg style={{ fill: rankColor[currentRank], height: 50, minWidth: 50, width: 50, transform: 'scale(0.6)' }}>
        <path d="M25 0 L12 15 L0 15 L25 37 " style={{ fillOpacity: 0.7 }} />
        <path d="M25 0 L25 37 L50 15 L38 15 " />
        <path d="M0 20 L0 28 L25 50 L25 42" style={{ fillOpacity: 0.7 }} />
        <path d="M25 42 L25 50 L50 28 L50 20" />
      </svg>
      <Typography sx={{ width: '100%' }} textAlign="center">{formatRankName(currentRank)}</Typography>
    </Stack>
  </RankLabel>
}

// ----------------------------------------------------------------------

const CustomCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: '0 1rem 1rem -0.75rem rgb(0 0 0 / 5%)',
  borderRadius: '15px',
  height: "100%",
  [theme.breakpoints.down('lg')]: {
    marginTop: '1rem'
  }
}));

const RankLabel = styled(Stack)(({ theme }) => ({
  borderRadius: "7px",
  width: 'fit-content',
  minWidth: 200,
  position: 'relative',
  "& .MuiTypography-root": {
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    padding: '0.4rem 1rem',
    background: "#fff",
    borderRadius: "7px",
    zIndex: 0,
  }
}));
