// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          [theme.breakpoints.down('sm')]: {
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: '"00 01" "10 11" "20 21" "end end"',
            paddingBottom: "0.5rem",
            borderBottom: "1px dashed rgba(255,255,255,0.05)"
          }
        },
        head: {
          [theme.breakpoints.down('sm')]: {
            display: "none",
          }
        }
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          whiteSpace: "nowrap"
        },
        head: {
          // color: theme.palette.text.secondary,
          background: `${theme.palette.background.neutral} !important`,
          borderRadius: '0px !important',
          '&:first-of-type': {
            paddingLeft: theme.spacing(3),
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            // boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3),
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            // boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
          },
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        body: {
          maxWidth: "400px",
          '&:first-of-type': {
            paddingLeft: theme.spacing(3),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3),
          },
          [theme.breakpoints.down('sm')]: {
            padding: 0,
            '&:first-of-type': {
              paddingLeft: 0,
            },
            '&:last-of-type': {
              paddingRight: 0,
            }
          }
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
          marginTop: "1rem",
          opacity: 0.7
        },
        toolbar: {
          height: 64,
          padding: 0
        },
        select: {
          '&:focus': {
            borderRadius: '5px',
          },
        },
        selectIcon: {
          marginTop: "-3px"
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0px 0.5rem"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minWidth: "48px",
          margin: "0.5rem 0px",
          borderRadius: "7px"
        }
      }
    }
  };
}
