// material
import { styled } from '@mui/material/styles';
import { Divider, Drawer, Hidden, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import $ from 'jquery'
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import { IconButtonAnimate } from './animate';
import { Box } from '@mui/system';
import { DRAWER_WIDTH } from '../layouts/config';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { _triggerDrawer } from '../store/actions/appActions';
import { useResponsive } from '../hooks';
// ----------------------------------------------------------------------

const DrawerHeader = styled(Box)(({ theme }) => ({
  justifyContent: "space-between",
}));

// ----------------------------------------------------------------------

export default function CustomDrawer({ children, open, onClose, actions, parent, title, anchor, paperStyle, zIndex }) {
  const [zIndexDrawer, setZIndexDrawer] = useState(1200000000)
  const dispatch = useDispatch()
  useEffect(() => {
    if (open === true) {
      setZIndexDrawer(zIndex ? zIndex : 1200)
      dispatch(_triggerDrawer(true))
      $(parent).css("transform", `translateX(${anchor === 'right' ? '-' : ''}100px)`)
      $("#mobile-toolbar").css("transform", `translateX(${anchor === 'right' ? '-' : ''}100px)`)

    } else {
      setTimeout(() => {
        setZIndexDrawer(zIndex ? zIndex : 1000000)
      }, 200);
      dispatch(_triggerDrawer(false))
      $(parent).css("transform", "unset")
      $("#mobile-toolbar").css("transform", "unset")
    }
  }, [open])

  useEffect(() => {
    return () => {
      dispatch(_triggerDrawer(false))
      $(parent).css("transform", "unset")
      $("#mobile-toolbar").css("transform", "unset")
    }
  }, [])

  return <>
    <Drawer sx={{ zIndex: `${zIndexDrawer} !important` }} anchor={anchor} open={open} onClose={() => onClose()} PaperProps={{
      sx: {
        border: 'none',
        zIndex: 1,
        height: "100%",
      }
    }}
    >
      <DrawerHeader>
        <Stack direction="row" alignItems="center" padding={1}>
          {
            anchor === 'right' && <IconButtonAnimate onClick={() => onClose()}>
              <Iconify icon={'line-md:arrow-left'} sx={{ width: 20, height: 20 }} />
            </IconButtonAnimate>
          }
          <Box sx={{ flexGrow: 1, textAlign: "center", paddingRight: anchor === 'right' ? "2rem" : "", paddingLeft: anchor === 'right' ? "" : "2rem" }}>{title}</Box>
          {
            anchor !== 'right' && <IconButtonAnimate onClick={() => onClose()}>
              <Iconify icon={'line-md:arrow-right'} sx={{ width: 20, height: 20 }} />
            </IconButtonAnimate>
          }
        </Stack>
        <Hidden smDown>
          <Divider />
        </Hidden>
      </DrawerHeader>
      <Scrollbar sx={{ width: DRAWER_WIDTH, height: "100%", ...paperStyle }}>
        {
          children
        }
      </Scrollbar>
      <Box sx={{ flexGrow: 1 }} />
      {
        actions && <>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Stack direction="row" alignItems="center" justifyContent="flex-start">
            {actions}
          </Stack>
        </>
      }
      <ToastContainer
        position="bottom-center"
        enableMultiContainer
        closeButton={false}
        closeOnClick={false}
        hideProgressBar
        autoClose={2000}
        theme="light"
      />
    </Drawer >
  </>;
}
