import { Card, Container, Grid, Hidden, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/robot.json'
import Image from '../../components/Image';
import { alpha, Box, useTheme } from '@mui/system';
import Iconify from '../../components/Iconify';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Procedure({ isVisible, ...other }) {
    const { app } = useSelector(state => state)
    const isTablet = useResponsive('down', 'md')
    const isMobile = useResponsive('down', 'sm')
    const theme = useTheme()
    const { langLibrary } = app ? app : {}

    return (<ContainerStyle {...other}>
        <Title variant='h3' color='primary' textAlign='center'>{langLibrary.key_553}</Title>
        <Stack my={isTablet ? (isMobile ? '-200px' : '-100px') : 1} direction={'row'} alignItems="center" justifyContent="center" width={'100vw'}  >
            <Stack direction={'row'} alignItems='center' justifyContent='center' sx={{ position: "relative", width: '1000px', mt: 10, transform: `scale(${isTablet ? (isMobile ? 0.4 : 0.6) : 1})` }}>
                <Image data src="/images/image-1.png" objectFit={'contain'} sx={{ width: '550px', marginRight: '450px', height: '550px', position: "relative", zIndex: 10 }} />
                {
                    isVisible && <>
                        <GuidItem index={0} isLeft lineWidth="180px" lineHeight="0px" ofset={{ left: '370px', top: '20px' }}
                            lineStye={{
                                borderBottom: '1px solid #51ae51',
                                width: "180px",
                                height: "0px",
                                zIndex: 0
                            }}
                            title={langLibrary.key_470}
                        />
                        <GuidItem index={1} isLeft lineWidth="330px" lineHeight="50px" zIndex={0} ofset={{ left: '220px', top: '60px' }}
                            lineStye={{
                                borderBottom: '1px solid #51ae51',
                                borderLeft: '1px solid #51ae51',
                                width: "330px",
                                height: "50px",
                                transform: "skew(.5rad)",
                            }}
                            title={langLibrary.key_471}
                        />
                        <GuidItem index={2} lineWidth="340px" lineHeight="30px" zIndex={0} ofset={{ left: '210px', top: '180px' }}
                            lineStye={{
                                borderTop: '1px solid #51ae51',
                                borderRight: '1px solid #51ae51',
                                width: "340px",
                                height: "30px",
                                transform: "skew(.8rad)",
                                marginRight: '10px'
                            }}
                            title={langLibrary.key_472}
                        />
                        <GuidItem index={3} lineWidth="225px" lineHeight="50px" zIndex={11} ofset={{ left: '325px', top: '250px' }}
                            lineStye={{
                                borderTop: '1px solid #51ae51',
                                borderRight: '1px solid #51ae51',
                                transform: "skew(.8rad)",
                                width: "225px",
                                height: "50px",
                                marginRight: '10px'
                            }}
                            title={langLibrary.key_473}
                        />
                        <GuidItem index={4} lineWidth="115px" lineHeight="45px" zIndex={11} ofset={{ left: '435px', top: '345px' }}
                            lineStye={{
                                borderBottom: '1px solid #51ae51',
                                borderLeft: '1px solid #51ae51',
                                width: "115px",
                                height: "45px",
                                transform: "skew(.8rad)",
                                marginRight: '10px'
                            }}
                            title={langLibrary.key_474}
                        />
                        <GuidItem index={5} lineWidth="180px" lineHeight='0px' ofset={{ left: '370px', top: '480px' }}
                            lineStye={{
                                borderBottom: '1px solid #51ae51',
                                zIndex: 0
                            }}
                            title={langLibrary.key_475}
                        />
                    </>
                }
            </Stack>
        </Stack>
    </ContainerStyle>
    );
}

const GuidItem = ({ title, ofset, index, zIndex, lineStye, lineWidth, lineHeight }) => {
    const animation = `@keyframes line-${index} {
        0%   { width:0px}
        100% { width:calc(${lineWidth} + 40px)}
      }
      @keyframes zoom-${index} {
        0%   { transform:scale(0)}
        70% { transform:scale(1.1)}
        100% { transform:scale(1)}
      }`;
    return <>
        <style children={animation} />
        <Stack direction={'row'} alignItems='center' sx={{ position: 'absolute', top: ofset?.top, right: ofset?.right, left: ofset?.left, bottom: ofset?.bottom, zIndex: zIndex }}>
            <Stack sx={{ position: "absolute", width: 0, animation: `line-${index} 2s forwards`, animationDelay: `${index}s`, height: 'lineHeight', overflow: 'hidden' }}>
                <Stack sx={{
                    ...lineStye,
                }} />
            </Stack>
            <Stack sx={{ width: lineWidth, height: 1 }}></Stack>
            <Stack direction={'row'} alignItems="center" justifyContent={'center'}
                sx={{
                    width: '50px', marginTop: lineHeight, height: '50px',
                    background: "linear-gradient(#0C947B,#51AE51)", borderRadius: "50%",
                    zIndex: 1,
                    position: "relative",
                    animation: `zoom-${index} 0.5s forwards`,
                    animationDelay: `${index + 1.2}s`,
                    transform: 'scale(0)',
                    "&:before": {
                        content: "''",
                        width: "calc(100% + 20px)",
                        height: "calc(100% + 20px)",
                        left: "-10px",
                        top: "-10px",
                        position: "absolute",
                        background: "#51AE51",
                        borderRadius: "50%",
                        zIndex: -1,
                        opacity: 0.3
                    }
                }}>
                <Typography variant='h3' fontWeight={'bold'} sx={{ color: "#fff" }}>{index + 1}</Typography>
            </Stack>
            <Typography noWrap variant='h5'
                sx={{
                    marginLeft: "10px",
                    position: "absolute", left: 'calc(100% + 20px)',
                    color: "#fff", marginTop: lineHeight,
                    animation: `zoom-${index} 0.5s forwards`,
                    animationDelay: `${index + 1.5}s`,
                    transform: 'scale(0)',
                }}
            >{title}</Typography>
        </Stack>
    </>
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: "5vw",
    paddingBottom: '50px',
    minHeight: '50vh',
}));
const Title = styled(Typography)(({ theme }) => ({
    color: "#63b545",
    position: "relative",
    '&:before': {
        content: '""',
        width: '100%',
        height: '2px',
        background: `linear-gradient(90deg,${alpha('#63b545', 0)},#63b545,${alpha('#63b545', 0)})`,
        left: 0,
        top: "-5px",
        position: "absolute"
    }
}));