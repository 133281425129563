import { Form, FormikProvider, useFormik } from "formik";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// component
import Countdown from "react-countdown";
import Iconify from "../../../components/Iconify";
// recaptcha
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
// api
import { useSelector } from "react-redux";
import Image from "../../../components/Image";
import { post, put } from "../../../utils/api";
import { isProduction } from "../../../settings/apiEndpoint";

// ----------------------------------------------------------------------

export default function ResetForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;

  const handleReCaptchaVerify = useCallback(async (successCallback) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("register");
    if (successCallback) successCallback(isProduction ? token : "recaptcha");
  });

  const handleGetOTP = (params, callback) => {
    handleReCaptchaVerify((token) => {
      post(
        `/user/send-reset-password-code?email=${params.email}&recaptcha=${token}`,
        null,
        () => {
          callback();
        },
        () => setIsSubmitting(false)
      );
    });
  };

  const handleReset = (params, callback) => {
    handleReCaptchaVerify((token) => {
      let submitParams = {
        email: params.email,
        password: params.password,
        otp: params.resetOTP,
        recaptcha: token,
      };
      put(
        `/user/reset-password`,
        submitParams,
        () => {
          callback();
        },
        () => {
          setIsSubmitting(false);
        }
      );
    });
  };

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().required(langLibrary.key_47).email(langLibrary.key_46),
    password: step === 2 && Yup.string().required(langLibrary.key_48),
    confirmPassword:
      step === 2 &&
      Yup.string()
        .required(langLibrary.key_58)
        .oneOf([Yup.ref("password"), null], langLibrary.key_59),
    resetOTP: step === 2 && Yup.string().required(langLibrary.key_68).length(6),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      resetOTP: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      setIsSubmitting(true);
      if (step === 1) {
        handleGetOTP(formik.values, () => {
          setStep(step + 1);
          setIsSubmitting(false);
        });
      }
      if (step === 2) {
        handleReset(formik.values, () => {
          setStep(step + 1);
          setIsSubmitting(false);
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {/* Step 1 ------------------------------------------------------------------------- */}

        {step === 1 && (
          <Stack spacing={2.5}>
            <Image
              alt="send-otp"
              src="https://zone-assets-api.vercel.app/assets/icons/ic_email_sent.svg"
              sx={{
                height: 120,
                width: 120,
                mx: "auto",
              }}
              objectFit="contain"
            />
            <Typography variant="h3" paragraph>
              {langLibrary.key_71}
            </Typography>
            <Typography variant="body2" sx={{ mb: 5 }}>
              {langLibrary.key_72}
            </Typography>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={langLibrary.key_51}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {langLibrary.key_73}
            </LoadingButton>
          </Stack>
        )}

        {/* Step 2 ------------------------------------------------------------------------- */}

        {step === 2 && (
          <Stack spacing={2.5}>
            <Image
              alt="lock_password"
              src="https://zone-assets-api.vercel.app/assets/icons/ic_lock_password.svg"
              sx={{
                height: 120,
                width: 120,
                mx: "auto",
              }}
              objectFit="contain"
            />
            <Typography variant="h3" paragraph>
              {langLibrary.key_74}
            </Typography>
            <Typography variant="body2" sx={{ mb: 5 }}>
              {langLibrary.key_75}
            </Typography>
            <TextField
              fullWidth
              autoComplete="Email OTP - 6dig"
              type="string"
              label="OTP"
              {...getFieldProps("resetOTP")}
              error={Boolean(touched.resetOTP && errors.resetOTP)}
              helperText={touched.resetOTP && errors.resetOTP}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label={langLibrary.key_76}
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              fullWidth
              autoComplete="Confirm-password"
              type={showPassword ? "text" : "password"}
              label={langLibrary.key_62}
              {...getFieldProps("confirmPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {langLibrary.key_41}
            </LoadingButton>
          </Stack>
        )}

        {/* Step 4 ------------------------------------------------------------------------- */}

        {step === 3 && (
          <Stack spacing={3} justifyContent="center">
            <img
              alt=""
              src="/images/register-success.jpeg"
              style={{ width: "70%", margin: "auto", borderRadius: "16px" }}
            />
            <Typography variant="h4" align="center">
              {langLibrary.key_77}
            </Typography>
            <Typography
              style={{ opacity: 0.7, margin: "0 0 5rem" }}
              align="center"
            >
              {langLibrary.key_66}{" "}
              <Countdown
                date={Date.now() + 9000}
                onComplete={() => navigate("/auth/login")}
                renderer={(props) => countDownRenderer(props)}
              />
            </Typography>
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}

const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return "";
  } else {
    return <span>{seconds}s</span>;
  }
};
