import styled from '@emotion/styled';
import { Hidden, IconButton, Stack, Step, StepConnector, StepContent, StepLabel, Stepper, Typography, stepConnectorClasses, useTheme } from '@mui/material';
import React from 'react'
import Iconify from '../../components/Iconify';
import { useResponsive } from '../../hooks';
import { useSelector } from 'react-redux';

const steps = [
    {
        label: `2016`,
        description:
            [
                `key_676`
            ],
        active: true
    },
    {
        label: `2018`,
        description:
            [
                `key_677`
            ],
        active: true
    },
    {
        label: `2019`,
        description:
            [
                `key_678`
            ],
        active: true
    },
    {
        label: `2021`,
        description:
            [
                `key_679`,
                `key_680`,
                `key_681`
            ],
        active: true
    },
    {
        label: `2022`,
        description:
            [
                `key_682`,
                `key_683`
            ],
        active: true
    },
    {
        label: `2023 - Q1`,
        description:
            [
                `key_684`,
                `key_685`,
                `key_686`,
                `key_687`,
                `key_688`
            ],
        active: true
    },
    {
        label: `2023 - Q2`,
        description:
            [
                `key_689`,
                `key_690`,
                `key_691`,
                `key_692`
            ],
        active: false
    },
    {
        label: `2023 - Q3`,
        description:
            [
                `key_693`
            ],
        active: false
    },
];
const ContainerStyle = styled(Stack)(({ theme }) => ({
    alignItems: 'center',
    paddingTop: "80px",
    paddingBottom: '50px',
    minHeight: '50vh',
    width: "100%",
    [theme.breakpoints.down("1160")]: {
        paddingInline: "30px !important"
    },
    [theme.breakpoints.down("1050")]: {
        paddingTop: "150px !important"
    },
    [theme.breakpoints.down("md")]: {
        paddingTop: "0 !important"
    },
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        // top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {

            borderColor: '#FD5685',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#FD5685',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#fff',
        borderTopWidth: 1,
        borderRadius: 1,
    },
}));
const QontoConnectorMobile = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {

            borderColor: '#FD5685',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#FD5685',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#fff',
        borderTopWidth: 1,
    },

}));

const QontoStepIconRoot = styled('div')(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

export const Roadmap = () => {
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const theme = useTheme()
    const isMobile = useResponsive("down", "md")
    function QontoStepIcon(props) {
        const { completed, active } = props;

        return (
            <QontoStepIconRoot>
                <IconButton sx={{
                    width: 59,
                    height: 59,
                    padding: 0,
                    background: completed || active ? "#853249" : "#505050",
                    zIndex: 10,
                    ':hover': {
                        background: completed || active ? "#853249" : "#505050",
                    }

                }} >
                    <Iconify icon={'ic:baseline-circle'} sx={{ width: 40, height: 40, color: completed || active ? "#FD5685" : "" }} />
                </IconButton>
            </QontoStepIconRoot>
        );
    }

    return (
        <>
            <Stack marginBottom={isMobile ? 5 : 15} marginTop={20}>
                <Typography textAlign={'center'} variant='h2'>{langLibrary.key_694}<span style={{ color: theme.palette.primary.main }}>{langLibrary.key_695}</span></Typography>
            </Stack >
            <Stack mt={isMobile ? 0 : 20} />
            <ContainerStyle>
                <Hidden mdDown>
                    <Stepper alternativeLabel activeStep={5} connector={<QontoConnector />} sx={{ width: "100%" }}>
                        {steps.map((stepInfo, indexStep) => (
                            <Step
                                key={stepInfo.label}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "relative",
                                    padding: 0,
                                }}
                            >
                                <Stack
                                    sx={{
                                        position: "absolute",
                                        top: "-2rem",
                                        width: "100%"
                                    }}

                                >
                                    <Stack
                                        sx={{
                                            position: "absolute",
                                            bottom: 0,
                                            width: "100%"
                                        }}
                                    >

                                        {indexStep % 2 === 1 && (
                                            <Stack flexDirection={"column-reverse"} width={"100%"} >

                                                <Typography textAlign={"center"} fontSize={"20px"} sx={{ marginTop: 2 }}>{stepInfo.label}</Typography>
                                                <Stack sx={{ marginLeft: "-40px", marginRight: "-40px" }}>
                                                    {stepInfo.description.map((des, index) => (
                                                        <Stack direction={"row"} alignItems={"flex-start"} spacing={0.1}>
                                                            <IconButton disabled>
                                                                <Iconify icon={"ic:round-square"} sx={{ width: "10px !important", height: "10px !important", color: theme.palette.primary.main }} />
                                                            </IconButton>
                                                            <Typography variant='caption' fontSize={"16px"} key={index} textAlign={"left"} fontFamily={"Exo,sans-serif !important"}>
                                                                {langLibrary[des]}
                                                            </Typography>
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        )}
                                    </Stack>

                                </Stack>
                                <StepLabel StepIconComponent={QontoStepIcon}>
                                    {indexStep % 2 === 0 && (
                                        <>
                                            <Stack spacing={2} sx={{ marginTop: 4 }}>
                                                <Typography fontSize={"20px"}>{stepInfo.label}</Typography>
                                                <Stack>
                                                    {stepInfo.description.map((des, index) => (
                                                        <Stack direction={"row"} alignItems={"flex-start"} spacing={0.1} sx={{ marginLeft: indexStep === 0 ? "-30px" : "-40px", marginRight: "-50px" }}>
                                                            <IconButton disabled>
                                                                <Iconify icon={"ic:round-square"} sx={{ width: "10px !important", height: "10px !important", color: theme.palette.primary.main }} />
                                                            </IconButton>
                                                            <Typography variant='caption' fontSize={"16px"} key={index} textAlign={"left"} fontFamily={"Exo,sans-serif !important"}>
                                                                {langLibrary[des]}
                                                            </Typography>
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        </>
                                    )}
                                </StepLabel>

                            </Step>
                        ))}
                    </Stepper>
                </Hidden>
                <Hidden mdUp>
                    <Stepper orientation='vertical' activeStep={5} connector={<QontoConnectorMobile />}
                        sx={{
                            '.MuiStepConnector-root': {
                                marginLeft: "30px",
                            }
                        }}
                    >
                        {steps.map((stepInfo, indexStep) => (
                            <Step
                                key={stepInfo.label}
                                expanded={true}
                            >
                                <StepLabel StepIconComponent={QontoStepIcon}>
                                    <Typography fontSize={"20px"}>{stepInfo.label}</Typography>
                                </StepLabel>
                                <StepContent
                                    sx={{
                                        marginLeft: "30px",
                                        borderLeft: indexStep === 7 ? "" : `1px solid ${indexStep < 5 ? "#FD5685" : "#fff"}`,
                                    }}
                                >
                                    {stepInfo.description.map((des, index) => (
                                        <Stack direction={"row"} alignItems={"flex-start"}>
                                            <IconButton disabled>
                                                <Iconify icon={"ic:round-square"} sx={{ width: "10px !important", height: "10px !important", color: theme.palette.primary.main }} />
                                            </IconButton>
                                            <Typography variant='caption' fontSize={"16px"} key={index} textAlign={"left"} fontFamily={"Exo,sans-serif !important"}>
                                                {langLibrary[des]}
                                            </Typography>
                                        </Stack>
                                    ))}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Hidden>
            </ContainerStyle >
        </>
    )
}
