import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },

      styleOverrides: {
        root: {
          textTransform: "initial",
          color: isLight ? theme.palette.primary.light : theme.palette.primary.main,
          [theme.breakpoints.down('sm')]: {
            fontSize: "0.875rem"
          },
        },
        sizeLarge: {
          height: 48,
        },
        contained: {
          color: theme.palette.grey[900],
          background: theme.palette.primary,
          position: 'relative',
          "&:before, &:after": {
            content: '""',
            width: "0px",
            height: "1px",
            position: "absolute",
            transition: 'all 0.2s ease-in-out'
          },
          "&:before": {
            top: '-5px',
            left: '0px',
            background: "linear-gradient(90deg,rgba(0,0,0,0),#F85482)",
            borderRadius: '0px 0px 10px 0px',
          },
          "&:after": {
            bottom: '-5px',
            right: '0px',
            background: "linear-gradient(90deg,#42E5F3,rgba(0,0,0,0))",
            borderRadius: '0px 0px 0px 10px',
          },
          '&:hover': {
            background: theme.palette.primary,
            "&:before,&:after": {
              width: "100%"
            }
          },
        },
        outlined: {
          color: isLight ? theme.palette.primary.light : theme.palette.grey[300],
          borderColor: isLight ? theme.palette.primary.light : theme.palette.grey[300],
        },
        containedInherit: {
          color: isLight ? theme.palette.common.white : theme.palette.grey[800],
          backgroundColor: isLight ? theme.palette.grey[800] : theme.palette.common.white,
          '&:hover': {
            backgroundColor: isLight ? theme.palette.grey[700] : theme.palette.grey[400],
          },
        },
        outlinedInherit: {
          borderColor: alpha(theme.palette.grey[500], 0.32),
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            borderColor: theme.palette.text.primary,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: isLight ? theme.palette.grey[800] : theme.palette.grey[400],
        },
        colorPrimary: {
          color: isLight ? theme.palette.primary.light : theme.palette.primary.main,
        }
      }
    }
  };
}
