import { Button, styled } from '@mui/material';
import { Stack, } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from '../../utils/api';
import FlexibleForm from './FlexibleForm';
import PermanentForm from './PermanentForm';



// ----------------------------------------------------------------------



export default function Stake({ onChange, onSuccess }) {
    const { app, user } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const { funding } = user ? user : {}
    const [tabIndex, setTabIndex] = useState('')
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)

    const StakingType = [
        { title: langLibrary.key_703, value: "FLEXIBLE" },
        { title: langLibrary.key_704, value: "PERMANENT" }
    ]

    const handleGetProduct = () => {
        setLoading(true)
        get('/staking/product/list', result => {
            setProducts(result)
            setTimeout(() => {
                setLoading(false)
            }, 1000);
        }, () => {
            setLoading(false)
        })
    }
    useEffect(() => {
        handleGetProduct()
        setTabIndex(StakingType[1].value)
    }, [])

    const productSelected = products.find(item => item.type === tabIndex)
    const balance = funding ? funding.find(item => item.asset === "USDT") : null

    return (
        <>
            <Stack direction={'row'} alignItems='center'>
                {
                    StakingType.map((item, index) => <CustomButton key={index} onClick={() => setTabIndex(item.value)} sx={{ background: tabIndex === item.value ? "none" : 'rgba(0,0,0,0.3)' }}>{item.title}</CustomButton>)
                }
            </Stack>
            <Stack p={3}>
                {
                    tabIndex === StakingType[0].value && <FlexibleForm loading={loading} onChange={onChange} onSuccess={handleGetProduct} balance={balance} product={productSelected?.products[0]} poolStaked={productSelected?.poolStaked} />
                }
                {
                    tabIndex === StakingType[1].value && <PermanentForm loading={loading} onChange={onChange} onSuccess={handleGetProduct} balance={balance} products={productSelected?.products} poolStaked={productSelected?.poolStaked} />
                }
            </Stack>
        </>
    );
}

const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    width: '100%',
    height: 50,
    color: '#fff'
}));