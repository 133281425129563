import { Container, Divider, Grid, Hidden, Link, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/robot-playing.json'
import Logo from '../../components/Logo';
import DisclaimerLiability from './DisclaimerLiability';
import { IconButtonAnimate } from '../../components/animate';
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { SocialMedia } from '../../components/SocialMedia';
import { _triggerPolicy } from '../../store/actions/appActions';



// ----------------------------------------------------------------------

export default function FooterBanner({ ...other }) {
    const theme = useTheme()
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const dispatch = useDispatch()

    const openLink = (link) => {
        window.open(link)
    }

    return (<>
        <ContainerStyle {...other}>
            <Container >
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} alignItems='center' justifyContent={'center'}>
                        <Stack sx={{ width: 300, margin: 'auto' }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                            <Logo />
                            <Stack mt={3} direction='row' alignItems='center' justifyContent='center' spacing={1}>
                                <Iconify icon='tabler:square-rounded-letter-c' sx={{ width: 15, height: 15, marginBottom: '2px' }} />
                                <Typography fontSize={'1.2rem'}>2019 - 2023</Typography>
                            </Stack>
                        </Stack>
                        <Typography mt={3} textAlign={'center'} variant='body3'>{langLibrary.key_664}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Stack py={5} maxWidth={500} spacing={4} margin="auto">
                            <SocialMedia />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction={{ xs: "", md: 'row' }} alignItems="center" justifyContent={'center'} spacing={{ xs: 0, md: 2 }} divider={<Divider orientation='vertical' sx={{ height: { xs: 0, md: 15 }, background: "#fff" }} />}>
                            <Link sx={{ color: "#fff" }} onClick={() => dispatch(_triggerPolicy(true))}>{langLibrary.key_578}</Link>
                            <Link sx={{ color: "#fff" }}>{langLibrary.key_579}</Link>
                            <Link sx={{ color: "#fff" }}>{langLibrary.key_580}</Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Typography textAlign={'center'} fontFamily={'arial !important'} sx={{ opacity: 0.6 }}>Powered By SR Finance</Typography>
                    </Grid>
                </Grid>
            </Container>
        </ContainerStyle>
    </>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    position: 'relative',
    minHeight: '400px',
    padding: "5rem 0px",
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid rgba(255,255,255,0.5)',
    overflow: 'hidden',
    "& img": {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    "&:before": {
        content: '""',
        width: '70%',
        height: "100%",
        boxShadow: '0px 0px 150px #43E9F5 , 0px 0px 10px #43E9F5',
        position: "absolute",
        transition: "all 0.2s ease-in-out",
        borderRadius: '100%',
        left: '15%',
        top: "-100%"
    }
}));