import { Form, FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// component
import Countdown from "react-countdown";
import Iconify from "../../../components/Iconify";
// recaptcha
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
// api
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { post } from "../../../utils/api";
import { isProduction } from "../../../settings/apiEndpoint";
import { useParams } from "react-router-dom";

// ----------------------------------------------------------------------

export default function RegisterForm({ onSuccess }) {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { referralId } = useParams()
  useEffect(() => {
    if (referralId) {
      formik.setValues({
        email: formik.values.email,
        registerOTP: formik.values.registerOTP,
        password: formik.values.password,
        confirmPassword: formik.values.confirmPassword,
        referralId: referralId
      })
    }
  }, [referralId])


  const handleReCaptchaVerify = useCallback(async (successCallback) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("register");
    if (successCallback) successCallback(isProduction ? token : "recaptcha");

  });

  const handleGetOTP = (params, callback) => {
    handleReCaptchaVerify((token) => {
      post(
        `/user/send-register-code?email=${params.email}&recaptcha=${token}`,
        null,
        () => {
          if (callback) callback();
        },
        () => setIsSubmitting(false)
      );
    });
  };

  const handleRegister = (params, callback) => {
    handleReCaptchaVerify((token) => {
      let submitParams = {
        email: params.email,
        password: params.password,
        otp: params.registerOTP,
        recaptcha: token,
        invitationCode: params.referralId
      };
      post(
        `/user/register`,
        submitParams,
        () => {
          if (callback) callback();
        },
        () => {
          setIsSubmitting(false);
        }
      );
    });
  };

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email(langLibrary.key_46).required(langLibrary.key_47),
    password: step === 1 && Yup.string().required(langLibrary.key_48).test('len', langLibrary.key_210, value => value.length >= 8),
    confirmPassword:
      step === 1 &&
      Yup.string()
        .required(langLibrary.key_58)
        .test('len', langLibrary.key_210, value => value.length >= 8)
        .oneOf([Yup.ref("password"), null], langLibrary.key_59),
    registerOTP:
      step === 2 && Yup.string().required(langLibrary.key_60).length(6),
    referralId: Yup.string().nullable()
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      registerOTP: "",
      password: "",
      confirmPassword: "",
      referralId: null
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      setIsSubmitting(true);
      if (step === 1) {
        handleGetOTP(formik.values, () => {
          setStep(step + 1);
          setIsSubmitting(false);
        });
      }
      if (step === 2) {
        handleRegister(formik.values, () => {
          navigate("/auth/login")
          setIsSubmitting(false);
          onSuccess();
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {/* Step 1 ------------------------------------------------------------------------- */}

        {step === 1 && (
          <Stack spacing={2.5}>
            <Typography variant="h3" paragraph>
              {langLibrary.key_34}
            </Typography>
            <Typography variant="body2">
              {langLibrary.key_61}
              <RouterLink to="/auth/login">
                <Link variant="subtitle2" color="primary">
                  {""} {langLibrary.key_32}
                </Link>
              </RouterLink>
            </Typography>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={langLibrary.key_51}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label={langLibrary.key_52}
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        color="#ffffff"
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              fullWidth
              autoComplete="Confirm-password"
              type={showPassword ? "text" : "password"}
              label={langLibrary.key_62}
              {...getFieldProps("confirmPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        color="#ffffff"
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />

            <TextField
              fullWidth
              autoComplete="Referral-id"
              type={"text"}
              label={langLibrary.key_278}
              {...getFieldProps("referralId")}
              error={Boolean(touched.referralId && errors.referralId)}
              helperText={touched.referralId && errors.referralId}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {langLibrary.key_40}
            </LoadingButton>
          </Stack>
        )}

        {/* Step 2 ------------------------------------------------------------------------- */}

        {step === 2 && (
          <Stack spacing={3}>
            <Typography variant="h3" paragraph>
              {langLibrary.key_57}
            </Typography>
            <Typography variant="body2">
              {langLibrary.key_63}
            </Typography>
            <TextField
              fullWidth
              autoComplete="Email code - 6dig"
              type="string"
              label={langLibrary.key_64}
              {...getFieldProps("registerOTP")}
              error={Boolean(touched.registerOTP && errors.registerOTP)}
              helperText={touched.registerOTP && errors.registerOTP}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {langLibrary.key_57}
            </LoadingButton>
          </Stack>
        )}


      </Form>
    </FormikProvider>
  );
}