import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ReactSlider from "react-slider";
// @mui
import { Backdrop, Box, CircularProgress, Stack, Table, TableBody, TableContainer, TableHead } from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";
import Scrollbar from "./Scrollbar";
import $ from 'jquery'
import CustomDrawer from "./CustomDrawer";

// ----------------------------------------------------------------------

const BackdropStyle = styled(Backdrop)(({ theme }) => ({
    position: "absolute",
    background: alpha(theme.palette.background.paper, 0.8),
    borderRadius: theme.shape.borderRadius,
    backdropFilter: "blur(1px)",
    zIndex: 999,
}));
const CustomScrollBar = styled(Scrollbar)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
}));

// ----------------------------------------------------------------------

const CustomTable = ({ tableHead, tableBody, tablePagination, loading, maxHeight, minHeight, smMinWidth }) => {
    const theme = useTheme()
    return (
        <>
            <TableContainer sx={{ position: "relative", overflow: 'hidden', minHeight: minHeight ? minHeight : "" }}>
                <CustomScrollBar sx={{ position: "relative", zIndex: 1, overflow: loading ? "hidden" : "auto", maxHeight: maxHeight }}>
                    <Table sx={{
                        minWidth: {
                            xs: "100%",
                            sm: smMinWidth ? smMinWidth : 600,
                        },
                        overflow: "scroll",
                    }}>
                        <TableHead sx={{
                            '.MuiTableCell-root': {
                                background: "none",
                                borderBottom: "1px solid rgba(145, 158, 171, 0.2)"
                            }
                        }}>
                            {tableHead}
                        </TableHead>
                        <TableBody>
                            {tableBody}
                        </TableBody>
                    </Table>
                </CustomScrollBar>
                {tablePagination}
                <BackdropStyle open={loading} >
                    <CircularProgress color="primary" />
                </BackdropStyle>
            </TableContainer>
        </>
    );
};

export default CustomTable;
