const Local = {
  API: "https://st.runaheadteam.com/api/v1",
  CAPTCHA_KEY: "6Lc46uIfAAAAAKdogVq0RjX5MV4FlyqignUFA_DD",
};

const Production = {
  API: "/api/v1",
  CAPTCHA_KEY: "6LeanpkpAAAAAAzAvTaw5SBq6tEPtKlacHzgQN6o",
};

export const isProduction =
  window.location.hostname.indexOf("ubot.finance") >= 0;

export const API = isProduction ? Production.API : Local.API;

export const CAPTCHA_KEY = isProduction
  ? Production.CAPTCHA_KEY
  : Local.CAPTCHA_KEY;
