import { useDispatch, useSelector } from "react-redux";
// @mui
import {
  Button,
  Divider,
  Stack,
  Typography,
  Avatar,
  InputAdornment,
  TextField,
  Hidden,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

// icon

import { fCurrency, fRoundDown } from "../../utils/format";
import CopyContent from "../../components/CopyContent";
import $, { param } from "jquery";
import { LoadingButton } from "@mui/lab";
import { post } from "../../utils/api";
import { _getFunding } from "../../store/actions/userActions";
import CustomDrawer from "../../components/CustomDrawer";
import CoinSelector from "../../components/CoinSelector";
import Popup from "../../components/Popup";
import { NetworkNeedMemo } from "../../settings/constants";

// ----------------------------------------------------------------------

export default function Withdraw({ onSuccess, ...other }) {
  const dispatch = useDispatch();
  const { user, app } = useSelector((state) => state);
  const { funding } = user ? user : {};
  const { contract, config, langLibrary } = app ? app : {};
  const { decimals } = config ? config : {};
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [coinSelected, setCoinSelected] = useState(null);
  const [available, setAvailable] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [withdrawFee, setWithdrawFee] = useState(0)
  const [feeAvailable, setFeeAvailable] = useState(true);
  const [showConfirmMemo, setShowConfirmMemo] = useState(false)
  const [withdrawFeeCurrency, setWithdrawFeeCurrency] = useState(null);

  const ReferralSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .positive()
      .max(available ? parseFloat(fRoundDown(available, 4)) : 0),
    toAddress: Yup.string().required(langLibrary.key_360),
    gaCode:
      user &&
      user.profile &&
      user.profile.gaEnable &&
      showConfirm &&
      Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      toAddress: "",
      memo: "",
      gaCode: "",
    },
    validationSchema: ReferralSchema,
    onSubmit: () => {
      if (NetworkNeedMemo.includes(coinSelected.network) && formik.values.memo.length === 0) {
        setShowConfirmMemo(true)
      } else {
        if (!showConfirm) {
          setShowConfirm(true);
        } else {
          handleWithdraw();
        }
      }
    },
  });

  const handleWithdraw = () => {
    let params = {
      amount: formik.values.amount,
      asset: coinSelected.asset,
      receiver: formik.values.toAddress,
      memo: formik.values.memo,
      gaCode: formik.values.gaCode,
    };
    if (!NetworkNeedMemo.includes(coinSelected.network) || params.memo.length === 0) {
      params.memo = null
    }
    setIsSubmitting(true);
    post(
      "/wallet/withdraw-ext",
      params,
      () => {
        dispatch(_getFunding());
        setIsSuccess(true);
        setIsSubmitting(false);
        onSuccess(langLibrary.key_209);
        handleClose();
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  const handleSelectCoin = (coin) => {
    setCoinSelected(coin)
  }

  const handleSkipMemo = () => {
    setShowConfirmMemo(false)
    setShowConfirm(true);
  }

  const handleClose = () => {
    setShowForm(false);
    setAvailable(null);
    setCoinSelected(null);
    setShowConfirm(false);
    setIsSuccess(false);
    const inputParams = {
      amount: "",
      toAddress: "",
      memo: "",
      gaCode: "",
    };
    formik.setValues(inputParams);
    setTimeout(() => {
      formik.setErrors(inputParams);
    }, 100);
  };

  const handleClickMax = () => {
    formik.setValues({
      amount: fRoundDown(available, 4),
      toAddress: formik.values.toAddress,
      memo: formik.values.memo,
      gaCode: formik.values.gaCode,
    });
  };

  const handleCheckFee = () => {
    const { funding } = user;
    if (funding) {
      const found = funding.find((item) => item.asset === coinSelected.asset);
      const assetContract = contract.find(
        (item) => item.symbol === coinSelected.asset
      );
      const fee = assetContract ? assetContract.withdrawFee : 0;
      setWithdrawFee(fee)
      setWithdrawFeeCurrency(assetContract ? assetContract.withdrawFeeCurrency : "")

      if (!found) return false;
      const feeContract = funding.find((item) => item.asset === assetContract.withdrawFeeCurrency);
      if (coinSelected && coinSelected.asset === assetContract.withdrawFeeCurrency) {
        if (
          parseFloat(formik.values.amount ? formik.values.amount : 0) + fee <=
          found.amount
        ) {
          return true;
        }
      } else {
        if (feeContract.amount >= fee) return true;
      }

    }
    return false;
  };

  useEffect(() => {
    const { funding } = user;
    if (funding && coinSelected) {
      const found = funding.find((item) => item.asset === coinSelected.asset);
      if (found) {
        const rs = found.amount - feeNeedSubtract(found.asset)
        setAvailable(rs < 0 ? 0 : rs)
      }
      const temp = handleCheckFee();
      setFeeAvailable(temp);
    }
  }, [coinSelected]);

  useEffect(() => {
    if (coinSelected) {
      const temp = handleCheckFee();
      setFeeAvailable(temp);
    }
  }, [formik.values.amount]);

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  if (!user || !user.profile) return null;
  const { profile } = user;
  const { gaEnable } = profile;
  const withdrawEnable = coinSelected ? coinSelected.withdrawEnable : false;

  const feeNeedSubtract = (asset) => {
    const assetContract = contract.find(
      (item) => item.symbol === coinSelected.asset
    );
    if (asset === assetContract.withdrawFeeCurrency) {
      return assetContract.withdrawFee
    } else {
      return 0
    }
  }

  return (
    <>
      <Button
        size={"large"}
        onClick={() => setShowForm(true)}
        color="primary"
        variant="outlined"
        sx={{ width: "100%" }}
      >
        {langLibrary.key_184}
      </Button>
      <FormikProvider value={formik}>
        <CustomDrawer
          anchor={"right"}
          paperStyle={{ maxWidth: "500px", width: "100vw" }}
          title={langLibrary.key_184}
          open={Boolean(showForm)}
          parent={$("#root")}
          onClose={() => handleClose(false)}
        >
          <Stack padding="1rem">
            <Hidden smDown>
              <img
                alt=""
                src="/images/withdraw-image.png"
                height={250}
                style={{ objectFit: "contain" }}
              />
            </Hidden>
            <Box
              sx={{ borderRadius: "7px", marginTop: { xs: "2rem", md: "0px" } }}
            >
              <CoinSelector
                options={funding}
                onSelect={(option) => handleSelectCoin(option)}
                sx={{ width: "100% !important" }}
              />
            </Box>
            {!withdrawEnable && (
              <Typography
                variant="body2"
                sx={{
                  padding: "0.5rem 1rem",
                  color: "#d85f5f !important",
                  background: "#ffeded",
                  margin: "1rem 0px",
                  borderRadius: "7px",
                }}
              >
                {langLibrary.key_185}
              </Typography>
            )}
            {coinSelected && (
              <Form onSubmit={handleSubmit}>
                <TextField
                  disabled={!withdrawEnable}
                  sx={{ marginTop: "1rem" }}
                  fullWidth
                  autoComplete="Amount"
                  type={"text"}
                  label={langLibrary.key_100}
                  {...getFieldProps("amount")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>{coinSelected.asset}</Typography>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ margin: "2px 0 0 0.7rem" }}
                >
                  <Typography variant="body2">
                    {langLibrary.key_144}:
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography variant="body2" noWrap>
                    {fCurrency(available ? available : 0, decimals)}{" "}
                    {coinSelected.asset}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ opacity: 0.4, margin: "0px 0.1rem 0px 1rem" }}
                  >
                    |
                  </Typography>
                  <Button
                    disabled={!withdrawEnable}
                    color="primary"
                    onClick={() => handleClickMax()}
                  >
                    {langLibrary.key_145}
                  </Button>
                </Stack>
                <TextField
                  disabled={!withdrawEnable}
                  sx={{ marginTop: "1rem" }}
                  fullWidth
                  autoComplete="Address"
                  type={"text"}
                  label={langLibrary.key_359}
                  {...getFieldProps("toAddress")}
                  error={Boolean(touched.toAddress && errors.toAddress)}
                  helperText={touched.toAddress && errors.toAddress}
                />
                {
                  NetworkNeedMemo.includes(coinSelected.network) && <TextField
                    disabled={!withdrawEnable}
                    sx={{ marginTop: "1rem" }}
                    fullWidth
                    autoComplete="Memo"
                    type={"text"}
                    label={'Memo'}
                    {...getFieldProps("memo")}
                    error={Boolean(touched.memo && errors.memo)}
                    helperText={touched.memo && errors.memo}
                  />
                }
                <Divider sx={{ borderStyle: "dashed", margin: "1rem 0px" }} />
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    padding: "0.5rem 0.7rem",
                  }}
                >
                  <Typography>{langLibrary.key_101}:</Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography noWrap>
                    {fCurrency(withdrawFee, decimals)} {" "}
                    {withdrawFeeCurrency && withdrawFeeCurrency}
                  </Typography>
                </Stack>
                <LoadingButton
                  disabled={!withdrawEnable || !feeAvailable}
                  sx={{ marginTop: "1rem" }}
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  {langLibrary.key_184}
                </LoadingButton>
              </Form>
            )}
          </Stack>
        </CustomDrawer>
        <CustomDrawer
          anchor={"right"}
          paperStyle={{ maxWidth: "500px", width: "100vw" }}
          title={"Confirm withdraw"}
          open={Boolean(showConfirm)}
          hideBackdrop
          onClose={isSuccess ? null : () => setShowConfirm(false)}
        >
          {coinSelected && !isSuccess && (
            <Stack sx={{ padding: "1rem 1.5rem" }} spacing={1}>
              <Typography sx={{ padding: "0.5rem 0px" }}>
                {langLibrary.key_186}
              </Typography>
              <Stack
                sx={{
                  padding: "10px 15px",
                  background: theme => theme.palette.background.neutral,
                  borderRadius: "10px",
                }}
                spacing={1}
              >
                <Stack direction="row" justifyContent='space-between'>
                  <Typography sx={{ width: "100px", opacity: 0.7 }}>
                    {langLibrary.key_100}:
                  </Typography>
                  <Typography>
                    {fCurrency(formik.values.amount, decimals)}{" "}
                    {coinSelected.asset}
                  </Typography>
                </Stack>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Stack direction="row" justifyContent='space-between'>
                  <Typography sx={{ width: "100px", opacity: 0.7 }}>
                    {langLibrary.key_101}:
                  </Typography>
                  <Typography>
                    {fCurrency(withdrawFee, decimals)}{" "}
                    {withdrawFeeCurrency && withdrawFeeCurrency}
                  </Typography>
                </Stack>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Stack>
                  <Typography sx={{ width: "200px", opacity: 0.7 }}>
                    {langLibrary.key_391}:
                  </Typography>
                  <CopyContent text={formik.values.toAddress} hideButton />
                </Stack>
              </Stack>
              <Box sx={{ height: 20 }} />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                {gaEnable && (
                  <>
                    <Typography mb="5px">{langLibrary.key_115}</Typography>
                    <TextField
                      fullWidth
                      autoComplete="Ga code"
                      type={"text"}
                      // label={"Google authenticator"}
                      {...getFieldProps("gaCode")}
                      error={Boolean(touched.gaCode && errors.gaCode)}
                      helperText={touched.gaCode && errors.gaCode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Avatar
                              src={
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Google_Authenticator_for_Android_icon.svg/1200px-Google_Authenticator_for_Android_icon.svg.png"
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
                <Stack direction="row" spacing={2}>
                  <Button
                    onClick={() => setShowConfirm(false)}
                    sx={{ width: "100%" }}
                    variant="outlined"
                    color="default"
                  >
                    {langLibrary.key_87}
                  </Button>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {langLibrary.key_57}
                  </LoadingButton>
                </Stack>
              </Form>
            </Stack>
          )}
        </CustomDrawer>
      </FormikProvider>

      <Popup title={langLibrary.key_57} open={showConfirmMemo} onClose={() => setShowConfirmMemo(false)}>
        <Typography sx={{ marginTop: "-3rem", marginBottom: "1rem", maxWidth: "300px" }}>{langLibrary.key_207}?</Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" color="inherit" onClick={() => setShowConfirmMemo(false)}>{langLibrary.key_87}</Button>
          <Button variant="contained" color="primary" onClick={() => handleSkipMemo()}>{langLibrary.key_98}</Button>
        </Stack>
      </Popup>
    </>
  );
}
