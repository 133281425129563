import { LoadingButton } from '@mui/lab';
import { Backdrop, Button, Checkbox, CircularProgress, Divider, Grid, IconButton, InputAdornment, Stack, styled, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { Form, FormikProvider, useFormik } from "formik";
import $ from "jquery";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { MotionViewport } from '../../components/animate';
import CoinSelector from '../../components/CoinSelector';
import CopyContent from '../../components/CopyContent';
import CustomDrawer from '../../components/CustomDrawer';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Popup from '../../components/Popup';
import { useResponsive } from '../../hooks';
import { get, post } from '../../utils/api';
import { isLoggedIn } from '../../utils/auth';
import { fCurrency } from '../../utils/format';
import { TermAndConditions } from './TermAndConditions';

// const SliderSetting = {
//     speed: 500,
//     slidesToScroll: 1,
//     dots: true,
//     centerMode: true,
//     infinite: false,
//     arrows: false
// }
// style

const PackageItemContainer = styled(Stack)(({ theme }) => ({
    overflow: 'hidden',
    width: 500,
    height: 512,
    borderRadius: 20,
    backgroundColor: "#202020",
    // boxShadow: theme.shadows[16],
    // background: theme.palette.background.paper,
    transition: 'all 0.4s ease-in-out',
    margin: '1rem',
    [theme.breakpoints.down('sm')]: {
        maxWidth: 350,
        width: "71vw",
    },
    position: "relative",
    // border: '2px solid #06927F',
    // background: 'linear-gradient(#06927F,#142523)',
    // 'p,span': {
    //     color: "#fff"
    // }
}))
const BackdropPackage = styled(Backdrop)(({ theme }) => ({
    position: "absolute",
    // background: "rgba(0,0,0,0.4)",
    background: "none",
    zIndex: 999,
    width: "100%",
}));
const BackdropStyle = styled(Backdrop)(({ theme }) => ({
    position: "absolute",
    background: "none",
    backdropFilter: "blur(1px)",
    zIndex: 999,
    width: "100%",
    minHeight: "100vh",
    top: "80px"
}));
// const SliderStyle = styled(Stack)(({ theme }) => ({

//     "& .main-slide > .slick-list": {
//         padding: "3rem",
//         [theme.breakpoints.down('sm')]: {
//             padding: '0rem !important',
//             "& >.slick-track > div[aria-hidden=true]": {
//                 opacity: '1 !important'
//             }
//         },
//         "& >.slick-track >.slick-slide": {
//             transition: 'all 0.2s ease-in-out',
//             "&.slick-current": {
//                 marginTop: "-2rem"
//             },

//         },
//         // "& >.slick-track > div[aria-hidden=true]": {
//         //     opacity: 0
//         // },
//         "& .left": {
//             borderRadius: "2rem 0px 0px 2rem",
//             overflow: "hidden",
//             [theme.breakpoints.down('lg')]: {
//                 borderRadius: '20px'
//             }
//         },
//         "& .right": {
//             borderRadius: "0px 2rem 2rem 0px",
//             overflow: "hidden",
//             [theme.breakpoints.down('lg')]: {
//                 borderRadius: '20px'
//             }
//         },
//         "& .active": {
//             boxShadow: "0px 10px 30px #06927F",
//             [theme.breakpoints.down('lg')]: {
//                 borderRadius: '20px'
//             }
//         }
//     }
// }));



export const Packages = ({ disableTitle, disableShadow }) => {
    const { app } = useSelector((state) => state);
    const { langLibrary } = app ? app : {};
    const isMobile = useResponsive('down', 'lg')
    const [packageList, setPackageList] = useState(null)
    // const [activeIndex, setActiveIndex] = useState(1)
    const slideRef = useRef()
    const handleGetPackageList = () => {
        get('/package/list',
            (result) => {
                // const handledData = handlePackageData(result)
                // const newArr = []
                // handledData.map((item) => {
                //     newArr.push({ ...item, color: PackageTypes[item.type].color, icon: PackageTypes[item.type].icon, allowPurchasePackage: item.options.some((option) => option.allowPurchase) })
                // })
                // const allowPurchaseList = result.filter((item) => item.allowPurchase)
                const newArr = []
                result.map((item) => {
                    // if (item.allowPurchase) {
                    newArr.push({ ...item, isComingSoon: checkIsComingSoon(item.startTime) })
                    // }
                })
                setPackageList(newArr)
                // if (slideRef && slideRef.current) {
                //     slideRef.current.slickGoTo(1)
                // }
            },
            () => {
            }
        )
    }
    const checkIsComingSoon = (startTime) => {
        return Date.now() < startTime
    }

    useEffect(() => {
        handleGetPackageList()
    }, [])

    // const handlePackageData = (rawData) => {
    //     const typeArr = []
    //     rawData.map((data) => {
    //         if (!typeArr.includes(data.type)) {
    //             typeArr.push(data.type)
    //         }
    //     })
    //     const handledData = []
    //     typeArr.forEach((type) => {
    //         const temp = rawData.filter((data) => data.type === type)
    //         const objTemp = {
    //             type: type,
    //             options: [...temp]
    //         }
    //         handledData.push(objTemp)

    //     })
    //     return handledData;

    // }

    // var list = packageList?.filter(item => item.allowPurchasePackage === true)
    // if (list?.length > 3) {
    //     list = list.slice(0, 3)
    // }
    // if (list?.length === 2) {
    //     list.push({
    //         allowPurchasePackage: true,
    //         color: "#EBDC5B",
    //         icon: "/images/icon1.png",
    //         options: [],
    //         type: "-/-",
    //         status: "UPCOMING"
    //     })
    // }

    return (
        <Page title={langLibrary.key_193} sx={{ position: "relative" }}>
            <MotionViewport>
                <Stack width={"100%"} alignItems='center' sx={{ position: "relative" }} pb={2}>
                    {
                        !disableTitle && <Typography sx={{ flexGrow: 1, fontSize: '2rem', color: "#fff" }}>
                            {langLibrary.key_193}
                        </Typography>
                    }
                    <Stack direction={"row"} width='fit-content' mb={4}>
                        <CopyContent
                            text={langLibrary.key_580}
                            sxText={{ color: "#fff", fontSize: "1.5rem" }}
                            notSlice={true}
                            sizeIconCopy={"1.5rem"}
                            iconColor={"#fff"}
                        />
                    </Stack>
                    <BackdropStyle open={!packageList}>
                        <CircularProgress />
                    </BackdropStyle>
                    {/* <SliderStyle sx={{ width: { xs: "115vw", sm: '70vw', md: '50vw', lg: '90vw' }, maxWidth: 1000, margin: 'auto' }}>
                        <Slider slidesToShow={isMobile ? 1 : 3} ref={slideRef} rows={isMobile ? 10 : 1} className='main-slide'  {...SliderSetting}  >
                            {list && list.length > 0 &&
                                list.map((data, index) => <Box key={index} p={2} py={5}>
                                    <PackageItem index={index} activeIndex={activeIndex} data={data} disableShadow={disableShadow} />
                                </Box>
                                )
                            }
                        </Slider>
                    </SliderStyle> */}
                    <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"center"}>
                        {packageList && packageList.length > 0 &&
                            packageList.map((data, index) =>
                                <Box>
                                    <PackageItem index={index} data={data} />
                                </Box>
                            )
                        }
                    </Stack>
                </Stack>
            </MotionViewport>
        </Page >
    )
}

const PackageItem = ({ index, data }) => {
    const { app, user } = useSelector((state) => state);
    const { funding } = user ? user : {};
    const { langCode, langLibrary, config } = app ? app : {};
    const { maxPurchasePackage } = config ? config : {};
    const [showForm, setShowForm] = useState(false)
    const [purchaseCurrency, setPurchaseCurrency] = useState(null)
    const [showConfirmPopup, setShowConfirmPopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [qty, setQty] = useState(1)
    const [promotionCode, setPromotionCode] = useState("");
    const [priceAfterPromotion, setPriceAfterPromotion] = useState(null)
    // const [selectedPackagePeriod, setSelectedPackagePeriod] = useState(null)
    // const [listBotMonth, setListBotMonth] = useState(null);
    // const [allowPurchaseOptions, setAllowPurchaseOptions] = useState(null)
    // const [prevSelectedPackagePeriod, setPrevSelectedPackagePeriod] = useState(null);
    const theme = useTheme()
    const isMobile = useResponsive("down", "sm")
    // const [openTooltip, setOpenTooltip] = useState(false)
    // const [isSale, setIsSale] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        if (data && data.currentPriceUsd && !promotionCode) {
            setPriceAfterPromotion(data.currentPriceUsd)
        }
    }, [data, promotionCode])

    const handleClose = (type) => {
        if (type === "submit") {
            setShowConfirmPopup(false)
            handlePurchasePackage()

        }
        if (type === "close_confirm") {
            setShowConfirmPopup(false)
        }
        if (type === "close_form") {
            setPromotionCode("")
            setShowForm(false)
            // setTimeout(() => {
            //     setSelectedPackagePeriod(prevSelectedPackagePeriod)
            // }, 100);
        }
    }
    const handlePurchasePackage = () => {
        setLoading(true)
        const params = {
            purchaseCurrency: purchaseCurrency?.asset,
            packageId: data?.id,
            qty: qty,
            promotionCode: promotionCode
        }
        post('/package/purchase',
            params,
            () => {
                setLoading(false)
                setShowForm(false)
                setPromotionCode("")
                setTimeout(() => {
                    // setSelectedPackagePeriod(prevSelectedPackagePeriod)
                    toast.success(langLibrary.key_343);
                }, 100);

            },
            (err) => {
                setLoading(false)
                setPromotionCode("")
            }
        )
    }
    const handleApplyPromotionCode = (promotionInput) => {
        setLoading(true)
        const params = {
            purchaseCurrency: purchaseCurrency?.asset,
            packageId: data?.id,
            qty: qty,
            promotionCode: promotionInput
        }
        post('/package/est-purchase', params,
            (result) => {
                setPriceAfterPromotion(result.purchasePriceUsd - result.discountUsd)
                setPromotionCode(promotionInput)
                setLoading(false)
                toast.success(langLibrary.key_565);

            },
            () => {
                setPriceAfterPromotion(data?.currentPriceUsd)
                setLoading(false)
                setPromotionCode("")
                formik.resetForm()
            }
        )
    }


    // useEffect(() => {
    //     if (data && data.options) {
    //         const filteredArr = data.options.filter((option) => option.allowPurchase)
    //         setAllowPurchaseOptions(filteredArr)

    //         setSelectedPackagePeriod(filteredArr[0])
    //         const temp = [];
    //         data.options.forEach((option) => {
    //             if (!temp.includes(option.botMonths) && option.allowPurchase) {
    //                 temp.push(option.botMonths)
    //             }
    //         })
    //         // setListBotMonth(temp)
    //     }
    // }, [data])

    // const onSelectedCallBack = (value) => {
    //     setSelectedPackagePeriod(value)
    //     const { initPaymentPriceUsd, currentPriceUsd } = value ? value : {}
    //     if (currentPriceUsd < initPaymentPriceUsd) {
    //         setIsSale(true)
    //     } else {
    //         setIsSale(false)
    //     }
    //     setPrevSelectedPackagePeriod(value)
    // }

    const promotionSchema = Yup.object().shape({
        promotionInput: Yup.string()
    })

    const formik = useFormik({
        initialValues: {
            promotionInput: "",
        },
        validationSchema: promotionSchema,
        onSubmit: () => {
            handleApplyPromotionCode(formik.values.promotionInput)
        }
    })
    const { handleSubmit, getFieldProps } = formik;
    return (
        <PackageItemContainer spacing={isMobile ? 6 : 3} width={"100%"} key={index} sx={{ position: "relative", }}>
            <BackdropPackage open={data?.isComingSoon}>
                <Typography fontWeight={600} fontSize={"35px"} sx={{ color: "#fff" }}>{langLibrary.key_502}</Typography>
            </BackdropPackage>
            <Stack paddingX={3} sx={{ width: "100%", height: "60px", marginTop: "0px !important" }} justifyContent={"center"}>
                <Typography fontSize={"16px"} sx={{ opacity: data?.isComingSoon ? 0.6 : 1 }}>{langLibrary.key_566}</Typography>
            </Stack>
            <Stack sx={{ marginTop: "0px !important" }}>
                <Divider sx={{ height: "0.5px", color: "#797979", }} />
            </Stack>
            <Stack pX={3} flexGrow={1} sx={{ marginTop: "0px !important", opacity: data?.isComingSoon ? 0.6 : 1 }}>
                <Stack flexGrow={1} alignItems={"center"} justifyContent={"space-evenly"}>
                    <Stack sx={{ width: 135, height: 170 }}>
                        <img alt="" src='/images/package/bot.png' style={{ height: "100%", }} />
                    </Stack>
                    <Stack alignItems={"center"}>
                        <Typography fontSize={"35px"} fontWeight={"700"} sx={{ color: "#fff" }}>
                            ${fCurrency(data.currentPriceUsd)}{" "}
                            {data.currentPriceUsd < data.initPaymentPriceUsd && (
                                <span style={{ textDecorationLine: "line-through", fontSize: "20px", fontWeight: "400", }}>${fCurrency(data.initPaymentPriceUsd)}</span>
                            )}

                        </Typography>
                        <Typography>
                            {data.botMonths} {langLibrary.key_314}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack padding={"0rem 1.5rem 1.5rem"}>
                    <LoadingButton
                        fullWidth
                        size='large'
                        variant="contained"
                        onClick={() => {
                            if (isLoggedIn()) {
                                setShowForm(true)
                            } else {
                                navigate("/auth/login")
                            }
                        }}
                        disabled={(data.status === "UPCOMING")}
                    >
                        <Typography fontWeight={'bold'} sx={{ color: `${data.color} !important` }}>
                            {isLoggedIn() ? langLibrary.key_342 : langLibrary.key_503}
                        </Typography>
                    </LoadingButton>
                </Stack>
            </Stack>
            {/* {
                isSale && <Stack alignItems='center' justifyContent='center' sx={{ position: "absolute", left: '-40px', top: "20px", transform: 'rotate(-45deg)', width: "150px", background: `linear-gradient(90deg,${alpha('#E8DB5B', 0)},#E8DB5B,${alpha('#E8DB5B', 0)})` }}>
                    <Typography variant='h4' sx={{ color: "#fff" }}>SALE</Typography>
                </Stack>
            } */}
            {/* <Hidden mdDown>
                <Tooltip
                    placement='bottom-end'
                    title={<Typography variant='caption' color={"#B78103"} fontWeight={"500"}>
                        {langLibrary.key_374}
                    </Typography>}
                    PopperProps={{
                        sx: {
                            '.MuiTooltip-tooltip': {
                                backgroundColor: "#FFF8D1",
                                maxWidth: 270
                            }
                        }
                    }}
                >
                    <IconButton sx={{ position: "absolute", top: 0, right: 2, width: "45px", height: "45px", cursor: "pointer" }}>
                        <Iconify icon={"mdi:warning-octagon"} color={'rgba(255,255,255,0.6)'} sx={{ width: "45px", height: "45px" }} />
                    </IconButton>
                </Tooltip>
            </Hidden> */}
            {/* <Hidden mdUp>
                <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                            sx: {
                                '.MuiTooltip-tooltip': {
                                    backgroundColor: "#FFF8D1"
                                }
                            }
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement='bottom-end'
                        onClose={() => (setOpenTooltip(false))}
                        open={openTooltip}
                        title={<Typography variant='caption' color={"#B78103"}
                        >
                            {langLibrary.key_374}
                        </Typography>}>
                        <IconButton onClick={() => setOpenTooltip(true)} sx={{ position: "absolute", top: 2, right: 2, width: "45px", height: "45px", cursor: "pointer" }}>
                            <Iconify icon={"mdi:warning-octagon"} color={'rgba(255,255,255,0.6)'} />
                        </IconButton>
                    </Tooltip>
                </ClickAwayListener>

            </Hidden> */}
            {/* <Stack width={"100%"} padding={isMobile ? "0px 0px 0px" : "0px 1rem 0px"}>
                <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
                    <Typography color={`${data.color} !important`} variant='h5' fontWeight={"bold"}>{data.type.replaceAll("_", " ")}</Typography>
                </Stack>
                <Image src={data.icon} sx={{ width: "30px", margin: '1rem auto 0px' }} />
            </Stack>
            <Stack width={"100%"} p={"0px 1rem 1rem"} minHeight={'100px'}>
                <SliderPackageItem options={allowPurchaseOptions} color={`${data.color} !important`} onSelectedCallBack={onSelectedCallBack} langLibrary={langLibrary} />
            </Stack>
            <Stack p={"0px 1rem"} width={"100%"} spacing={2} height={"200px"}>
                {selectedPackagePeriod?.description && (
                    <>
                        <Stack direction={"row"} alignItems="center" spacing={1}>
                            <Iconify icon="material-symbols:check-circle-rounded" sx={{ marginTop: '-5px', width: 20, height: 20, color: "#7ADE56" }} />
                            <Typography variant='body2' sx={{ fontSize: "0.8rem", height: "30px" }}>
                                {langCode === "en" ? (
                                    <>
                                        {langLibrary.key_375} <Typography variant='body2' fontWeight={"bold"} sx={{ display: "inline" }}>{selectedPackagePeriod.description?.title}</Typography> {langLibrary.key_376} <Typography variant='body2' fontWeight={"bold"} sx={{ display: "inline" }}>{selectedPackagePeriod.description?.symbol}</Typography> {langLibrary.key_377}
                                    </>
                                ) : (
                                    <>
                                        <Typography variant='body2' fontWeight={"bold"} sx={{ display: "inline" }}>{selectedPackagePeriod.description?.symbol}</Typography> {langLibrary.key_492} <Typography variant='body2' fontWeight={"bold"} sx={{ display: "inline" }}>{selectedPackagePeriod.description?.title}</Typography>{langLibrary.key_493}
                                    </>
                                )}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center" spacing={1}>
                            <Iconify icon="material-symbols:check-circle-rounded" sx={{ marginTop: '-1px', width: 20, height: 20, color: "#7ADE56" }} />
                            <Typography fontSize={"1.1rem"} fontWeight={"bold"} color={`${data.color} !important`}>${fCurrency(selectedPackagePeriod.description?.limitInvestUsd)}</Typography>
                            <Typography variant='body2' lineHeight={"1rem"}>{langLibrary.key_378}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center" spacing={1}>
                            <Iconify icon="material-symbols:check-circle-rounded" sx={{ marginTop: '-3px', width: 20, height: 20, color: "#7ADE56" }} />
                            <Typography variant='body2' lineHeight={"1rem"}>{langCode === "ko" && `${langLibrary.key_487} `}{selectedPackagePeriod.description?.monthlyProfit}</Typography>
                            <Typography variant='body2' lineHeight={"1rem"}>{langLibrary.key_379}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center" spacing={1}>
                            <Iconify icon="material-symbols:check-circle-rounded" sx={{ marginTop: '-1px', width: 20, height: 20, color: "#7ADE56" }} />
                            <Typography fontWeight={"bold"} color={`${data.color} !important`}>{selectedPackagePeriod.description?.numberOfBot}</Typography>
                            <Typography lineHeight={"1rem"}>{langLibrary.key_380}{langCode === "en" ? `${selectedPackagePeriod.description?.numberOfBot > 1 ? "s" : ""}` : ``} </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center" spacing={1}>
                            <Iconify icon="material-symbols:check-circle-rounded" sx={{ marginTop: '-1px', width: 20, height: 20, color: "#7ADE56" }} />
                            <Typography variant='body2'>{selectedPackagePeriod.description?.numberOfSupporter}</Typography>
                            <Typography variant='body2'>{langLibrary.key_381}{langCode === "en" ? `${selectedPackagePeriod.description?.numberOfSupporter > 1 ? "s" : ""}` : ``}</Typography>
                        </Stack>
                    </>
                )}
                {
                    data.status === "UPCOMING" && <Stack sx={{ height: "50%" }} direction={'row'} alignItems='center' justifyContent={'center'}>
                        <Typography>{data.status}</Typography>
                    </Stack>
                }
            </Stack> */}
            {/* <Stack width={"100%"}>
                <LoadingButton
                    fullWidth
                    size='large'
                    variant="contained"
                    sx={{
                        background: `linear-gradient(90deg,${alpha('#028F7C', 0)},#028F7C,${alpha('#028F7C', 0)}) !important`,
                        borderRadius: "0px",
                    }}
                    onClick={() => {
                        if (isLoggedIn()) {
                            setShowForm(true)
                        } else {
                            navigate("/auth/login")
                        }
                    }}
                    disabled={(data.status === "UPCOMING")}
                >
                    <Typography fontWeight={'bold'} sx={{ color: `${data.color} !important` }}>
                        {isLoggedIn() ? langLibrary.key_342 : langLibrary.key_503}
                    </Typography>
                </LoadingButton>
            </Stack> */}
            <CustomDrawer
                anchor={"right"}
                paperStyle={{ maxWidth: "500px", width: "100vw" }}
                title={langLibrary.key_342}
                open={Boolean(showForm)}
                parent={$("#root")}
                onClose={() => handleClose("close_form")}
            >
                <Stack padding="1rem" spacing={"1rem"}>
                    {/* <Stack sx={{ borderRadius: "7px", marginTop: { xs: "2rem", md: "0px" } }}>
                        <Typography variant='body2'>{langLibrary.key_324}</Typography>
                        <OptionPackagePopover initialOption={selectedPackagePeriod} options={allowPurchaseOptions} onSelect={(option) => setSelectedPackagePeriod(option)} />
                    </Stack> */}
                    <Stack>
                        <Typography variant='body2'>{langLibrary.key_341}</Typography>
                        <CoinSelector
                            options={funding}
                            onSelect={(option) => setPurchaseCurrency(option)}
                            sx={{ width: "100% !important" }}
                            hideChainName
                        />
                    </Stack>
                    <Stack>
                        <Typography variant='body2'>{langLibrary.key_326}</Typography>
                        <Stack direction={"row"} justifyContent={"center"} spacing={2} alignItems={"center"}>
                            <IconButton
                                color="inherit"
                                sx={{
                                    background: theme.palette.background.neutral,
                                    cursor: "pointer",
                                }}
                                disabled={qty === 1}
                                onClick={() => setQty(qty - 1)}
                            >
                                <Iconify
                                    sx={{ width: 20, height: 20 }}
                                    icon="ic:baseline-minus"
                                />
                            </IconButton>
                            <Typography fontWeight={'bold'}>{qty}</Typography>
                            <IconButton color="inherit"
                                sx={{
                                    background: theme.palette.background.neutral,
                                    cursor: "pointer",
                                }}
                                disabled={qty >= maxPurchasePackage}
                                onClick={() => setQty(qty + 1)}
                            >
                                <Iconify
                                    sx={{ width: 20, height: 20 }}
                                    icon="ic:baseline-plus"
                                />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Stack>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                                <Typography variant='body2'>{"Promotion"}</Typography>
                                <TextField
                                    sx={{
                                        '.MuiOutlinedInput-root': {
                                            paddingRight: "8px !important",
                                        },

                                    }}
                                    {...getFieldProps("promotionInput")}
                                    fullWidth
                                    variant='outlined'
                                    placeholder={langLibrary.key_571}
                                    InputLabelProps={{ shrink: false }}
                                    InputProps={{
                                        sx: {
                                            height: 46,
                                            "input::placeholder": {
                                                opacity: 0.6,
                                                fontSize: "0.9rem"
                                            },
                                            '&:hover': {
                                                border: "none !important"
                                            }
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button size='small' variant='contained' type='submit' disabled={!formik.values.promotionInput}>
                                                    {langLibrary.key_567}
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </Form>
                        </FormikProvider>

                    </Stack>
                    <Stack
                        sx={{
                            border: "1px solid rgba(145, 158, 171, 0.32)",
                            padding: "1rem",
                            borderRadius: "7px",
                        }}
                        spacing={2}
                    >
                        {/* <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_311}:</Typography>
                            <Typography fontWeight={'bold'}>{data?.type.replaceAll("_", " ")}</Typography>
                        </Stack> */}

                        {/* <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_378}</Typography>
                            <Typography fontWeight={'bold'}>${fCurrency(data?.description?.limitInvestUsd)}</Typography>
                        </Stack> */}
                        <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_379}</Typography>
                            <Typography fontWeight={'bold'}>{langLibrary.key_487} {data?.description?.monthlyProfit}</Typography>
                        </Stack>
                        <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_382}</Typography>
                            <Typography fontWeight={'bold'}>{data?.description?.title}</Typography>
                        </Stack>
                        <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_204}</Typography>
                            <Typography fontWeight={'bold'}>{data?.description?.symbol}</Typography>
                        </Stack>


                        {/* <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_383}</Typography>
                            <Typography fontWeight={'bold'}>{data?.description?.numberOfBot}</Typography>
                        </Stack> */}
                        <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_307}</Typography>
                            <Typography fontWeight={'bold'}>{data?.botMonths} {langCode === "en" ? `month${data?.botMonths > 1 ? "s" : ""}` : `${langLibrary.key_314}`}</Typography>
                        </Stack>
                        {/* <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_384}:</Typography>
                            <Typography fontWeight={'bold'}>{data?.description?.numberOfSupporter}</Typography>
                        </Stack> */}
                    </Stack>
                    <Divider />
                    <LoadingButton
                        sx={{
                            marginTop: "1rem", background: `${data.color} !important`, "&:hover": {
                                background: `${data.color} !important`,
                            }
                        }}
                        fullWidth
                        size='large'
                        variant="contained"
                        disabled={(!data?.allowPurchase)}
                        onClick={() => setShowConfirmPopup(true)}
                        loading={loading}
                    >
                        {!loading && (
                            <>
                                {priceAfterPromotion < data?.currentPriceUsd ? (
                                    <Stack direction={"row"} alignItems={"baseline"} spacing={0.5}>
                                        <Typography color={"inherit"} fontSize={"20px"} fontWeight={"700"}>
                                            {purchaseCurrency?.asset === "USDT" ? `$${fCurrency(priceAfterPromotion * qty)} ` : `${fCurrency((priceAfterPromotion / purchaseCurrency?.usdPrice) * qty, 2)} ${purchaseCurrency?.asset}`}
                                        </Typography>
                                        <Typography color={"inherit"} sx={{ textDecorationLine: "line-through", color: "#494949" }} fontSize={"13px"} fontWeight={"700"}>{purchaseCurrency?.asset === "USDT" ? `$${fCurrency(data?.currentPriceUsd * qty)} ` : ` ${fCurrency((data?.currentPriceUsd / purchaseCurrency?.usdPrice) * qty, 2)} `}</Typography>
                                    </Stack>
                                ) : (
                                    <Typography color={"inherit"} fontSize={"20px"} fontWeight={"700"}>{purchaseCurrency?.asset === "USDT" ? `$${fCurrency(data?.currentPriceUsd * qty)} ` : `${fCurrency((data?.currentPriceUsd / purchaseCurrency?.usdPrice) * qty, 2)} ${purchaseCurrency?.asset}`}</Typography>
                                )}
                            </>
                        )}



                    </LoadingButton>
                </Stack>
            </CustomDrawer>
            <PurchaseConfirmPopup open={showConfirmPopup} data={data} qty={qty} onClose={handleClose} purchaseCurrency={purchaseCurrency} langLibrary={langLibrary} langCode={langCode} priceAfterPromotion={priceAfterPromotion} />
        </PackageItemContainer >
    )
}

const PurchaseConfirmPopup = ({ open, data, qty, onClose, purchaseCurrency, langLibrary, langCode, priceAfterPromotion }) => {
    const [termChecked, setTermChecked] = useState(false);
    const [openTerm, setOpenTerm] = useState(false)
    const theme = useTheme()
    return (
        <Popup
            title={
                <>
                    <Typography variant="h5" align="center">
                        {langLibrary.key_340}
                    </Typography>
                </>
            }
            open={open}
            onClose={() => {
                setTermChecked(false)
                onClose("close_confirm")
            }}
        >
            <Stack
                sx={{ width: "75vw", maxWidth: "350px" }}
                padding={{ xs: "1rem 0px", sm: 0 }}
            >
                <Stack sx={{ margin: "-60px 0px 0px" }} >
                    <Divider />
                    <Stack py={"1.5rem"}>
                        {/* <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_311}:</Typography>
                            <Typography fontWeight={'bold'}>{data?.type.replaceAll("_", " ")}</Typography>
                        </Stack> */}
                        <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_307}</Typography>
                            <Typography fontWeight={'bold'}>{data?.botMonths} {langCode === "en" ? `month${data?.botMonths > 1 ? "s" : ""}` : `${langLibrary.key_314}`}</Typography>
                        </Stack>
                        {/* <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_383}</Typography>
                            <Typography fontWeight={'bold'}>{data?.description?.numberOfBot}</Typography>
                        </Stack> */}
                        <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography>{langLibrary.key_329}</Typography>
                            <Typography fontWeight={'bold'}>{purchaseCurrency?.asset === "USDT" ?
                                `$${fCurrency(priceAfterPromotion < data?.currentPriceUsd ? priceAfterPromotion * qty : data?.currentPriceUsd * qty)} ` :
                                `${fCurrency(((priceAfterPromotion < data?.currentPriceUsd ? priceAfterPromotion : data?.currentPriceUsd) / purchaseCurrency?.usdPrice) * qty, 2)} ${purchaseCurrency?.asset}`}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} pb={"1.5rem"} alignItems={"center"}>
                        <Checkbox
                            checked={termChecked}
                            checkedIcon={<Iconify icon={"fluent:checkbox-checked-16-filled"} color={"inherit"} sx={{ height: 20, width: 20 }} />}
                            onChange={() => setTermChecked(!termChecked)}
                            disableRipple
                            sx={{
                                paddingLeft: 0,
                                // height: 20,
                                '&:hover': {
                                    background: "transparent"
                                },
                                '.MuiSvgIcon-root': {
                                    width: 20,
                                    height: 20
                                }
                            }}
                        />
                        {langCode === "en" ? (
                            <Typography variant='body2' lineHeight={"1.5"}>
                                {langLibrary.key_385} {" "}
                                <Typography
                                    variant='body2'
                                    lineHeight={"1.5"}
                                    sx={{ display: "inline", color: theme.palette.primary.main, cursor: "pointer", textDecoration: "underline" }}
                                    onClick={() => setOpenTerm(true)}
                                >
                                    {langLibrary.key_386}</Typography>
                            </Typography>
                        ) : (
                            <Stack>
                                <Typography variant='body2' lineHeight={"1.5"}>
                                    {langLibrary.key_488}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    lineHeight={"1.5"}
                                    sx={{ color: theme.palette.primary.main, cursor: "pointer", textDecoration: "underline" }}
                                    onClick={() => setOpenTerm(true)}
                                >
                                    {langLibrary.key_386}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
                <Divider />
                <Stack direction="row" spacing={2} mt={3}>
                    <Button
                        color="default"
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                        onClick={() => {
                            setTermChecked(false)
                            onClose("close_confirm")
                        }}
                    >
                        {langLibrary.key_87}
                    </Button>
                    <Button
                        disabled={!termChecked}
                        variant="contained"
                        sx={{ flexGrow: 1 }}
                        onClick={() => {
                            setTermChecked(false)
                            onClose("submit")
                        }}
                    >
                        {langLibrary.key_57}
                    </Button>
                </Stack>
            </Stack>
            <TermAndConditions open={openTerm} onClose={() => setOpenTerm(false)} />
        </Popup >
    )
}