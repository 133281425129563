// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
// toast
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { _getConfig, _getContract } from "./store/actions/appActions";
// ----------------------------------------------------------------------
import { useTheme } from "@mui/material/styles";
import UpdateReferral from "./sections/profile/UpdateReferral";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SWITCH_LANGUAGE } from "./store/constants";
import languages from "./languages";
import { useLocation } from "react-router-dom";
import { isLoggedIn } from "./utils/auth";
import { _getNewProfile } from "./store/actions/userActions";
import UbotDictionary from "./sections/UbotDictionary";
import Policy from "./sections/Policy";
import TelegramQRCode from "./sections/TelegramQRCode";
import { mergeJson } from "./utils/utils";
import PartnerForm from "./sections/PartnerForm";
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function App() {
  const { app } = useSelector((state) => state);
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const { langCode, langLibrary } = app;
  const { activeDrawer } = app ? app : {};
  const query = useQuery();

  const dispatch = useDispatch();
  useEffect(() => {

    if (isLoggedIn()) {
      dispatch(_getNewProfile())
    }

    let isMobileDevice = query.get("isMobileDevice")
    localStorage.setItem("isMobileDevice", isMobileDevice);
    dispatch(_getConfig());
    dispatch(_getContract());
    var interval = setInterval(() => {
      dispatch(_getContract());
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);


  useEffect(() => {
    const localLangCode = localStorage.getItem("langCode");
    if (localLangCode !== null) {
      dispatch({ type: SWITCH_LANGUAGE, payload: localLangCode });
    } else {
      localStorage.setItem("langCode", langCode);
    }
    if (langCode) {
      localStorage.setItem("langLibrary", JSON.stringify(languages[langCode]));
    }
  }, [dispatch, langCode]);
  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
      {/* <UpdateReferral /> */}
      {!activeDrawer && (
        <ToastContainer
          position="bottom-center"
          enableMultiContainer
          closeButton={false}
          closeOnClick={false}
          hideProgressBar
          autoClose={2000}
        />
      )}
      <UbotDictionary />
      <Policy />
      <PartnerForm />
      <TelegramQRCode />
    </ThemeProvider>
  );
}
