import { Avatar, Card, CardHeader, Collapse, Container, Divider, Grid, IconButton, Stack, Typography, } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/bitcoin-trading-bot.json'
import Image from '../../components/Image';
import TrackVisibility from 'react-on-screen';
import Title from './Title';
import { get } from '../../utils/api';
import { CoinImage } from '../../settings/constants';
import { fCurrency } from '../../utils/format';



// ----------------------------------------------------------------------

export default function StakingPool({ ...other }) {
    const theme = useTheme()
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const [products, setProducts] = useState([])


    const handleGetProduct = () => {
        get('/staking/product/list', result => {
            var output = []
            result.map(item => {
                var sum = 0
                item.products.map(e => sum += e.totalStaked)
                output.push({ ...item, sum: sum, apr: item.products[0].apr })
            })
            setProducts(output)
        })
    }
    useEffect(() => {
        handleGetProduct()
    }, [])

    return (<ContainerStyle {...other} py='10rem !important'>
        <Container>
            <TrackVisibility offset={100} partialVisibility>
                {
                    ({ isVisible }) => <Title isVisible={isVisible} title={langLibrary.key_732} />
                }
            </TrackVisibility>
        </Container>
        <Container sx={{ position: 'relative', zIndex: 1, mt: 5 }}>
            <Grid container alignItems={'center'} justifyContent='center'>
                {
                    products.map((item, index) => <Grid item xs={12} sm={8} md={5} key={index}>
                        <Card sx={{ p: 5 }}>
                            <Stack alignItems={'center'} spacing={4}>
                                <Typography textAlign={'center'}>{item.type}</Typography>
                                <Avatar src={CoinImage.USDT} sx={{ width: 50, height: 50 }} />
                                <Stack width={'100%'} spacing={3} direction={'row'} alignItems='center' justifyContent={'space-around'} divider={<Divider orientation='vertical' sx={{ height: 50 }} />}>
                                    <Stack>
                                        <Typography >APR</Typography>
                                        <Typography >{fCurrency(item.apr, 2)}%</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography textAlign={'center'}>{langLibrary.key_79}</Typography>
                                        <Typography textAlign={'center'}>${fCurrency(item.sum, 2)}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>)
                }
            </Grid>
        </Container>
    </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    [theme.breakpoints.down('md')]: {
        '& h3': {
            fontSize: '1.2rem'
        }
    }
}));
const CardItem = styled(Card)(({ theme }) => ({
    height: '100px',
    background: 'none',
    position: "relative",
    zIndex: 10,
    boxShadow: "none",
    borderRadius: "15px",
    cursor: "pointer",
    // '&:before': {
    //     content: '""',
    //     position: 'absolute',
    //     width: "calc(100% - 4px)",
    //     height: "calc(100% - 4px)",
    //     left: '2px',
    //     top: "2px",
    //     background: "#171717",
    //     zIndex: -1,
    //     borderRadius: "13px"
    // },
    // '&:after': {
    //     content: '""',
    //     position: 'absolute',
    //     width: "100%",
    //     height: "100%",
    //     left: 0,
    //     top: 0,
    //     background: `linear-gradient(140deg,${alpha('#63b545', 0.2)},${alpha('#63b545', 1)})`,
    //     zIndex: -2
    // }
}));