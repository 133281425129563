import { useSelector } from 'react-redux';
import { Card, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MotionViewport } from '../../components/animate';
import CustomSlider from '../../components/CustomSlider';




// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    maxWidth: "500px",
    margin: "0px auto",
    padding: "2rem 3rem",
    [theme.breakpoints.down('sm')]: {
        background: "none",
        boxShadow: 'none',
        padding: "0px"
    }
}));

// ----------------------------------------------------------------------


export default function AffiliatePool({ products, onSuccess, ...other }) {
    const { user, app } = useSelector(state => state)
    return (
        <>
            <MotionViewport>
                <CardStyle {...other}>
                    <CustomSlider value={100} max={500} height="25px" />
                </CardStyle>
            </MotionViewport>
        </>
    );
}
