// ----------------------------------------------------------------------

export default function Typography(theme) {
  const isLight = theme.palette.mode === 'light';
  return {
    MuiTypography: {
      defaultProps: {
        color: isLight ? theme.palette.grey[700] : theme.palette.grey[0],
        variantMapping: {
          body3: 'p',
        },
      },
      styleOverrides: {
        // root: {
        //   [theme.breakpoints.down('sm')]: {
        //     fontSize: "0.875rem"
        //   },
        // },
        paragraph: {
          marginBottom: theme.spacing(2),
          color: isLight ? theme.palette.common.white : theme.palette.grey[800],
          [theme.breakpoints.down('sm')]: {
            fontSize: "0.875rem"
          },
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
