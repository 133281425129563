import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// ----------------------------------------------------------------------

export const APP_BAR_MOBILE = 115;
export const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  transition: "all 0.2s ease-in-out",
  flexGrow: 1,
  overflow: "unset",
  minHeight: "100%",
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { app } = useSelector(state => state)
  const { openNav } = app ? app : {}

  return (
    <RootStyle>
      <MainStyle id="app_layout_main">
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
