import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  CardHeader,
  Collapse,
  Divider,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import IconButtonAnimate from "../../components/animate/IconButtonAnimate";
import Iconify from "../../components/Iconify";
import { _getNewProfile } from "../../store/actions/userActions";
import { put } from "../../utils/api";

const EditForm = styled(Stack)(({ theme }) => ({}));

// ----------------------------------------------------------------------

export default function BasicInfo() {
  const dispatch = useDispatch();
  const [showEditForm, setShowEditForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user, app } = useSelector((state) => state);
  const { profile } = user ? user : {};
  const { langLibrary } = app;
  const theme = useTheme()

  const ReferralSchema = Yup.object().shape({
    displayName: Yup.string().required(langLibrary.key_82),
  });

  const formik = useFormik({
    initialValues: {
      displayName: "",
    },
    validationSchema: ReferralSchema,
    onSubmit: () => {
      handleUpdate();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const { displayName, email } = profile ? profile : {};

  const cancelUpdate = () => {
    setShowEditForm(false);
  };

  const handleUpdate = () => {
    const params = {
      displayName: formik.values.displayName,
    };
    setIsSubmitting(true);
    put(
      "/user/profile",
      params,
      () => {
        setShowEditForm(false);
        dispatch(_getNewProfile());
        setIsSubmitting(false);
        toast.success(langLibrary.key_83);
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  useEffect(() => {
    if (displayName)
      formik.setValues({
        displayName: displayName,
      });
  }, [displayName]);

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <CardHeader title={<Stack direction='row' alignItems="flex-end">
          <Typography sx={{ color: '#fff', fontSize: '1.5rem' }}>{displayName}</Typography>
          <IconButtonAnimate onClick={() => setShowEditForm(true)} sx={{ marginLeft: "0.5rem", marginBottom: "4px", cursor: 'point' }}>
            <Iconify sx={{ color: 'rgba(255,255,255,0.7)' }} icon='bx:message-square-edit' />
          </IconButtonAnimate>
        </Stack>} subheader={<Typography sx={{ color: '#fff', marginTop: '-0.5rem' }}>{email}</Typography>} />
      </Stack>
      <Collapse in={showEditForm}>
        <FormikProvider value={formik}>
          <EditForm sx={{ background: theme.palette.background.paper, padding: '1.5rem', borderRadius: '15px' }}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack maxWidth="500px">
                <Typography>{langLibrary.key_84}</Typography>
                <TextField
                  fullWidth
                  autoComplete="displayName"
                  type={"text"}
                  label={langLibrary.key_85}
                  {...getFieldProps("displayName")}
                  error={Boolean(touched.displayName && errors.displayName)}
                  helperText={touched.displayName && errors.displayName}
                />
                <Typography
                  variant="body2"
                  mt={2}
                >
                  {langLibrary.key_303}
                </Typography>
                <Stack direction="row" mt={1.5} spacing={2}>
                  <Button
                    disabled={isSubmitting}
                    variant="outlined"
                    color="default"
                    onClick={() => cancelUpdate()}
                  >
                    {langLibrary.key_87}
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {langLibrary.key_88}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          </EditForm>
        </FormikProvider>
      </Collapse>
    </>
  );
}
