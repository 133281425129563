import { Card, Collapse, Container, Grid, IconButton, Stack, Typography, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { alpha, Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Lottie from 'react-lottie';
import * as Introduce4 from '../../lotties/chart.json'
import * as Introduce3 from '../../lotties/pc.json'
import * as Introduce2 from '../../lotties/coin.json'
import Image from '../../components/Image';
import Title from './Title';
import TrackVisibility from "react-on-screen";




// ----------------------------------------------------------------------

export default function Solution({ ...other }) {
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const [showMore, setShowMore] = useState(false)

    return (<ContainerStyle {...other}>
        <Container sx={{ position: 'relative', zIndex: 1 }}>
            <Stack alignItems='center'>
                <TrackVisibility offset={100} partialVisibility>
                    {
                        ({ isVisible }) => <Title isVisible={isVisible} title={langLibrary.key_246} />
                    }
                </TrackVisibility>
            </Stack>
            <Stack direction={{ xs: "", sm: 'row' }} spacing={10} mt={5} mb={'10vw'} alignItems="center" justifyContent={'space-between'}>
                <ItemImage data={'/images/chart.gif'} title={langLibrary.key_573} />
                <ItemImage data={'/images/pc.gif'} title={langLibrary.key_574} />
                <ItemImage data={'/images/coins.gif'} title={langLibrary.key_575} />
            </Stack>
        </Container>
    </ContainerStyle>
    );
}

const ItemImage = ({ data, title }) => {
    const [playing, setPlaying] = useState(false)
    return <BoxItem>
        <Item onMouseMove={() => setPlaying(true)} onMouseLeave={() => setPlaying(false)}>
            <img src={data} style={{ color: "#fff", width: "50%", margin: 'auto' }} />
        </Item>
        <Typography mt={3} textAlign={'center'}>{title}</Typography>
    </BoxItem>
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    position: 'relative',
    minHeight: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5rem !important',
    paddingBottom: '5rem !important',
    scrollMargin: 0,
    width: '100vw',
    [theme.breakpoints.down('md')]: {
        '& h3': {
            fontSize: '1.2rem'
        }
    }
}));

const Item = styled(Stack)(({ theme }) => ({
    borderBottom: "1px solid rgba(255,255,255,0.5)",
    width: "100%",
    height: 300,
    color: "#fff",
    overflow: 'hidden',
    position: "relative",
    "&:before": {
        content: '""',
        width: '100px',
        height: "100px",
        boxShadow: '0px 0px 10px #43E9F5 , 0px 0px 10px #43E9F5 , 0px 0px 0px #43E9F5',
        position: "absolute",
        transition: "all 0.2s ease-in-out",
        borderRadius: '100%',
        left: "calc(50% - 50px)",
        bottom: "-100px"
    },
    "&:hover": {
        "&:before": {
            boxShadow: '0px 0px 100px #43E9F5 , 0px 0px 100px #43E9F5, 0px 0px 50px #43E9F5',
        }
    }
}));

const BoxItem = styled(Stack)(({ theme }) => ({
    overflow: 'hidden',
    position: "relative",
}));