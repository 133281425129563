import PropTypes from "prop-types";
// icons
import { IconFolders } from "@tabler/icons";
// @mui
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";

// thirtpaty
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from 'react-toastify'
import Popup from "./Popup";
import { useState } from "react";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

CopyContent.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  ellipsis: PropTypes.bool,
  length: PropTypes.number,
};

export default function CopyContent({
  children,
  text,
  ellipsis,
  to,
  hideButton,
  confirmMessage,
  showNextContent,
  sxText,
  notSlice,
  sizeIconCopy,
  iconColor,
  ...other
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const handleCopy = () => {
    if (confirmMessage) {
      setShowConfirm(true);
    } else {
      toast.success("Copied")
    }
  };
  return text ? (
    <Grid container width="100%">
      <Grid item xs={12}>
        <Stack
          direction={"row"}
          flexGrow={1}
          alignItems="center"
          justifyContent={showNextContent ? "" : "space-between"}
          {...other}
        >
          <Stack
            direction={"row"}
            sx={{
              cursor: "pointer",
              maxWidth: `calc(100% - ${!hideButton ? "40px" : "0px"})`,
            }}
            onClick={() => (to ? window.open(to) : null)}
          >
            {notSlice ?
              <Typography variant="body1" sx={{ ...sxText }}>
                {text}
              </Typography>
              : (
                <>
                  <Typography variant="body1" noWrap sx={{ ...sxText }}>
                    {text.slice(0, text.length - 5)}
                  </Typography>
                  <Typography variant="body1" sx={{ ...sxText }}>
                    {text.slice(text.length - 5, text.length)}
                  </Typography>
                </>
              )}
          </Stack>
          {!hideButton && (
            <CopyToClipboard text={text} onCopy={() => handleCopy()}>
              <IconButton color="primary" sx={{ color: iconColor ? iconColor : "" }}>
                <IconFolders stroke={2} size={sizeIconCopy ? sizeIconCopy : "1.2rem"} />
              </IconButton>
            </CopyToClipboard>
          )}
        </Stack>
      </Grid>
      {confirmMessage && (
        <Popup open={showConfirm} onClose={() => setShowConfirm(false)}>
          <Stack
            alignItems="center"
            sx={{ width: "cacl(90vw + 50px)", maxWidth: "450px" }}
          >
            {
              <Box
                sx={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "150px",
                  overflow: "hidden",
                  margin: "2rem auto 0.5rem",
                }}
              >
                <img
                  alt=""
                  src="/images/animated-success.gif"
                  width={"100%"}
                  height="100%"
                  style={{
                    objectFit: "cover",
                    transform: "scale(1.7)",
                    marginLeft: "2px",
                    marginTop: "-2px",
                  }}
                />
              </Box>
            }
            <Typography variant="h5" sx={{ color: "#54C7EF" }}>
              {langLibrary.key_3}
            </Typography>
            <Typography margin={"2rem 0px"}>{confirmMessage}</Typography>
          </Stack>
        </Popup>
      )}
    </Grid>
  ) : null;
}
