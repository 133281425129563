// @mui
import { Button, Card, Divider, Grid, Slider, Stack, styled, Tab, Tabs, Typography, useTheme } from "@mui/material";
import Page from "../components/Page";
import FundOverview from "../sections/profile/FundOverview";
import DepositHistory from "../sections/profile/DepositHistory";
import { MotionViewport } from "../components/animate";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../utils/auth";
import { _getFunding } from "../store/actions/userActions";
import { toast } from "react-toastify";
import CopyContent from "../components/CopyContent";
import Stake from "../sections/staking/Stake";
import { fCurrency } from "../utils/format";
import Iconify from "../components/Iconify";
import StackList from "../sections/staking/StackList";

// ----------------------------------------------------------------------

export default function Staking() {
  const dispatch = useDispatch()
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const theme = useTheme()
  const [triggerLoading, setTriggerLoading] = useState(0);
  const [tabIndex, setTabIndex] = useState(0)
  const [product, setProduct] = useState({})

  const handleSuccess = (msg) => {
    setTimeout(() => {
      toast.success(msg)
    }, 200);
    setTriggerLoading(triggerLoading + 1)
  }

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(_getFunding())
    }
  }, [])

  const { apr, totalStaker, totalStaked, amount, poolStaked } = product ? product : {}

  return (
    <MotionViewport>
      <Page title={langLibrary.key_192}>
        <Stack width={"100%"} >
          <Typography sx={{ flexGrow: 1, fontSize: '2rem', color: "#fff" }}>
            {langLibrary.key_42}
          </Typography>
          <Stack direction={"row"} width='fit-content' mb={4}>
            <CopyContent
              text={langLibrary.key_580}
              sxText={{ color: "#fff", fontSize: "1.5rem" }}
              notSlice={true}
              sizeIconCopy={"1.5rem"}
              iconColor={"#fff"}
            />
          </Stack>

          <Stack mt={4} pb={5}>
            <Stack direction={'row'} alignItems='center' justifyContent='center' spacing={2} mt={12}>
              <Button onClick={() => setTabIndex(0)} variant={tabIndex === 0 ? "contained" : ''} sx={{ minWidth: 100, borderRadius: 50, fontSize: '1.2rem' }}>Stake</Button>
              <Button onClick={() => setTabIndex(1)} variant={tabIndex === 1 ? "contained" : ''} sx={{ minWidth: 100, borderRadius: 50, fontSize: '1.2rem' }}>Stake List</Button>
            </Stack>
            {
              tabIndex === 0 && <>
                <CustomCard sx={{ mt: 4 }}>
                  <Stake onChange={product => setProduct(product)} />
                </CustomCard>
                {
                  product && <>
                    <CustomCard sx={{ mt: 4, p: 4 }}>
                      <Stack p={3} spacing={2}>
                        <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                          <Typography>Annual percentage rate</Typography>
                          <Typography sx={{ color: theme => theme.palette.text.default }}>{fCurrency(apr, 2)}%</Typography>
                        </Stack>
                        <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                          <Typography>Stakers</Typography>
                          <Typography >{fCurrency(totalStaker, 2)}</Typography>
                        </Stack>
                        <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                          <Typography>USDT price</Typography>
                          <Typography >~${fCurrency(1, 2)}</Typography>
                        </Stack>
                      </Stack>
                    </CustomCard>
                    <Typography variant="h5" my={2} mt={3} textAlign='center'>Total staked with Ubot</Typography>
                    <Stack alignItems='center' justifyContent="center">
                      <Stack sx={{ maxWidth: 600, width: '90vw', background: theme => theme.palette.background.paper, borderRadius: 20, mx: 3, position: 'relative' }}>
                        <Stack sx={{ height: 20, width: `${totalStaked * 100 / poolStaked}%`, borderRadius: 20, background: `linear-gradient(90deg,rgba(0,0,0,0),${theme.palette.text.default})` }}></Stack>
                        <Typography sx={{ position: 'absolute', bottom: -40, right: 0 }}>{fCurrency(poolStaked, 2)} USDT</Typography>
                      </Stack>
                    </Stack>
                  </>
                }
              </>
            }
            {
              tabIndex === 1 && <StackList />
            }
          </Stack>
        </Stack>
      </Page>
    </MotionViewport>
  );
}

const CustomCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: '0 1rem 1rem -0.75rem rgb(0 0 0 / 5%)',
  borderRadius: '15px',
  height: "100%",
  width: '100%',
  maxWidth: '600px',
  margin: "auto",
  padding: '0px !important',
  [theme.breakpoints.down('lg')]: {
    marginTop: '1rem'
  }
}));