import * as Yup from "yup";
import { useCallback, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Link,
  Stack,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
// recaptcha
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
// api
import { post } from "../../../utils/api";
import { setAccessToken } from "../../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { _getNewProfile } from "../../../store/actions/userActions";
import { isProduction } from "../../../settings/apiEndpoint";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const theme = useTheme()

  const handleReCaptchaVerify = useCallback(async (successCallback) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("register");

    if (successCallback) successCallback(isProduction ? token : "recaptcha");

  });

  const handleLogin = (params, callback) => {
    handleReCaptchaVerify((token) => {
      let submitParams = {
        email: params.email,
        password: params.password,
        gaCode: params.gaCode,
        recaptcha: token,
      };
      post(
        `/user/login`,
        submitParams,
        (result) => {
          callback(result.accessToken);
        },
        (error) => {
          if (error.code === "GACODE_REQUIRED") {
            setStep(2);
          }
          setIsSubmitting(false);
        },
        "GACODE_REQUIRED"
      );
    });
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(langLibrary.key_46).required(langLibrary.key_47),
    password: Yup.string().required(langLibrary.key_48),
    gaCode:
      step == 2 &&
      Yup.string().required(langLibrary.key_49).length(6, langLibrary.key_50),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      gaCode: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      setIsSubmitting(true);
      handleLogin(formik.values, (token) => {
        setAccessToken(token);
        dispatch(_getNewProfile())
        navigate("/profile/dashboard");
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {step === 1 && (
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={langLibrary.key_51}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label={langLibrary.key_52}
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify
                        color="#ffffff"
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Link
                component={RouterLink}
                variant="body3"
                underline="always"
                // color="text.secondary"
                to="/auth/reset-password"
              >
                <Typography variant="caption" sx={{ color: theme.palette.primary.main }}>{langLibrary.key_53}</Typography>
              </Link>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {langLibrary.key_32}
            </LoadingButton>
          </Stack>
        )}

        {step === 2 && (
          <Stack spacing={3} data-aos="fade-left" data-aos-duration="300">
            <Typography
              sx={{ color: "text.secondary", mb: 5, mt: "0px !important" }}
            >
              {langLibrary.key_54}
            </Typography>
            <TextField
              fullWidth
              autoComplete="gaCode"
              type="string"
              placeholder={langLibrary.key_56}
              label={langLibrary.key_55}
              {...getFieldProps("gaCode")}
              error={Boolean(touched.gaCode && errors.gaCode)}
              helperText={touched.gaCode && errors.gaCode}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {langLibrary.key_57}
            </LoadingButton>
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}
