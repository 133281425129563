// ----------------------------------------------------------------------

export default function Link(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },

      styleOverrides: {
        root: {
          cursor: 'pointer',
          color: isLight ? theme.palette.primary.light : theme.palette.primary.main,

        },
      },
    },
  };
}
