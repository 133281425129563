import { Button, Divider, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Popup from '../../components/Popup'

const data = [
    "key_361",
    "key_362",
    "key_363",
    "key_364",
    "key_365",
    "key_366",
    "key_367",
    "key_368",
    "key_369",
    "key_370",
    "key_371",
    "key_372"
]
export const TermAndConditions = ({ open, onClose }) => {
    const { app } = useSelector((state) => state);
    const { langLibrary } = app ? app : {};
    return (
        <Popup
            title={
                <>
                    <Typography variant="h5" align="center">
                        {langLibrary.key_373}
                    </Typography>
                </>
            }
            open={open}
            onClose={onClose}
        >
            <Stack
                sx={{ maxWidth: "600px" }}
                padding={{ xs: "1rem 0px 0px", sm: 0 }}
            >
                <Stack sx={{ margin: "-60px 0px 0px" }} >
                    <Divider />
                    <Stack py={"1.5rem"} spacing={1}>
                        {data.map((item, index) => (
                            <Typography key={index} variant='body2'>{langLibrary[item]}</Typography>
                        ))}
                    </Stack>
                </Stack>
                <Divider />
                <Stack direction="row" mt={3} justifyContent={"center"}>
                    <Button
                        variant="contained"
                        sx={{ width: "180px" }}
                        onClick={() => {
                            onClose()
                        }}
                    >
                        {langLibrary.key_98}
                    </Button>
                </Stack>
            </Stack>
        </Popup >
    )
}
