import PropTypes from "prop-types";
// icons
// @mui
import { CardHeader, Grid, Stack } from "@mui/material";
// components
import { useSelector } from "react-redux";
import GaSetting from "../../sections/profile/GaSetting";
import PasswordSetting from "../../sections/profile/PasswordSetting";

// ----------------------------------------------------------------------


Setting.propTypes = {
  profile: PropTypes.object,
};

export default function Setting({ profile }) {
  return (
    <Stack>
      <Grid container spacing={3} mt={-5}>
        <Grid item xs={12} md={6} lg={6}>
          <PasswordSetting />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <GaSetting />
        </Grid>
      </Grid>
    </Stack>
  );
}
