import { Card, CircularProgress, Container, Divider, Hidden, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/robot.json'
import { IconButtonAnimate } from '../../components/animate';
import Iconify from '../../components/Iconify';
import { useEffect, useRef, useState } from 'react';
import { put } from '../../utils/api';

// ----------------------------------------------------------------------

export default function Title({ data, onSuccess, ...other }) {
    const { app } = useSelector(state => state)
    const InputRef = useRef()
    const [submitting, setSubmitting] = useState(false)
    const [title, setTitle] = useState('')
    const handleFocusInput = () => {
        InputRef.current.focus()
    }
    const handleUpdate = () => {
        if (title.length > 0) {
            const params = {
                ...data,
                title: title,
            }
            setSubmitting(true)
            put('/package/bot-options', params, () => {
                setSubmitting(false)
                onSuccess({ ...data, title: title })
            }, () => {
                setSubmitting(false)
            })
        }
    }
    useEffect(() => {
        if (data) {
            setTitle(data.title)
        }
    }, [data])
    const { langLibrary } = app ? app : {}
    if (!data) return null
    return (
        <ContainerStyle direction='row' alignItems={'center'} spacing={2} >
            <Stack alignItems='center' justifyContent='center' sx={{ background: 'rgba(255,255,255,0.2)', height: '58px', minWidth: '58px', borderRadius: '7px', px: 2 }}>
                <Typography noWrap variant='caption' sx={{ color: '#fff' }} >{langLibrary.key_300}</Typography>
                <Typography variant='h5' sx={{ color: '#fff' }}>#{data.id}</Typography>
            </Stack>
            <InputStyle
                inputRef={InputRef}
                label={langLibrary.key_301}
                onBlur={() => handleUpdate()}
                value={title}
                onChange={e => setTitle(e.target.value)}
                InputProps={{
                    endAdornment: <IconButtonAnimate onClick={() => handleFocusInput()}><Iconify icon="fluent:edit-16-filled" sx={{ color: "#fff" }} /></IconButtonAnimate>
                }}
            />
            {
                submitting && <CircularProgress sx={{ color: "#fff", opacity: 0.8, width: "30px !important", height: "30px !important" }} />
            }
        </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
}));
const InputStyle = styled(TextField)(({ theme }) => ({
    width: "100%",
    maxWidth: "400px",
    '& .MuiInputBase-root': {
        background: 'rgba(255,255,255,0.2) !important',
        color: "#fff",
    },
    '& input': {
        fontSize: '1.1rem'
    },
    '& .MuiFormLabel-root': {
        color: "#fff !important"
    }
}));