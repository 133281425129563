import moment from "moment";
export const ACCESS_TOKEN_KEY = "CBt3gpbqeMdWPNG1";
export const SCOPES_KEY = "AhBcmvr1EkMdPnL5";

export const LOCAL_KEY_REFERRAL_ID = "referralId";

export const ProjectModule = [
  {
    name: "support",
    isRelease:
      moment("2022-08-12 21:00", "YYYY-MM-DD hh:mm").utc().unix() -
      moment().utc().unix() <=
      0,
  },
];

export const CoinImage = {
  BTC: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
  FIL: "https://s2.coinmarketcap.com/static/img/coins/64x64/2280.png",
  LUNA: "https://s2.coinmarketcap.com/static/img/coins/64x64/4172.png",
  BNB: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
  BUSD: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png",
  USDT: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
  ETH: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
  TRX: "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png",
  XRP: "https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
  MATIC: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
};

export const CommissionTypes = [
  { label: "key_230", value: "DIRECT_COMMISSION" }, //DIRECT
  { label: "key_231", value: "RANKING_COMMISSION" }, //RANKING
  { label: "key_232", value: "MATCHING_COMMISSION" }, //MATCHING
];

export const NetworkNeedMemo = ["XRP"];

export const MemberLevel = [
  "F1",
  "F2",
  "F3",
  "F4",
  "F5",
];

export const Ranks = {
  USER: { title: "USER", image: "" },
  MANAGER: { title: "MANAGER", image: "" },
  GOLD: { title: "GOLD", image: "" },
  DIAMOND: { title: "DIAMOND", image: "" },
  MASTER: { title: "MASTER", image: "" },
};

export const ErrorMsg = {
  UNKNOWN: "Unknown error.",
  OPERATION_FAIL: "Operation fail",
  UNAUTHORIZED: "Unauthorized.",
  INVALID_PARAMETERS: "Invalid parameters.",
  INVALID_OPERATION: "Invalid operation.",
  PERMISSION_DENIED: "Permission denied.",
  SYSTEM_BUSY: "System busy.",
  TOO_MANY_REQUESTS: "Too many requests.",
  RECORD_NOTFOUND: "Record not found.",
  TOKEN_INVALID: "Token invalid.",
  TOKEN_WRONG: "Token wrong.",
  TOKEN_EXPIRED: "Token expired.",
  OTP_INVALID: "OTP invalid.",
  OTP_WRONG: "OTP wrong.",
  OTP_EXPIRED: "OTP expired.",
  PASSWORD_WRONG: "Password or email is wrong.",
  GACODE_REQUIRED: "Need gacode.",
  GACODE_WRONG: "GaCode is wrong.",
  WRONG_CREDENTIALS: "Wrong credentials.",
  USER_INACTIVE: "Your account is inactive.",
  INSUFFICIENT_FUNDS: "Insufficient funds.",
  EMAIL_EXIST: "Email exist.",
  SOLD_OUT: "Sold out.",
  OWNER_ERROR: "Owner error.",
  FORBIDDEN: "Forbidden.",
};

export const PackageTypes = {
  BEGINNER: {
    title: "Beginner",
    color: '#ffffff',
    icon: "/images/icon3.png"
  },
  STARTERS: {
    title: "Starters",
    color: '#7ADE56',
    icon: "/images/icon2.png"
  },
  PROFESSIONAL_SAFE: {
    title: "Professional safe",
    color: '#EBDC5B',
    icon: "/images/icon1.png"
  },
  SENIOR: {
    title: "Senior",
    color: '#d039a0',
  },
  MASTER: {
    title: "Master",
    color: '#87bc36',
  }
}
