import PropTypes from "prop-types";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Button,
  AppBar,
  Divider,
  Container,
  Hidden,
  Popover,
  MenuItem,
} from "@mui/material";
// hooks
import { useOffSetTop, useResponsive } from "../../hooks";
// config
import { HEADER_DESKTOP_HEIGHT } from "../config";
// components
import Logo from "../../components/Logo";
import Label from "../../components/Label";
import { Link as RouterLink, useLocation } from "react-router-dom";

//
import LanguagePopover from "../LanguagePopover";
import { ToolbarStyle } from "./HeaderToolbarStyle";
import AccountPopover from "../profile/AccountPopover";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../utils/auth";
import MainMenu from "./MainMenu";

// ----------------------------------------------------------------------

Header.propTypes = {
  transparent: PropTypes.bool,
};

const BtnStyle = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  width: "150px",
  [theme.breakpoints.down("450")]: {
    fontSize: "0.8rem !important",
    width: "100px !important"
  },
}));

export default function Header({ transparent, isLight, hideAccountMenu, offsetTop, sxHeader }) {
  const theme = useTheme();

  const isDesktop = useResponsive("up", "md");

  const isScrolling = true

  const { app } = useSelector((state) => state);
  const { langLibrary } = app;

  return (
    <AppBar sx={{ boxShadow: 0, background: 'none', zIndex: 1000000, ...sxHeader }} position={"relative"}>
      <ToolbarStyle
        disableGutters
        transparent={transparent}
        scrolling={isScrolling}
      >
        <Container maxWidth={false} >
          <Stack direction="row" px={{ sm: 0, lg: 4 }} sx={{ mt: 2 }} width="100%">
            <Hidden lgDown>
              <Box sx={{ flexGrow: 1 }} />
            </Hidden>
            <Stack spacing={1} direction="row" alignItems="center" width="100%" justifyContent='center'>
              <Stack
                direction="row"
                mr={isDesktop ? "50px" : "0px"}
              >
                <Box sx={{ lineHeight: 0, position: "relative" }}>
                  <Logo />
                </Box>
              </Stack>
              <Box sx={{ flexGrow: 1 }} />
              <MainMenu />
              {!hideAccountMenu && (

                <AccountPopover />

              )}
              <LanguagePopover
                sx={{
                  ...(isScrolling && { color: "text.primary" }),
                }}
              />
            </Stack>
            <Hidden lgDown>
              <Box sx={{ flexGrow: 1 }} />
            </Hidden>
          </Stack>
        </Container>
      </ToolbarStyle>
    </AppBar>
  );
}
