import { combineReducers } from "redux";
import { AppReducer } from "./reducers/appReducer";
import { UserReducer } from "./reducers/userReducer";

const rootReducer = combineReducers({
  user: UserReducer,
  app: AppReducer,
});

export default rootReducer;
