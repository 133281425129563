const en = {
  key_1: "Select coin",
  key_2: "We are currently working hard building this page",
  key_3: "Copied",
  key_4: "Not found",
  key_5: "Try checking for typos or using complete words.",
  key_6: "Sign up",
  key_7: "Login",
  key_8: "Password and preferences for Google services",
  key_9: "Ways to verify it’s you and settings for the web",
  key_10: "Account setting",
  key_11: "English",
  key_12: "Korea",
  key_13: "Japan",
  key_14: "Profit",
  key_15: "From",
  key_16: "To",
  key_17: "Please staking to receive commissions.",
  key_18:
    "Your affiliate pool is limited, to continue receive commissions you must staking more.",
  key_19: "Invite your friends",
  key_20: "Up to",
  key_21: "requirements",
  key_22: "Commission",
  key_23: "Direct Commission",
  key_24: "Ranking Commission",
  key_25: "Ranking Bonus",
  key_26: "Matching Commission",
  key_27: "Total revenue",
  key_28: "Team",
  key_29: "Funding",
  key_30: "Deposit & withdraw",
  key_31: "Swap",
  key_32: "Login",
  key_33: "Don’t have an account?",
  key_34: "Get started",
  key_35: "404 Page Not Found",
  key_36: "Sorry, page not found!",
  key_37:
    "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.",
  key_38: "Go to Home",
  key_39: "Promotions",
  key_40: "Register",
  key_41: "Reset password",
  key_42: "Staking",
  key_43: "Stakings",
  key_44: "Profits",
  key_45: "member",
  key_46: "Email must be a valid email address",
  key_47: "Email is required",
  key_48: "Password is required",
  key_49: "Google Authenticator code is required",
  key_50: "Google Authenticator code must 6-dig",
  key_51: "Email address",
  key_52: "Password",
  key_53: "Forgot password?",
  key_54: "Please input Google Authenticator code.",
  key_55: "Google Authenticator Code",
  key_56: "Google Authenticator - 6dig",
  key_57: "Confirm",
  key_58: "Confirm-Password is required",
  key_59: "Passwords must match",
  key_60: "Email code is required",
  key_61: "Already have an account?",
  key_62: "Confirm Password",
  key_63: "Check mail box to get 6dig of OTP code.",
  key_64: "OTP code",
  key_65: "Register success!",
  key_66: "Will automatically redirect you to the login page after",
  key_67: "Buy NFT success!",
  key_68: "OTP is required",
  key_69: "Invitation Code is required",
  key_70: "Invitation Code",
  key_71: "Forgot Your Password?",
  key_72:
    "Please enter the email address associated with your account and We will email you a link to reset your password.",
  key_73: "Send Request",
  key_74: "Set new password.",
  key_75: "The OTP was sent to your email. Please check the mailbox.",
  key_76: "New password",
  key_77: "Reset success!",
  key_78: "OR",
  key_79: "Staked",
  key_80: "F1 Investor",
  key_81: "Total sale",
  key_82: "Display name is required",
  key_83: "Update success!",
  key_84: "Enter your name",
  key_85: "Display Name",
  key_86: "Hi",
  key_87: "Cancel",
  key_88: "Update",
  key_89: "Staking profit",
  key_90: "Matching",
  key_91: "Direct",
  key_92: "Ranking",
  key_93: "Date",
  key_94: "Deposit",
  key_95: "Address",
  key_96: "Both address and memo are required to deposit",
  key_97: "successfully",
  key_98: "OK",
  key_99: "Transaction Hash",
  key_100: "Amount",
  key_101: "Fee",
  key_102: "Time",
  key_103: "Status",
  key_104: "Type",
  key_105: "Update your name.",
  key_106: "This name will appear on the Profile and Ranking.",
  key_107: "Created Time",
  key_108: "Level",
  key_109: "Rank",
  key_110: "Join time",
  key_111: "Member",
  key_112: "Hot wallet",
  key_113: "Google Authenticator (GA)",
  key_114: "Protect your account with 2-Step verification",
  key_115: "Google authenticator",
  key_116: "NEXT",
  key_117: "1. Backup this key:",
  key_118: "2. Get the [Authenticator App] from Google Play or AppStore.",
  key_119: "3. In the App select [Set up account].",
  key_120: "4. Choose [Scan a barcode].",
  key_121: "Scan this code",
  key_122: "Turn off",
  key_123: "Turn on",
  key_124: "Please enter code.",
  key_125: "Google Authenticator code",
  key_126: "Set up Authenticator",
  key_127: "ID",
  key_128: "Email",
  key_129: "Old password is required",
  key_130: "New password is required",
  key_131: "A secure password helps protect your account",
  key_132: "Change your password",
  key_133: "Choose a strong password and don't reuse it for other accounts",
  key_134: "Old password",
  key_135: "Password strength",
  key_136:
    "Use at least 8 characters. Don’t use a password from another site, or something too obvious like your pet’s name",
  key_137: "Confirm password",
  key_138: "Amount is required",
  key_139: "Add success!",
  key_140: "Staking info",
  key_141: "Process",
  key_142: "days",
  key_143: "Re-investment",
  key_144: "Available",
  key_145: "All",
  key_146: "Add",
  key_147: "Add staking",
  key_148: "Are you sure you want to add",
  key_149: "Amount must be more than",
  key_150: "Staking success!",
  key_151: "Minimum",
  key_152: "Stake",
  key_153: "Estimate",
  key_154: "Period",
  key_155: "Interest",
  key_156: "per month",
  key_157: "End date",
  key_158: "By confirming stake you agree to",
  key_159: "Term and Conditions",
  key_160: "Withdrawals capital in the first 30 days, a fee is 10%",
  key_161: "After 30 days, a fee is 5%.",
  key_162:
    "For 180 days will deduct previously received earnings and commissions.",
  key_163:
    "User used to have MANAGER rank or above will not be able to withdraw capital",
  key_164: "Collapse",
  key_165: "Payment",
  key_166: "Un-stake",
  key_167: "Lock affiliate",
  key_168: "day",
  key_169: "Un-stake success!",
  key_170: "Deduct",
  key_171: "Receive",
  key_172: "Your capital return will deduct",
  key_173: "by Term and conditions.",
  key_174: "Lock success!",
  key_175: "Swap success!",
  key_176: "Rate",
  key_177: "Max",
  key_178: "Min",
  key_179: "Are you sure to swap?",
  key_180: "Referral ID is required",
  key_181: "Invitation Code.",
  key_182: "Please provide a invitation code that we need for first login.",
  key_183: "Change account",
  key_184: "Withdraw",
  key_185: "Withdrawal is temporary disabled",
  key_186: "Please check information!",
  key_187: "To address",
  key_188: "Select option",
  key_189: "Dashboard",
  key_190: "Settings",
  key_191: "Logout",
  key_192: "Wallet",
  key_193: "Packages",
  key_194: "My Packages",
  key_195: "Home",
  key_196: "Please wait",
  key_197: "This bot is being started.",
  key_198: "This bot is being stopped.",
  key_199: "This bot is being changed options.",
  key_200: "Your request has been denied",
  key_201: "Note",
  key_202: "Save options",
  key_203: "Reminder:",
  key_204: "Symbol",
  key_205: "Trailing Stop",
  key_206: "Deposit is temporary disabled",
  key_207: "Are you sure you want to withdraw without memo",
  key_208: "Support",
  key_209: "Withdraw success...!",
  key_210: "Password must be a string with s minimum length of 8.",
  key_211: "Account",
  key_212:
    "Your request has been sent successfully. We have sent you an email with details of this request.",
  key_213: "Back to Support",
  key_214: "If you have problems, you can submit them for us to resolve",
  key_215: "Sorry, this case is not supported yet.",
  key_216: "Please enter a valid email.",
  key_217: "Please enter email of receiver.",
  key_218: "Please enter the amount.",
  key_219: "Please enter the Tx Hash.",
  key_220: "Receiver (email)",
  key_221: "Send",
  key_222: "Please enter email.",
  key_223: "Please select image.",
  key_224: "Please select commission type.",
  key_225: "Please select date.",
  key_226: "Sponsor Email",
  key_227: "F1 Email",
  key_228: "Commission Type",
  key_229: "Missing Date",
  key_230: "DIRECT",
  key_231: "RANKING",
  key_232: "MATCHING",
  key_233: "GA verification",
  key_234: "Tx Hash is not valid",
  key_235: "Download App",
  key_236: "Bonus",
  key_237: "Enter Market",
  key_238: "Claim",
  key_239: "Are you sure to claim",
  key_240: "Claim success!",
  key_241: "Date | Status",
  key_242: "Claim all",
  key_243: "Automatic",
  key_244: "Ubot",
  key_245:
    "An AI-based Forex trading system, helping you earn  profit around 5-15% per month.",
  key_246: "Perfect Investment Solution",
  key_247:
    "Ubot is an AI-powered automatic Forex trading product produced by BIGWAVE - a technology company with over 7 years of experience in the CFD market. Operating 24/5 automatically based on Fibonacci commands, Ubot creates stable profits with an average rate of 5-15% per month. With many innovative ideas and improvements related to Forex trading implemented since 2019, Ubot is the perfect choice for those who want to automate and optimize their trading strategy.",
  key_248:
    "CFD is a widely used financial trading area in more than 100 countries.",
  key_249:
    "CFD brokers offer excellent trading conditions designed to meet all realistic expectations from both professional and novice traders. From the achievements and experience of Ubot has created a safe trading network business direction that includes advanced trading technology and reputable partnerships, all of which make us a safe, transparent and trusted broker.",
  key_250:
    "Company Orientation: To become the world's leading Ubot in the field of CFD, Crypto, Usdt, Payment Coint...",
  key_251:
    "Ubot  aims at the profit of the investor partners as the main, transparent, clear, reputable, good liquidity, guaranteed investment cash flow. Currently, we are constantly developing in a field of CFD but also have new directions in the near future, bringing many elements of community and value.",
  key_252:
    "Through the EI BOT trading that we have been operating with our partners, Ubot also wants to bring safe trading experiences to investors on financial platforms. Profit and ensure the lowest risks that other companies cannot do, more proud of the sure profit from trading with EI BOT partners do not have to spend too much time with other companies. tools of tradition.",
  key_253: "BOT TRADE",
  key_254: "Products",
  key_255:
    "BOT TRADE's Trend Analysis Tools are an extremely effective solution and provide a sure return for investors. You can easily manage hundreds of different accounts, but the profit performance is the same depending on the capital you choose. Our BOT Trade often uses tools for trading analysis such as: Accelerator Oscillator, Bollinger Bands, Averge Directinal Index, Moving Avergae Channel, Stochastic RIS, Williams Alligator, ...",
  key_256: "Why should you choose Ubot",
  key_257:
    "Trading logics applied and learned by the BOT from seasoned experts in the market",
  key_258:
    "Simply set up your bot strategies with just a few clicks. Automate trades easily using indicators to catch or follow trends.",
  key_259: "Bring in passive income of 5-15% per month for investors.",
  key_260:
    "We support our customers with a system of order alerts and automatic account updates 24/5.",
  key_261: "The active device is integrated with the available multimedia.",
  key_262: "We have been choosing to invest in our fund organization.",
  key_263: "EI BOT",
  key_264: "Easy Automation",
  key_265: "Passive Income",
  key_266: "Customer Support",
  key_267: "Multimedia",
  key_268: "Organization",
  key_269: "Contact",
  key_270: "Package",
  key_271: "has active package >= 6 months",
  key_272: "from F1 to F5",
  key_273: "Purchased a package for at least...months",
  key_274: "Have",
  key_275: "level in difference branchs",
  key_276: "Search by level",
  key_277: "Search by rank",
  key_278: "Referral ID",
  key_279: "Referral link",
  key_280: "Commission transactions",
  key_281: "Total share income",
  key_282: "Bot setting",
  key_283: "Bot options",
  key_284: "Max Buy Orders",
  key_285: "Max Sell Orders",
  key_286: "Type Of Top Or Bottom",
  key_287: "Fx username",
  key_288: "Fx password",
  key_289: "Fx server",
  key_290: "First distance",
  key_291: "Next distance",
  key_292: "Profit perMonth",
  key_293: "Loss perMonth",
  key_294: "Request note",
  key_295: "Request is being executing",
  key_296: "Request",
  key_297: "Change options",
  key_298: "Start bot",
  key_299: "Stop bot",
  key_300: "Bot ID",
  key_301: "Bot title",
  key_302:
    "Forex Bot Trade also known as Expert Advisor (EA) is a type of software",
  key_303:
    "Display name could be your first name or your nickname, or other name you want other people know you on Ubot.",
  key_304: "Page size",
  key_305: "Extend success!",
  key_306: "Package name",
  key_307: "Duration",
  key_308: "Buy time",
  key_309: "Extend",
  key_310: "Select extend currency",
  key_311: "Package type",
  key_312: "Extend price",
  key_313: "Package period",
  key_314: "month",
  key_315: "Title",
  key_316: "Side",
  key_317: "Setting",
  key_318: "bots",
  key_319: "Bot list",
  key_320: "Confirm extend",
  key_321: "Purchase success!",
  key_322: "bot",
  key_323: "Purchase",
  key_324: "Select package",
  key_325: "Select purchase currency",
  key_326: "Select quantity",
  key_327: "Package Price",
  key_328: "Quantity",
  key_329: "Total Price",
  key_330: "Confirm purchase",
  key_331: "Package Period",
  UNKNOWN: "Unknown error.",
  OPERATION_FAIL: "Operation fail",
  UNAUTHORIZED: "Unauthorized.",
  INVALID_PARAMETERS: "Invalid parameters.",
  INVALID_OPERATION: "Invalid operation.",
  PERMISSION_DENIED: "Permission denied.",
  SYSTEM_BUSY: "System busy.",
  TOO_MANY_REQUESTS: "Too many requests.",
  RECORD_NOTFOUND: "Record not found.",
  TOKEN_INVALID: "Token invalid.",
  TOKEN_WRONG: "Token wrong.",
  TOKEN_EXPIRED: "Token expired.",
  OTP_INVALID: "OTP invalid.",
  OTP_WRONG: "OTP wrong.",
  OTP_EXPIRED: "OTP expired.",
  PASSWORD_WRONG: "Password or email is wrong.",
  GACODE_REQUIRED: "Need gacode.",
  GACODE_WRONG: "GaCode is wrong.",
  WRONG_CREDENTIALS: "Wrong credentials.",
  USER_INACTIVE: "Your account is inactive.",
  INSUFFICIENT_FUNDS: "Insufficient funds.",
  EMAIL_EXIST: "Email exist.",
  SOLD_OUT: "Sold out.",
  OWNER_ERROR: "Owner error.",
  FORBIDDEN: "Forbidden.",
  key_332: "Intelligence Trading",
  key_333: "Trusted Partner",
  key_334: "Disclaimer of Liability",
  key_335: "My bots",
  key_336: "The options is updating.",
  key_337: "Request logs",
  key_338: "Form",
  key_339: "Search by email",
  key_340: "Confirm subscribe",
  key_341: "Select currency",
  key_342: "Subscribe",
  key_343: "Subscribe success!",
  key_344:
    "Ensure stable internet connection for transactions; if trader loses internet, the trade order will not be sent.",
  key_345:
    "Consider risk management to prevent rare cases of risk such as power outage or mechanical problems. The sensitivity of the Ubot means that the bot may be affected by false information in the market.",
  key_346:
    "Do not follow advertisements or opinions from those outside of the project. Especially those who claim to be part of the Ubot project.",
  key_347:
    "Our profits depend on the BOT package you possess, so choose the right BOT package to ensure the desired profits.",
  key_348: "The average monthly return is estimated at 5-15%.",
  key_349: "Admin will never message you first.",
  key_350:
    "If there are any questions or need for support, immediately contact the Ubot support department, which works continuously from Monday to Friday, 23 hours a day.",
  key_351:
    "We are not responsible for any trade-related risks outside of business hours and activities.",
  key_352: "Absolutely do not follow unverified investment advice.",
  key_353: "Do not change the password without a proper reason.",
  key_354:
    "No responsibility will be taken for any damage caused by unauthorized access to the account.",
  key_355: "Please only deposit",
  key_356: "to this address",
  key_357: "Deposit has been disabled for",
  key_358: "on",
  key_359: "Address/Email",
  key_360: "Address or User Email is required",
  key_361:
    "1. We only provide trading technology. Ubot is not a discretionary investment service.",
  key_362:
    "2. Ensure stable internet connection for transactions; if a trader loses the internet, the trade order will not be sent.",
  key_363:
    "3. Consider risk management to prevent rare cases of risk such as power outage or mechanical problems. The sensitivity of the Ubot means that the bot may be affected by false information in the market.",
  key_364:
    "4. Do not follow advertisements or opinions from those outside of the project. Especially those who claim to be part of the Ubot project.",
  key_365: "5. The average monthly return is estimated at 5-15%.",
  key_366: "6. Admin will never message you first.",
  key_367:
    "7. If there are any questions or need for support, immediately contact the Ubot support department, which works continuously from Monday to Friday, 23 hours a day.",
  key_368:
    "8. We are not responsible for any trade-related risks outside of business hours and activities.",
  key_369: "9. Absolutely do not follow unverified investment advice.",
  key_370:
    "10. Do not change the MT4 password after installing the bot, if the password is changed, the BOT will stop functioning.",
  key_371:
    "11. Need to complete KYC on the Exchange Market before activate the Bot",
  key_372:
    "12. No responsibility will be taken for any damage caused by unauthorized access to the account.",
  key_373: "Terms and conditions",
  key_374:
    "The BOT package brings high returns along with risk. Depending on market conditions, please note that users may have to deposit additional funds up to 100% of their initial investment amount, depending on the market situation.",
  key_375: "Invest in",
  key_376: "with",
  key_377: "symbol",
  key_378: "Min invest",
  key_379: "Monthly profit",
  key_380: "Bot",
  key_381: "Supporter",
  key_382: "Investment market",
  key_383: "Number of bot",
  key_384: "Number of supporter",
  key_385: "I have read and agree to the ",
  key_386: "terms and conditions.",
  key_387: "MT4 ID",
  key_388: "MT4 Password",
  key_389:
    "The Ubot is committed to providing the best possible trading service based on the established script. Users may set up the script for themselves, however, if they do not use our recommended script, the Ubot will not be responsible for any trading losses. We are also not responsible for any products or services of other companies. Please make sure you understand the risks and comply with all regulations and guidelines before using the Ubot.",
  key_390:
    "Do not change the MT4 password after installing the bot, if the password is changed, the BOT will stop functioning.",
  key_391: "To Address/Email",
  key_392: "About us",
  key_393: "Automatic Intelligence Trading",
  key_394:
    "Since 2019, we have committed to empowering each and every financial journey, providing attractive trading conditions combined with an outstanding trading and investing ecosystem",
  key_395: "Prominent benefits we offer",
  key_396: "Experience",
  key_397:
    "3 Years of Active Product Monitoring and Verification with Over 2000+ Users",
  key_398: "23/5 Auto Trading",
  key_399: "No FOMO, No User Emotion-Induced Errors",
  key_400: "EA BOT",
  key_401:
    "Set up bot strategies with just a few clicks. Use indicators to take advantage of follow trends",
  key_402: "Efficient capital management - Stable profit",
  key_403:
    "superior capital management system. Bringing passive income about 5-15% monthly for investors",
  key_404: "Customer Support",
  key_405:
    "We support customers with a real-time order alert system and automatic account status updates, available 23/5",
  key_406: "Reliability",
  key_407: "Trusted by reputable partners around the world",
  key_408: "Ubot commits to you",
  key_409: "Safe and Trust",
  key_410:
    "Ubot, a company with a global presence, has been a trustworthy and reliable provider for our client. Focusing on AI-powered automated Forex trading products produced by BIGWAVE - a technology company with over 7 years of experience in the CFD market. With Ubot, clients have access to robust financial security arrangements and various customer care policies",
  key_411: "How Ubot makes a difference",
  key_412: "We Unite",
  key_413: "We unite trading, investing and AI Automatic Trading",
  key_414: "We Simplify",
  key_415:
    "We simplify how to manage your money and how you can learn more about trading & investing",
  key_416: "We Connect",
  key_417: "We connect the world with our global expertise, access and offer",
  key_418: "We support financial education",
  key_419:
    "We provide our clients with services beyond trading and investing.The financial world can be daunting but with the right educational support, our clients can make informed decisions. Whether you are a beginner in the world of trading and investing or an expert in the field who likes to be on top of the daily trends, Ubot provides knowledge to both via our carefully curated online courses, seminars, webinars, trading podcasts and educational articles",
  key_420:
    "We also keep our clients up to date with daily investment news and analysis to trade smartly. Learn from Ebooks, stay ahead with daily trading and investing news, video tutorials, FX courses and much more",
  key_421: "We provide market-leading solution",
  key_422: "The Market",
  key_423:
    "With the goal of becoming an indispensable partner for investors and a valuable tool to be in their arsenal, Ubot aims to be the leading company that applies Bot Trade to the fields of CFD, Crypto, USDT, Payment Coin, and more, providing safe investment solutions based on refined and effective analysis and logic, improved every day by AI artificial intelligence. Ubot operates 24/5 automatically generated by the power of AI, following Fibonacci commands, creating stable profits with an average of 5-15% per month",
  key_424: "Traders and investors choose Ubot to",
  key_425: "Learn",
  key_426:
    "Learn about trading CFDs and investing in the stock markets with free webinars and live trading sessions with experts, comprehensive Forex courses and much more",
  key_427: "Earn",
  key_428: "Earning stable profits with minimum Earn of 5 - 15%/month",
  key_429: "Invest",
  key_430:
    "If you prefer the classic way of investing and diversifying with CFDs, use modern technology and gain an edge while investing in thousands of instruments",
  key_431: "What are the operating hours for the Ubot?",
  key_432:
    "The Ubot operates continuously on 23/5, which means the BOT will run continuously for 23 hours from Monday to Friday.",
  key_433: "Does Ubot work on weekends?",
  key_434:
    "Due to the nature of the Ubot, which is similar to the forex market, it only operates continuously from Monday to Friday. On weekends, central banks are closed, so foreign exchange trading will not take place.",
  key_435:
    "However, don't worry too much because the time zones of Vietnam and other Countries are completely different, so trading seems to take place continuously. When one trading session ends, another will open, moreover, there may even be overlapping trading sessions that bring greater trading opportunities to traders.",
  key_436: "How does Ubot work?",
  key_437:
    "With the automatic mechanism named Ubot, you only need to deposit money and purchase the Bot package. You don't have to spend much time watching charts. The trading logics are applied and learned from our Bot based on the experiences from long-time experts in the market.",
  key_438:
    "Additionally, with its automated mechanism, setting up your bot strategies is just a few clicks away. You can choose your desired level of profit and acceptable level of loss for each trade. Ubot will fully handle monitoring the market, waiting for market events that might affect the price, then send signals or make decisions based on the rules you have set for buying or selling actions.",
  key_439: "How long will it take for me to receive money after withdrawing?",
  key_440:
    "You can withdraw your money at any time and completely free of charge. You will receive the money within 24-36 hours.",
  key_441: "How to deposit in Ubot?",
  key_442: "Learn how to make a deposit",
  key_443: "here",
  key_444: "Learn how to withdraw money",
  key_445: "How to withdraw money from Ubot?",
  key_446: "What is the minimum and maximum possible deposit to Ubot?",
  key_447:
    "For only $350 per month, you can fully join and experience the Ubot. We have no maximum limit for your deposit amount, you can choose the BOT package that suits your financial conditions.",
  key_448: "What is the estimated profit of Ubot?",
  key_449:
    "We do not guarantee the level of profit for you, as the market can sometimes be impacted slightly and prices fluctuate. However, the estimated profit that Ubot can ensure for you is 5-15% per month, although this profit will depend on which BOT package you own.",
  key_450: "How to renew Bot?",
  key_451:
    "We have extremely advantageous limits on renewing your BOT packages. For renewals with a term of less than 12 months, the BOT price will be calculated at the original value (no discount). For renewals with a term of 12 months or more, the BOT price will be calculated at 50% of the original value.",
  key_452: "Which currency pairs should I trade in Ubot?",
  key_453:
    "One of the most common questions is how many currency pairs there are in Forex. There are over 200 countries and territories in the world, so there are many different currencies. However, due to the size of the economy, trade, and other features, only a few major currencies and currency pairs are frequently traded in the Forex market. At Ubot, we prioritize customer benefits, which is why we choose the AUD-CAD trading pair, with features such as:",
  key_454: "Stable, not too volatile compared to the market",
  key_455:
    "A cross currency pair between two friendly countries, not involved in wars or arms races.",
  key_456: "Will I lose money when participating in Ubot?",
  key_457:
    "When participating in Ubot, you can rest assured that you will be informed of the risks and methods of generating profits from Ubot. Thus, almost over 1000 BOTs that have been used for many years have not lost money.",
  key_458: "What is the best time for me to trade?",
  key_459:
    "Usually on Monday the market will be quite quiet, the news is not as busy as other days.",
  key_460:
    "Contrary to the second day, on the third, fourth, and fifth days, the market will be lively and there will be strong fluctuations. Important news will mainly emerge during these 3 days. You can fully confidently participate in trading during these 3 days. In the morning of the sixth day, the market is still lively, however, in the afternoon, calmness will return.",
  key_461: "Can I join if I don't have any knowledge about investment?",
  key_462:
    "Our Ubot offers a beginner-friendly BOT package priced at $350 per month trial if you want to try it out. However, this BOT package only grants you with one BOT ownership.",
  key_463: "Which bot package is the most convenient for users?",
  key_464:
    "We recommend you to purchase the Pro-safe package, where you will be able to own 5 AI trading bots, have access to 3 financial trading advisors and enjoy returns of up to 5-15%.",
  key_465: "Does Ubot guarantee 5-10% monthly mining profit?",
  key_466:
    "In business or investment, there's no guarantee of profit, but we always strive for safety and stability. Over the years, the profit has consistently reached from 5-10% per month.",
  key_467: "How can I install the Bot into my MT4 account?",
  key_468:
    "Ubot will assist you with the installation and guide you to create a script for your account.",
  key_469: "Exchange Partners",
  key_470: "Create an account",
  key_471: "Subscribe a package",
  key_472: "Register in Fxprimus",
  key_473: "Deposit to Fxprimus wallet",
  key_474: "Setting BOT options",
  key_475: "Start BOT",
  key_476: "Turn on GA verification ",
  key_477: "Turn off GA verification ",
  key_478: "로 구독",
  key_479: "Not have Referral commission",
  key_480: "최소",
  key_481: "개월 패키지 구매해야함",
  key_482: "Direct commission",
  key_483: "Normal shared revenue income",
  key_484: "Normal shared volume income",
  key_485: "Senior shared revenue income",
  key_486: "Senior shared volume income",
  key_487: "약",
  key_488: "I have read and agree to the terms and conditions",
  key_489: "에서 이 주소로만",
  key_490: "를 입금해주세요.",
  key_491: "Amount",
  key_492: "통화 쌍으로",
  key_493: "시장에 투자",
  key_494: "Up to MANAGER requirements",
  key_495: "Up to GOLD requirements",
  key_496: "Up to DIAMOND requirements",
  key_497: "Up to MASTER requirements",
  key_498: "Media",
  key_499: "Forex",
  key_500: "Crypto",
  key_501: "Stock",
  key_502: "Coming soon",
  key_503: "Login to Subscribe",
  key_516: "Glossary",
  key_517: "Description",
  key_518: "Ubot",
  key_519:
    "Ubot is an automated Forex trading product with AI technology, produced by a technology company with more than 7 years of experience in the CFD market. Ubot operates 23/5. automatically follow Fibonacci orders, generating stable profits with an average of 5-15% per month.",
  key_520: "FXPrimus",
  key_521:
    "We are a specialized intermediary platform for foreign exchange, metals, energy, and securities markets, with a presence in over 13 years worldwide, including countries such as the UK, Australia, Singapore, China, Vietnam, etc.",
  key_522: "EA (Expert Advisor)",
  key_523:
    "This is a type of software that provides financial trading signals or executes trading orders (closing orders, cutting losses, taking profits, etc.) based on a pre-existing programming program using the MT4 or MT5 trading platform.",
  key_524: "MT4 (MetaTrade4)",
  key_525:
    'As a popular online trading platform with a simple and intuitive interface, MT4 allows traders to easily create their own automated trading strategies.\\nNote: "MT4" likely refers to MetaTrader 4, a widely used trading platform in the financial industry`',
  key_526: "BOT Package",
  key_527:
    "The cost that users need to pay in order to use the BOT. The BOT cost will depend on the package of BOT usage that the user chooses.",
  key_528: "Investment amount",
  key_529:
    "The amount of money that users need to deposit in order for the BOT to initiate trading",
  key_530:
    "The amount of money that users need to deposit in order to maintain their position when the market experiences significant fluctuations.",
  key_531: "Estimated profit",
  key_532:
    "This is the estimated profit value to be earned within the period of using Ubot based on the pre-set trading scenarios. Depending on the trading scenario, the estimated profit may vary.",
  key_533: "Trading Scenario",
  key_534:
    "A scenario is a set of predetermined rules that a user can choose to apply to their BOT. The BOT will rely on this scenario to carry out buy and sell transactions. Depending on the scenario chosen by the user, the profit and risk levels may vary.",
  key_535:
    "The title of the Scenario varies depending on the user's preference.",
  key_536: "EA Type",
  key_537:
    "The type of transactions that the BOT will carry out. It is recommended to select both Buy and Sell.",
  key_538:
    "The currency pair that users should choose. We recommend selecting the AUD/CAD currency pair.",
  key_539: "Maximum buy order. Ubot recommends users to set up to 10 orders.",
  key_540: "Maximum sell order.Ubot recommends users to set up to 10 orders.",
  key_541:
    '"Enter market" is the entry point for executing trades. Users can choose tools in this section and, based on the signals of the selected tool, the BOT will execute the trade. The tools include RSI, MACD, Stoch, and peak-bottom tools. Ubot recommends using the RSI indicator when users set up a scenario.',
  key_542:
    '"Period" refers to a time frame. There are various time frames for users to choose from depending on their trading strategy (M5 - M15 - M30 - H1 - H4).\\nUbot recommends using M5 when users are setting up the script.',
  key_543: "Ubot recommends users to set the Next distance at 20 pips.",
  key_544:
    "Expected monthly profit. In this section, users can choose the profit value that they expect to achieve on a monthly basis. When the expected profit is reached, the BOT will automatically stop trading. Ubot recommends that users set the Profit per month at around 3 times the minimum initial investment capital. For example, if the minimum initial investment capital is $2000, then Profit per month should be set at around $6000.",
  key_545:
    "This refers to the level of loss that users can tolerate each month. Users can choose the level of loss they can accept each month. When the acceptable loss level is reached, the BOT will automatically stop placing orders. Ubot recommends that users set the Loss per month at approximately three times the minimum initial investment capital. For example, if the minimum initial investment capital is $2,000, then the Loss per month should be set at around $6,000.",
  key_546:
    "The Buy/Sell order with the placed Buy/Sell price is automatically adjusted to follow the market's upward/downward trend, helping investors achieve the optimal price within the expected period. Ubot recommends users to set the trailing stop at 40%. This is a safe level according to our research.",
  key_547: "Take profit",
  key_548:
    '"Take profit" means the order to close a trade with a desired profit level achieved.\\nPlace a sell order (when holding a long position) or a buy order (when holding a short position) when reaching a desired profit level.',
  key_549: "Scenario code",
  key_550:
    "Scenario code. Each trading BOT scenario will be assigned a unique code.",
  key_551: "Pips",
  key_552:
    "Pips are a unit of measurement used to gauge the fluctuation of financial instruments (currency pairs). Most currency pairs have four decimal places, and a pip is equivalent to the fourth decimal place. For example, if the price changes from 14.0000 to 14.0001, it means the price has increased by one pip.",
  key_553: "Bots user guide",
  key_554: "Please login to see packages",
  key_555: "Ubot",
  key_556: "Guideline",
  key_557: "Sponsor",
  key_558: "Bot Expiry",
  key_559: "Secret Formula",
  key_560: "Connect bot with MT4",
  key_561: "Wait for confirmation",
  key_562: "Change info",
  key_563: "Choose bots",
  key_564:
    "Please select 1, 5, 10,... Bot Id. The number of selected bots is a multiple of 5",
  key_565: "Successfully applied",
  key_566: "Package Details",
  key_567: "Apply",
  key_568: "Contact us",
  key_569: "Personal Account Performance",
  key_570: "Referrals list",
  key_571: "Type your code",
  key_572: "Ubot",
  key_573: "Low risk - Low Cap - Hight profit",
  key_574: "Invest with only a smart-phone",
  key_575: "Time and financial freedom",
  key_576: "Introduce",
  key_577:
    "Ubot is a forex trading product that has been operating for 3 years, based on AI technology. It operates automatically according to Fibonacci commands and combines the superiority of AI, using artificial intelligence with multiple analysis algorithms that are constantly updated to fit the market situation. Ubot generates profits of over 100% per year (about 5-15% per month). Ubot is the fastest shortcut to lead you to financial freedom.",
  key_578: "Disclaimer of Liability",
  key_579: "Help center",
  key_580: "support@ubot.finance",
  key_581: "Company",
  key_582: "Market",
  key_583: "Education",
  key_584: "7 years ",
  key_585: "of finding a stable job in the financial industry",
  key_586:
    "The Founder team behind Ubot has spent the past 7 years dedicated to finding a safe and secure trading product for investors. They have implemented advanced AI technology and sophisticated algorithms that continuously analyze and update market trends. Additionally, they have created a rigorous risk management system to minimize loss and protect investors' funds. Ubot is the result of their unwavering commitment to investor safety and security",
  key_587: "Oppotunities",
  key_588:
    "Forex (Foreign Exchange) is a massive market where people trade currencies for various reasons, such as tourism, commerce, and investment. It involves buying one currency and selling another, with the aim of making a profit from the exchange rate fluctuations. With a daily trading volume of around $6 trillion, it's much larger than both the cryptocurrency and NASDAQ markets combined, and it operates 24/5, except on weekends and holidays. Its size and liquidity make it an attractive option for traders and investors seeking opportunities to profit.",
  key_589: "Experiences",
  key_590:
    "Ubot, with over 3 years of product testing and a user base of 2000 investors, has acquired significant experience in the financial market. This prolonged operational period, coupled with user feedback, has enabled the team to optimize their product to meet the demanding standards of the trading industry.",
  key_591:
    "Ubot is widely known for its dependable and efficient trading tool, delivering consistent annual profits in excess of 100%. With state-of-the-art AI technology and sophisticated algorithms, the platform is continuously updated to keep up with the ever-evolving market conditions, offering investors a more secure and trustworthy trading experience.",
  key_592:
    "Ubot's success is largely attributed to its unwavering commitment to delivering high-quality trading products, ensuring customer satisfaction.",
  key_593: "23/5 - No FOMO",
  key_594:
    "Ubot's Auto Trade 23/5 is a unique feature that sets it apart from other trading products in the financial market. With Auto Trade 23/5, traders no longer have to worry about FOMO or making mistakes based on their emotions. This feature enables Ubot to operate 24/7 without any human intervention, providing traders with a continuous and reliable trading experience. By utilizing advanced AI technology and sophisticated algorithms, Ubot's Auto Trade 23/5 ensures that traders can take advantage of every trading opportunity without any fear of missing out or being affected by emotions.",
  key_595: "Capital management",
  key_596:
    "Effective capital management is a critical component of Ubot's success in achieving consistent profits in the financial market. Ubot's superior capital management system ensures that investors can enjoy a steady passive income of around 5-15% every month. This is achieved by implementing strict risk management policies and utilizing advanced algorithms that analyze and adapt to market trends in real-time. With Ubot's capital management system, investors can trust that their funds are in good hands and can enjoy a reliable and stable source of income from their investments.",
  key_597: "Trust",
  key_598:
    "Ubot has earned a reputation for reliability and trustworthiness in the financial market, thanks to its partnerships with reputable institutions worldwide.",
  key_599:
    "Choose the most convenient way to contact our support team. Ask your question via email or online chat. Experienced professionals will answer any of your questions about the platform. Our Support system work 24/7",
  key_600: "Open account",
  key_601: "Metals",
  key_602: "Stock Trading",
  key_603: "Futures",
  key_604: "Why Ubot",
  key_605: "Docs",
  key_606: "Trading statistics",
  key_607: "Partner with us",
  key_608: "FAQ",
  key_609: "Deposits and Withdrawals",
  key_610: "Promotions and Contests",
  key_611: "Refer a Friend",
  key_612: "Pricing",
  key_613: "Guide for Beginner",
  key_614: "Ubot Dictionary",
  key_615: "Market Analysis",
  key_616: "Trading Education",
  key_617: "Policy",
  key_618: "Get Help",
  key_619:
    "Ubot is an automated Forex trading product with AI technology, produced by a technology company with more than 7 years of experience in the CFD market. Ubot operates 23/5. automatically follow Fibonacci orders, generating stable profits with an average of 5-15% per month.",
  key_620: "FPMarkets",
  key_621:
    "FPMarket is proud to be the most stable and gained high level of trust by having won more than 40 global awards. In particular with being voted as Australia’s Top Forex Broker in 2020.",
  key_622: "FXPrimus",
  key_623:
    "We are a specialized intermediary platform for foreign exchange, metals, energy, and securities markets, with a presence in over 13 years worldwide, including countries such as the UK, Australia, Singapore, China, Vietnam, etc.",
  key_624: "EA (Expert Advisor)",
  key_625:
    "This is a type of software that provides financial trading signals or executes trading orders (closing orders, cutting losses, taking profits, etc.) based on a pre-existing programming program using the MT4 or MT5 trading platform.",
  key_626:
    'As a popular online trading platform with a simple and intuitive interface, MT4 allows traders to easily create their own automated trading strategies."\\nNote: "MT4" likely refers to MetaTrader 4, a widely used trading platform in the financial industry',
  key_627: "BOT Package",
  key_628: "The cost that users need to pay in order to use the BOT.",
  key_629: "Investment amount",
  key_630:
    "The amount of money that users need to deposit in order for the BOT to initiate trading",
  key_631:
    "The amount of money that users need to deposit in order to maintain their position when the market experiences significant fluctuations.",
  key_632:
    "This is the estimated profit value to be earned within the period of using Ubot based on the pre-set trading scenarios. Depending on the trading scenario, the estimated profit may vary.",
  key_633: "Trading Scenario",
  key_634:
    "A scenario is a set of predetermined rules that a user can choose to apply to their BOT. The BOT will rely on this scenario to carry out buy and sell transactions.",
  key_635: "The type of transactions that the BOT will carry out.",
  key_636: "The currency pair that the user chooses to trade",
  key_637: "Maximum buy order",
  key_638: "Maximum sell order",
  key_639: "Tralling stop",
  key_640:
    "The Buy/Sell order with the placed Buy/Sell price is automatically adjusted to follow the market's upward/downward trend, helping investors achieve the optimal price within the expected period.",
  key_641:
    "1. We only provide trading technology. Ubot is not a discretionary investment service.",
  key_642:
    "2. Ensure stable internet connection for transactions; if a trader loses the internet, the trade order will not be sent.",
  key_643:
    "3. Consider risk management to prevent rare cases of risk such as power outage or mechanical problems. The sensitivity of the Ubot means that the bot may be affected by false information in the market.",
  key_644:
    "4. Do not follow advertisements or opinions from those outside of the project. Especially those who claim to be part of the Ubot project.",
  key_645: "5. The average monthly return is estimated at 5-15%.",
  key_646: "6. Admin will never message you first.",
  key_647:
    "7. If there are any questions or need for support, immediately contact the Ubot support department, which works continuously from Monday to Friday, 23 hours a day.",
  key_648:
    "8. We are not responsible for any trade-related risks outside of business hours and activities.",
  key_649: "9. Absolutely do not follow unverified investment advice.",
  key_650:
    "10. Do not change the MT4 password after installing the bot, if the password is changed, the BOT will stop functioning.",
  key_651:
    "11. Need to complete KYC on the Exchange Market before activate the Bot.",
  key_652:
    "12. No responsibility will be taken for any damage caused by unauthorized access to the account.",
  key_653: "Transfer",
  key_654: "Enter the receiver email",
  key_655: "Confirm transfer",
  key_656: "Successfully transferred",
  key_657: "To email",
  key_658: "Buy Bot now",
  key_659: "PitchDeck English",
  key_660: "PitchDeck Korean",
  key_661: "PitchDeck Vietnamese",
  key_662: "PitchDeck Chinese",
  key_663:
    "Account verification will take 8-24 hours, please come back when you receive an email notification",
  key_664:
    "Ubot is a Financial services company that provides trading software to clients on the MetaTrade 4(MT4) trading platform. It is not an investment delegation platform. Before deciding to participate in the foreign exchange market, you should carefully consider your investment objectives, level of experience, and risk tolerance. Most importantly, do not invest an amount that you cannot afford to lose. Only adults with sufficient capacity are eligible to perform the transactions provided by this website.",
  key_665: "Must be a positive value",
  key_666: "Receiver",
  key_667: "Transfer Time",
  key_668: "Receiver Id",
  key_669: "Receiver Email",
  key_670: "Go to transfer bot history",
  key_671: "Search",
  key_672: "Bot Transfer History",
  key_673: "Show filter",
  key_674: "Phone number",
  key_675: "Sender",
  key_676: "Start trading forex",
  key_677:
    "Start utilizing both forex with multiple currency pairs, including USD & Gold",
  key_678: "Start trading with AUD/CAD currency pair",
  key_679: "GBP/USD currency pair test",
  key_680: "Start BW BOT",
  key_681: "Cooperation with Fxprimus exchange",
  key_682: "Alpha version of Ubot",
  key_683: "GBP/USD currency pair test",
  key_684: "Lauching global Ubot",
  key_685: "Start trading with GBP/USD currency pair",
  key_686: "Cooperation with FPMarket exchange",
  key_687: "Beta test new trading pair",
  key_688: "Partner with other Exchange",
  key_689: "Ubot token, NFT, whitepaper release plan",
  key_690: "Ubot token listing, NFT sales plan",
  key_691: "Mobile app release ver1",
  key_692: "New trading pair and new package open",
  key_693: "Open crypto AI trading and more new product",
  key_694: "Road",
  key_695: "map",
  key_696: "Scan to join telegram group for support",
  key_697: "Vietnamese",
  key_698:
    "Do Not Change MT4 Password after Bot has been installed, if the password is changed the bot will Stop Working",
  key_699: "Change password",
  key_700: "Name",
  key_701: "Successfully sent",
  key_702: "Token",
  key_703: "Flexible",
  key_704: "Permanent",
  key_705: "Redeem history",
  key_706: "Redeem success",
  key_707: "Redeem fail",
  key_708: "Unstake success",
  key_709: "Unstake fail",
  key_710: "Month",
  key_711: "Earned",
  key_712: "Lock-up term",
  key_713: "Hide",
  key_714: "Detail",
  key_715: "Stake amount",
  key_716: "Start time join",
  key_717: "End time join",
  key_718: "Current profit",
  key_719: "Redeem",
  key_720: "UnStake",
  key_721: "Staking Amount",
  key_722: "Current  profit",
  key_723: "Claim profit",
  key_724: "Unstake with profit",
  key_725: "Received Profit",
  key_726: "Stake success",
  key_727: "Stake fail",
  key_728: "Estimate profit",
  key_729: "Annual percentage rate (per year)",
  key_730: "Stakers",
  key_731: "USDT price",
  key_732: "Staking Pool",
};

export default en;
