import { alpha } from '@mui/material/styles';
// utils
import createGradient from '../utils/createGradient';

// ----------------------------------------------------------------------

// SETUP COLORS

export const contrastText = {
  white: '#FFFFFF',
  black: '#212B36',
};

export const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#000',
  600: '#637381',
  700: '#454F5B',
  800: '#231f3a',
  900: '#201c35',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const DEFAULT = {
  lighter: GREY[200],
  light: GREY[300],
  main: GREY[500],
  dark: GREY[600],
  darker: GREY[800],
  contrastText: contrastText.white,
};

const PRIMARY = {
  lighter: '#439362',
  light: '#62b345',
  light1: '#62b345',
  main: '#43E9F7',
  dark: '#22c0cc',
  dark1: '#439362',
  darker: '#439362',
  contrastText: contrastText.white,
};
const SECONDARY = {
  lighter: '#D2FCF4',
  light: '#77F0ED',
  main: '#1315FD',
  dark: '#116E95',
  darker: '#063963',
  contrastText: contrastText.white,
};
const INFO = {
  lighter: GREY[200],
  light: GREY[300],
  main: GREY[500],
  dark: GREY[600],
  darker: GREY[800],
  contrastText: contrastText.white,
};
const SUCCESS = {
  lighter: '#CDFCD1',
  light: '#69F290',
  main: '#0CD66E',
  dark: '#069A6B',
  darker: '#02665B',
  contrastText: contrastText.black,
};
const WARNING = {
  lighter: '#FFF8D1',
  light: '#FFE475',
  main: '#FFC81A',
  dark: '#B7860D',
  darker: '#7A5204',
  contrastText: contrastText.black,
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: contrastText.white,
};



const GRADIENTS = {
  primary: createGradient(PRIMARY.main, SECONDARY.main),
  secondary: createGradient(SECONDARY.light, SECONDARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  default: { ...DEFAULT, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: PRIMARY.dark,
    selected: GREY[500_12],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200], primary: '#00B1C8' },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600], default: PRIMARY.main },
    background: { paper: '#202020', default: '#0D0D0D', neutral: GREY[500_12], primary: '#00B1C8' },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
