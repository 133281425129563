import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/app";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import ProfileLayout from "./layouts/profile";
//
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import AccountSetting from "./pages/AccountSetting";

// recaptcha
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { CAPTCHA_KEY } from "./settings/apiEndpoint";

import Funding from "./pages/Funding";
import Account from "./pages/Account";
import Team from "./pages/Team";
import RegisterToolPage from "./pages/RegisterToolPage";
import Commission from "./pages/Commission";
import Dashboard from "./pages/Dashboard";
import Module from "./components/Module";
import Homepage from "./pages/Homepage";
import { Packages } from "./sections/profile/Packages";
import { MyPackages } from "./sections/profile/MyPackages";
import BotSetting from "./pages/BotSetting";
import { MyBots } from "./sections/profile/MyBots";
import AboutUs from "./pages/AboutUs";
import FAQ from "./pages/FAQ";
import { BotTransferHistory } from "./sections/profile/BotTransferHistory";
import GuideLine from "./pages/GuideLine";
import Staking from "./pages/Staking";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "", element: <Homepage /> },
        { path: "about-us", element: <AboutUs /> },
        { path: "faq", element: <FAQ /> },
        { path: "404", element: <NotFound /> },
      ],
    },
    {
      path: "/auth",
      element: <LogoOnlyLayout />,
      children: [
        {
          path: "login",
          element: (
            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY} language={"en"}>
              <Login />
            </GoogleReCaptchaProvider>
          ),
        },
        {
          path: "reset-password",
          element: (
            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY} language={"en"}>
              <ResetPassword />
            </GoogleReCaptchaProvider>
          ),
        },
        {
          path: "register",
          element: (
            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY} language={"en"}>
              <Register />
            </GoogleReCaptchaProvider>
          ),
        },
        {
          path: "register/:referralId",
          element: (
            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY} language={"en"}>
              <Register />
            </GoogleReCaptchaProvider>
          ),
        },
        {
          path: "register-tool",
          element: (
            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY} language={"en"}>
              <RegisterToolPage />
            </GoogleReCaptchaProvider>
          ),
        },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/profile",
      element: <ProfileLayout />,
      children: [
        { path: "", element: <Dashboard /> },
        { path: "setting", element: <Account /> },
        { path: "wallet", element: <Funding /> },
        { path: "packages", element: <Packages /> },
        { path: "guideline", element: <GuideLine /> },
        { path: "staking", element: <Staking /> },
        // { path: "my-package", element: <MyPackages /> },
        { path: "my-bots", element: <MyBots /> },
        { path: "transfer-bot-history", element: <BotTransferHistory /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "team", element: <Team /> },
        { path: "commission", element: <Commission /> },
        { path: "account-setting", element: <AccountSetting /> },
        { path: "bot-setting/:id", element: <BotSetting /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
