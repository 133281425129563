import PropTypes from "prop-types";
// @mui
import {
  Card,
  Divider,
  Stack,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// icon
import { useEffect, useState } from "react";
import CopyContent from "../../components/CopyContent";
import CustomTable from "../../components/CustomTable";
import Iconify from "../../components/Iconify";
import SearchNotFound from "../../components/SearchNotFound";
import { useResponsive } from "../../hooks";
import { post } from "../../utils/api";
import { isLoggedIn } from "../../utils/auth";
import { fCurrency, fDate, fDateTime } from "../../utils/format";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const TABLE_HEAD_DESKTOP = [
  {
    id: "txHash",
    label: "key_99", //Transaction Hash
    alignRight: false,
    render: (row) => <CopyContent showNextContent ellipsis={true} text={row.txHash} />,
  },
  {
    id: "typeDescription",
    label: "key_104",
    alignRight: false,
    render: (row) => row.typeDescription.replaceAll("_", " ")
  }, //Type
  {
    id: "amount",
    label: "key_100", //Amount
    alignRight: false,
    render: (row) => (
      <Typography>
        {fCurrency(row.amount)} {row.asset}
      </Typography>
    ),
  },
  {
    id: "fee",
    label: "key_101", //Fee
    alignRight: false,
    render: (row) => (
      <Typography>
        {fCurrency(row.fee)} {row.feeCurrency}
      </Typography>
    ),
  },
  {
    id: "createdTime",
    label: "key_102", //Time
    alignRight: true,
    render: (row) => <Typography>{fDateTime(row.createdTime)}</Typography>,
  },
  { id: "status", label: "key_103", alignRight: false }, //Status
];

const TABLE_HEAD_MOBILE = [
  {
    render: (row, lang) => (
      <Stack direction="row" alignItems="center" spacing={1}>
        {row.type === "WITHDRAW" && (
          <Iconify
            sx={{
              transform: "scale(1.1)",
              color: (theme) => theme.palette.error.main,
            }}
            icon="line-md:external-link-rounded"
          />
        )}
        {row.type === "DEPOSIT" && (
          <Iconify
            sx={{
              transform: "scale(1)",
              color: (theme) => theme.palette.success.main,
            }}
            icon="line-md:arrow-align-bottom"
          />
        )}
        <Typography>{row.typeDescription.replaceAll("_", " ")}</Typography>
      </Stack>
    ),
  },
  {
    render: (row, lang) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
        divider={<Divider orientation="vertical" sx={{ height: "15px" }} />}
      >
        <Typography sx={{ opacity: 0.6 }}>{fDate(row.createdTime)}</Typography>
        <Typography>{row.status}</Typography>
      </Stack>
    ),
  },
  {
    render: (row, lang) => (
      <Typography sx={{ opacity: 0.6 }}>{lang}</Typography>
    ), //Amount
    label: "key_100",
  },
  {
    render: (row, lang) => (
      <Typography align="right">
        {fCurrency(row.amount)} {row.asset}
      </Typography>
    ),
  },
  {
    render: (row, lang) => (
      <Typography sx={{ opacity: 0.6 }}>{lang}</Typography>
    ), //Fee
    label: "key_101",
  },
  {
    render: (row, lang) => (
      <Typography align="right">
        {fCurrency(row.fee)} {row.feeCurrency}
      </Typography>
    ),
  },
];

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  // background: theme.palette.warning.lighter,
  borderRadius: theme.shape.borderRadius,
}));

// ----------------------------------------------------------------------

DepositHistory.propTypes = {
  data: PropTypes.object,
};

export default function DepositHistory({ triggerLoading, ...other }) {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const { user } = useSelector((state) => state);
  const { profile } = user ? user : {};
  const isDesktop = useResponsive("up", "sm");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null)


  const handleGetHistory = () => {
    const params = {
      page: page + 1,
      pageSize: pageSize,
      search: "",
      orderBy: "",
      isDesc: true,
      returnStatistic: false,
    };
    setLoading(true);
    post(
      `/wallet/funding-wallet-txs`,
      params,
      (result) => {
        setData(result);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    if (profile && profile.id) {
      setUserId(profile.id)
    }
  }, [profile])


  useEffect(() => {
    if (isLoggedIn()) handleGetHistory();
  }, [page, pageSize, triggerLoading]);

  const TABLE_HEAD = isDesktop ? TABLE_HEAD_DESKTOP : TABLE_HEAD_MOBILE;

  return (
    <Card sx={{ padding: isDesktop ? "0px !important" : "1rem" }}>
      <CustomTable
        loading={loading}
        tableHead={
          <TableRow>
            {TABLE_HEAD.map((item, index) => (
              <TableCell key={index}>{langLibrary[item.label]}</TableCell>
            ))}
          </TableRow>
        }
        tableBody={
          <>
            {data.items &&
              data.items.map((item, index) => (
                <TableRow key={index}>
                  {TABLE_HEAD.map((e, j) => (
                    <TableCell key={j}>
                      {e.render
                        ? e.render(item, langLibrary[e.label])
                        : item[e.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {!loading && data.items && data.items.length === 0 && (
              <TableRow sx={{ '&.MuiTableRow-root': { display: !isDesktop ? "flex" : "", justifyContent: "center" } }}>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound />
                </TableCell>
              </TableRow>
            )}
          </>
        }
        tablePagination={
          data && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={data.itemCount ? data.itemCount : 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={langLibrary.key_304}
              page={page}
              onPageChange={(e, newPage) => {
                setPage(newPage);
              }}
              onRowsPerPageChange={(e) => setPageSize(e.target.value)}
            />
          )
        }
      />
    </Card>
  );
}
