import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
// icons
import { IconChevronDown } from "@tabler/icons";
// @mui
import {
  IconButton,
  ListItem,
  ListItemButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import MenuPopover from "../components/MenuPopover";
import { CoinImage } from "../settings/constants";
import { useSelector } from "react-redux";

const SelectButton = styled(ListItem)(({ theme }) => ({
  border: "1px solid rgba(145, 158, 171, 0.32)",
  borderRadius: "7px",
  maxHeight: "56px",
  minHeight: "48px",
  padding: "0px",
  "& .MuiListItemButton-root": {
    "&:hover": {
      background: "none",
    },
  },
  "& .MuiListItemSecondaryAction-root": {
    right: "0.5rem",
  },
  "& img": {
    width: "30px",
    height: "30px",
    objectFit: "contain",
  },
}));

const SelectOptions = styled(MenuPopover)(({ theme }) => ({
  "& img": {
    width: "30px",
    height: "30px",
    objectFit: "contain",
  },
}));

export default function CoinSelector({ onSelect, options, hideChainName, ...other }) {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const buttonRef = useRef();
  const [open, setOpen] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSelect = (item) => {
    setItemSelected(item);
    setOpen(false);
    onSelect(item);
  };

  useEffect(() => {
    const usdtIndex = options.findIndex((option) => option.asset === "USDT")
    if (usdtIndex > -1) {
      setItemSelected(options[usdtIndex]);
      onSelect(options[usdtIndex]);
    } else {
      setItemSelected(options[0]);
      onSelect(options[0]);
    }
  }, [options]);

  return (
    <>
      <SelectButton
        onClick={handleOpen}
        ref={buttonRef}
        secondaryAction={
          <IconButton>
            <IconChevronDown />
          </IconButton>
        }
      >
        <ListItemButton>
          {itemSelected ? (
            <>
              <img
                alt=""
                src={
                  itemSelected.icon
                    ? itemSelected.icon
                    : CoinImage[itemSelected.asset]
                }
              />
              <Typography ml="10px" fontWeight="bold">
                {itemSelected.coin || itemSelected.asset}
              </Typography>
              {!hideChainName && (
                <>
                  <Divider
                    orientation="vertical"
                    sx={{ height: "15px", margin: "0px 10px" }}
                  />
                  <Typography variant="body2" sx={{ opacity: 0.6 }}>
                    {itemSelected.networkName}
                  </Typography>
                </>
              )}
            </>
          ) : (
            <>{langLibrary.key_1}</>
          )}
        </ListItemButton>
      </SelectButton>
      <SelectOptions
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          minWidth: "300px",
          "& .MuiMenuItem-root": {
            typography: "body2",
          },
        }}
      >
        {options.map((item, index) => (
          <MenuItem
            sx={{ m: 1 }}
            key={index}
            onClick={() => handleSelect(item)}
          >
            <ListItemIcon>
              <img alt="" src={item.icon ? item.icon : CoinImage[item.asset]} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">{item.coin || item.asset}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </SelectOptions>
    </>
  );
}
