// user ------------------------------------------------------
export const FETCH_USER = "FETCH_USER";
export const FETCH_FUNDING = "FETCH_FUNDING";
export const FETCH_AFFILIATE_PROFILE = "FETCH_AFFILIATE_PROFILE";
export const FETCH_MY_NFTS = "FETCH_MY_NFTS";

// app ------------------------------------------------------
export const FETCH_CONFIG = "FETCH_CONFIG";
export const FETCH_CONTRACT = "FETCH_CONTRACT";
export const TRIGGER_DRAWER = "TRIGGER_DRAWER";
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
export const TRIGGER_DICTIONARY = "TRIGGER_DICTIONARY";
export const TRIGGER_POLICY = "TRIGGER_POLICY";
export const TRIGGER_TELEGRAM_QR_CODE = "TRIGGER_TELEGRAM_QR_CODE";
export const TRIGGER_PARTNER_FORM = "TRIGGER_PARTNER_FORM";
