import { get } from "../../utils/api";
import {
  FETCH_AFFILIATE_PROFILE,
  FETCH_FUNDING,
  FETCH_USER,
  TRIGGER_DICTIONARY,
} from "../constants";

export const _getNewProfile = () => (dispatch) => {
  get(
    "/user/profile",
    (data) => {
      dispatch({
        type: FETCH_USER,
        payload: data,
      });
    },
    (error) => {
      console.log(error);
      // logout();
    }
  );
};
export const _getFunding = () => (dispatch) => {
  get(
    "/wallet/funding-wallet",
    (data) => {
      dispatch({
        type: FETCH_FUNDING,
        payload: data,
      });
    },
    (error) => {
      console.log(error);
      // logout();
    }
  );
};

export const _getAffiliateProfile = () => (dispatch) => {
  get(
    "/affiliate/profile",
    (data) => {
      dispatch({
        type: FETCH_AFFILIATE_PROFILE,
        payload: data,
      });
    },
    (error) => {
      console.log(error);
      // logout();
    }
  );
};

