// @mui
import {
  Card,
  Collapse,
  Divider,
  Grid,
  Link,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import CopyContent from "../components/CopyContent";
import Iconify from "../components/Iconify";
import Image from "../components/Image";
import Page from "../components/Page";
import { useResponsive } from "../hooks";
import CommissionList from "../sections/profile/CommissionList";
import DownLineList from "../sections/profile/DownLineList";
import { MyBots } from "../sections/profile/MyBots";
import DiamondRank from "../sections/profile/RankCommission/DiamondRank";
import GoldRank from "../sections/profile/RankCommission/GoldRank";
import ManagerRank from "../sections/profile/RankCommission/ManagerRank";
import UserRank from "../sections/profile/RankCommission/UserRank";
import UpdateReferral from "../sections/profile/UpdateReferral";
import { formatRankName } from "../utils/utils";
import { Link as RouterLink } from "react-router-dom";

// ----------------------------------------------------------------------

export default function GuideLine() {
  const { app, user } = useSelector((state) => state);
  const { langLibrary } = app;
  const { profile, affiliateProfile } = user ? user : {};
  if (!profile || !affiliateProfile) return null;

  const data = [
    {
      title: "Guideline for FPMarket",
      contents: [
        {
          langCode: "ko",
          url: "https://drive.google.com/file/d/1SeenHlH8-qT2lhElkaE4a4A67ZSQrtsz/view",
        },
        {
          langCode: "en",
          url: "https://drive.google.com/file/d/133As3ZCNivn3hK8ncU62Erd7cCbO2rLC/view",
        },
        {
          langCode: "vi",
          url: "https://drive.google.com/file/d/1tx23paeoBgtD09kYdZDOsUB5cpPEVCFN/view",
        },
      ],
    },
    {
      title: "Guideline for FXPrimus",
      contents: [
        {
          langCode: "ko",
          url: "https://drive.google.com/file/d/1HG57Hs3wbmFNcm7AIzzNM225jPtJowXQ/view",
        },
        {
          langCode: "en",
          url: "https://drive.google.com/file/d/1Ftt5jiVFm1fdiTYo38lCkIXXg8xEju2S/view",
        },
        {
          langCode: "vi",
          url: "https://drive.google.com/file/d/1HQ-df0Yb70KiY_ozlP6aS_R6D3ZQe3DW/view",
        },
      ],
    },
    {
      title: "Guideline for Ubot.finance",
      contents: [
        {
          langCode: "ko",
          url: "https://drive.google.com/file/d/1y0Bol_F_wrtJp5O6AsZ9Babz2PCBUawV/view",
        },
        {
          langCode: "en",
          url: "https://drive.google.com/file/d/1Jxx-7OW4_cq7fbI6LC3WGZ-_dUTxFx8R/view",
        },
        {
          langCode: "vi",
          url: "https://drive.google.com/file/d/1dtAeEvi2OKWqk-5FDFXe95cksWjMJJRO/view",
        },
      ],
    },
    {
      title: "Guideline how to use MT4",
      contents: [
        {
          langCode: "ko",
          url: "https://drive.google.com/file/d/1362jBp2qg7eXofSB9z96ppTJxQ3_zucU/view",
        },
        {
          langCode: "en",
          url: "https://drive.google.com/file/d/16mRnxe-c10o7_CYWoSjv1Ze6fUGndrd3/view",
        },
        {
          langCode: "vi",
          url: "https://drive.google.com/file/d/158MZqGgU6UbleLBJBH95sCcvwDyfHtb8/view",
        },
      ],
    },
  ];

  return (
    <Page title={langLibrary.key_189}>
      <Grid container m={0} width="100%" justifyContent="space-between">
        <Grid item xs={12} sx={{ minHeight: 150, marginBottom: "1rem" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <Stack alignItems="flex-start"></Stack>

            <Stack
              direction={{ xs: "row-reverse", lg: "row" }}
              marginTop={{ xs: 2, lg: 0 }}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
              spacing={2}
              width={{ xs: "100%", lg: "50%" }}
            >
              <Stack justifyContent={"flex-end"}>
                <Typography
                  variant="body2"
                  textAlign={{ xs: "left", lg: "right" }}
                  sx={{ color: "#fff" }}
                >
                  {langLibrary.key_278}
                </Typography>
                <Typography
                  variant="h4"
                  mt={-1}
                  textAlign={{ xs: "left", lg: "right" }}
                  sx={{ color: "#fff" }}
                >
                  {profile?.id}
                </Typography>
                <CustomLabel>
                  <CopyContent
                    text={`${window.location.origin}/auth/register/${profile.id}`}
                  />
                </CustomLabel>
              </Stack>
              <QRBox>
                <QRCode
                  value={`${window.location.origin}/auth/register/${profile.id}`}
                  size={100}
                />
              </QRBox>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ minHeight: 150 }}>
          <CustomCard sx={{ py: 3, px: 4 }}>
            {data.map((item, index) => (
              <CollapseItem
                key={index}
                title={item.title}
                contents={item.contents}
              />
            ))}
          </CustomCard>
        </Grid>
      </Grid>
    </Page>
  );
}

// ----------------------------------------------------------------------

const CollapseItem = ({ title, contents = [] }) => {
  const theme = useTheme();
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        my={1}
      >
        <Typography sx={{ textDecoration: "underline" }}>{title}</Typography>
        <Stack
          direction={"row"}
          alignItems="center"
          spacing={1}
          sx={{ color: theme.palette.primary.main }}
        >
          <Typography
            sx={{ cursor: "pointer", color: theme.palette.primary.main }}
            onClick={() => setOpened(!opened)}
          >
            Read more
          </Typography>
          <Iconify icon={`solar:alt-arrow-${opened ? "up" : "down"}-bold`} />
        </Stack>
      </Stack>
      <Collapse in={Boolean(opened)}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"flex-end"}
          spacing={1}
          py={1}
        >
          {contents.map((item, index) => (
            <a href={item.url} target="_blank">
              <Image
                key={index}
                src={`/images/flags/${item.langCode}.png`}
                sx={{ width: 30, height: "auto", cursor: "pointer" }}
              />
            </a>
          ))}
        </Stack>
      </Collapse>
    </>
  );
};

const CustomCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: "0 1rem 1rem -0.75rem rgb(0 0 0 / 5%)",
  borderRadius: "15px",
  height: "100%",
  [theme.breakpoints.down("lg")]: {
    marginTop: "1rem",
  },
}));
const CustomLabel = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: "10px",
  padding: "0.3rem 1rem",
  width: "100%",
  marginTop: "0.5rem",
  maxWidth: 300,
  width: "calc(100vw - 180px)",
}));

const QRBox = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.paperRadius,
  margin: "0px",
  padding: "1rem",
}));
