import { Card, Container, Grid, Hidden, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/robot-playing.json'
import Image from '../../components/Image';
import { alpha, Box, useTheme } from '@mui/system';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function Education({ ...other }) {
    const { app } = useSelector(state => state)
    const theme = useTheme()
    const { langLibrary } = app ? app : {}
    return (<ContainerStyle {...other}>
        <MarketContent container>
            <Grid item xs={12} p={2}>
                <Typography textAlign={'left'} variant='h2'>{langLibrary.key_418}</Typography>
            </Grid>
            <Hidden mdDown>
                <Grid item xs={4} p={2} sx={{ marginBottom: -5, }}>
                    <Lottie options={{ animationData: robotLottieData, autoplay: true }} />
                </Grid>
            </Hidden>
            <Grid item md={8} sm={12} p={2}>
                <Typography>{langLibrary.key_419}.
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {langLibrary.key_420}.
                </Typography>
            </Grid>
        </MarketContent>
    </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    alignItems: 'center',
    paddingTop: "50px",
    paddingBottom: '50px',
    minHeight: '50vh',
}));
const MarketContent = styled(Grid)(({ theme }) => ({
    padding: '3rem',
    paddingBottom: '0',
    position: "relative",
    height: 'fit-content',
    zIndex: 10,
    flexDirection: 'row',
    background: theme.palette.background.neutral,
    borderRadius: '2rem',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
        padding: '1rem 0px',
    }
}));