import { Avatar, Card, CardHeader, Collapse, Container, Grid, IconButton, Stack, Typography, } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/bitcoin-trading-bot.json'
import Image from '../../components/Image';
import TrackVisibility from 'react-on-screen';
import Title from './Title';



// ----------------------------------------------------------------------

export default function Media({ ...other }) {
    const theme = useTheme()
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const [showMore, setShowMore] = useState(false)

    const handleClick = (item) => {
        if (item.url)
            window.open(item.url)
    }

    const medias = [
        {
            image: '/images/medias/marketwatch.png',
            url: "https://www.marketwatch.com/press-release/ubot---an-auto-forex-trading-system-based-on-ai-2023-04-12"
        },

        {
            image: '/images/medias/nasdaq.png',
            url: "https://www.nasdaq.com/press-release/ubot-an-auto-forex-trading-system-based-on-ai-2023-04-12"
        },
        {
            image: '/images/medias/google_news.webp',
            url: "https://news.google.com/publications/CAAqBwgKMObSnAsw8ty0Aw?hl=en-US&gl=US&ceid=US%3Aen"
        },
        {
            image: '/images/medias/americanpress.png',
            url: "https://smb.americanpress.com/article/Ubot-an-Auto-Forex-trading-system-based-on-AI?storyId=6437758f63b31b0008702af2"
        },
        {
            image: '/images/medias/washingtoncitypaper.png',
            url: "https://pr.washingtoncitypaper.com/article/Ubot-an-Auto-Forex-trading-system-based-on-AI?storyId=6437758f63b31b0008702af2"
        },
        {
            image: '/images/medias/washingtondailynews.png',
            url: "https://smb.thewashingtondailynews.com/article/Ubot-an-Auto-Forex-trading-system-based-on-AI?storyId=6437758f63b31b0008702af2"
        },
        {
            image: '/images/medias/apnews.png',
            url: "https://apnews.com/press-release/news-direct-corporation/business-news-direct-corporation-c6414058f0891bdc66ab5ffac452f1c5"
        },
        {
            image: '/images/medias/yahoo_finance.png',
            url: "https://finance.yahoo.com/news/ubot-auto-forex-trading-system-023247580.html?guccounter=1"
        }
    ]

    return (<ContainerStyle {...other} py='10rem !important'>
        <Container>
            <TrackVisibility offset={100} partialVisibility>
                {
                    ({ isVisible }) => <Title isVisible={isVisible} title={langLibrary.key_498} />
                }
            </TrackVisibility>
        </Container>
        <Container sx={{ position: 'relative', zIndex: 1, mt: 5 }}>
            <Grid container>
                {
                    medias.map((media, index) => <Grid key={index} xs={12} sm={6} md={4} lg={3} p={1}>
                        <CardItem onClick={() => handleClick(media)} component={Stack} direction='row' alignItems='center' justifyContent='center' >
                            <Image src={media.image} sx={{ height: '100%', width: '100%', padding: 2 }} objectFit="contain" />
                        </CardItem>
                    </Grid>)
                }
            </Grid>
        </Container>
    </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    [theme.breakpoints.down('md')]: {
        '& h3': {
            fontSize: '1.2rem'
        }
    }
}));
const CardItem = styled(Card)(({ theme }) => ({
    height: '100px',
    background: 'none',
    position: "relative",
    zIndex: 10,
    boxShadow: "none",
    borderRadius: "15px",
    cursor: "pointer",
    // '&:before': {
    //     content: '""',
    //     position: 'absolute',
    //     width: "calc(100% - 4px)",
    //     height: "calc(100% - 4px)",
    //     left: '2px',
    //     top: "2px",
    //     background: "#171717",
    //     zIndex: -1,
    //     borderRadius: "13px"
    // },
    // '&:after': {
    //     content: '""',
    //     position: 'absolute',
    //     width: "100%",
    //     height: "100%",
    //     left: 0,
    //     top: 0,
    //     background: `linear-gradient(140deg,${alpha('#63b545', 0.2)},${alpha('#63b545', 1)})`,
    //     zIndex: -2
    // }
}));