// @mui
import {
  Divider,
  Hidden,
  Stack,
  Typography,
  TextField,
  Card,
  Alert,
} from "@mui/material";
import { useState } from "react";
import { MotionViewport } from "../components/animate";
import Page from "../components/Page";
import CommissionList from "../sections/profile/CommissionList";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AffiliatePool from "../sections/profile/AffiliatePool";
import { useSelector } from "react-redux";
import { fCurrency } from "../utils/format";
import CustomSlider from "../components/CustomSlider";
import Iconify from "../components/Iconify";

export default function Commission() {
  const { user, app } = useSelector((state) => state);
  const { langLibrary } = app;
  const { profile, affiliateProfile } = user ? user : {};
  const [commissionType, setCommissionType] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  return (
    <MotionViewport>
      {profile && (
        <Page title={langLibrary.key_14}>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
            mt={5}
            flexWrap="wrap"
          >
            <Stack>
              <Stack
                direction="row"
                mb={1}
                paddingRight="3rem"
                alignItems="center"
                spacing={2}
                divider={
                  <Divider orientation="vertical" sx={{ height: "15px" }} />
                }
              >
                <Typography variant="h5">{langLibrary.key_14}</Typography>
                {profile && (
                  <Typography>
                    ${fCurrency(profile.totalUsdEarned)} / $
                    {fCurrency(profile.usdAffiliatePool)}
                  </Typography>
                )}
              </Stack>
              <CustomSlider
                value={profile.totalUsdEarned}
                max={profile.usdAffiliatePool}
                step={profile.usdAffiliatePool / 4}
                height={15}
              />
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction="row" spacing={2} mt="1rem">
                <DatePicker
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                  }}
                  maxDate={toDate}
                  inputFormat="yyyy-MM-dd"
                  renderInput={(params) => (
                    <TextField {...params} label={langLibrary.key_15} />
                  )}
                />
                <DatePicker
                  value={toDate}
                  onChange={(newValue) => {
                    setToDate(newValue);
                  }}
                  minDate={fromDate}
                  inputFormat="yyyy-MM-dd"
                  renderInput={(params) => (
                    <TextField {...params} label={langLibrary.key_16} />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>
          {profile.usdAffiliatePool <= 0 && (
            <Alert
              icon={<Iconify icon="line-md:alert-circle" />}
              sx={{ mt: "1rem" }}
              severity="warning"
            >
              {langLibrary.key_17}
            </Alert>
          )}
          {profile.usdAffiliatePool > 0 &&
            profile.totalUsdEarned > profile.usdAffiliatePool && (
              <Alert
                icon={<Iconify icon="line-md:beer-alt-filled-loop" />}
                sx={{ mt: "1rem" }}
                severity="error"
              >
                {langLibrary.key_18}
              </Alert>
            )}
          <Divider sx={{ margin: "1rem 0px 2rem" }} />
          <CommissionList fromDate={fromDate} toDate={toDate} />
        </Page>
      )}
    </MotionViewport>
  );
}
