import { IconButton, Stack, alpha, useTheme } from "@mui/material";
import React from "react";
import Iconify from "./Iconify";

const socialList = [
  {
    title: "ch",
    icon: "tabler:message-circle-2-filled",
    link: "http://pf.kakao.com/_ExcLkxj",
    fromSvg: true,
  },
  {
    title: "talk",
    link: "https://open.kakao.com/o/gUYbAxaf",
    icon: "ri:kakao-talk-fill",
  },
  {
    title: "twitter",
    link: "https://twitter.com/Ubot_today",
    icon: "ri:twitter-fill",
  },
  {
    title: "telegram",
    link: "http://t.me/ubotaitrading",
    icon: "fa-brands:telegram-plane",
  },
  {
    title: "facebook",
    link: "https://www.facebook.com/Ubot.finance",
    icon: "ri:facebook-fill",
  },
];

export const SocialMedia = ({ sxIcon }) => {
  const theme = useTheme();
  return (
    <Stack
      direction={"row"}
      width={"100%"}
      alignItems="center"
      justifyContent={"space-between"}
    >
      {socialList.map((item, index) => (
        <IconButton
          key={index}
          onClick={() => window.open(item.link, "blank")}
          sx={{
            ":hover": {
              backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity
              ),
            },
            "&.MuiIconButton-root": {
              color: "#fff",
            },
          }}
        >
          {item.fromSvg ? (
            <img
              src="/images/social/ch.svg"
              alt=""
              style={{
                ...sxIcon,
                cursor: "pointer",
                width: 25,
                height: 25,
                padding: 2,
              }}
            />
          ) : (
            <Iconify
              icon={item.icon}
              sx={{ ...sxIcon, cursor: "pointer" }}
              onClick={() => {
                window.open(item.link, "blank");
              }}
            />
          )}
        </IconButton>
      ))}
    </Stack>
  );
};
