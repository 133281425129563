import { Button, Card, Divider, Link, Stack, TableCell, TablePagination, TableRow, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/CustomTable';
import Iconify from '../../components/Iconify';
import Label from '../../components/Label';
import Page from '../../components/Page';
import SearchNotFound from '../../components/SearchNotFound';
import { useResponsive } from '../../hooks';
import { post, put } from '../../utils/api';
import { fDate, fDateTime } from '../../utils/format';
import CustomDrawer from '../../components/CustomDrawer';
import $ from "jquery";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import Popup from '../../components/Popup';
import { toast } from 'react-toastify';
import CopyContent from '../../components/CopyContent';


export const MyBots = ({ justGetTable }) => {
    const navigate = useNavigate()
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [loading, setLoading] = useState(false)
    const [bots, setBots] = useState([])
    const [statistic, setStatistic] = useState(null)
    const isMobile = useResponsive('down', 'sm')
    const { app } = useSelector((state) => state);
    const { langLibrary } = app;
    const [selectedTransferBot, setSelectedTransferBot] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showConfirmPopup, setShowConfirmPopup] = useState(false)
    const theme = useTheme()
    const handleGetBots = () => {
        const params = {
            "page": page + 1,
            "pageSize": pageSize,
            "search": "",
            "orderBy": "",
            "isDesc": true,
            "returnStatistic": false,
            "filters": {
            }
        }
        setLoading(true)
        post('/package/my-bots', params, result => {
            setLoading(false)
            setBots(result.items)
            setStatistic({ itemCount: result.itemCount })
        }, () => {
            setLoading(false)
            setBots([])
        })
    }
    const handleLinkToSetting = (id) => {
        navigate(`/profile/bot-setting/${id}`)
    }

    //transfer

    const handleLinkToTransfer = (row) => {
        setSelectedTransferBot(row)
    }
    const handleTransferBot = () => {
        setIsSubmitting(true)
        const params = {
            botId: selectedTransferBot?.id,
            receiverEmail: formik.values.email
        }
        put(`/package/bot/transfer`, params,
            () => {
                setIsSubmitting(false)
                setSelectedTransferBot(null)
                setTimeout(() => {
                    toast.success(langLibrary.key_656)
                }, 100);
                //after transfer, RELOAD LIST BOT
                handleGetBots()
                //if transfer success, CLOSE DRAWER
            },
            (err) => {
                setIsSubmitting(false)
                setSelectedTransferBot(null)
                setTimeout(() => {
                    toast.error(err.msg)
                }, 100);
            }
        )



    }
    const handleClose = (type) => {
        if (type === "submit") {
            setShowConfirmPopup(false)
            handleTransferBot()
            formik.resetForm()
        }
        if (type === "close_confirm") {
            setShowConfirmPopup(false)
        }
        if (type === "close_form") {
            setSelectedTransferBot(null)
            formik.resetForm()
        }
    }
    const TransferSchema = Yup.object().shape({
        email: Yup.string().email(langLibrary.key_46).required(langLibrary.key_47),
    })
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: TransferSchema,
        onSubmit: () => {
            setShowConfirmPopup(true)
        }
    })


    useEffect(() => {
        handleGetBots()
    }, [page, pageSize])

    const TABLE_HEAD_DESKTOP = justGetTable ? [
        {
            label: "key_300", render: row => `#${row.id}` //Bot ID
        },
        {
            label: "key_103",    //Status
            // sx: { textTransform: 'lowercase' },
            render: (row) => <Stack direction={'row'} alignItems="center" >
                <Iconify icon="octicon:dot-fill-16" sx={{ marginRight: 0.2, color: row.requestStatus === "DISCONNECT" ? "#FD5685" : (row.requestStatus === "PENDING" ? "#F6C443" : "#43E9F7") }} />
                {row.requestStatus?.toLowerCase()}
            </Stack>,
        },
        {
            label: "key_558",
            sx: { textAlign: 'center' },
            alignRight: false, render: (row) => row.status !== 'EXPIRED' ? `${fDate(row.createdTime)} - ${fDate(row.expiredTime)}` : row.status
        },
    ] : [
        {
            label: "key_300", render: row => `#${row.id}` //Bot ID
        },
        {
            label: "key_204",                                //Symbol
            alignRight: false,
            render: (row) => row.options ? row.options.symbol : '--'
        },
        {
            label: "key_558",
            sx: { textAlign: 'center' },
            alignRight: false, render: (row) => row.status !== 'EXPIRED' ? `${fDate(row.createdTime)} - ${fDate(row.expiredTime)}` : row.status
        },
        {
            label: "key_103",    //Status
            sx: { textTransform: 'capitalize' },
            render: (row) => <Stack direction={'row'} alignItems="center" >
                <Iconify icon="octicon:dot-fill-16" sx={{ marginRight: 0.2, color: row.requestStatus === "DISCONNECT" ? "#FD5685" : (row.requestStatus === "PENDING" ? "#F6C443" : "#43E9F7") }} />
                {row.requestStatus}
            </Stack>,
        },
        {
            label: "",
            sx: { textAlign: 'right' },
            render: (row) => (
                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                    {row.status !== "EXPIRED" && (
                        <>
                            <Link onClick={() => handleLinkToSetting(row.id)}>{langLibrary.key_317}</Link>
                            <Divider orientation='vertical' flexItem sx={{ border: `1px solid rgba(255,255,255,0.4) `, margin: "0 0.5rem" }} />
                        </>
                    )}
                    <Link onClick={() => handleLinkToTransfer(row)}>{langLibrary.key_653}</Link>

                </Stack>
            ),
        }
    ];
    const TABLE_HEAD_MOBILE = [
        {
            sx: { gridArea: 'end', minWidth: '100%' },
            render: row => (
                <Stack direction='row' p={1} justifyContent='space-between'>
                    <Stack>
                        <Stack direction='row' spacing={0.5}>
                            <Label>#{row.id}</Label>
                            {<Typography>{row.options.symbol}</Typography>}
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 0.5 }}>
                            <Typography variant='caption' noWrap sx={{ maxWidth: '50vw' }}>{row.title}</Typography>
                            {
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                                    <Link onClick={() => handleLinkToTransfer(row)}>{langLibrary.key_653}</Link>
                                    {row.status !== "EXPIRED" && (
                                        <>
                                            <Divider orientation='vertical' flexItem sx={{ border: `1px solid rgba(255,255,255,0.4) `, margin: "0 0.5rem" }} />
                                            <Link onClick={() => handleLinkToSetting(row.id)}>{langLibrary.key_317}</Link>
                                        </>
                                    )}
                                </Stack>
                            }
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography textAlign={'right'} variant='caption' sx={{ opacity: 0.6 }}>{fDateTime(row.createdTime)}</Typography>
                        <Label color={row.requestStatus === "ACTIVE" ? 'success' : 'error'}>{row.requestStatus}</Label>
                        {/* <Typography textAlign={'right'} variant='caption'>{row.status}</Typography> */}
                    </Stack>
                </Stack>
            )
        },
    ]
    const TABLE_HEAD_SUB = isMobile ? TABLE_HEAD_MOBILE : TABLE_HEAD_DESKTOP
    const { errors, touched, handleSubmit, getFieldProps } = formik;

    return <>
        <Page title={justGetTable ? "" : langLibrary.key_335} sx={{ position: "relative" }}>
            {!justGetTable && <Stack width={"100%"} minHeight={130} mb={2}>
                <Typography sx={{ flexGrow: 1, fontSize: '2rem', color: "#fff" }}>
                    {langLibrary.key_335}
                </Typography>
                <Stack direction={"row"} width='fit-content' mb={4}>
                    <CopyContent
                        text={langLibrary.key_580}
                        sxText={{ color: "#fff", fontSize: "1.5rem" }}
                        notSlice={true}
                        sizeIconCopy={"1.5rem"}
                        iconColor={"#fff"}
                    />
                </Stack>
                <Link onClick={() => { navigate(`/profile/transfer-bot-history`) }}
                    sx={{
                        textDecorationLine: "underline",
                        color: "#fff",

                    }}
                ><Typography>{langLibrary.key_670}</Typography></Link>
            </Stack>

            }

            <Card>
                {justGetTable &&
                    <>
                        <Typography variant="h6" textAlign={{ xs: "center", sm: "left" }}
                            padding={{ xs: "1rem 0px", sm: "1rem 1.5rem" }}>{langLibrary.key_569}</Typography>
                        <Divider />
                    </>
                }
                <CustomTable
                    loading={loading}
                    tableHead={
                        <TableRow>
                            {TABLE_HEAD_SUB.map((item, index) => (
                                <TableCell sx={{ ...item.sx }} key={index}>{langLibrary[item.label]}</TableCell>
                            ))}
                        </TableRow>
                    }
                    minHeight={110}
                    smMinWidth={justGetTable ? 480 : 800}
                    tableBody={
                        <>

                            {bots &&
                                bots.map((item, index) => (
                                    <TableRow key={index}>
                                        {TABLE_HEAD_SUB.map((e, j) => (
                                            <TableCell key={j} sx={{ ...e.sx }}>
                                                {
                                                    e.render
                                                        ? e.render(item, e.label)
                                                        : item[e.id]
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            {!loading && bots.length === 0 && (
                                <TableRow sx={{ '&.MuiTableRow-root': { display: isMobile ? "flex" : "", justifyContent: "center" } }}>
                                    <TableCell align="center" colSpan={20} sx={{ py: 3 }}>
                                        <SearchNotFound />
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    }
                    tablePagination={
                        statistic && (
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={statistic.itemCount ? statistic.itemCount : 0}
                                rowsPerPage={pageSize}
                                labelRowsPerPage={langLibrary.key_304}
                                page={page}
                                onPageChange={(e, newPage) => {
                                    setPage(newPage);
                                }}
                                onRowsPerPageChange={(e) => setPageSize(e.target.value)}
                            />
                        )
                    }
                />
            </Card>

            <CustomDrawer anchor={"right"}
                paperStyle={{ maxWidth: "500px", width: "100vw" }}
                title={<Typography>{langLibrary.key_653}</Typography>}
                open={Boolean(selectedTransferBot)}
                parent={$("#root")}
                onClose={() => handleClose("close_form")}
            >
                <FormikProvider value={formik}>
                    <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                        <Stack padding={"1rem"} spacing={"1rem"}>
                            {/* email */}
                            <Stack
                                sx={{ borderRadius: "7px", marginTop: { xs: "2rem", md: "0px" } }}
                            >
                                <Typography variant='body2'>{langLibrary.key_654}</Typography>
                                <TextField
                                    fullWidth
                                    autoComplete="email"
                                    type="email"
                                    label={langLibrary.key_51}
                                    {...getFieldProps("email")}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Stack>
                            <Stack
                                sx={{
                                    border: "1px solid rgba(145, 158, 171, 0.32)",
                                    padding: "1rem",
                                    borderRadius: "7px",
                                }}
                                spacing={2}
                            >
                                <Stack justifyContent={"space-between"} direction={"row"}>
                                    <Typography>{langLibrary.key_300}</Typography>
                                    <Typography fontWeight={'bold'}>#{selectedTransferBot?.id}</Typography>
                                </Stack>
                                <Stack justifyContent={"space-between"} direction={"row"}>
                                    <Typography>{langLibrary.key_204}</Typography>
                                    <Typography fontWeight={'bold'}>{selectedTransferBot?.options?.symbol ? selectedTransferBot?.options?.symbol : '--'}</Typography>
                                </Stack>
                                <Stack justifyContent={"space-between"} direction={"row"}>
                                    <Typography>{langLibrary.key_558}</Typography>
                                    <Typography fontWeight={'bold'}>
                                        {selectedTransferBot && selectedTransferBot.status !== 'EXPIRED' ? `${fDate(selectedTransferBot.createdTime)} - ${fDate(selectedTransferBot.expiredTime)}` : selectedTransferBot?.status}
                                    </Typography>
                                </Stack>
                                <Stack justifyContent={"space-between"} direction={"row"}>
                                    <Typography>{langLibrary.key_103}</Typography>
                                    <Stack direction={"row"} alignItems={"center"}>
                                        <Iconify icon="octicon:dot-fill-16" sx={{ marginRight: 0.2, color: selectedTransferBot?.requestStatus === "DISCONNECT" ? "#FD5685" : (selectedTransferBot?.requestStatus === "PENDING" ? "#F6C443" : "#43E9F7") }} />
                                        <Typography fontWeight={'bold'}>{selectedTransferBot?.requestStatus}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Divider />
                            <LoadingButton
                                fullWidth
                                size='large'
                                variant="contained"
                                type='submit'
                                loading={isSubmitting}
                                disabled={!formik.values.email || errors.email}
                            >
                                {langLibrary.key_653}
                            </LoadingButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            </CustomDrawer>
            <TransferConfirmPopup open={showConfirmPopup} data={selectedTransferBot} onClose={handleClose} langLibrary={langLibrary} email={formik.values.email} />
        </Page>
    </>
}
const TransferConfirmPopup = ({ open, data, onClose, langLibrary, email }) => {
    const isMobile = useResponsive('down', 'sm')
    return (
        <Popup
            title={<Typography variant='h5' align='center'>
                {langLibrary.key_655}
            </Typography>}
            open={open}
            onClose={() => onClose("close_confirm")}
        >
            <Stack
                sx={{ width: "75vw", maxWidth: "350px" }}
                padding={{ xs: "1rem 0px", sm: 0 }}
            >
                <Stack sx={{ margin: "-60px 0px 0px" }} >
                    <Divider />
                    <Stack py={"1.5rem"}>
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"baseline"}>
                            <Typography variant={isMobile ? "body2" : "body1"}>{langLibrary.key_657}</Typography>
                            <Typography fontWeight={'bold'} textOverflow={"ellipsis"} overflow={"hidden"}>{email}</Typography>
                        </Stack>
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"baseline"}>
                            <Typography variant={isMobile ? "body2" : "body1"}>{langLibrary.key_300}</Typography>
                            <Typography fontWeight={'bold'}>#{data?.id}</Typography>
                        </Stack>
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"baseline"}>
                            <Typography variant={isMobile ? "body2" : "body1"}>{langLibrary.key_204}</Typography>
                            <Typography fontWeight={'bold'}>{data?.options?.symbol ? data?.options?.symbol : '--'}</Typography>
                        </Stack>
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"baseline"}>
                            <Typography variant={isMobile ? "body2" : "body1"}>{langLibrary.key_558}</Typography>
                            <Typography fontWeight={'bold'}>
                                {data && data.status !== 'EXPIRED' ? `${fDate(data.createdTime)} - ${fDate(data.expiredTime)}` : data?.status}
                            </Typography>
                        </Stack>
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"baseline"}>
                            <Typography variant={isMobile ? "body2" : "body1"}>{langLibrary.key_103}</Typography>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Iconify icon="octicon:dot-fill-16" sx={{ marginRight: 0.2, color: data?.requestStatus === "DISCONNECT" ? "#FD5685" : (data?.requestStatus === "PENDING" ? "#F6C443" : "#43E9F7") }} />
                                <Typography fontWeight={'bold'}>{data?.requestStatus}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider />
                <Stack direction="row" spacing={2} mt={3}>
                    <Button
                        color="default"
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                        onClick={() => {
                            onClose("close_confirm")
                        }}
                    >
                        {langLibrary.key_87}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ flexGrow: 1 }}
                        onClick={() => {
                            onClose("submit")
                        }}
                    >
                        {langLibrary.key_57}
                    </Button>
                </Stack>
            </Stack>

        </Popup>
    )
}
