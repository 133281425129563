// @mui
import { Card, Divider, Grid, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import Page from "../components/Page";
import FundOverview from "../sections/profile/FundOverview";
import DepositHistory from "../sections/profile/DepositHistory";
import { MotionViewport } from "../components/animate";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../utils/auth";
import { _getFunding } from "../store/actions/userActions";
import { toast } from "react-toastify";
import CopyContent from "../components/CopyContent";

// ----------------------------------------------------------------------

export default function Funding() {
  const dispatch = useDispatch()
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const theme = useTheme()
  const [triggerLoading, setTriggerLoading] = useState(0);

  const handleSuccess = (msg) => {
    setTimeout(() => {
      toast.success(msg)
    }, 200);
    setTriggerLoading(triggerLoading + 1)
  }

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(_getFunding())
    }
  }, [])


  return (
    <MotionViewport>
      <Page title={langLibrary.key_192}>
        <Stack width={"100%"} >
          <Typography sx={{ flexGrow: 1, fontSize: '2rem', color: "#fff" }}>
            {langLibrary.key_192}
          </Typography>
          <Stack direction={"row"} width='fit-content' mb={4}>
            <CopyContent
              text={langLibrary.key_580}
              sxText={{ color: "#fff", fontSize: "1.5rem" }}
              notSlice={true}
              sizeIconCopy={"1.5rem"}
              iconColor={"#fff"}
            />
          </Stack>
          <FundOverview
            onSuccess={(msg) => handleSuccess(msg)}
          />
          <Stack mt={4}>
            <DepositHistory triggerLoading={triggerLoading} />
          </Stack>
        </Stack>
      </Page>
    </MotionViewport>
  );
}
