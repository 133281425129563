import { useParams } from "react-router-dom";
// @mui
import { Card, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
import Page from "../components/Page";
// sections
import { useEffect } from "react";
import RegisterTool from "../sections/auth/register/RegisterTool";
import { LOCAL_KEY_REFERRAL_ID } from "../settings/constants";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterToolPage() {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;

  const { referralId } = useParams();

  useEffect(() => {
    window.localStorage.setItem(
      LOCAL_KEY_REFERRAL_ID,
      JSON.stringify({ referralId })
    );
  }, [referralId]);

  return (
    <Page title={langLibrary.key_40}>
      <RootStyle>
        <Container>
          <ContentStyle>
            <RegisterTool />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
