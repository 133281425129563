import { Stack } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { MotionViewport } from "../components/animate";
import Page from "../components/Page";
import Setting from "../layouts/profile/Setting";
import BasicInfo from "../sections/profile/BasicInfo";

// ----------------------------------------------------------------------

const RootStyle = styled(Stack)(({ theme }) => ({}));

// ----------------------------------------------------------------------

export default function Account() {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app ? app : {};
  const theme = useTheme()
  return (
    <Page title={langLibrary.key_211} sx={{ position: "relative" }}>
      <MotionViewport>
        <RootStyle spacing={5} mt={1}>
          <BasicInfo />
          <Setting />
        </RootStyle>
      </MotionViewport>
    </Page>
  );
}
