import { Card, Collapse, Container, Grid, IconButton, Stack, Typography, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { alpha, Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Lottie from 'react-lottie';
import * as Introduce4 from '../../lotties/chart.json'
import * as Introduce3 from '../../lotties/pc.json'
import * as Introduce2 from '../../lotties/coin.json'
import Image from '../../components/Image';
import TrackVisibility from "react-on-screen";
import Title from './Title';

// ----------------------------------------------------------------------

export default function Introduce({ isVisible, ...other }) {
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const [renderCount, setRenderCount] = useState(0)
    useEffect(() => {
        if (isVisible) setRenderCount(renderCount + 1)
    }, [isVisible])

    return (<ContainerStyle {...other}>
        <Container sx={{ position: 'relative', zIndex: 1 }}>
            <Stack alignItems='center'>
                <TrackVisibility offset={100} partialVisibility>
                    {
                        ({ isVisible }) => <Title isVisible={isVisible} title={langLibrary.key_576} />
                    }
                </TrackVisibility>
            </Stack>
            <Stack direction={{ sm: "", md: 'row' }} spacing={10} mt={5} mb={'10vw'} alignItems="center" justifyContent={'space-between'}>
                <Typography sx={{ minWidth: '70%', textAlign: 'justify', paddingRight: { sm: "", md: 10 } }}>{langLibrary.key_577}</Typography>
                <ItemImage data={Introduce4} title={langLibrary.key_573} renderCount={renderCount} />
            </Stack>
        </Container>
    </ContainerStyle>
    );
}

const ItemImage = ({ renderCount }) => {
    const animation = `
      @keyframes animation1 {
        0%   { transform: scale(5) rotate(60deg) translateX(-50%) translateY(20%) ;opacity:0}
        100% {transform: scale(1) rotate(0deg);opacity:1}
      } 
      @keyframes animation2 {
        0%   { transform: scale(5) rotate(60deg) translateX(50%) translateY(-20%) ;opacity:0}
        100% {transform: scale(1) rotate(0deg);opacity:1}
      }`;

    return <>
        <style children={animation} />
        {
            renderCount > 0 && <BoxItem >
                <Box sx={{ animation: 'jum 2s ease-in-out infinite' }}>
                    <img src='/images/Group27.png' style={{ width: '70%', margin: '3rem auto', position: 'relative', zIndex: 10, opacity: 0 }} />
                    <img src='/images/Vector1.png' style={{ animation: 'animation1 1.5s ease-in-out ', width: '51.5%', position: 'relative', zIndex: 10, position: 'absolute', top: '22%', left: '15%' }} />
                    <img src='/images/Vector2.png' style={{ animation: 'animation2 1.5s ease-in-out ', width: '51.5%', position: 'relative', zIndex: 5, position: 'absolute', top: '14.2%', right: '15.7%' }} />
                </Box>
                <BoxLight />
            </BoxItem>
        }
    </>
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    position: 'relative',
    minHeight: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5rem !important',
    paddingBottom: '5rem !important',
    scrollMargin: 0,
    width: '100vw',
    [theme.breakpoints.down('md')]: {
        '& h3': {
            fontSize: '1.2rem'
        }
    }
}));

const BoxItem = styled(Stack)(({ theme }) => ({
    position: "relative",
    width: 'fit-content',
    minWidth: "30%",
}));
const BoxLight = styled(Stack)(({ theme }) => ({
    position: "absolute",
    width: '100%',
    height: '100%',
    borderBottom: "1px solid rgba(255,255,255,0.5)",
    overflow: "hidden",
    "&:before": {
        content: '""',
        width: '100px',
        height: "100px",
        boxShadow: '0px 0px 100px #43E9F5 , 0px 0px 100px #43E9F5, 0px 0px 50px #43E9F5',
        position: "absolute",
        transition: "all 0.2s ease-in-out",
        borderRadius: '100%',
        left: "calc(50% - 50px)",
        bottom: "-100px"
    },
    "&:hover": {
        "&:before": {
            transform: "scale(1)"
        }
    }
}));