import { LoadingButton } from "@mui/lab";
import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../components/Popup";
import { LOCAL_KEY_REFERRAL_ID } from "../../settings/constants";
import { put } from "../../utils/api";
import { getLocalStorage } from "../../utils/utils";
import { _getNewProfile } from "../../store/actions/userActions";
import { logout, setAccessToken } from "../../utils/auth";
import { useLocation } from "react-router-dom";

export default function UpdateReferral() {
  const { user, app } = useSelector((state) => state);
  const { profile } = user ? user : {};
  const { langLibrary } = app ? app : {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation()
  const dispatch = useDispatch();
  const { referralId } = getLocalStorage(LOCAL_KEY_REFERRAL_ID);
  useEffect(() => {
    if (referralId) {
      formik.setValues({
        referralId: referralId
      })
    }
  }, [location])

  const ReferralSchema = Yup.object().shape({
    referralId: Yup.string().required(langLibrary.key_180),
  });

  const formik = useFormik({
    initialValues: {
      referralId: referralId ? referralId : "",
    },
    validationSchema: ReferralSchema,
    onSubmit: () => {
      setIsSubmitting(true);
      handleUpdate();
    },
  });

  const handleUpdate = () => {
    put(
      `/user/invitation-code?invitationCode=${formik.values.referralId}`,
      null,
      (result) => {
        setIsSubmitting(false);
        dispatch(_getNewProfile());
        window.localStorage.removeItem("referralId")
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  if (!profile) return null;

  const haveReferral = profile.referralId;
  if (haveReferral) return null;

  const { errors, touched, handleSubmit, getFieldProps } = formik;


  return (
    <>
      <Button sx={{ background: "#fff !important" }} onClick={() => setOpen(true)}>Update</Button>
      <Popup open={open} onClose={() => setOpen(false)}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  {langLibrary.key_181}
                </Typography>
              </Box>
              <Typography
                sx={{ mb: 5, mt: "0px !important" }}
              >
                {langLibrary.key_182}
              </Typography>
              <TextField
                fullWidth
                autoComplete="referralId"
                type="email"
                label={langLibrary.key_70}
                {...getFieldProps("referralId")}
                error={Boolean(touched.referralId && errors.referralId)}
                helperText={touched.referralId && errors.referralId}
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {langLibrary.key_88}
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Popup>
    </>
  );
}
