import languages from "../../languages";
import {
  FETCH_CONFIG,
  FETCH_CONTRACT,
  SWITCH_LANGUAGE,
  TRIGGER_DRAWER,
  TRIGGER_DICTIONARY,
  TRIGGER_POLICY,
  TRIGGER_TELEGRAM_QR_CODE,
  TRIGGER_PARTNER_FORM
} from "../constants";

const initialState = {
  config: {},
  contract: [],
  activeDrawer: false,
  langCode: "en",
  langLibrary: languages["en"],
  triggerDictionary: false,
  triggerPolicy: false,
  triggerTelegramQRCode: false,
  triggerPartnerForm: false,
};

export const AppReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CONFIG:
      return {
        ...state,
        config: payload,
      };
    case FETCH_CONTRACT:
      return {
        ...state,
        contract: payload,
      };
    case TRIGGER_DRAWER:
      return {
        ...state,
        activeDrawer: payload,
      };
    case SWITCH_LANGUAGE:
      return { ...state, langCode: payload, langLibrary: languages[payload] };
    case TRIGGER_DICTIONARY:
      return {
        ...state,
        triggerDictionary: payload,
      };
    case TRIGGER_POLICY:
      return {
        ...state,
        triggerPolicy: payload,
      };
    case TRIGGER_PARTNER_FORM:
      return {
        ...state,
        triggerPartnerForm: payload,
      };
    case TRIGGER_TELEGRAM_QR_CODE:
      return {
        ...state,
        triggerTelegramQRCode: payload,
      };
    default:
      return { ...state };
  }
};
