import { Card, Container, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/robot.json'
import Image from '../../components/Image';
import { alpha, Box, useTheme } from '@mui/system';

// ----------------------------------------------------------------------

export default function Banner({ ...other }) {
    const { app } = useSelector(state => state)
    const theme = useTheme()
    const { langLibrary, langCode } = app ? app : {}
    return (<ContainerStyle {...other} direction='row'>
        <Grid container alignItems={'center'} mt={4} >
            <Grid item md={7} pl={{ xs: 2, md: 6 }}>
                <Stack sx={{ minWidth: '40%' }}>
                    <Typography variant='h4'>
                        <span style={{ color: langCode === 'ko' ? "inherit" : theme.palette.text.default, marginRight: '7px' }}>{langCode === 'ko' ? langLibrary.key_585 : langLibrary.key_584}</span>
                        <span style={{ color: langCode === 'ko' ? theme.palette.text.default : 'inherit', marginRight: '7px' }}>{langCode === 'ko' ? langLibrary.key_584 : langLibrary.key_585}</span>
                    </Typography>
                    <Typography variant='body3' mt={2}>{langLibrary.key_586}.</Typography>
                </Stack>
            </Grid>
            <Grid item md={5} sm={12}>
                <ImageContent>
                    <Image src="/images/Group433.png" />
                </ImageContent>
            </Grid>
        </Grid>
    </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    alignItems: 'center',
    paddingTop: "50px",
    paddingBottom: '50px',
    minHeight: '90vh',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        minHeight: '30vh',
    }
}));
const ImageContent = styled(Stack)(({ theme }) => ({
    padding: '3rem',
    position: "relative",
    height: 'fit-content',
    zIndex: 10,
    "&:after": {
        content: '""',
        width: "10%",
        height: '10%',
        position: 'absolute',
        top: "45%",
        left: "45%",
        boxShadow: `0px 0px 50px ${theme.palette.text.default}`
    }
}));