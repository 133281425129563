import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  InputAdornment,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// component

// icon
import Iconify from "../../components/Iconify";
//
import { useState } from "react";
import { toast } from "react-toastify";
import { put } from "../../utils/api";

import $ from "jquery";
import CustomDrawer from "../../components/CustomDrawer";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------
const PasswordContentStyle = styled(Card)(({ theme }) => ({
  // background: "#fff",
  minHeight: 200,
  borderRadius: '15px',
  boxShadow: '0 1rem 1rem -0.75rem rgb(0 0 0 / 5%)',
}));
// ----------------------------------------------------------------------

export default function PasswordSetting() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;

  const handleUpdate = () => {
    setIsSubmitting(true);
    const params = {
      oldPassword: formik.values.oldPassword,
      password: formik.values.newPassword,
    };
    put(
      "/user/password",
      params,
      () => {
        toast.success(langLibrary.key_83);
        setIsSubmitting(false);
        handleClose();
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
    const param = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
    setTimeout(() => {
      formik.setValues(param);
    }, 200);
    setTimeout(() => {
      formik.setErrors(param);
    }, 400);
  };

  const ReferralSchema = Yup.object().shape({
    oldPassword: Yup.string().required(langLibrary.key_129),
    newPassword: Yup.string().required(langLibrary.key_130),
    confirmPassword: Yup.string()
      .required(langLibrary.key_58)
      .oneOf([Yup.ref("newPassword"), null], langLibrary.key_59),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: ReferralSchema,
    onSubmit: () => {
      handleUpdate();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <PasswordContentStyle>
        <CardHeader
          sx={{ padding: "1.5rem 1.5rem 0.5rem 1.5rem" }}
          title={langLibrary.key_52}
          subheader={<Typography>{langLibrary.key_131}</Typography>}
        />
        <ListItemButton
          disableRipple
          sx={{
            padding: "1rem 1.5rem",
            ':hover': {
              '.MuiTypography-root': {
                color: "#fff"
              },
              "svg": {
                opacity: 1
              }
            }
          }}
          onClick={() => setOpen(true)}
        >
          <Stack
            direction={"row"}
            width="100%"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <CardHeader
              sx={{ padding: 0 }}
              title="**********"
              subheader={<Typography>{langLibrary.key_132}</Typography>}
            />
            <Iconify
              icon="carbon:chevron-right"
              sx={{ width: 25, height: 25, opacity: 0.5 }}
            />
          </Stack>
        </ListItemButton>
      </PasswordContentStyle>
      <CustomDrawer
        parent={$("#profile-layout")}
        anchor="right"
        paperStyle={{
          width: "100vw",
          maxWidth: "500px",
        }}
        open={open}
        onClose={() => handleClose()}
        title={<Typography variant="h5">{langLibrary.key_52}</Typography>}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2} padding={{ xs: 2, sm: 4 }}>
              <Typography variant="body2">{langLibrary.key_133}</Typography>
              <TextField
                fullWidth
                autoComplete="oldPassword"
                type={showPassword ? "text" : "password"}
                label={langLibrary.key_134}
                {...getFieldProps("oldPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                helperText={touched.oldPassword && errors.oldPassword}
              />
              <TextField
                fullWidth
                autoComplete="newPassword"
                type={showPassword ? "text" : "password"}
                label={langLibrary.key_76}
                {...getFieldProps("newPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.newPassword && errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
              <Typography padding={"0px 0.7rem"} variant="body2">
                {langLibrary.key_135}
              </Typography>
              <Typography
                padding={"0px 0.7rem"}
                variant="body2"
                sx={{ opacity: 0.6, marginTop: "0px !important" }}
              >
                {langLibrary.key_136}
              </Typography>
              <TextField
                fullWidth
                autoComplete="confirmPassword"
                type={showPassword ? "text" : "password"}
                label={langLibrary.key_137}
                {...getFieldProps("confirmPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {langLibrary.key_88}
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </CustomDrawer>
    </>
  );
}
