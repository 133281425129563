import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
// icons
import chevronRight from "@iconify/icons-carbon/chevron-right";
import chevronDown from "@iconify/icons-carbon/chevron-down";
// @mui
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  Divider,
  Stack,
  useTheme,
  Typography,
  IconButton,
} from "@mui/material";
// components
import Iconify from "../components/Iconify";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { logout } from "../utils/auth";
import { useResponsive } from "../hooks";
import { SocialMedia } from "./SocialMedia";

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}));

const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "activeRoot" && prop !== "activeSub",
})(({ activeRoot, activeSub, theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: "1.3rem",
  paddingRight: theme.spacing(2.5),
  ...(activeRoot && {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": {
      top: 0,
      left: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: "block",
      position: "absolute",
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(activeSub && {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  }),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2.5),
    borderRadius: '0px',
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "1.5rem",
  "& svg": {
    width: 22,
    height: 22,
  },
});

const ListSubItemIconStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active, theme }) => ({
  width: 4,
  height: 4,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.text.disabled,
  transition: theme.transitions.create("transform"),
  ...(active && {
    transform: "scale(2)",
    backgroundColor: theme.palette.primary.main,
  }),
}));

// ----------------------------------------------------------------------

NavSection.propTypes = {
  navConfig: PropTypes.array.isRequired,
};


export default function NavSection({ navConfig, onClickItem, isShowSocial, ...other }) {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const theme = useTheme();
  return (
    <Box {...other} sx={{ height: "100%" }}>
      {navConfig.map((list, index) => (
        <Stack sx={{ height: "100%" }}>
          <Stack key={index} sx={{ px: 0, py: "0rem" }}>
            {
              list.subheader && <ListSubheaderStyle>{list.subheader}</ListSubheaderStyle>
            }
            {list.items.map((item) => (
              <NavSectionItem
                key={item.title}
                item={item}
                onClickItem={onClickItem}
                langLibrary={langLibrary}
              />
            ))}
            <Divider sx={{ margin: "1rem 0px" }} />
            <ListItemStyle onClick={() => logout()}>
              <ListItemIconStyle>
                <Iconify icon={"clarity:logout-line"} />
              </ListItemIconStyle>
              <ListItemText disableTypography primary={langLibrary.key_191} />
            </ListItemStyle>


          </Stack>
          {
            isShowSocial && <Stack flexGrow={1} justifyContent={"flex-end"} spacing={2} padding={"1.3rem"}>
              <Typography variant="body2">
                {langLibrary.key_568}
              </Typography>
              <SocialMedia sxIcon={{ width: 22, height: 22 }} />
            </Stack>
          }
        </Stack>
      ))}
    </Box>
  );
}

// ----------------------------------------------------------------------

NavSectionItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

function NavSectionItem({ item, onClickItem, langLibrary }) {

  const location = useLocation()
  const sectionNameArr = location.pathname.split('/')
  const sectionName = sectionNameArr[2]
  const { title, path, icon, info, children, onClick, divider, value } = item;
  const isActiveRoot = sectionName === value;
  // const isActiveRoot = false;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  if (children) {
    return (
      <>
        <Divider />
        <ListItemStyle onClick={handleOpen} activeRoot={isActiveRoot}>
          {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
          <ListItemText disableTypography primary={langLibrary[title]} />
          {info && info}
          <Iconify
            icon={open ? chevronDown : chevronRight}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div">
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = false;
              return (
                <ListItemStyle key={path} activeSub={isActiveSub}>
                  <ListItemIconStyle>
                    <ListSubItemIconStyle
                      component="span"
                      active={isActiveSub}
                    />
                  </ListItemIconStyle>
                  <ListItemText
                    disableTypography
                    primary={langLibrary[title]}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <>
      {divider && (
        <>
          <Divider sx={{ marginTop: "1rem" }} />
          <Box sx={{ height: "15px" }} />
        </>
      )}
      <ListItemStyle
        onClick={onClickItem}
        key={path}
        component={RouterLink}
        to={path}
        activeRoot={isActiveRoot}
      >
        {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
        <ListItemText disableTypography primary={langLibrary[title]} />
        {info && info}
      </ListItemStyle>
    </>
  );
}
