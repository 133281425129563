// ----------------------------------------------------------------------

export default function Grid(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiGrid: {
      styleOverrides: {
        root: {
          transition: "all 0.2s ease-in-out"
        },
        item: {
          transition: "all 0.2s ease-in-out"
        }
      },
    },
  };
}
