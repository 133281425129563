import PropTypes from 'prop-types';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  stroke: PropTypes.any
};

export default function Iconify({ icon, sx, stroke, ...other }) {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} stroke={stroke} />;
}
