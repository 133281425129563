import { useParams } from "react-router-dom";
// @mui
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
// hooks
// components
import Page from "../components/Page";
// sections
import { useEffect } from "react";
import { ResetForm } from "../sections/auth/resetPassword";
import { LOCAL_KEY_REFERRAL_ID } from "../settings/constants";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: theme.spacing(15, 2.5),
  [theme.breakpoints.up("sm")]: {
    height: "100vh",
  },
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  return (
    <Page title={langLibrary.key_41}>
      <RootStyle>
        <Box sx={{ maxWidth: 480 }}>
          <ResetForm />
        </Box>
      </RootStyle>
    </Page>
  );
}
