import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../components/Popup';
import { _triggerDictionary } from '../store/actions/appActions';
import { useResponsive } from '../hooks';


// ----------------------------------------------------------------------


export default function UbotDictionary() {
    const dispatch = useDispatch()
    const { app } = useSelector(state => state)
    const { triggerDictionary, langLibrary } = app ? app : {}
    const isMobile = useResponsive("down", "sm")
    const handleClose = () => {
        dispatch(_triggerDictionary(false))
    }

    const data = [
        {
            title: langLibrary.key_572,
            description: langLibrary.key_619
        }, {
            title: langLibrary.key_620,
            description: langLibrary.key_621
        }, {
            title: langLibrary.key_622,
            description: langLibrary.key_623
        }, {
            title: langLibrary.key_624,
            description: langLibrary.key_625
        }, {
            title: langLibrary.key_524,
            description: langLibrary.key_626
        }, {
            title: langLibrary.key_627,
            description: langLibrary.key_628
        }, {
            title: langLibrary.key_629,
            description: langLibrary.key_630
        }, {
            title: langLibrary.key_94,
            description: langLibrary.key_631
        }, {
            title: langLibrary.key_536,
            description: langLibrary.key_635
        }, {
            title: langLibrary.key_204,
            description: langLibrary.key_636
        }, {
            title: langLibrary.key_637,
            description: langLibrary.key_637
        }, {
            title: langLibrary.key_638,
            description: langLibrary.key_638
        }, {
            title: langLibrary.key_237,
            description: langLibrary.key_541
        }, {
            title: langLibrary.key_154,
            description: langLibrary.key_542
        }, {
            title: langLibrary.key_639,
            description: langLibrary.key_640
        }, {
            title: langLibrary.key_547,
            description: langLibrary.key_548
        }, {
            title: langLibrary.key_551,
            description: langLibrary.key_552
        }
    ]

    return (
        <Popup open={Boolean(triggerDictionary)} onClose={handleClose}>
            <Stack divider={<Divider />} px={4}>
                {
                    data.map((item, index) => <KeyItem key={index} title={item.title} description={item.description} isMobile={isMobile} />)
                }
            </Stack>
        </Popup>
    );
}

export const KeyItem = ({ title, description, isMobile }) => {
    return <Stack spacing={3} py={1} direction='row' alignItems='center' >
        <Typography variant={'body3'} sx={{ fontFamily: "conthrax", minWidth: isMobile ? 100 : 200, maxWidth: isMobile ? 100 : 200 }}>{title}</Typography>
        <Typography variant={'body3'} fontFamily="Exo,sans-serif" textAlign={'justify'}>{description}</Typography>
    </Stack>
}