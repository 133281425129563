import { useTheme } from "@mui/material/styles";
import {
  Hidden,
  MenuItem,
  Stack,
  Typography,
  Popover,
  Collapse,
  ClickAwayListener,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IconButtonAnimate } from "../../components/animate";
import Iconify from "../../components/Iconify";
import { useState } from "react";
import CustomDrawer from "../../components/CustomDrawer";
import { useNavigate } from "react-router-dom";
import { TermAndConditions } from "../../sections/profile/TermAndConditions";
import Image from "../../components/Image";
import { Link as RouterLink } from "react-router-dom";
import {
  _triggerDictionary,
  _triggerPartnerForm,
  _triggerPolicy,
  _triggerTelegramQRCode,
} from "../../store/actions/appActions";

// ----------------------------------------------------------------------

export default function MainMenu({ lightMode }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state);
  const [open, setOpen] = useState(null);
  const [openTerm, setOpenTerm] = useState(false);

  const { langLibrary } = app;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const menus = [
    {
      title: langLibrary.key_582,
      items: [
        {
          title: langLibrary.key_499,
          url: "/profile/packages",
        },
        {
          title: langLibrary.key_500,
          url: "/",
          isComingSoon: true,
        },
        {
          title: langLibrary.key_601,
          url: "/",
          isComingSoon: true,
        },
        {
          title: langLibrary.key_602,
          url: "/",
          isComingSoon: true,
        },
        {
          title: langLibrary.key_603,
          url: "/",
          isComingSoon: true,
        },
      ],
    },
    {
      title: langLibrary.key_581,
      items: [
        {
          title: langLibrary.key_392,
          url: "/about-us",
        },
        {
          title: langLibrary.key_604,
          url: "/",
        },
        {
          title: langLibrary.key_605,
          items: [
            {
              title: langLibrary.key_659,
              url: "https://drive.google.com/file/d/1Qtc2XC_XGlhR_X-x69b6kX9kduTfTQ3w/view",
              outsite: true,
            },
            {
              title: langLibrary.key_660,
              url: "https://drive.google.com/file/d/1pPdIxv0kesbYxuXy-GyjtQ3O9OC2pIEj/view",
              outsite: true,
            },
            {
              title: langLibrary.key_661,
              url: "https://drive.google.com/file/d/1fhU_KD32o2O-T6njfo0a7DLB-oayNaRO/view",
              outsite: true,
            },
            {
              title: langLibrary.key_662,
              url: "https://drive.google.com/file/d/1492AWUlnP0Trfb8lblOojodyoKwTDopD/view",
              outsite: true,
            },
          ],
        },
        {
          title: langLibrary.key_606,
          url: "/",
        },
        {
          title: langLibrary.key_607,
          url: "/",
          onClick: () => {
            setOpen(null);
            dispatch(_triggerPartnerForm(true));
          },
        },
        {
          title: langLibrary.key_608,
          url: "/faq",
        },
      ],
      // caption1: langLibrary.key_612,
      items1: [
        {
          title: langLibrary.key_612,
          url: "",
        },
        {
          title: langLibrary.key_609,
          url: "/profile/wallet",
        },
        {
          title: langLibrary.key_610,
          url: "/",
          isComingSoon: true,
        },
        {
          title: langLibrary.key_611,
          url: "/",
          isComingSoon: true,
        },
      ],
    },
    {
      title: langLibrary.key_469,
      items: [
        {
          url: "https://portal.fpmarkets.com/int-EN/register?fpm-affiliate-utm-source=IB&fpm-affiliate-agt=21234",
          outsite: true,
          icon: "/images/logo_BW.png",
        },
        {
          url: "https://clients.fxprimus.com/en/register?ref=50013221",
          outsite: true,
          icon: "/images/logo_FX.png",
        },
      ],
    },
    {
      title: langLibrary.key_282,
      ulr: "https://clients.ubot.finance",
      onClick: () => {
        window.open("https://clients.ubot.finance", "_blank");
      },
    },
    {
      title: langLibrary.key_702,
      ulr: "https://clients.ubot.finance",
      onClick: () => {
        window.open("https://ubot.gitbook.io/ubot-whitepaper/ ", "_blank");
      },
    },
    {
      title: langLibrary.key_208,
      items: [
        {
          title: langLibrary.key_617,
          url: "/",
          onClick: () => {
            setOpen(null);
            dispatch(_triggerPolicy(true));
          },
        },
        {
          title: langLibrary.key_568,
          url: "/",
        },
        {
          title: langLibrary.key_618,
          items: [
            {
              title: "Kakao",
              iconAttach: "/images/social/ch.svg",
              url: "https://pf.kakao.com/_ExcLkxj", //kakao
              outsite: true,
            },
            {
              title: "Telegram",
              iconAttach: "/images/social/telegram.svg",
              onClick: () => {
                setOpen(null);
                dispatch(_triggerTelegramQRCode(true));
              },
              url: "/",
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Hidden lgUp>
        <IconButtonAnimate color="inherit" onClick={handleOpen}>
          <Iconify
            icon={"iconoir:menu"}
            sx={{
              width: 30,
              height: 30,
              fontWeight: "bold",
              color: lightMode ? theme.palette.text.secondary : "#fff",
            }}
          />
        </IconButtonAnimate>
      </Hidden>
      <Hidden lgDown>
        <Stack direction="row" spacing={1} ml="50px">
          <MenuList menus={menus} lightMode={lightMode} />
        </Stack>
      </Hidden>
      <CustomDrawer open={open} onClose={handleClose}>
        <MenuList horizontal menus={menus} lightMode />
      </CustomDrawer>
      <TermAndConditions open={openTerm} onClose={() => setOpenTerm(false)} />
    </>
  );
}

const MenuList = ({ menus, horizontal }) => {
  return (
    <>
      {
        <Stack
          direction={horizontal === true ? "column" : "row"}
          alignItems={horizontal ? "" : "center"}
          justifyContent={horizontal ? "" : "center"}
        >
          {menus.map((item, index) => (
            <Menu item={item} key={index} />
          ))}
        </Stack>
      }
    </>
  );
};

const Menu = ({ item }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(null);

  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClick = (menu, e) => {
    if (item.isComingSoon) return;
    if (menu.onClick) {
      menu.onClick();
      return;
    }
    if (menu && menu.url) {
      if (menu.outsite) {
        window.open(menu.url);
      } else navigate(menu.url);
      return;
    }
    handleOpen(e);
  };
  return (
    <>
      <MenuItem
        onClick={(e) => handleClick(item, e)}
        sx={{
          color: "#fff",
          background: "none !important",
          opacity: item.isComingSoon ? 0.6 : 1,
        }}
      >
        {item.icon ? (
          <img src={item.icon} style={{ height: 30 }} />
        ) : (
          <>
            <Typography
              sx={{
                color: "#fff",
                position: "relative",
                fontFamily: "conthrax",
                "&:hover": {
                  color: theme.palette.text.default,
                  "&:before": { opacity: 1 },
                },
                "&:before": {
                  content: '""',
                  width: "100%",
                  height: "2px",
                  background: theme.palette.text.default,
                  position: "absolute",
                  bottom: "-10px",
                  left: 0,
                  opacity: 0,
                },
              }}
            >
              {item.iconAttach ? (
                <Stack direction={"row"} alignItems={"center"}>
                  <img
                    alt=""
                    src={item.iconAttach}
                    style={{ height: 25, width: 25, marginRight: 10 }}
                  />
                  {item.title}
                </Stack>
              ) : (
                <>{item.title}</>
              )}
            </Typography>
            {item.items && (
              <Hidden lgDown>
                <Iconify
                  icon="material-symbols:keyboard-arrow-down-rounded"
                  sx={{ width: 25, height: 25, marginLeft: 1, opacity: 0.7 }}
                />
              </Hidden>
            )}
          </>
        )}
      </MenuItem>
      {item.items && (
        <>
          <Hidden lgDown>
            <Popover
              open={Boolean(open)}
              onClose={handleClose}
              anchorEl={open}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              PaperProps={{
                sx: {
                  boxShadow: "0px 0px 40px #000",
                  minWidth: 200,
                  width: "fit-content",
                  px: 2,
                  borderTop: `5px solid ${theme.palette.text.default}`,
                  borderRadius: "0px 0px 10px 10px",
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Stack direction={"row"} spacing={10} pt={1}>
                  <Stack>
                    {item?.caption && (
                      <Typography
                        mt={1.5}
                        mb={2}
                        fontWeight={"bold"}
                        sx={{
                          fontFamily: "conthrax",
                          color: theme.palette.text.default,
                        }}
                      >
                        {item?.caption}
                      </Typography>
                    )}
                    <MenuList horizontal={true} menus={item.items} />
                  </Stack>
                  {item.items1 && (
                    <Stack>
                      {item?.caption1 && (
                        <Typography
                          mt={1.5}
                          fontWeight={"bold"}
                          sx={{
                            fontFamily: "conthrax",
                            color: theme.palette.text.default,
                          }}
                        >
                          {item?.caption1}
                        </Typography>
                      )}
                      <MenuList horizontal menus={item.items1} />
                    </Stack>
                  )}
                </Stack>
              </ClickAwayListener>
            </Popover>
          </Hidden>
          <Hidden lgUp>
            <CustomDrawer
              open={Boolean(open)}
              onClose={handleClose}
              zIndex={100000000}
            >
              <Stack direction={"row"} spacing={10} pt={1}>
                <Stack>
                  {item?.caption && (
                    <Typography
                      mt={1.5}
                      mb={2}
                      fontWeight={"bold"}
                      sx={{
                        fontFamily: "conthrax",
                        color: theme.palette.text.default,
                      }}
                    >
                      {item?.caption}
                    </Typography>
                  )}
                  <MenuList horizontal={true} menus={item.items} />
                </Stack>
                {item.items1 && (
                  <Stack>
                    {item?.caption1 && (
                      <Typography
                        mt={1.5}
                        fontWeight={"bold"}
                        sx={{
                          fontFamily: "conthrax",
                          color: theme.palette.text.default,
                        }}
                      >
                        {item?.caption1}
                      </Typography>
                    )}
                    <MenuList horizontal menus={item.items1} />
                  </Stack>
                )}
              </Stack>
            </CustomDrawer>
          </Hidden>
        </>
      )}
    </>
  );
};
