import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Divider, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Icon
import CloseIcon from '@mui/icons-material/Close';
import Iconify from './Iconify';

// ----------------------------------------------------------------------



const Popup = forwardRef(({ children, open = false, title = null, contentStyle, actions, onClose, fullScreen, noBorderTop, ...other }, ref) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={Boolean(fullScreen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        {...other}
        sx={{ zIndex: 10000000, borderRadius: 0 }}
        PaperProps={{
          sx: { ...contentStyle, borderRadius: '0px 0px 15px 15px !important', borderTop: !noBorderTop ? theme => `5px solid ${theme.palette.text.default}` : `` }
        }}
      >
        {
          title && <DialogTitle component={'div'}>
            {title}
          </DialogTitle>
        }
        <DialogContent sx={{ paddingTop: "60px !important" }}>
          {children}
        </DialogContent>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.text.default,
              zIndex: 10000,
              background: theme => theme.palette.background.paper
            }}
          >
            <Iconify icon="line-md:close" />
          </IconButton>
        ) : null}
        {
          actions && <DialogActions>
            {actions}
          </DialogActions>
        }
      </Dialog>
    </>
  )
}
);

Popup.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.node
};

export default Popup;
