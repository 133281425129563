import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
    Link,
    Stack,
    Box,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    useTheme,
    Button,
    Avatar,
    MenuItem,
    Divider,
    FormControl,
    InputLabel,
    Select,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../components/Iconify";
import { CoinImage } from "../../settings/constants";
import { fCurrency } from "../../utils/format";
import Popup from "../../components/Popup";
import { post } from "../../utils/api";
import { toast } from "react-toastify";
import { _getFunding } from "../../store/actions/userActions";

// ----------------------------------------------------------------------

export default function PermanentForm({ products = [], onChange, balance, onSuccess, poolStaked, loading }) {
    const { app, user } = useSelector((state) => state);
    const dispatch = useDispatch()
    const { langLibrary } = app;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)
    const [product, setProduct] = useState(null)
    const theme = useTheme()

    const LoginSchema = Yup.object().shape({
        amount: Yup.number().required().max(balance ? balance.amount : 0, langLibrary.INSUFFICIENT_FUNDS),
    });

    const formik = useFormik({
        initialValues: {
            amount: ''
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            setShowConfirm(true);
        },
    });

    const handleStake = () => {
        setShowConfirm(false)
        setIsSubmitting(true)
        const param = {
            "productId": product.id,
            "amount": parseFloat(formik.values.amount)
        }
        post('/staking/stake', param, () => {
            setIsSubmitting(false)
            toast.success(langLibrary.key_726)
            onSuccess()
            dispatch(_getFunding())
        }, () => {
            toast.error(langLibrary.key_727)
            setIsSubmitting(false)
        })
    }

    const handleChange = (e) => {
        setProduct(e.target.value)
    };

    useEffect(() => {
        onChange({ ...product, amount: formik.values.amount, poolStaked: poolStaked })
    }, [formik.values.amount, product])

    const { errors, touched, handleSubmit, getFieldProps } = formik;
    const { amount } = formik.values
    const { apr = 0, expiredMonth = 0 } = product ? product : {}

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {
                            !loading && <Backdrop open={!Boolean(products)} sx={{ position: 'absolute', zIndex: 1000, background: theme.palette.background.paper }}>
                                <Typography>{langLibrary.key_502}</Typography>
                            </Backdrop>
                        }
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography>{langLibrary.key_144}: {fCurrency(balance?.amount, 2)} USDT</Typography>
                        </Stack>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder={langLibrary.key_100}
                            {...getFieldProps("amount")}
                            InputProps={{
                                sx: { fontSize: '1.2rem' },
                                startAdornment: (<InputAdornment position='start'>
                                    <Avatar src={CoinImage.USDT} sx={{ width: 40, height: 40 }} />
                                </InputAdornment>),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button variant="contained" onClick={() => formik.setValues({ amount: balance?.amount })}>
                                            {langLibrary.key_177}
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(touched.amount && errors.amount)}
                            helperText={touched.amount && errors.amount}
                        />
                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label">{langLibrary.key_102}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                            >
                                {products.map((option) => (
                                    <MenuItem key={option.id} onClick={() => setProduct(option)} value={option.id}>
                                        <Stack spacing={2} direction={'row'} alignItems='center' divider={<Divider orientation="vertical" sx={{ height: 15 }} />}>
                                            <Typography>{option.expiredMonth} {langLibrary.key_710}</Typography>
                                            <Typography sx={{ color: theme.palette.text.default }}>APR {fCurrency(option.apr, 2)}%</Typography>
                                        </Stack>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ my: 2 }}
                        >
                            <Typography>{langLibrary.key_728}: {fCurrency((amount ? amount : 0) * (apr / 100) / 12 * (expiredMonth), 2)} USDT</Typography>
                        </Stack>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            {langLibrary.key_42}
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
            <Confirming open={showConfirm} data={{ ...product, amount: formik.values.amount }} onSubmit={() => handleStake()} onClose={() => setShowConfirm(false)} />
        </>
    );
}

const Confirming = ({ open, onClose, data, onSubmit }) => {
    const { app } = useSelector((state) => state);
    const { langLibrary } = app;
    return <>
        <Popup open={open} title="Confirm" onClose={onClose}>
            {
                data && <Stack sx={{ width: '80vw', maxWidth: 500 }} spacing={1}>
                    <Stack width={'100%'} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <Typography maxWidth={'60%'}>{langLibrary.key_715}</Typography>
                        <Typography >{fCurrency(data.amount, 2)} USDT</Typography>
                    </Stack>
                    <Stack width={'100%'} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <Typography maxWidth={'60%'}>{langLibrary.key_729}</Typography>
                        <Typography >{fCurrency(data.apr, 2)}%</Typography>
                    </Stack>
                    <Stack width={'100%'} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <Typography maxWidth={'60%'}>{langLibrary.key_730}</Typography>
                        <Typography >{fCurrency(data.totalStaker, 2)}</Typography>
                    </Stack>
                    <Stack width={'100%'} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <Typography maxWidth={'60%'}>{langLibrary.key_731}</Typography>
                        <Typography >~ {fCurrency(1, 2)}</Typography>
                    </Stack>
                </Stack>
            }
            <Button onClick={onSubmit} variant="contained" fullWidth height={30} sx={{ fontSize: '1.3rem', mt: 3 }}>{langLibrary.key_57}</Button>
        </Popup>
    </>
}
