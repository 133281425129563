import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
//
import { varContainer, varFade } from '.';

// ----------------------------------------------------------------------

MotionViewport.propTypes = {
  children: PropTypes.node.isRequired,
  disableAnimatedMobile: PropTypes.bool,
};

export default function MotionViewport({ children, animate, disableAnimatedMobile = true, ...other }) {
  const isDesktop = useResponsive('up', 'sm');
  const effect = animate ? varFade()[animate] : varFade()['in']

  return (
    <Box
      component={motion.div}
      initial={effect.initial}
      whileInView={effect.animate}
      viewport={{ once: true, amount: 0.5 }}
      {...other}
    >
      {children}
    </Box>
  );
}
