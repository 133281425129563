import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Logo from '../components/Logo';
import { Stack, useTheme } from '@mui/system';
import { APP_BAR_DESKTOP } from '../layouts/app';
import AccountPopover from '../layouts/profile/AccountPopover';
import LanguagePopover from '../layouts/LanguagePopover';
import Banner from '../sections/about-us/Banner';
import Service from '../sections/about-us/Service';
import Commit from '../sections/about-us/Commit';
import Benefit from '../sections/about-us/Benefit';
import Market from '../sections/about-us/Market';
import Education from '../sections/about-us/Education';
import Difference from '../sections/about-us/Difference';
import MainMenu from '../layouts/header/MainMenu';
import { useSelector } from 'react-redux';
import { Collapse, Divider, MenuItem, Link, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Iconify from '../components/Iconify';
import Image from '../components/Image';
import { IconButtonAnimate } from '../components/animate';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};



export default function FAQ(props) {
  const theme = useTheme()
  const { app } = useSelector((state) => state);
  const [activeIndex, setActiveIndex] = React.useState(0)
  const { langLibrary } = app;

  const faqs = [
    {
      question: langLibrary.key_431,
      answers: [
        [
          { text: langLibrary.key_432 },
        ]
      ]
    },
    {
      question: langLibrary.key_433,
      answers: [
        [
          { text: langLibrary.key_434 },
        ],
        [
          { text: langLibrary.key_435 },
        ],
      ]
    },
    {
      question: langLibrary.key_436,
      answers: [
        [
          { text: langLibrary.key_437 },
        ],
        [
          { text: langLibrary.key_438 },
        ],
      ]
    },
    {
      question: langLibrary.key_439,
      answers: [
        [
          { text: langLibrary.key_440 },
        ]
      ]
    },
    {
      question: langLibrary.key_441,
      answers: [
        [
          { text: langLibrary.key_442 },
          { text: langLibrary.key_443, to: "#" }
        ]
      ]
    },
    {
      question: langLibrary.key_445,
      answers: [
        [
          { text: langLibrary.key_444 },
          { text: langLibrary.key_443, to: "#" }
        ]
      ]
    },
    {
      question: langLibrary.key_448,
      answers: [
        [
          { text: langLibrary.key_449 },
        ]
      ]
    },
    {
      question: langLibrary.key_452,
      answers: [
        [
          { text: langLibrary.key_453 }
        ],
        [
          { text: langLibrary.key_454, isSub: true }
        ],
        [
          { text: langLibrary.key_455, isSub: true }
        ]
      ]
    },
    {
      question: langLibrary.key_456,
      answers: [
        [
          { text: langLibrary.key_457 },
        ]
      ]
    },
    {
      question: langLibrary.key_465,
      answers: [
        [
          { text: langLibrary.key_466 },
        ]
      ]
    },
    {
      question: langLibrary.key_467,
      answers: [
        [
          { text: langLibrary.key_468 },
        ]
      ]
    }
  ]

  const glossaries = [
    {
      key: langLibrary.key_518,
      desc: langLibrary.key_519
    },
    {
      key: langLibrary.key_520,
      desc: langLibrary.key_521
    },
    {
      key: langLibrary.key_522,
      desc: langLibrary.key_523
    },
    {
      key: langLibrary.key_524,
      desc: langLibrary.key_525
    },
    {
      key: langLibrary.key_526,
      desc: langLibrary.key_527
    },
    {
      key: langLibrary.key_528,
      desc: langLibrary.key_529
    },
    {
      key: langLibrary.key_94,
      desc: langLibrary.key_530
    },
    {
      key: langLibrary.key_531,
      desc: langLibrary.key_532
    },
    {
      key: langLibrary.key_533,
      desc: langLibrary.key_534
    },
    {
      key: langLibrary.key_315,
      desc: langLibrary.key_535
    },
    {
      key: langLibrary.key_536,
      desc: langLibrary.key_537
    },
    {
      key: langLibrary.key_204,
      desc: langLibrary.key_538
    },
    {
      key: langLibrary.key_284,
      desc: langLibrary.key_539
    },
    {
      key: langLibrary.key_285,
      desc: langLibrary.key_540
    },
    {
      key: langLibrary.key_237,
      desc: langLibrary.key_541
    },
    {
      key: langLibrary.key_154,
      desc: langLibrary.key_542
    },
    {
      key: langLibrary.key_291,
      desc: langLibrary.key_543
    },
    {
      key: langLibrary.key_292,
      desc: langLibrary.key_544
    },
    {
      key: langLibrary.key_293,
      desc: langLibrary.key_545
    },
    {
      key: langLibrary.key_205,
      desc: langLibrary.key_546
    },
    {
      key: langLibrary.key_547,
      desc: langLibrary.key_548
    },
    {
      key: langLibrary.key_549,
      desc: langLibrary.key_550
    },
    {
      key: langLibrary.key_551,
      desc: langLibrary.key_552
    }
  ]

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar sx={{ background: 'none', zIndex: 1000, background: theme.palette.background.default, boxShadow: theme.customShadows.z8, padding: 1 }}>
          <Stack px={{ sm: 0, lg: 6 }} py={1} direction={'row'} alignItems="center" justifyContent={'space-between'}>
            <Logo onDark={false} />
            <Stack direction={'row'} alignItems='center'>
              <MainMenu />
              <AccountPopover />
              <LanguagePopover />
            </Stack>
          </Stack>
        </AppBar>
      </HideOnScroll>
      <Container>
        <Stack pt={15} divider={<Divider />} pb={10}>
          {
            faqs.map((item, index) => <FAQItem key={index} data={item} active={activeIndex === index} onActive={() => setActiveIndex(index)} />)
          }
        </Stack>
        {/* <Typography variant='h4'>{langLibrary.key_516}</Typography>
        <Table sx={{ mb: 2, mt: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>{langLibrary.key_516}</TableCell>
              <TableCell>{langLibrary.key_517}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              glossaries.map((item, index) => <TableRow key={index}>
                <TableCell><b>{item.key}</b></TableCell>
                <TableCell sx={{ whiteSpace: "pre-wrap" }}>{item.desc}</TableCell>
              </TableRow>)
            }
          </TableBody>
        </Table> */}
      </Container>
    </React.Fragment>
  );
}

const FAQItem = ({ data, active, onActive }) => {
  const theme = useTheme()
  const { question, answers } = data ? data : {}
  return <>
    {
      question && <MenuItem sx={{ minHeight: 60 }} onClick={() => onActive()}>
        <Stack direction={'row'} width="100%" alignItems='center' justifyContent='space-between'>
          <Typography sx={{ whiteSpace: 'pre-wrap' }} fontWeight="bold">{question}</Typography>
          <IconButtonAnimate>
            <Iconify sx={{ opacity: 0.6 }} icon={active ? "material-symbols:keyboard-arrow-up-rounded" : "material-symbols:keyboard-arrow-down-rounded"} />
          </IconButtonAnimate>
        </Stack>
      </MenuItem>
    }
    {
      answers && <Collapse in={active}>
        <Stack pl={2} pb={2}>
          {
            answers.map((answer, index) => <Stack>
              <Typography key={index} sx={{ opacity: 0.7 }}>
                {
                  answer.map((e, j) => e.image ? <Image sx={{ borderRadius: '1rem', mt: 2 }} src={e.image} /> : <Typography component={e.to ? Link : ''} variant='body' sx={{ color: e.to ? theme.palette.background.primary : '', fontStyle: e.to ? 'italic' : 'normal' }} ml={e.to ? 0.5 : 0} key={j}>{e.text}</Typography>)
                }
              </Typography>
            </Stack>)
          }
        </Stack>
      </Collapse>
    }
  </>
}