import PropTypes from "prop-types";
import { memo } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Stack } from "@mui/system";

// ----------------------------------------------------------------------

Logo.propTypes = {
  isSimple: PropTypes.bool,
  onDark: PropTypes.bool,
  sx: PropTypes.object,
};

function Logo({ onDark = false, isSimple = false, sx }) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  const PRIMARY_MAIN = theme.palette.primary.main;
  const LIGHT_COLOR = theme.palette.common.white;
  const DARK_COLOR = theme.palette.grey[800];

  return (
    <Stack>
      <RouterLink to="/" style={{ display: 'flex', alignItems: 'center' }}>
        {
          isSimple ? <img src={onDark ? "/images/logo-mini-white.png" : "/images/logo-mini.png"} height={40} alt="logo" />
            : <img src={onDark ? "/images/logo_white.png" : "/images/logo.png"} height={40} alt="logo" />
        }
        <br />

      </RouterLink>
      <Typography sx={{ fontSize: '0.8rem' }}>AI Trading Platform</Typography>
    </Stack>
  );
}

export default memo(Logo);
