// material
import { Grid, Stack, CardHeader, Divider, Hidden } from "@mui/material";
// components
import Page from "../components/Page";
import GaSetting from "../sections/profile/GaSetting";
import PasswordSetting from "../sections/profile/PasswordSetting";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

export default function AccountSetting() {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  return (
    <Page title={langLibrary.key_190}>
      <Grid container justifyContent="center">
        <Grid item xs={11} md={11} lg={10}>
          <Stack>
            <Stack
              mb={"1.5rem"}
              direction={"row"}
              justifyContent="space-between"
              alignItems={"flex-end"}
            >
              <CardHeader
                sx={{ padding: 0 }}
                title={langLibrary.key_8}
                subheader={langLibrary.key_9}
              />
              <Hidden smDown>
                <img
                  alt=""
                  src="https://www.gstatic.com/identity/boq/accountsettingsmobile/profile_scene_preferences_720x256_b83e731b89910d1e55bbe298246b0a12.png"
                  width={250}
                />
              </Hidden>
            </Stack>
            <Divider style={{ borderStyle: "dashed", marginBottom: "1rem" }} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <PasswordSetting />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <GaSetting />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Page>
  );
}
