import { Avatar, Card, Collapse, Container, Dialog, DialogContent, DialogTitle, Button, Hidden, IconButton, Stack, Typography, Box } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FabButtonAnimate, IconButtonAnimate } from '../../components/animate';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import Popup from '../../components/Popup';
import { useResponsive } from '../../hooks';
import { isLoggedIn } from '../../utils/auth';
import { Packages } from '../profile/Packages';

// ----------------------------------------------------------------------

export default function Products({ ...other }) {
    const theme = useTheme()
    const navigate = useNavigate()
    const [showPackages, setShowPackages] = useState(false)
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}

    const isMobile = useResponsive('down', 'sm')

    const handleClick = () => {
        if (isLoggedIn()) {
            navigate('/profile/packages')
        } else {
            setShowPackages(true)
        }
    }

    return (<>
        <ContainerStyle {...other} >
            <Container >
                <AnimationContent id='scene' direction="row" alignItems='center' justifyContent='center' sx={{ transform: isMobile ? 'scale(1.5)' : '' }}>
                    <RootNote alignItems='center' justifyContent='center' p={2}>
                        <Image src="/images/Group27.png" sx={{ width: '40%', mb: { xs: 0, md: 3 } }} />
                        <Typography sx={{ color: "#fff", fontSize: '2vw' }}>{langLibrary.key_254}</Typography>
                    </RootNote>
                    <NoteItem
                        size={30}
                        noteSize={'5vw'}
                        border={`1px solid ${alpha('#43E9F7', 0.8)}`}
                        initRotate={40}
                        speed={20}
                        onClick={() => handleClick()}
                    >
                        <Typography fontWeight={'bold'} sx={{ color: "#000", fontSize: '1.1vw !important' }}>{langLibrary.key_499}</Typography>
                    </NoteItem>
                    <NoteItem
                        size={30}
                        noteSize={'5vw'}
                        initRotate={-180}
                        speed={20}
                    >
                        <Typography fontWeight={'bold'} sx={{ color: "#000", fontSize: '1vw !important' }}>{langLibrary.key_500}</Typography>
                    </NoteItem>
                    <NoteItem
                        size={40}
                        noteSize={'2vw'}
                        initRotate={-20}
                        speed={15}
                    />
                    <NoteItem
                        size={40}
                        noteSize={'6vw'}
                        border={`1px solid ${alpha('#43E9F7', 0.6)}`}
                        initRotate={-80}
                        speed={30}
                    >
                        <Typography fontWeight={'bold'} sx={{ color: "#000", fontSize: '1.5vw !important' }}>{langLibrary.key_501}</Typography>
                    </NoteItem>
                    <NoteItem
                        size={50}
                        noteSize={'1vw'}
                        border={`1px solid ${alpha('#43E9F7', 0.4)}`}
                        initRotate={110}
                        speed={25}
                    />
                    <NoteItem
                        size={50}
                        noteSize={'3vw'}
                        initRotate={210}
                        speed={15}
                    />
                    <NoteItem
                        size={60}
                        noteSize={'3vw'}
                        border={`1px solid ${alpha('#43E9F7', 0.2)}`}
                        initRotate={0}
                        speed={25}
                    />
                </AnimationContent>
            </Container>
        </ContainerStyle>
        <Dialog open={showPackages} sx={{ zIndex: 1000 }} PaperProps={{ sx: { margin: '0px !important' } }}>
            <DialogTitle sx={{ pb: 2 }}>
                <Stack direction={'row'} justifyContent='space-between'>
                    <Typography variant='h4'>{langLibrary.key_193}</Typography>
                    <IconButtonAnimate onClick={() => setShowPackages(false)}>
                        <Iconify icon='ion:close-outline' />
                    </IconButtonAnimate>
                </Stack>
            </DialogTitle>
            <DialogContent >
                <Stack sx={{ width: '100vw', maxWidth: '400px' }}>
                    <Typography>{langLibrary.key_554}</Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    </>
    );
}

// ----------------------------------------------------------------------

const NoteItem = ({ size, border, children, noteSize, initRotate, speed, onClick }) => {
    const [active, setActive] = useState(false)
    const animation = `@keyframes rotate-${initRotate}deg {
      0%   { transform: rotate(${initRotate}deg); }
      100% { transform: rotate(${initRotate + 360}deg); }
    }
    @keyframes rotate-${180 - initRotate}deg {
        0%   { transform: rotate(${-initRotate}deg); }
        100% { transform: rotate(${-initRotate - 360}deg); }
      }
    `;
    return <>
        <style children={animation} />
        <Box sx={{ width: `${size}vw`, height: `${size}vw`, borderRadius: '50%', border: border, position: "absolute", zIndex: 0 }} />
        <Stack data-depth-x={0.25} sx={{ width: `fit-content`, height: `${size}vw`, borderRadius: '50%', position: "absolute", zIndex: 100 - size, }}>
            <Stack sx={{
                width: "100%", height: "100%",
                animation: `rotate-${initRotate}deg ${speed}s linear infinite`,
                animationPlayState: active ? 'paused' : '',
            }}>
                <Stack
                    component={'button'}
                    onMouseMove={() => setActive(true)}
                    onMouseLeave={() => setActive(false)}
                    onClick={onClick}
                    sx={{
                        width: noteSize, height: noteSize,
                        background: "#43E9F7", borderRadius: "50%",
                        boxShadow: '0px 0px 20px rgba(0,0,0,0.5)',
                        margin: `calc(-${noteSize} / 2) auto`,
                        animation: `rotate-${180 - initRotate}deg ${speed}s linear infinite`,
                        cursor: 'pointer',
                        transition: 'all 0.2s ease-in-out',
                        border: 'none',
                        animationPlayState: active ? 'paused' : '',
                        '&:hover': {
                            boxShadow: "0px 0px 20px #43E9F7",
                        }
                    }}
                    direction='row'
                    alignItems='center'
                    justifyContent='center'
                >
                    {children}
                </Stack>
            </Stack>
        </Stack>
    </>
}
const ContainerStyle = styled(Stack)(({ theme }) => ({
    position: 'relative',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100vw',
    [theme.breakpoints.down('md')]: {
        '& h3': {
            fontSize: '1.2rem'
        }
    }
}));
const AnimationContent = styled(Stack)(({ theme }) => ({
    width: "90vw",
    maxWidth: 900,
    height: "90vw",
    maxHeight: 900,
    borderRadius: '50%',
    margin: "auto"

}));
const RootNote = styled(Stack)(({ theme }) => ({
    width: "18vw",
    height: "18vw",
    borderRadius: '50%',
    border: "1px solid #43E9F7",
    position: 'relative'
}));