const vi = {
  key_1: "Chọn Coin",
  key_2: "Chúng tôi hiện đang nỗ lực xây dựng trang này",
  key_3: "Đã được sao chép",
  key_4: "Không tìm thấy",
  key_5: "Hãy thử kiểm tra lỗi chính tả hoặc sử dụng các từ hoàn chỉnh.",
  key_6: "Đăng ký",
  key_7: "Đăng nhập",
  key_8: "Mật khẩu và tùy chọn cho các dịch vụ của Google",
  key_9: "Các cách xác minh danh tính của bạn và cài đặt cho web",
  key_10: "Thiết lập tài khoản",
  key_11: "Tiếng Anh",
  key_12: "Tiếng Hàn",
  key_13: "Tiếng Nhật",
  key_14: "Lợi nhuận",
  key_15: "Từ",
  key_16: "Đến",
  key_17: "Vui lòng staking để nhận hoa hồng.",
  key_18:
    "Your affiliate pool is limited, to continue receive commissions you must staking more.",
  key_19: "Mời bạn bè của bạn",
  key_20: "Lên đến",
  key_21: "Yêu cầu",
  key_22: "Hoa hồng",
  key_23: "Direct Commission",
  key_24: "Ranking Commission",
  key_25: "Ranking Bonus",
  key_26: "Matching Commission",
  key_27: "Tổng doanh thu",
  key_28: "Team",
  key_29: "Quỹ",
  key_30: "Nạp và rút tiền",
  key_31: "Hoán đổi",
  key_32: "Đăng nhập",
  key_33: "Bạn chưa có tài khoản?",
  key_34: "Đăng ký",
  key_35: "Lỗi 404 Không tìm được trang",
  key_36: "Xin lỗi, không tìm thấy trang!",
  key_37:
    "Xin lỗi, chúng tôi không thể tìm thấy trang bạn đang tìm kiếm. Có lẽ bạn đã nhập sai URL? Hãy lại kiểm tra chính tả.",
  key_38: "Di chuyển về trang chủ",
  key_39: "Khuyến mãi",
  key_40: "Đăng ký",
  key_41: "Đặt lại mật khẩu",
  key_42: "Staking",
  key_43: "Stakings",
  key_44: "Lợi nhuận",
  key_45: "Thành viên",
  key_46: "Email phải là một địa chỉ email hợp lệ",
  key_47: "Email là mục bắt buộc",
  key_48: "Mật khẩu là mục bắt buộc",
  key_49: "Mã Google Authenticator là mục bắt buộc",
  key_50: "Mã Google Authenticator có 6 chữ số",
  key_51: "Địa chỉ Email",
  key_52: "Mật khẩu ",
  key_53: "Bạn quên mật khẩu?",
  key_54: "Vui lòng nhập mã Google Authenticator.",
  key_55: "Mã Google Authenticator",
  key_56: "Google Authenticator - 6 chữ số",
  key_57: "Xác nhận",
  key_58: "Xác nhận mật khẩu là mục bắt buộc",
  key_59: "Mật khẩu phải trùng khớp",
  key_60: "Mã email là mục bắt buộc",
  key_61: "Bạn đã có tài khoản?",
  key_62: "Xác nhận mật khẩu là mục bắt buộc",
  key_63: "Kiểm tra hộp thư để nhận  mã OTP 6 chữ số",
  key_64: "Mã OTP",
  key_65: "Đăng ký thành công",
  key_66: "Sẽ tự động chuyển hướng bạn đến trang đăng nhập sau",
  key_67: "Mua NFT thành công!",
  key_68: "OTP là mục bắt buộc",
  key_69: "Mã giới thiệu là mục bắt buộc",
  key_70: "Mã giới thiệu",
  key_71: "Bạn quên mật khẩu?",
  key_72:
    "Vui lòng nhập địa chỉ email được liên kết với tài khoản của bạn.Chúng tôi sẽ gửi cho bạn một liên kết để đặt lại mật khẩu của bạn.",
  key_73: "Gửi yêu cầu",
  key_74: "Đặt mật khẩu mới.",
  key_75: "OTP đã được gửi đến email của bạn. Vui lòng kiểm tra hộp thư.",
  key_76: "Mật khẩu mới",
  key_77: "Đặt lại thành công!",
  key_78: "Hoặc",
  key_79: "Staked",
  key_80: "Nhà đầu tư F1",
  key_81: "Tổng doanh thu",
  key_82: "Tên hiển thị là mục bắt buộc",
  key_83: "Cập nhật thành công!",
  key_84: "Nhập tên của bạn",
  key_85: "Tên hiển thị",
  key_86: "Xin chào",
  key_87: "Hủy",
  key_88: "Cập nhật ",
  key_89: "Staking lợi nhuận",
  key_90: "Matching",
  key_91: "Direct",
  key_92: "Ranking",
  key_93: "Ngày",
  key_94: "Nạp tiền",
  key_95: "Địa chỉ",
  key_96: "Địa chỉ và ghi chú là mục bắt buộc khi nạp tiền",
  key_97: "Thành công",
  key_98: "OK",
  key_99: "Mã băm giao dịch",
  key_100: "Số tiền",
  key_101: "Phí",
  key_102: "Thời gian",
  key_103: "Trạng thái",
  key_104: "Loại",
  key_105: "Cập nhật tên của bạn",
  key_106: "Tên này sẽ xuất hiện trên Hồ sơ và Bảng xếp hạng.",
  key_107: "Thời gian tạo",
  key_108: "Cấp",
  key_109: "Thứ hạng",
  key_110: "Thời gian tham gia",
  key_111: "Thành viên",
  key_112: "Ví nóng",
  key_113: "Google Authenticator (GA)",
  key_114: "Bảo vệ tài khoản của bạn bằng xác minh 2 bước",
  key_115: "Trình xác thực Google",
  key_116: "Tiếp theo",
  key_117: "Sao lưu khóa này",
  key_118: "2. Tải [Authenticator App] từ Google hoặc AppStore",
  key_119: "3. Chọn [Set up account] trên App",
  key_120: "4. Chọn [Scan a barcode]",
  key_121: "Scan mã trên",
  key_122: "Tắt",
  key_123: "Bật",
  key_124: "Vui lòng nhập mã",
  key_125: "Mã Google authenticator ",
  key_126: "Thiết lập Trình xác thực",
  key_127: "ID",
  key_128: "Email",
  key_129: "Mật khẩu cũ là mục bắt buộc",
  key_130: "Mật khẩu mới là mục bắt buộc",
  key_131: "Mật khẩu an toàn giúp bảo vệ tài khoản của bạn",
  key_132: "Thay đổi mật khẩu ",
  key_133: "Chọn một mật khẩu mạnh và không sử dụng lại cho các tài khoản khác",
  key_134: "Mật khẩu cũ ",
  key_135: "Mật khẩu mạnh",
  key_136:
    "Sử dụng ít nhất 8 ký tự. Không sử dụng mật khẩu từ một trang web khác hoặc thứ gì đó quá rõ ràng như tên thú cưng của bạn",
  key_137: "Xác nhận mật khẩu",
  key_138: "Số tiền là mục bắt buộc",
  key_139: "Thêm thành công!",
  key_140: "Thông tin Staking",
  key_141: "Quy trình",
  key_142: "Ngày",
  key_143: "Tái đầu tư",
  key_144: "Khả dụng",
  key_145: "Tất cả",
  key_146: "Thêm ",
  key_147: "Thêm staking",
  key_148: "Bạn có chắc chắn muốn thêm",
  key_149: "Số tiền phải nhiều hơn",
  key_150: "Staking thành công",
  key_151: "Tổi thiểu",
  key_152: "Stake",
  key_153: "Ước tính",
  key_154: "Thời gian",
  key_155: "Tiền lãi",
  key_156: "Mỗi tháng",
  key_157: "Ngày hết hạn",
  key_158: "Đồng ý thông qua việc xác nhận staking",
  key_159: "Điều khoản và Điều kiện",
  key_160: "Rút vốn trong 30 ngày đầu, phí là 10%",
  key_161: "Sau 30 ngày, phí là 5%.",
  key_162: "Trong 180 ngày sẽ khấu trừ thu nhập và hoa hồng đã nhận trước đó.",
  key_163: "Người dùng đã từng có cấp QUẢN LÝ trở lên sẽ không thể rút vốn",
  key_164: "Sụp đổ",
  key_165: "Thanh toán",
  key_166: "Un-stake",
  key_167: "Khóa affiliate",
  key_168: "Ngày",
  key_169: "Un-stake thành công",
  key_170: "Khấu trừ",
  key_171: "Nhận",
  key_172: "Hoàn vốn của bạn sẽ khấu trừ",
  key_173: "theo Điều khoản và điều kiện.",
  key_174: "Khóa thành công",
  key_175: "Hoán đổi thành công",
  key_176: "Tỷ lệ",
  key_177: "Tối đa",
  key_178: "Tối thiểu",
  key_179: "Bạn có chắc muốn hoán đổi?",
  key_180: "ID giới thiệu là mục bắt buộc",
  key_181: "Mã giới thiệu",
  key_182: "Vui lòng cung cấp mời cho lần đăng nhập đầu tiên.",
  key_183: "Thay đổi tài khoản",
  key_184: "Rút tiền",
  key_185: "Rút tiền tạm thời bị vô hiệu hóa",
  key_186: "Vui lòng kiểm tra thông tin!",
  key_187: "Đến địa chỉ",
  key_188: "Chọn tùy chọn",
  key_189: "Bảng điều khiển",
  key_190: "Cài đặt",
  key_191: "Đăng xuất",
  key_192: "Ví ",
  key_193: "Gói",
  key_194: "Gói của tôi",
  key_195: "Trang chủ",
  key_196: "Vui lòng chờ",
  key_197: "Bot này đang được bắt đầu.",
  key_198: "Bot này đang bị dừng.",
  key_199: "Bot này đang được thay đổi tùy chọn.",
  key_200: "Yêu cầu của bạn đã bị từ chối",
  key_201: "Ghi chú",
  key_202: "Lưu tùy chọn",
  key_203: "Nhắc nhở:",
  key_204: "Cặp tiền tệ",
  key_205: "Trailing Stop",
  key_206: "Nạp tiền tạm thời bị vô hiệu hóa",
  key_207: "Bạn có chắc chắn muốn rút tiền mà không cần ghi chú",
  key_208: "Hỗ trợ",
  key_209: "Rút tiền thành công...!",
  key_210: "Mật khẩu phải là một chuỗi có độ dài tối thiểu là 8 chữ số",
  key_211: "Tài khoản",
  key_212:
    "Yêu cầu của bạn đã được gửi thành công. Chúng tôi đã gửi cho bạn một email với các chi tiết của yêu cầu này.",
  key_213: "Quay lại Hỗ trợ",
  key_214: "Nếu đang gặp vấn đề, bạn có thể gửi chúng để chúng tôi giải quyết",
  key_215: "Xin lỗi, trường hợp này chưa được hỗ trợ.",
  key_216: "Vui lòng nhập email hợp lệ.",
  key_217: "Vui lòng nhập email của người nhận.",
  key_218: "Vui lòng nhập số tiền.",
  key_219: "Vui lòng nhập Tx Hash.",
  key_220: "Người nhận (email)",
  key_221: "Gửi",
  key_222: "Vui lòng nhập email.",
  key_223: "Vui lòng chọn hình ảnh.",
  key_224: "Vui lòng chọn loại hoa hồng",
  key_225: "Vui lòng chọn ngày.",
  key_226: "Email nhà tài trợ",
  key_227: "Email F1",
  key_228: "Loại hoa hồng",
  key_229: "Thiếu ngày",
  key_230: "DIRECT",
  key_231: "RANKING",
  key_232: "MATCHING",
  key_233: "Xác minh GA",
  key_234: "Tx Hash không hợp lệ",
  key_235: "Tải App",
  key_236: "Thưởng",
  key_237: "Enter Market",
  key_238: "Khiếu nại",
  key_239: "Bạn có chắc sẽ khiếu nại?",
  key_240: "Khiếu nại thành công!",
  key_241: "Ngày tháng/ Trạng thái",
  key_242: "Tất cả khiếu nại",
  key_243: "Tự động",
  key_244: "Ubot",
  key_245:
    "Hệ thống giao dịch Forex dựa trên AI, giúp bạn kiếm lợi nhuận khoảng 5-15% mỗi tháng.",
  key_246: "Giải pháp đầu tư hoàn hảo",
  key_247:
    "Là sản phẩm giao dịch Forex dựa trên công nghệ AI đã được vận hành 3 năm. Ubot hoạt động tự động theo bộ lệnh Fibonacci và kết hợp tính ưu việt của AI là sử dụng trí tuệ nhân tạo với nhiều thuật toán phân tích và cập nhật liên tục để phù hợp với tình hình thị trường. Tạo ra lợi nhuận trên 100%/năm (khoảng 5-15%/tháng). Ubot là con đường tắt nhanh nhất dẫn bạn đến với tự do tài chính",
  key_248:
    "CFD is a widely used financial trading area in more than 100 countries.",
  key_249:
    "CFD brokers offer excellent trading conditions designed to meet all realistic expectations from both professional and novice traders. From the achievements and experience of Ubot has created a safe trading network business direction that includes advanced trading technology and reputable partnerships, all of which make us a safe, transparent and trusted broker.",
  key_250:
    "Company Orientation: To become the world's leading Ubot in the field of CFD, Crypto, Usdt, Payment Coint...",
  key_251:
    "Ubot  aims at the profit of the investor partners as the main, transparent, clear, reputable, good liquidity, guaranteed investment cash flow. Currently, we are constantly developing in a field of CFD but also have new directions in the near future, bringing many elements of community and value.",
  key_252:
    "Through the EI BOT trading that we have been operating with our partners, Ubot also wants to bring safe trading experiences to investors on financial platforms. Profit and ensure the lowest risks that other companies cannot do, more proud of the sure profit from trading with EI BOT partners do not have to spend too much time with other companies. tools of tradition.",
  key_253: "Bot giao dịch",
  key_254: "Sản phẩm",
  key_255:
    "BOT TRADE's Trend Analysis Tools are an extremely effective solution and provide a sure return for investors. You can easily manage hundreds of different accounts, but the profit performance is the same depending on the capital you choose. Our BOT Trade often uses tools for trading analysis such as: Accelerator Oscillator, Bollinger Bands, Averge Directinal Index, Moving Avergae Channel, Stochastic RIS, Williams Alligator, ...",
  key_256: "Tại sao bạn nên chọn Ubot",
  key_257:
    "Các logic giao dịch được BOT áp dụng và học hỏi từ các chuyên gia lâu năm trong thị trường",
  key_258:
    "Simply set up your bot strategies with just a few clicks. Automate trades easily using indicators to catch or follow trends.",
  key_259:
    "Hệ thống quản lý vốn ưu việt. Mang đến thu nhập thụ động khoảng từ 5-15% hằng tháng cho các nhà đầu tư ",
  key_260:
    "Chúng tôi hỗ trợ khách hàng bằng hệ thống cảnh báo đơn hàng và cập nhật trạng thái tài khoản tự động 24/7",
  key_261: "Thiết bị đang hoạt động được tích hợp với đa phương tiện có sẵn.",
  key_262: "Chúng tôi đã và đang lựa chọn đầu tư vào tổ chức quỹ của mình.",
  key_263: "EI BOT",
  key_264: "Tự động hóa dễ dàng",
  key_265: "Thu nhập thụ động",
  key_266: "Hỗ trợ khách hàng",
  key_267: "Đa phương tiện",
  key_268: "Tổ chức",
  key_269: "Liên lạc",
  key_270: "Gói",
  key_271: "has active package >= 6 months",
  key_272: "from F1 to F5",
  key_273: "Purchased a package for at least...months",
  key_274: "Have",
  key_275: "level in difference branchs",
  key_276: "Tìm kiếm theo cấp độ",
  key_277: "Tìm kiếm theo thứ hạng",
  key_278: "ID giới thiệu",
  key_279: "Link giới thiệu",
  key_280: "Hoa hồng giao dịch",
  key_281: "Total share income",
  key_282: "Cài đặt bot",
  key_283: "Tùy chọn bot",
  key_284: "Max Buy Orders",
  key_285: "Max Sell Orders",
  key_286: "Type Of Top Or Bottom",
  key_287: "ID MT4 ",
  key_288: "Mật khẩu MT4 ",
  key_289: "Server sàn giao dịch",
  key_290: "First distance",
  key_291: "Next distance",
  key_292: "Profit perMonth",
  key_293: "Loss perMonth",
  key_294: "Phiếu yêu cầu",
  key_295: "Yêu cầu đang được thực hiện",
  key_296: "Yêu cầu",
  key_297: "Thay đổi tùy chọn",
  key_298: "Bật Bot",
  key_299: "Tắt Bot",
  key_300: "Bot ID",
  key_301: "Tiêu đề Bot",
  key_302:
    "Forex Bot Trade còn được gọi là Expert Advisor (EA) là một loại phần mềm",
  key_303:
    "Tên hiển thị có thể là tên hoặc biệt hiệu của bạn hoặc tên khác mà bạn muốn người khác biết mình trên Ubot.",
  key_304: "Kích thước trang",
  key_305: "Gia hạn thành công!",
  key_306: "Tên gói",
  key_307: "Thời hạn ",
  key_308: "Thời gian mua",
  key_309: "Gia hạn",
  key_310: "Chọn loại tiền",
  key_311: "Loại gói",
  key_312: "Giá gia hạn",
  key_313: "Thời hạn gói",
  key_314: "Tháng",
  key_315: "Tiêu đề",
  key_316: "Side",
  key_317: "Cài đặt ",
  key_318: "Bots",
  key_319: "Danh sách Bot",
  key_320: "Xác nhận gia hạn",
  key_321: "Mua hàng thành công!",
  key_322: "Bot",
  key_323: "Mua hàng ",
  key_324: "Chọn gói",
  key_325: "Chọn tiền tệ thanh toán",
  key_326: "Chọn số lượng",
  key_327: "Giá gói",
  key_328: "Số lượng",
  key_329: "Tổng tiền",
  key_330: "Xác nhận mua",
  key_331: "Thời hạn gói",
  UNKNOWN: "Lỗi không rõ.",
  OPERATION_FAIL: "Hoạt động thất bại",
  UNAUTHORIZED: "Không được phép.",
  INVALID_PARAMETERS: "Thông số không hợp lệ.",
  INVALID_OPERATION: "Hoạt động không hợp lệ.",
  PERMISSION_DENIED: "Quyền này bị từ chối.",
  SYSTEM_BUSY: "Hệ thống bận.",
  TOO_MANY_REQUESTS: "Quá nhiều yêu cầu.",
  RECORD_NOTFOUND: "Không tìm thấy bản ghi.",
  TOKEN_INVALID: "Token không hợp lệ",
  TOKEN_WRONG: "Token sai",
  TOKEN_EXPIRED: "Token đã hết hạn",
  OTP_INVALID: "OTP không hợp lệ",
  OTP_WRONG: "OTP sai",
  OTP_EXPIRED: "OTP đã hết hạn",
  PASSWORD_WRONG: "Mật khẩu hoặc email bị sai.",
  GACODE_REQUIRED: "Cần GA code",
  GACODE_WRONG: "GA code sai.",
  WRONG_CREDENTIALS: "Thông tin đăng nhập sai.",
  USER_INACTIVE: "Tài khoản của bạn không hoạt động.",
  INSUFFICIENT_FUNDS: "Không đủ tiền.",
  EMAIL_EXIST: "Email tồn tại.",
  SOLD_OUT: "Đã bán hết.",
  OWNER_ERROR: "Lỗi của chủ sở hữu.",
  FORBIDDEN: "Chặn truy cập",
  key_332: "Giao dịch thông minh",
  key_333: "Đối tác đáng tin cậy",
  key_334: "Miễn trừ trách nhiệm",
  key_335: "Bot của tôi",
  key_336: "Các tùy chọn đang cập nhật.",
  key_337: "Nhật ký yêu cầu",
  key_338: "Form",
  key_339: "Tìm kiếm theo email",
  key_340: "Xác nhận đăng ký",
  key_341: "Chọn tiền tệ ",
  key_342: "Đăng ký",
  key_343: "Đăng ký thành công",
  key_344:
    "Ensure stable internet connection for transactions; if trader loses internet, the trade order will not be sent.",
  key_345:
    "Consider risk management to prevent rare cases of risk such as power outage or mechanical problems. The sensitivity of the Ubot means that the bot may be affected by false information in the market.",
  key_346:
    "Do not follow advertisements or opinions from those outside of the project. Especially those who claim to be part of the Ubot project.",
  key_347:
    "Our profits depend on the BOT package you possess, so choose the right BOT package to ensure the desired profits.",
  key_348: "The average monthly return is estimated at 5-15%.",
  key_349: "Admin will never message you first.",
  key_350:
    "If there are any questions or need for support, immediately contact the Ubot support department, which works continuously from Monday to Friday, 23 hours a day.",
  key_351:
    "We are not responsible for any trade-related risks outside of business hours and activities.",
  key_352: "Absolutely do not follow unverified investment advice.",
  key_353: "Do not change the password without a proper reason.",
  key_354:
    "No responsibility will be taken for any damage caused by unauthorized access to the account.",
  key_355: "Please only deposit",
  key_356: "to this address",
  key_357: "Deposit has been disabled for",
  key_358: "on",
  key_359: "Address/Email",
  key_360: "Address or User Email is required",
  key_361:
    "1. Chúng tôi chỉ cung cấp công nghệ giao dịch.Ubot không phải là dịch vụ ủy thác đầu tư.",
  key_362:
    "2. Đảm bảo đường truyền internet ổn định khi giao dịch, nếu như trader mất internet thì lệnh giao dịch sẽ không được gửi.",
  key_363:
    "3. Cân nhắc kiểm soát giao dịch, để đề phòng các trường hợp rủi ro hiếm có như mất điện, máy móc gặp sự cố. Vì độ nhạy của Ubot nên đôi khi bot sẽ bị ảnh hưởng bởi thông tin sai lệch trên thị trường.",
  key_364:
    "4. Không nghe theo tin các quảng cáo, nhận định khác của người không thuộc trong dự án. Đặc biệt những admin tự nhận mình thuộc dự án Ubot.",
  key_365: "5. Lợi nhuận trung bình hàng tháng ước tính 5-15%.",
  key_366: "6. Admin không bao giờ nhắn tin trước với bạn",
  key_367:
    "7. Nếu có bất kỳ thắc mắc, cần liên hệ hỗ trợ thì phải lập tức gọi đến bộ phận hỗ trợ của Ubot, chúng tôi sẽ làm việc liên tục trong 23 giờ từ thứ 2-thứ 6",
  key_368:
    "8. Chúng tôi không chịu trách nhiệm bất cứ trường hợp rủi ro giao dịch nào ngoài giờ khung giờ làm việc, hoạt động.",
  key_369: "9. Tuyệt đối không nghe theo các lời khuyên đầu tư chưa kiểm chứng",
  key_370:
    "10. Không thay đổi mật khẩu MT4 sau khi đã cài đặt Bot, nếu mật khẩu bị thay đổi, Bot sẽ ngưng hoạt động",
  key_371: "11. Cần hoàn tất KYC trên sàn giao dịch trước khi kích hoạt Bot",
  key_372:
    "12. Chúng tôi sẽ không chịu trách nhiệm cho bất kỳ thiệt hại nào do truy cập trái phép vào tài khoản.",
  key_373: "Terms and conditions",
  key_374:
    "The BOT package brings high returns along with risk. Depending on market conditions, please note that users may have to deposit additional funds up to 100% of their initial investment amount, depending on the market situation.",
  key_375: "Invest in",
  key_376: "with",
  key_377: "Cặp tiền tệ",
  key_378: "Min invest",
  key_379: "Lợi nhuận tháng",
  key_380: "Bot",
  key_381: "Supporter",
  key_382: "Thị trường đầu tư",
  key_383: "Number of bot",
  key_384: "Number of supporter",
  key_385: "Tôi đã đọc và đồng ý với",
  key_386: "các điều khoản và điều kiện.",
  key_387: "ID MT4 ",
  key_388: "Mật khẩu MT4 ",
  key_389:
    "The Ubot is committed to providing the best possible trading service based on the established script. Users may set up the script for themselves, however, if they do not use our recommended script, the Ubot will not be responsible for any trading losses. We are also not responsible for any products or services of other companies. Please make sure you understand the risks and comply with all regulations and guidelines before using the Ubot.",
  key_390:
    "Không thay đổi mật khẩu MT4 sau khi đã cài đặt Bot, nếu mật khẩu bị thay đổi, Bot sẽ ngưng hoạt động",
  key_391: "Đến địa chỉ/Email",
  key_392: "Giới thiệu công ty",
  key_393: "Giao dịch thông minh tự động",
  key_394:
    "Since 2019, we have committed to empowering each and every financial journey, providing attractive trading conditions combined with an outstanding trading and investing ecosystem",
  key_395: "Lợi ích nổi bật mà chúng tôi cung cấp",
  key_396: "Kinh nghiệm",
  key_397:
    "Hoạt động và kiểm chứng sản phẩm trong suốt 3 năm với hơn 2000+ users",
  key_398: "Giao dịch tự động 23/5",
  key_399: "Không FOMO, không vấp phải lỗi từ cảm xúc người dùng",
  key_400: "EA BOT",
  key_401:
    "Các logic giao dịch được BOT áp dụng và học hỏi từ các chuyên gia lâu năm trong thị trường",
  key_402: "Quản lý vốn hiệu quả - Lợi nhuận ổn định",
  key_403:
    "Hệ thống quản lý vốn ưu việt. Mang đến thu nhập thụ động khoảng từ 5-15% hằng tháng cho các nhà đầu tư",
  key_404: "Hỗ trợ khách hàng",
  key_405:
    "Chúng tôi hỗ trợ khách hàng bằng hệ thống cảnh báo đơn hàng và cập nhật trạng thái tài khoản tự động 24/7",
  key_406: "Uy tín",
  key_407: "Được tin cậy bởi các đối tác uy tín trên toàn thế giới",
  key_408: "Ubot cam kết với bạn",
  key_409: "An toàn và Tin cậy",
  key_410:
    "Ubot, a company with a global presence, has been a trustworthy and reliable provider for our client. Focusing on AI-powered automated Forex trading products produced by BIGWAVE - a technology company with over 7 years of experience in the CFD market. With Ubot, clients have access to robust financial security arrangements and various customer care policies",
  key_411: "How Ubot makes a difference",
  key_412: "We Unite",
  key_413: "We unite trading, investing and AI Automatic Trading",
  key_414: "We Simplify",
  key_415:
    "We simplify how to manage your money and how you can learn more about trading & investing",
  key_416: "We Connect",
  key_417: "We connect the world with our global expertise, access and offer",
  key_418: "We support financial education",
  key_419:
    "We provide our clients with services beyond trading and investing.The financial world can be daunting but with the right educational support, our clients can make informed decisions. Whether you are a beginner in the world of trading and investing or an expert in the field who likes to be on top of the daily trends, Ubot provides knowledge to both via our carefully curated online courses, seminars, webinars, trading podcasts and educational articles",
  key_420:
    "We also keep our clients up to date with daily investment news and analysis to trade smartly. Learn from Ebooks, stay ahead with daily trading and investing news, video tutorials, FX courses and much more",
  key_421: "We provide market-leading solution",
  key_422: "The Market",
  key_423:
    "With the goal of becoming an indispensable partner for investors and a valuable tool to be in their arsenal, Ubot aims to be the leading company that applies Bot Trade to the fields of CFD, Crypto, USDT, Payment Coin, and more, providing safe investment solutions based on refined and effective analysis and logic, improved every day by AI artificial intelligence. Ubot operates 24/5 automatically generated by the power of AI, following Fibonacci commands, creating stable profits with an average of 5-15% per month",
  key_424: "Traders and investors choose Ubot to",
  key_425: "Learn",
  key_426:
    "Learn about trading CFDs and investing in the stock markets with free webinars and live trading sessions with experts, comprehensive Forex courses and much more",
  key_427: "Earn",
  key_428: "Earning stable profits with minimum Earn of 5 - 15%/month",
  key_429: "Invest",
  key_430:
    "If you prefer the classic way of investing and diversifying with CFDs, use modern technology and gain an edge while investing in thousands of instruments",
  key_431: "What are the operating hours for the Ubot?",
  key_432:
    "The Ubot operates continuously on 23/5, which means the BOT will run continuously for 23 hours from Monday to Friday.",
  key_433: "Does Ubot work on weekends?",
  key_434:
    "Due to the nature of the Ubot, which is similar to the forex market, it only operates continuously from Monday to Friday. On weekends, central banks are closed, so foreign exchange trading will not take place.",
  key_435:
    "However, don't worry too much because the time zones of Vietnam and other Countries are completely different, so trading seems to take place continuously. When one trading session ends, another will open, moreover, there may even be overlapping trading sessions that bring greater trading opportunities to traders.",
  key_436: "How does Ubot work?",
  key_437:
    "With the automatic mechanism named Ubot, you only need to deposit money and purchase the Bot package. You don't have to spend much time watching charts. The trading logics are applied and learned from our Bot based on the experiences from long-time experts in the market.",
  key_438:
    "Additionally, with its automated mechanism, setting up your bot strategies is just a few clicks away. You can choose your desired level of profit and acceptable level of loss for each trade. Ubot will fully handle monitoring the market, waiting for market events that might affect the price, then send signals or make decisions based on the rules you have set for buying or selling actions.",
  key_439: "How long will it take for me to receive money after withdrawing?",
  key_440:
    "You can withdraw your money at any time and completely free of charge. You will receive the money within 24-36 hours.",
  key_441: "How to deposit in Ubot?",
  key_442: "Learn how to make a deposit",
  key_443: "here",
  key_444: "Learn how to withdraw money",
  key_445: "How to withdraw money from Ubot?",
  key_446: "What is the minimum and maximum possible deposit to Ubot?",
  key_447:
    "For only $350 per month, you can fully join and experience the Ubot. We have no maximum limit for your deposit amount, you can choose the BOT package that suits your financial conditions.",
  key_448: "What is the estimated profit of Ubot?",
  key_449:
    "We do not guarantee the level of profit for you, as the market can sometimes be impacted slightly and prices fluctuate. However, the estimated profit that Ubot can ensure for you is 5-15% per month, although this profit will depend on which BOT package you own.",
  key_450: "How to renew Bot?",
  key_451:
    "We have extremely advantageous limits on renewing your BOT packages. For renewals with a term of less than 12 months, the BOT price will be calculated at the original value (no discount). For renewals with a term of 12 months or more, the BOT price will be calculated at 50% of the original value.",
  key_452: "Which currency pairs should I trade in Ubot?",
  key_453:
    "One of the most common questions is how many currency pairs there are in Forex. There are over 200 countries and territories in the world, so there are many different currencies. However, due to the size of the economy, trade, and other features, only a few major currencies and currency pairs are frequently traded in the Forex market. At Ubot, we prioritize customer benefits, which is why we choose the AUD-CAD trading pair, with features such as:",
  key_454: "Stable, not too volatile compared to the market",
  key_455:
    "A cross currency pair between two friendly countries, not involved in wars or arms races.",
  key_456: "Will I lose money when participating in Ubot?",
  key_457:
    "When participating in Ubot, you can rest assured that you will be informed of the risks and methods of generating profits from Ubot. Thus, almost over 1000 BOTs that have been used for many years have not lost money.",
  key_458: "What is the best time for me to trade?",
  key_459:
    "Usually on Monday the market will be quite quiet, the news is not as busy as other days.",
  key_460:
    "Contrary to the second day, on the third, fourth, and fifth days, the market will be lively and there will be strong fluctuations. Important news will mainly emerge during these 3 days. You can fully confidently participate in trading during these 3 days. In the morning of the sixth day, the market is still lively, however, in the afternoon, calmness will return.",
  key_461: "Can I join if I don't have any knowledge about investment?",
  key_462:
    "Our Ubot offers a beginner-friendly BOT package priced at $350 per month trial if you want to try it out. However, this BOT package only grants you with one BOT ownership.",
  key_463: "Which bot package is the most convenient for users?",
  key_464:
    "We recommend you to purchase the Pro-safe package, where you will be able to own 5 AI trading bots, have access to 3 financial trading advisors and enjoy returns of up to 5-15%.",
  key_465: "Does Ubot guarantee 5-10% monthly mining profit?",
  key_466:
    "In business or investment, there's no guarantee of profit, but we always strive for safety and stability. Over the years, the profit has consistently reached from 5-10% per month.",
  key_467: "How can I install the Bot into my MT4 account?",
  key_468:
    "Ubot will assist you with the installation and guide you to create a script for your account.",
  key_469: "Đối tác sàn giao dịch",
  key_470: "Tạo tài khoản",
  key_471: "Đăng ký gói",
  key_472: "Đăng ký Fxprimus",
  key_473: "Nạp tiền vào ví Fxprimus",
  key_474: "Cài đặt tùy chọn Bot",
  key_475: "Bật Bot",
  key_476: "Bật xác thực GA",
  key_477: "Tắt xác thực GA",
  key_478: "로 구독",
  key_479: "Không có hoa hồng giới thiệu",
  key_480: "최소",
  key_481: "개월 패키지 구매해야함",
  key_482: "Direct commission",
  key_483: "Normal shared revenue income",
  key_484: "Normal shared volume income",
  key_485: "Senior shared revenue income",
  key_486: "Senior shared volume income",
  key_487: "약",
  key_488: "Tôi đã đọc và đồng ý với các điều khoản và điều kiện",
  key_489: "에서 이 주소로만",
  key_490: "를 입금해주세요.",
  key_491: "Hoa hồng",
  key_492: "통화 쌍으로",
  key_493: "시장에 투자",
  key_494: "Up to MANAGER requirements",
  key_495: "Up to GOLD requirements",
  key_496: "Up to DIAMOND requirements",
  key_497: "Up to MASTER requirements",
  key_498: "Media",
  key_499: "Forex",
  key_500: "Crypto",
  key_501: "Stock",
  key_502: "Sắp ra mắt",
  key_503: "Đăng nhập để đăng ký",
  key_516: "Từ điển thuật ngữ",
  key_517: "Mô tả",
  key_518: "Ubot",
  key_519:
    "Ubot là một sản phẩm giao dịch Forex tự động với công nghệ AI, được sản xuất bởi một công ty công nghệ với hơn 7 năm kinh nghiệm trong thị trường CFD. Ubot hoạt động 23/5 tự động theo các bộ lệnh Fibonacci, tạo ra lợi nhuận ổn định với mức trung bình từ 5-15% mỗi tháng.",
  key_520: "FXPrimus",
  key_521:
    "Là sàn trung gian chuyên về thị trường ngoại hối, kim loại, năng lượng, chứng khoán, đã có mặt trên thế giới trong hơn 13 năm, bao gồm các nước như Anh, Úc, Singapore, Trung Quốc, Việt Nam, v.v...",
  key_522: "EA (Expert Advisor)",
  key_523:
    "Là 1 loại phần mềm đưa ra các tín hiệu giao dịch tài chính hoặc thực hiện các lệnh giao dịch (đóng lệnh, cắt lỗ, chốt lời…) theo 1 chương trình lập trình có sẵn dựa trên nền tảng giao dịch MT4 hoặc MT5",
  key_524: "MT4 (MetaTrader4)",
  key_525:
    "Là một nền tảng giao dịch trực tuyến phổ biến với giao diện đơn giản và trực quan. Với MT4 các nhà giao dịch có thể dễ dàng tạo ra chiến lược giao dịch tự động của riêng mình",
  key_526: "BOT Package",
  key_527: "Là chi phí mà người dùng cần trả để có thể sử dụng BOT.",
  key_528: "Khoản tiền đầu tư",
  key_529:
    "Là khoản tiền mà người dùng cần nạp vào để BOT có thể bắt đầu giao dịch",
  key_530:
    "Là khoản tiền mà người dùng cần nạp nếu muốn giữ vị thế khi thị trường có biến động lớn",
  key_531: "Estimated profit",
  key_532:
    "This is the estimated profit value to be earned within the period of using Ubot based on the pre-set trading scenarios. Depending on the trading scenario, the estimated profit may vary.",
  key_533: "Trading Scenario",
  key_534:
    "A scenario is a set of predetermined rules that a user can choose to apply to their BOT. The BOT will rely on this scenario to carry out buy and sell transactions. Depending on the scenario chosen by the user, the profit and risk levels may vary.",
  key_535:
    "The title of the Scenario varies depending on the user's preference.",
  key_536: "EA Type",
  key_537: "Loại hình mà BOT sẽ tiến hành giao dịch.",
  key_538: "Cặp tiền tệ mà người dùng lựa chọn để giao dịch",
  key_539: "Lệnh mua tối đa.",
  key_540: "Lệnh bán tối đa.",
  key_541:
    "Enter market là điểm vào lệnh. Người dùng có thể lựa chọn các công cụ trong mục này và thông qua tín hiệu của công cụ đã lựa chọn, BOT sẽ tiến hành vào lệnh. Các công cụ gồm có RSI, MACD, Stoch, công cụ đỉnh đáy.",
  key_542:
    "Period là khung giờ. Có các khung giờ để người dùng lựa chọn sao cho phù hợp với chiến lược giao dịch ( M5 - M15 - M30 - H1 - H4 ). ",
  key_543: "Ubot recommends users to set the Next distance at 20 pips.",
  key_544:
    "Expected monthly profit. In this section, users can choose the profit value that they expect to achieve on a monthly basis. When the expected profit is reached, the BOT will automatically stop trading. Ubot recommends that users set the Profit per month at around 3 times the minimum initial investment capital. For example, if the minimum initial investment capital is $2000, then Profit per month should be set at around $6000.",
  key_545:
    "This refers to the level of loss that users can tolerate each month. Users can choose the level of loss they can accept each month. When the acceptable loss level is reached, the BOT will automatically stop placing orders. Ubot recommends that users set the Loss per month at approximately three times the minimum initial investment capital. For example, if the minimum initial investment capital is $2,000, then the Loss per month should be set at around $6,000.",
  key_546:
    "Là lệnh Mua/bán với giá đặt Mua/Bán được tự động điều chỉnh để bám sát xu thế giảm/tăng của thị trường giúp nhà đầu tư đạt được mức giá tối ưu nhất trong khoảng kỳ vọng.",
  key_547: "Chớt lời",
  key_548:
    "Là lệnh chốt lãi, khi giao dịch đạt đến mức lãi thực tế mong muốn thì lệnh sẽ được đóng. Đặt lệnh bán (với vị thế đang là long) hoặc lệnh mua (với vị thế đang là short) khi đạt đến một mức lãi mong muốn",
  key_549: "Scenario code",
  key_550:
    "Scenario code. Each trading BOT scenario will be assigned a unique code.",
  key_551: "Pips",
  key_552:
    "Pips là đơn vị để đo lường sự biến động của công cụ tài chính (cặp tiền tệ). Hầu hết các cặp tiền đều có 4 số thập phân và pip tương đương với số thập phân thứ 4. Chẳng hạn, nếu giá thay đổi từ 14.0000 lên 14.0001 thì có nghĩa là giá tăng lên 1 Pip.",
  key_553: "Hướng dẫn sử dụng bot",
  key_554: "Vui lòng đăng nhập để xem các gói",
  key_555: "Ubot",
  key_556: "Hướng dẫn sử dụng bot",
  key_557: "Sponsor",
  key_558: "Thời hạn sử dụng",
  key_559: "Secret Formula",
  key_560: "Đấu nối Bot với MT4",
  key_561: "Chờ xác nhận",
  key_562: "Thay đổi thông tin",
  key_563: "Chọn Bot",
  key_564:
    "Vui lòng chọn số lượng ID Bot là 1, 5, 10,... Số lượng Bot được chọn là bội số của 5",
  key_565: "Áp dụng thành công",
  key_566: "Chi tiết gói",
  key_567: "Áp dụng",
  key_568: "Liên hệ với chúng tôi",
  key_569: "Tài khoản cá nhân",
  key_570: "Danh sách giới thiệu",
  key_571: "Nhập mã",
  key_572: "Ubot",
  key_573: "Vốn thấp, lợi nhuận cao",
  key_574: "Đầu tư chỉ cần smart-phone",
  key_575: "Tự do thời gian và tài chính",
  key_576: "Giới thiệu",
  key_577:
    "Là sản phẩm giao dịch Forex dựa trên công nghệ AI đã được vận hành 3 năm. Ubot hoạt động tự động theo bộ lệnh Fibonacci và kết hợp tính ưu việt của AI là sử dụng trí tuệ nhân tạo với nhiều thuật toán phân tích và cập nhật liên tục để phù hợp với tình hình thị trường. Tạo ra lợi nhuận trên 100%/năm (khoảng 5-15%/tháng). Ubot là con đường tắt nhanh nhất dẫn bạn đến với tự do tài chính",
  key_578: "Miễn trừ trách nhiệm",
  key_579: "Trung tâm trợ giúp",
  key_580: "support@ubot.finance",
  key_581: "Về chúng tôi",
  key_582: "Thị trường",
  key_583: "Đào tạo",
  key_584: "7 năm ",
  key_585: "tìm kiếm vị trí ổn định trong ngành tài chính",
  key_586:
    "Đội ngũ Người sáng lập đằng sau Ubot đã dành hơn 7 năm qua để tận tâm tìm kiếm một sản phẩm giao dịch an toàn và bảo mật cho các nhà đầu tư. Họ đã triển khai công nghệ AI tiên tiến và các thuật toán tinh vi liên tục phân tích và cập nhật xu hướng thị trường. Ngoài ra, họ đã tạo ra một hệ thống quản lý rủi ro nghiêm ngặt để giảm thiểu tổn thất và bảo vệ quỹ của nhà đầu tư. Ubot là một trái ngọt mà các nhà phát triển và nhiều nhà đầu tư đã tìm kiếm bấy lâu nay.",
  key_587: "Cơ hội",
  key_588:
    "Forex (ngoại hối) là một thị trường lớn nơi mà người ta trao đổi tiền tệ với nhiều mục đích khác nhau như du lịch, thương mại và đầu tư. Bao gồm việc mua một loại tiền tệ và bán loại tiền tệ khác, với mục đích kiếm lời từ sự biến động tỷ giá hối đoái. Với khối lượng giao dịch hàng ngày khoảng 6 nghìn tỷ USD, thị trường này lớn hơn nhiều so với thị trường tiền điện tử và NASDAQ kết hợp lại. Forex hoạt động liên tục 24/5, trừ ngày cuối tuần và ngày lễ. Kích thước và tính thanh khoản của Forex là một lựa chọn hấp dẫn cho các nhà giao dịch và nhà đầu tư tìm kiếm cơ hội để thu lợi từ biến động tỷ giá hối đoái.",
  key_589: "Kinh nghiệm",
  key_590:
    "Ubot với hơn 3 năm kiểm tra sản phẩm và hơn 2000 nhà đầu tư tin dùng, đã tích lũy được kinh nghiệm đáng kể trong thị trường tài chính. Thời gian hoạt động kéo dài  kết hợp với phản hồi từ người dùng đã cho phép đội ngũ Ubot tối ưu hóa sản phẩm của mình để đáp ứng các tiêu chuẩn khắt khe của ngành giao dịch.",
  key_591:
    "Ubot được biết đến rộng rãi là một công cụ giao dịch đáng tin cậy và hiệu quả, cung cấp lợi nhuận hàng năm ổn định vượt trên 100%. Với công nghệ trí tuệ nhân tạo tiên tiến và các thuật toán phức tạp, nền tảng này đã và đang được cập nhật liên tục để đáp ứng các điều kiện thị trường đang thay đổi liên tục, mang lại cho nhà đầu tư một trải nghiệm giao dịch an toàn và đáng tin cậy hơn.",
  key_592:
    "Thành công của Ubot chủ yếu đến từ cam kết kiên định trong việc cung cấp các sản phẩm giao dịch chất lượng cao, đảm bảo sự hài lòng của khách hàng.",
  key_593: "23/5 - Không FOMO",
  key_594:
    "Auto Trade 23/5 của Ubot là một tính năng độc đáo giúp nó khác biệt với các sản phẩm giao dịch khác trên thị trường tài chính. Với Auto Trade 23/5, các nhà giao dịch không còn phải lo lắng về các yếu tố FOMO (cảm xúc) hoặc phạm sai lầm dựa trên cảm tính. Tính năng này cho phép Ubot hoạt động liên tục mà không cần bất kỳ sự can thiệp nào của con người, mang đến cho các nhà giao dịch trải nghiệm giao dịch liên tục và đáng tin cậy. Bằng cách sử dụng công nghệ AI tiên tiến và các thuật toán phức tạp, Auto Trade 23/5 của Ubot đảm bảo rằng các nhà giao dịch có thể tận dụng mọi cơ hội giao dịch mà không sợ bỏ lỡ hoặc bị ảnh hưởng bởi cảm xúc.",
  key_595: "Quản lý vốn",
  key_596:
    "Quản lý vốn hiệu quả là một phần quan trọng mang đến thành công của Ubot trong việc đạt được lợi nhuận ổn định trên thị trường tài chính. Hệ thống quản lý vốn vượt trội của Ubot đảm bảo rằng các nhà đầu tư có thể tận hưởng thu nhập thụ động ổn định khoảng 5-15% mỗi tháng. Điều này đạt được bằng cách thực hiện các chính sách quản lý rủi ro nghiêm ngặt và sử dụng các thuật toán tiên tiến để phân tích và điều chỉnh theo xu hướng thị trường trong thời gian thực. Với hệ thống quản lý vốn của Ubot, các nhà đầu tư có thể tin tưởng rằng tiền của họ đang được quản lý tốt và có thể tận hưởng nguồn thu nhập đáng tin cậy và ổn định từ các khoản đầu tư.",
  key_597: "Uy tín",
  key_598:
    "Ubot đã nổi tiếng về mức độ tin cậy và tín nhiệm trên thị trường tài chính, nhờ vào quan hệ đối tác với các tổ chức có uy tín trên toàn thế giới.",
  key_599:
    "Chọn cách thuận tiện nhất để liên hệ với đội ngũ hỗ trợ của chúng tôi. Gửi câu hỏi của bạn qua email hoặc qua các phương thức trò chuyện trực tuyến. Các chuyên gia giàu kinh nghiệm sẽ trả lời bất kỳ câu hỏi nào của bạn về nền tảng này. Hệ thống hỗ trợ của chúng tôi hoạt động 24/7, trừ các ngày lễ trong năm.",
  key_600: "Mở tài khoản",
  key_601: "Kim loại",
  key_602: "Chứng khoán",
  key_603: "Futures",
  key_604: "Tại sao chọn Ubot",
  key_605: "Tài liệu",
  key_606: "Thống kê giao dịch",
  key_607: "Trở thành đối tác",
  key_608: "FAQ",
  key_609: "Nạp/Rút tiền",
  key_610: "Khuyến mãi và cuộc thi",
  key_611: "Mời bạn bè tham gia",
  key_612: "Tài sản",
  key_613: "Hướng dẫn cho người mới",
  key_614: "Từ điển Ubot ",
  key_615: "Phân tích thị trường",
  key_616: "Học viện Trading ",
  key_617: "Chính sách",
  key_618: "Trợ giúp",
  key_619:
    "Ubot là một sản phẩm giao dịch Forex tự động với công nghệ AI, được sản xuất bởi một công ty công nghệ với hơn 7 năm kinh nghiệm trong thị trường CFD. Ubot hoạt động 23/5 tự động theo các bộ lệnh Fibonacci, tạo ra lợi nhuận ổn định với mức trung bình từ 5-15% mỗi tháng.",
  key_620: "FPMarkets",
  key_621:
    "FPMarkets là nhà môi giới Forex và CFD được thành lập và quản lý từ năm 2005. Đây cũng là một công ty được công nhận về sự ổn định và đáng tin cậy khi đã giành được hơn 40 giải thưởng toàn cầu. Ngoài ra FPMarkets cũng được chọn là Nhà môi giới ngoại hối hàng đầu của Úc vào năm 2020.",
  key_622: "FXPrimus",
  key_623:
    "Là sàn trung gian chuyên về thị trường ngoại hối, kim loại, năng lượng, chứng khoán, đã có mặt trên thế giới trong hơn 13 năm, bao gồm các nước như Anh, Úc, Singapore, Trung Quốc, Việt Nam, v.v...",
  key_624: "EA (Expert Advisor)",
  key_625:
    "Là 1 loại phần mềm đưa ra các tín hiệu giao dịch tài chính hoặc thực hiện các lệnh giao dịch (đóng lệnh, cắt lỗ, chốt lời…) theo 1 chương trình lập trình có sẵn dựa trên nền tảng giao dịch MT4 hoặc MT5",
  key_626:
    "Là một nền tảng giao dịch trực tuyến phổ biến với giao diện đơn giản và trực quan. Với MT4 các nhà giao dịch có thể dễ dàng tạo ra chiến lược giao dịch tự động của riêng mình",
  key_627: "Chi phí BOT",
  key_628: "Là chi phí mà người dùng cần trả để có thể sử dụng BOT.",
  key_629: "Khoản tiền đầu tư",
  key_630:
    "Là khoản tiền mà người dùng cần nạp vào để BOT có thể bắt đầu giao dịch",
  key_631:
    "Là khoản tiền mà người dùng cần nạp nếu muốn giữ vị thế khi thị trường có biến động lớn",
  key_632:
    "This is the estimated profit value to be earned within the period of using Ubot based on the pre-set trading scenarios. Depending on the trading scenario, the estimated profit may vary.",
  key_633: "Trading Scenario",
  key_634:
    "A scenario is a set of predetermined rules that a user can choose to apply to their BOT. The BOT will rely on this scenario to carry out buy and sell transactions.",
  key_635: "Loại hình mà BOT sẽ tiến hành giao dịch.",
  key_636: "Cặp tiền tệ mà người dùng lựa chọn để giao dịch",
  key_637: "Lệnh mua tối đa.",
  key_638: "Lệnh bán tối đa.",
  key_639: "Tralling stop",
  key_640:
    "Là lệnh Mua/bán với giá đặt Mua/Bán được tự động điều chỉnh để bám sát xu thế giảm/tăng của thị trường giúp nhà đầu tư đạt được mức giá tối ưu nhất trong khoảng kỳ vọng.",
  key_641:
    "1. Chúng tôi chỉ cung cấp công nghệ giao dịch.Ubot không phải là dịch vụ ủy thác đầu tư.",
  key_642:
    "2. Đảm bảo đường truyền internet ổn định khi giao dịch, nếu như trader mất internet thì lệnh giao dịch sẽ không được gửi.",
  key_643:
    "3. Cân nhắc kiểm soát giao dịch, để đề phòng các trường hợp rủi ro hiếm có như mất điện, máy móc gặp sự cố. Vì độ nhạy của Ubot nên đôi khi bot sẽ bị ảnh hưởng bởi thông tin sai lệch trên thị trường.",
  key_644:
    "4. Không nghe theo tin các quảng cáo, nhận định khác của người không thuộc trong dự án. Đặc biệt những admin tự nhận mình thuộc dự án Ubot.",
  key_645: "5. Lợi nhuận trung bình hàng tháng ước tính 5-15%.",
  key_646: "6. Admin không bao giờ nhắn tin trước với bạn",
  key_647:
    "7. Nếu có bất kỳ thắc mắc, cần liên hệ hỗ trợ thì phải lập tức gọi đến bộ phận hỗ trợ của Ubot, chúng tôi sẽ làm việc liên tục trong 23 giờ từ thứ 2-thứ 6",
  key_648:
    "8. Chúng tôi không chịu trách nhiệm bất cứ trường hợp rủi ro giao dịch nào ngoài giờ khung giờ làm việc, hoạt động.",
  key_649: "9. Tuyệt đối không nghe theo các lời khuyên đầu tư chưa kiểm chứng",
  key_650:
    "10. Không thay đổi mật khẩu MT4 sau khi đã cài đặt Bot, nếu mật khẩu bị thay đổi, Bot sẽ ngưng hoạt động",
  key_651: "11. Cần hoàn tất KYC trên sàn giao dịch trước khi kích hoạt Bot",
  key_652:
    "12. Chúng tôi sẽ không chịu trách nhiệm cho bất kỳ thiệt hại nào do truy cập trái phép vào tài khoản.",
  key_653: "Chuyển nhượng",
  key_654: "Nhập email người nhận",
  key_655: "Xác nhận chuyển nhượng",
  key_656: "Chuyển nhượng thành công",
  key_657: "Đến email",
  key_658: "Mua Bot ngay",
  key_659: "PitchDeck English",
  key_660: "PitchDeck Korean",
  key_661: "PitchDeck Vietnamese",
  key_662: "PitchDeck Chinese",
  key_663:
    "Quá trình xác minh tài khoản sẽ mất từ 8 - 24 giờ, vui lòng quay lại khi nhận được thông báo qua email",
  key_664:
    "Ubot là công ty dịch vụ Tài chính cung cấp phần mềm giao dịch cho khách hàng trên nền tảng giao dịch MetaTrade 4(MT4). Đây không phải là nền tảng uỷ thác đầu tư. Trước khi quyết định tham gia vào thị trường ngoại hối, bạn nên xem xét cẩn thận các mục tiêu đầu tư, mức độ kinh nghiệm và khả năng chấp nhận rủi ro của mình. Quan trọng nhất, đừng đầu tư số tiền mà bạn không thể để mất. Chỉ người trưởng thành có đầy đủ năng lực mới được thực hiện các giao dịch được trang web này cung cấp.\n ",
  key_665: "Phải là giá trị dương",
  key_666: "Người nhận",
  key_667: "Thời gian chuyển nhượng",
  key_668: "ID người nhận",
  key_669: "Email người nhận",
  key_670: "Di chuyển đến lịch sử chuyển nhượng Bot",
  key_671: "Tìm kiếm",
  key_672: "Lịch sử chuyển nhượng bot",
  key_673: "Hiển thị bộ lọc",
  key_674: "Số điện thoại",
  key_675: "Người gửi",
  key_676: "Bắt đầu giao dịch ngoại hối",
  key_677:
    "Bắt đầu sử dụng cả ngoại hối với nhiều cặp tiền tệ, bao gồm USD & Vàng",
  key_678: "Bắt đầu giao dịch với cặp tiền AUD/CAD",
  key_679: "Kiểm tra cặp tiền GBP/USD",
  key_680: "Bắt đầu phát triển BW BOT",
  key_681: "Hợp tác với sàn giao dịch Fxprimus",
  key_682: "Lên Demo Ubot",
  key_683: "Thử nghiệm Demo cặp tiền GBP/USD",
  key_684: "Ra mắt Ubot toàn cầu",
  key_685: "Bắt đầu giao dịch với cặp tiền GBP/USD",
  key_686: "Hợp tác với sàn giao dịch FPMarket",
  key_687: "Beta thử nghiệm cặp giao dịch/thị trường mới",
  key_688: "Hợp tác với nhiều sàn giao dịch khác",
  key_689: "Ubot token, NFT, kế hoạch phát hành whitepaper",
  key_690: "Niêm yết token Ubot, kế hoạch bán hàng NFT",
  key_691: "Phát hành ứng dụng di động phiên bản 1",
  key_692: "Mở cặp giao dịch mới và gói mới",
  key_693: "Tạo bot giao dịch tiền điện tử AI và nhiều sản phẩm mới",
  key_694: "Chặng đường",
  key_695: "phát triển",
  key_696: "Quét mã QR tham gia nhóm telegram để được hỗ trợ",
  key_697: "Tiếng Việt",
  key_698:
    "Không thay đổi mật khẩu MT4 sau khi đã cài đặt Bot, nếu mật khẩu bị thay đổi, Bot sẽ ngưng hoạt động",
  key_699: "Thay đổi mật khẩu",
  key_700: "Tên",
  key_701: "Gửi thành công",
  key_702: "Token",
  key_703: "Linh hoạt",
  key_704: "Cố định",
  key_705: "Lịch sử Staking",
  key_706: "Nhận thưởng thành công",
  key_707: "Nhận thưởng thất bại",
  key_708: "Unstake thành công",
  key_709: "Unstake thất bại",
  key_710: "Tháng",
  key_711: "Nhận",
  key_712: "Điều khoản khoá tài sản",
  key_713: "ẩn",
  key_714: "Chi tiết",
  key_715: "Tổng số tài khoản khoá",
  key_716: "Thời gian bắt đầu tham gia",
  key_717: "Thời gian kết thúc",
  key_718: "lợi nhuận hiện tại",
  key_719: "Nhận",
  key_720: "Huỷ khoá",
  key_721: "Số tiền đã khoá",
  key_722: "lợi nhuận hiện tại",
  key_723: "Nhận thưởng/lãi",
  key_724: "Ngưng khoá với lợi nhuận",
  key_725: "Nhận thưởng/lãi",
  key_726: "Khoá tài sản thành công",
  key_727: "Khoá tài sản thất bại",
  key_728: "Lợi nhuận ước tính",
  key_729: "Tỷ suất phần trăm hàng năm",
  key_730: "Số người Stake",
  key_731: "Giá USDT",
  key_732: "Staking Pool",
};
export default vi;
