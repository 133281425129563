import { Form, FormikProvider, useFormik } from "formik";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import { LoadingButton } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
// component
// recaptcha
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
// api
import { post, put } from "../../../utils/api";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setAccessToken } from "../../../utils/auth";
import { isProduction } from "../../../settings/apiEndpoint";

// ----------------------------------------------------------------------

export default function RegisterTool() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;

  const handleReCaptchaVerify = useCallback(async (successCallback) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("register");
    if (successCallback) successCallback(isProduction() ? token : 'recaptcha');
  });

  const hanldeBuyNFT = (id) => {
    post(`/nft/buy-base-nft?id=${id}`, null, () => {
      toast.success(langLibrary.key_67);
    });
  };

  const handleLogin = (params, callback) => {
    let submitParams = {
      email: params.email,
      password: params.password,
      recaptcha: "recaptcha",
    };
    post(
      `/user/login`,
      submitParams,
      (result) => {
        callback(result.accessToken);
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  const handleUpdate = () => {
    put(
      `/user/invitation-code?invitationCode=${formik.values.invitationCode}`,
      null,
      () => {
        setIsSubmitting(false);
        toast.success(langLibrary.key_65);
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  const handleRegister = (params, callback) => {
    let submitParams = {
      email: params.email,
      password: params.password,
      otp: params.registerOTP,
      recaptcha: "recaptcha",
    };
    post(
      `/user/register`,
      submitParams,
      () => {
        handleLogin(formik.values, (token) => {
          setAccessToken(token);
          setTimeout(() => {
            handleUpdate();
          }, 1000);
          // setTimeout(() => {
          //     hanldeBuyNFT('8')
          // }, 2000);
        });
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().required(langLibrary.key_47).email(langLibrary.key_46),
    password: Yup.string().required(langLibrary.key_48),
    confirmPassword: Yup.string()
      .required(langLibrary.key_58)
      .oneOf([Yup.ref("password"), null], langLibrary.key_59),
    registerOTP: Yup.string().required(langLibrary.key_68).length(6),
    invitationCode: Yup.string().required(langLibrary.key_69),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      registerOTP: "888888",
      password: "12345678",
      confirmPassword: "12345678",
      invitationCode: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      setIsSubmitting(true);
      if (step === 1) {
        handleRegister(formik.values, () => {
          setIsSubmitting(false);
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {/* Step 1 ------------------------------------------------------------------------- */}

        {step === 1 && (
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="invitationCode"
              type="text"
              label={langLibrary.key_70}
              {...getFieldProps("invitationCode")}
              error={Boolean(touched.invitationCode && errors.invitationCode)}
              helperText={touched.invitationCode && errors.invitationCode}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={langLibrary.key_51}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {langLibrary.key_40}
            </LoadingButton>
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}

const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return "";
  } else {
    return <span>{seconds}s</span>;
  }
};
