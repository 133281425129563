import { get } from "../../utils/api";
import { logout } from "../../utils/auth";
import { FETCH_CONFIG, FETCH_CONTRACT, TRIGGER_DRAWER, TRIGGER_DICTIONARY, TRIGGER_POLICY, TRIGGER_TELEGRAM_QR_CODE, TRIGGER_PARTNER_FORM } from "../constants";

export const _getConfig = () => (dispatch) => {
  get('/system/constants',
    (data) => {
      dispatch({
        type: FETCH_CONFIG,
        payload: data,
      });
    },
    (error) => {
      console.log(error);
      logout();
    }
  );
};

export const _getContract = () => (dispatch) => {
  get('/system/token-contracts',
    (data) => {
      dispatch({
        type: FETCH_CONTRACT,
        payload: data,
      });
    },
    (error) => {
      console.log(error);
      logout();
    }
  );
};

export const _triggerDrawer = (isActive) => dispatch => {
  dispatch({
    type: TRIGGER_DRAWER,
    payload: isActive,
  })
}
export const _triggerDictionary = (isOpen) => (dispatch) => {
  dispatch({
    type: TRIGGER_DICTIONARY,
    payload: isOpen,
  });
}
export const _triggerPolicy = (isOpen) => (dispatch) => {
  dispatch({
    type: TRIGGER_POLICY,
    payload: isOpen,
  });
}
export const _triggerTelegramQRCode = (isOpen) => (dispatch) => {
  dispatch({
    type: TRIGGER_TELEGRAM_QR_CODE,
    payload: isOpen,
  });
}
export const _triggerPartnerForm = (isOpen) => (dispatch) => {
  dispatch({
    type: TRIGGER_PARTNER_FORM,
    payload: isOpen,
  });
}