import { Hidden, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MotionViewport } from "../components/animate";
import Page from "../components/Page";
import Options from "../sections/bot/Options";
import Title from "../sections/bot/Title";
import { post } from "../utils/api";

// ----------------------------------------------------------------------

const RootStyle = styled(Stack)(({ theme }) => ({}));

// ----------------------------------------------------------------------

export default function BotSetting() {
  const { app } = useSelector((state) => state);
  const { id } = useParams()
  const { langLibrary } = app ? app : {};
  const [data, setData] = useState(null)
  const theme = useTheme()

  const handleGetDetail = (id) => {
    const params = {
      "page": 1,
      "pageSize": 10,
      "search": "",
      "orderBy": "",
      "isDesc": true,
      "returnStatistic": false,
      "filters": {
        Id: id
      }
    }
    post('/package/my-bots', params, (result) => {
      if (result && result.items && result.items[0]) {
        setData(result.items[0])
      }
    })
  }

  useEffect(() => {
    if (id > 0) {
      handleGetDetail(id)
    }
  }, [id])

  return (
    <Page title={langLibrary.key_282} sx={{ position: "relative" }}>
      <MotionViewport>
        <RootStyle spacing={2} mt={10}>
          {/* <Hidden smDown>
            <Typography noWrap sx={{ color: "#fff", fontSize: '2rem', mb: -2 }}>{langLibrary.key_282}</Typography>
          </Hidden> */}
          {/* <Title data={data} onSuccess={data => setData(data)} /> */}
          <Options data={data} onSuccess={data => setData(data)} />
        </RootStyle>
      </MotionViewport>
    </Page>
  );
}
