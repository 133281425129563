import { Avatar, Card, CardHeader, Collapse, Container, IconButton, Stack, Typography, } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/bitcoin-trading-bot.json'
import Image from '../../components/Image';
import TrackVisibility from "react-on-screen";
import Title from './Title';

// ----------------------------------------------------------------------

export default function WhyChoose({ ...other }) {
    const theme = useTheme()
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const [showMore, setShowMore] = useState(false)

    const reasions = [
        {
            title: langLibrary.key_396,
            subTitle: langLibrary.key_397
        },
        {
            title: langLibrary.key_398,
            subTitle: langLibrary.key_399
        },
        {
            title: langLibrary.key_400,
            subTitle: langLibrary.key_401
        },
        {
            title: langLibrary.key_402,
            subTitle: langLibrary.key_403
        },
        {
            title: langLibrary.key_404,
            subTitle: langLibrary.key_405
        },
        {
            title: langLibrary.key_406,
            subTitle: langLibrary.key_407
        }
    ]

    const animation = `@keyframes jum {
        0%   { transform:translateY(-30px)}
        50% {transform:translateY(30px)}
        100% {transform:translateY(-30px)}
      } 
      @keyframes jum1 {
        0%   { transform:scale(1) translateY(50px) }
        50% {transform:scale(0.7) translateY(200px) }
        100% {transform:scale(1) translateY(50px)}
      }`;

    return (<ContainerStyle {...other} py='10rem !important'>
        <Container>
            <TrackVisibility offset={100} partialVisibility>
                {
                    ({ isVisible }) => <Title isVisible={isVisible} title={langLibrary.key_256} />
                }
            </TrackVisibility>
        </Container>
        <Container sx={{ position: 'relative', zIndex: 1, }} >
            <ContentStyle direction='row' mt={10} alignItems='center' justifyContent='center'>
                <Stack sx={{
                    mb: { xs: 10, sm: 0 },
                    position: "relative",
                    "& img, & span.MuiBox-root": { position: 'relative', zIndex: 10 },
                    "&:after": {
                        content: '""',
                        width: "30%",
                        height: '30%',
                        position: 'absolute',
                        top: "35%",
                        left: "22%",
                        boxShadow: `0px 0px 200px ${theme.palette.text.default}, 0px 0px 200px ${theme.palette.text.default}`,
                        borderRadius: '50%',
                        zIndex: 0
                    }
                }}>
                    <Image src="/images/Group380.png" sx={{ width: '100%', }} />
                    <img src="/images/Ellipse18.png" style={{ width: '30%', height: "30px", margin: 'auto', marginLeft: "25%", }} />
                    <img src="/images/Group27.png" style={{ width: '15%', position: 'absolute', top: "25%", right: '12%', }} />

                </Stack>
                <Stack sx={{ minWidth: '70%' }} spacing={4} pl={{ xs: 0, sm: 10 }}>
                    {
                        reasions.map((item, index) => <Reasion data={item} key={index} />)
                    }
                </Stack>
            </ContentStyle>
        </Container>
    </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const Reasion = ({ data }) => {
    const theme = useTheme()
    return <CardHeader
        sx={{ alignItems: 'flex-start' }}
        // avatar={<Iconify icon="material-symbols:arrow-forward-ios-rounded" sx={{ color: theme.palette.text.default, mt: 0.7, width: '20px', height: '20px' }} />}
        title={<Typography><span style={{ color: theme.palette.text.default }}>{data.title}:</span> {data.subTitle}</ Typography>}
    />
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    position: 'relative',
    minHeight: '100vh',
    marginTop: '10vw',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    [theme.breakpoints.down('md')]: {
        '& h3': {
            fontSize: '1.2rem'
        }
    }
}));
const ContentStyle = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap'
    }
}));
