import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui
import {
  Card,
  Chip,
  Collapse,
  Divider,
  Grid,
  Hidden,
  Link,
  Stack,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// components
import Label from "../../components/Label";
import SearchNotFound from "../../components/SearchNotFound";

// icon
//
import { Box } from "@mui/system";
import CustomDrawer from "../../components/CustomDrawer";
import CustomTable from "../../components/CustomTable";
import useResponsive from "../../hooks/useResponsive";
import { MemberLevel, Ranks } from "../../settings/constants";
import { post } from "../../utils/api";
import { fDate, fDateTime } from "../../utils/format";
import { formatRankName } from "../../utils/utils";
import MemberDownLine from "./MemberDownLine";
import { useSelector } from "react-redux";
import Iconify from "../../components/Iconify";
import { IconButtonAnimate } from "../../components/animate";
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";

// ----------------------------------------------------------------------

const TABLE_HEAD_DESKTOP = [
  { id: "id", label: "key_127", alignRight: false }, //ID
  { id: "email", label: "key_128", alignRight: false }, //Email
  {
    id: "level",
    label: "key_108", //Level
    alignRight: false,
    render: (row) => <Label color="default">{`F${row.level}`}</Label>,
  },
  {
    id: "createdTime",
    label: "key_110", //Join time
    alignRight: false,
    render: (row) => `${fDateTime(row.createdTime)}`,
  },
  { id: "", label: "", alignRight: true },
];

const TABLE_HEAD_MOBILE = [
  {
    render: (row) => (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        divider={<Divider orientation="vertical" sx={{ height: "10px" }} />}
      >
        <Typography>#{row.id}</Typography>
        <Typography sx={{ opacity: 0.6 }}>F{row.level}</Typography>
      </Stack>
    ),
  },
  {
    sx: { textAlign: "right" },
    render: (row) => <Typography></Typography>,
  },
  {
    render: (row) => (
      <Typography noWrap sx={{ opacity: 0.6 }}>{`${row.email}`}</Typography>
    ),
  },
  {
    sx: { textAlign: "right" },
    render: (row) => (
      <Typography sx={{ opacity: 0.6 }}>{fDate(row.createdTime)}</Typography>
    ),
  },
];

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  // background: theme.palette.warning.lighter,
  // borderRadius: theme.shape.borderRadius,
}));

// ----------------------------------------------------------------------

DownLineList.propTypes = {
  email: PropTypes.string,
  level: PropTypes.string,
  rank: PropTypes.string,
  onSelect: PropTypes.func,
};

export default function DownLineList({
  downlineId,
  onSelect,
  onLoaded,
  showFilter,
  ...other
}) {
  const theme = useTheme()
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isFirst, setIsFirst] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [memberSelected, setMemberSelected] = useState(null);
  const [showMemberDownLine, setShowMemberDownLine] = useState(false);
  const [levelSelected, setLevelSelected] = useState("All");
  const [rankSelected, setRankSelected] = useState("All");
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const formSchema = Yup.object().shape({
    email: null,
  });

  const isDesktop = useResponsive("up", "sm");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: formSchema,
    onSubmit: () => {
      if (page === 0) {
        handleGetList()
      } else {
        setPage(0)
      }
    },
  });

  const handleGetList = () => {
    let params = {
      page: page + 1,
      pageSize: pageSize,
      search: "",
      orderBy: "",
      isDesc: true,
      returnStatistic: false,
      filters: {
        Level: parseInt(
          levelSelected === "All" ? "" : levelSelected.slice(1, 10)
        ),
        Rank: rankSelected === "All" ? "" : rankSelected.split(" ").join("_"),
        Email: formik.values.email
      },
    };
    setLoading(true);
    post(
      `/affiliate/my-referrals`,
      params,
      (result) => {
        setData(result);
        setLoading(false);
        onLoaded(result.itemCount);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const handleSelectMember = (member) => {
    setMemberSelected(member);
    setShowMemberDownLine(true);
  };

  useEffect(() => {
    handleGetList();
    setTimeout(() => {
      setIsFirst(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isFirst === false) {
      handleGetList();
    }
  }, [page]);
  useEffect(() => {
    if (isFirst === false && page > 0) {
      setPage(0);
    } else {
      handleGetList();
    }
  }, [pageSize, levelSelected, rankSelected]);

  const TABLE_HEAD = isDesktop ? TABLE_HEAD_DESKTOP : TABLE_HEAD_MOBILE;

  const { errors, touched, handleSubmit, getFieldProps } = formik;


  return (
    <CardStyle>
      <Typography variant="h6" textAlign={{ xs: "center", sm: "left" }}
        padding={{ xs: "1rem 0px", sm: "1rem 1.5rem" }}>{langLibrary.key_570}</Typography>
      <Divider />
      <Stack px={{ xs: "1rem", sm: 0 }}>
        <Collapse in={showFilter}>
          <Stack px={2}>
            <FormikProvider value={formik}>
              <Form onSubmit={handleSubmit}>
                <TextField
                  size="small"
                  label={langLibrary.key_339}
                  variant="outlined"
                  {...getFieldProps("email")}
                  sx={{ width: "80vw", maxWidth: 400 }}
                  InputProps={{
                    endAdornment: <IconButtonAnimate color={'primary'} type={'submit'} sx={{ mr: -1.5 }}>
                      <Iconify icon='tabler:search' />
                    </IconButtonAnimate>,
                  }}
                />
              </Form>
            </FormikProvider>

          </Stack>
          <Grid
            container
            padding={{ xs: "0px 0px 1rem", sm: "0.8rem 1.2rem 1rem" }}
            spacing={{ xs: 1, lg: 10 }}
          >
            <Grid item sm={12} lg={5}>
              <Typography variant="body2" mb={1} sx={{ color: theme.palette.text.secondary }}>{langLibrary.key_276}</Typography>
              <Stack direction='row' spacing={1} flexWrap="wrap">
                <Chip
                  sx={{ cursor: "pointer", marginBottom: '0.5rem' }}
                  onClick={() => setLevelSelected("All")}
                  color={levelSelected === "All" ? "primary" : "default"}
                  label={"All"}
                />
                {MemberLevel.map((item) => (
                  <Chip
                    onClick={() => setLevelSelected(item)}
                    sx={{ cursor: "pointer", marginBottom: '0.5rem' }}
                    color={levelSelected === item ? "primary" : "default"}
                    key={item}
                    label={item}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Collapse>
        <CustomTable
          loading={loading}
          tableHead={
            <TableRow>
              {TABLE_HEAD.map((item, index) => (
                <TableCell key={index} sx={item.sx}>
                  {langLibrary[item.label]}
                </TableCell>
              ))}
            </TableRow>
          }
          smMinWidth={500}
          tableBody={
            <>
              {data.items &&
                data.items.map((item, index) => (
                  <TableRow
                    onClick={() => !isDesktop && handleSelectMember(item)}
                    key={index}
                  >
                    {TABLE_HEAD.map(
                      (e, j) =>
                        j < 4 && (
                          <TableCell key={j} sx={e.sx}>
                            {e.render ? e.render(item) : item[e.id]}
                          </TableCell>
                        )
                    )}
                    {/* <Hidden smDown>
                      <TableCell textAlign="right">
                        <Link onClick={() => handleSelectMember(item)}>
                          {langLibrary.key_111}
                        </Link>
                      </TableCell>
                    </Hidden> */}
                  </TableRow>
                ))}
              {!loading && data.items && data.items.length === 0 && (
                <TableRow sx={{ '&.MuiTableRow-root': { display: !isDesktop ? "flex" : "", justifyContent: "center" } }}>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              )}
            </>
          }
          tablePagination={
            data && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage={langLibrary.key_304}
                count={data.itemCount ? data.itemCount : 0}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, newPage) => {
                  setPage(newPage);
                }}
                onRowsPerPageChange={(e) => setPageSize(e.target.value)}
              />
            )
          }
        />
      </Stack>
      <CustomDrawer
        anchor="right"
        open={showMemberDownLine}
        onClose={() => setShowMemberDownLine(false)}
        paperStyle={{ maxWidth: "850px", width: "100vw" }}
        parent={"#profile-layout"}
        title={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            divider={<Divider orientation="vertical" sx={{ height: "15px" }} />}
          >
            <Typography>
              <strong>#{memberSelected && memberSelected.id}</strong>
            </Typography>
            <Typography>{memberSelected && memberSelected.email}</Typography>
          </Stack>
        }
      >
        {memberSelected && (
          <Box>
            <MemberDownLine userId={memberSelected.id} />
          </Box>
        )}
      </CustomDrawer>
    </CardStyle>
  );
}
