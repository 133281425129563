import { Card, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Iconify from "./Iconify";

// ----------------------------------------------------------------------

const CustomPage = styled(Stack)(({ theme }) => ({
  width: "100%",
  minHeight: "100%",
  alignItems: "center",
  justifyContent: "center",
}));

export default function ComingSoon({ ...other }) {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  return (
    <>
      <CustomPage>
        <Grid container justifyContent={"center"}>
          <Grid item xs={11} sm={9} md={9} lg={9} minHeight="70vh">
            <Grid
              container
              sx={{ height: "100%", color: "#738699" }}
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Stack
                  sx={{ height: "100%" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography fontSize={"1.4rem"}>
                    <Typography variant="body" fontSize={"2rem"}>
                      C
                    </Typography>
                    OMING SOON
                    <Iconify icon='line-md:uploading-loop' sx={{ width: 30, height: 30, margin: "0px 0px 0px 5px" }} />
                  </Typography>
                  <Typography fontSize={"0.9rem"} textAlign="center">
                    {langLibrary.key_2}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomPage>
    </>
  );
}
