import { CircularProgress, Collapse, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import { post } from '../../utils/api';


// ----------------------------------------------------------------------

export default function RequestLogs({ botId }) {
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const [logs, setLogs] = useState({})
    const [loading, setLoading] = useState(false)
    const handleGetLogs = (id) => {
        const params = {
            "page": 1,
            "pageSize": 1,
            "filters": {
                "BotId": botId
            }
        }
        setLoading(true)
        post('/package/bot-logs', params, result => {
            setLogs(result.items[0])
            setLoading(false)
        }, () => {
            setLogs({})
            setLoading(false)
        })
    }
    useEffect(() => {
        if (botId > 0) {
            handleGetLogs(botId)
        }
    }, [botId])
    const { logItems } = logs ? logs : {}
    return (
        <Stack px={2} py={1} divider={<Divider sx={{ my: 1, borderStyle: 'dashed' }} />}>
            {
                logItems && logItems.map((log, index) => <LogItem key={index} data={log} langLibrary={langLibrary} />)
            }
            {
                loading && <Stack alignItems={'center'} justifyContent={'center'} sx={{ minHeight: "70vh" }}>
                    <CircularProgress />
                </Stack>
            }
        </Stack>
    );
}
const LogItem = ({ data, langLibrary }) => {
    const [showDetail, setShowDetail] = useState(true)
    if (!data) return null
    const { fieldName, previousValue, currentValue } = data
    return <Stack sx={{ width: '100%' }}>
        <Stack direction='row' alignItems='center' onClick={() => setShowDetail(!showDetail)}>
            <Iconify icon='icon-park-solid:exchange-four' sx={{ width: 25, height: 25, color: "#60db9b" }} />
            <Typography ml={1} mr={1} sx={{ color: '#5370F2' }}>{langLibrary.key_88} {fieldName}</Typography>
            <Iconify icon={showDetail ? 'material-symbols:keyboard-arrow-up-rounded' : 'material-symbols:keyboard-arrow-down-rounded'} sx={{ width: 20, height: 20, opacity: 0.4 }} />
        </Stack>
        <Collapse in={showDetail}>
            <Stack pl={4.2} mt={0.5}>
                <Stack direction='row' spacing={1.5} sx={{ opacity: 0.6 }}>
                    <Iconify icon='carbon:dot-mark' sx={{ mt: -0.1, ml: -0.5 }} />
                    <Typography lineHeight={1} >{previousValue ? previousValue : 'null'}</Typography>
                </Stack>
                <Stack direction='row' spacing={1} mt={1}>
                    <Iconify icon='mdi:arrow-right-bottom-bold' sx={{ mt: -0.1, color: "#60db9b" }} />
                    <Typography lineHeight={1.3}>{currentValue}</Typography>
                </Stack>
            </Stack>
        </Collapse>
    </Stack>
}