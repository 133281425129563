import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { _getFunding, _getAffiliateProfile, _getNewProfile } from '../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedIn } from '../../utils/auth';
import LanguagePopover from '../LanguagePopover';
import Logo from '../../components/Logo';
import { Stack } from '@mui/system';
import { AppBar, Avatar, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import Iconify from '../../components/Iconify';
import { IconButtonAnimate } from '../../components/animate';
import NavSection from '../../components/NavSection';
import { PROFILE_NAV_CONFIG } from '../menuConfig';
import useResponsive from '../../hooks/useResponsive'
import CustomDrawer from '../../components/CustomDrawer';
import Image from '../../components/Image';

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});



export default function MiniDrawer() {
  const { user, app } = useSelector(state => state)
  const isMobile = useResponsive('down', 'sm')
  const { profile } = user ? user : {}
  const { langLibrary } = app;
  const [open, setOpen] = useState(true);
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false)
  const navigator = useNavigate()
  const dispatch = useDispatch()

  const handleTriggleDrawer = (isOpen) => {
    setOpen(isOpen)
    setOpenMobileDrawer(isOpen)
  }

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(_getNewProfile())
      dispatch(_getFunding())
    } else {
      navigator('/auth/login')
    }
  }, [])

  const isPackagePage = window.location.pathname === '/profile/packages'

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden', background: theme => theme.palette.background.default }}>
      <AppBar sx={{ boxShadow: 'none', background: 'linear-gradient(90deg,#42E7F6,#2386FF)', maxWidth: 2000, margin: 'auto', right: 'initial', zIndex: 1100 }}>
        <Toolbar sx={{ background: "none" }}>
          <Stack direction='row' spacing={1} alignItems='center' height={isMobile ? 40 : 80} pl={isMobile ? 0 : `${(open ? drawerWidth : 65) + 15}px`} sx={{ transition: 'all 0.2s ease-in-out ' }}>
            <IconButtonAnimate onClick={() => handleTriggleDrawer(isMobile ? !openMobileDrawer : !open)}>
              <Iconify sx={{ color: '#fff' }} icon='mingcute:menu-line' />
            </IconButtonAnimate>
            <IconButtonAnimate onClick={() => navigator('/')}>
              <Iconify sx={{ color: '#fff' }} icon='mingcute:home-3-fill' />
            </IconButtonAnimate>
          </Stack>
          <Stack flexGrow={1}></Stack>
          <Stack pr={isMobile ? 0 : "1.5rem"}>
            <LanguagePopover />
          </Stack>
        </Toolbar>
      </AppBar>
      <Hidden smDown>
        <Drawer variant={"permanent"} sx={{ boxShadow: 'none' }} PaperProps={{ sx: { background: "none" } }} open={open} onClose={() => handleTriggleDrawer(false)}>
          <DrawerHeader>
            <Image src={open ? '/images/logo.png' : '/images/Group27.png'} sx={{ height: '40px', width: 'auto', maxWidth: 300, cursor: "pointer" }} objectFit={"contain"} onClick={() => navigator('/')} />
          </DrawerHeader>
          <Menus profile={profile} isOpen={open} langLibrary={langLibrary} />
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <CustomDrawer open={openMobileDrawer} onClose={() => setOpenMobileDrawer(false)}>
          <Menus profile={profile} isOpen={open} onClose={() => setOpenMobileDrawer(false)} langLibrary={langLibrary} />
        </CustomDrawer>
      </Hidden>
      <Box component="main" sx={{ flexGrow: 1, p: '4vw', minHeight: '100vh', pt: 10, position: 'relative', overflow: "auto" }}>
        <ContentHeaderBg />
        <Stack sx={{ position: 'relative', zIndex: 10 }}>
          <Outlet />
        </Stack>
      </Box>
    </Box>
  );
}

// ----------------------------------------------------------------------

const Menus = ({ profile, isOpen, onClose, langLibrary }) => {
  return <Stack sx={{ background: theme => theme.palette.background.paper, borderRadius: '0px 15px 0px 0px', marginTop: "-15px", pt: 1, height: "100%" }}>
    <Avatar sx={{ margin: '1rem auto 0px' }} />
    <Stack direction='row' py={1} justifyContent='center' sx={{ opacity: isOpen ? 1 : 0 }}>
      <Typography>{langLibrary.key_86}</Typography>
      <Typography ml={0.5} color="primary" fontWeight={'bold'} textAlign='left'>{profile?.displayName}</Typography>
      <Typography ml={0.5}>!</Typography>
    </Stack>
    <MenuListStyle sx={{ padding: 0, overflow: 'hidden', pt: 2, width: '100%' }}>
      <NavSection navConfig={PROFILE_NAV_CONFIG} sx={{ dispatch: 'none' }} onClickItem={onClose && onClose} isShowSocial={isOpen} />
    </MenuListStyle>
  </Stack>
}

// ----------------------------------------------------------------------

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  background: 'none',
  height: '95px',
  paddingBottom: '5px',
  paddingLeft: "20px",
  ...theme.mixins.toolbar,
}));
const ContentHeaderBg = styled('div')(({ theme }) => ({
  position: 'fixed',
  background: 'linear-gradient(90deg,#42E7F6,#2386FF)',
  top: 0,
  left: 0,
  width: '100%',
  height: '305px'
}));
const MenuListStyle = styled(List)(({ theme }) => ({
  minHeight: '50vh',
  borderRadius: '0px 15px 0px 0px',
  height: "100%"
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    boxShadow: theme.customShadows.z16b,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        left: 'initial',
        border: 'none',
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        left: 'initial',
        border: 'none'
      },
    }),
  }),
);
