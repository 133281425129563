import { API } from "../settings/apiEndpoint";
import { CustomToast } from "../utils/utils";
import { getAccessToken, logout } from "../utils/auth";
import { ErrorMsg } from "../settings/constants";
export const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export function get(endpoint, successCallback, errorCallback) {
  myFetch("GET", endpoint, undefined, successCallback, errorCallback);
}

export function post(
  endpoint,
  body,
  successCallback,
  errorCallback,
  exceptErrorCode
) {
  myFetch(
    "POST",
    endpoint,
    body,
    successCallback,
    errorCallback,
    exceptErrorCode
  );
}

export function put(endpoint, body, successCallback, errorCallback) {
  myFetch("PUT", endpoint, body, successCallback, errorCallback);
}

export function _delete(endpoint, body, successCallback, errorCallback) {
  myFetch("DELETE", endpoint, body, successCallback, errorCallback);
}

export function postFormData(endpoint, data, successCallback, errorCallback) {
  let url = API + endpoint;

  let response = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "bearer " + getAccessToken(),
    },
    body: data,
  });

  handleResponse(response, successCallback, errorCallback, endpoint);
}

export const alertError = (error) => {
  alert(error.code + (error.msg ? ": " + error.msg : ""));
};

function myFetch(
  method,
  endpoint,
  body,
  successCallback,
  errorCallback,
  exceptErrorCode
) {
  let url = API + endpoint;

  body = JSON.stringify(body);

  let headers = defaultHeaders;
  headers["Authorization"] = "bearer " + getAccessToken();

  let response = null;

  if (body === undefined)
    response = fetch(url, {
      method: method,
      headers: headers,
    });
  else {
    response = fetch(url, {
      method: method,
      headers: headers,
      body: body,
    });
  }
  handleResponse(response, successCallback, errorCallback, exceptErrorCode);
}

const handleResponse = (
  response,
  successCallback,
  errorCallback,
  exceptErrorCode
) => {
  response.then((r) => {
    if (r.status === 200) {
      if (successCallback) {
        r.json().then((result) => {
          if (result.success) {
            successCallback(result.data);
          } else {
            if (errorCallback) {
              if (result.error.code !== exceptErrorCode) {
                if (
                  ["INVALID_PARAMETERS", "INVALID_OPERATION"].includes(
                    result.error.code
                  )
                ) {
                  CustomToast(
                    "error",
                    result.error.msg.length > 0
                      ? result.error.msg
                      : getErrorMessage(result.error),
                    true
                  );
                } else {
                  CustomToast(
                    "error",
                    result.error.msg.length > 0
                      ? result.error.code
                      : getErrorMessage(result.error)
                  );
                }
              }
              errorCallback(result.error);
            } else {
              console.log(result.error);
            }
          }
        });
      }
    } else if (r.status === 403) {
      throwError(errorCallback, "Forbidden");
      CustomToast("error", "FORBIDDEN");
    } else if (r.status === 401) {
      // throwError(null, "Unauthorized");
      console.log("Unauthorized");
      // window.localStorage.removeItem("SHOW_ADS");
      logout();
    } else if (r.status === 500) {
      throwError(null, "Internal server error");
    } else if (r.status === 502) {
      throwError(null, "Service unavailable");
    } else if (r.status === 526) {
      throwError(null, "Please connect to VPN");
    } else {
      errorCallback("Undefined");
      console.log("Undefined");
      // throwError(null, "Undefined");
    }
  });
};

const getErrorMessage = (error) => {
  if (ErrorMsg[error.code]) {
    return ErrorMsg[error.code];
  }

  return error.msg;

  return;
};
const throwError = (errorCallback, message) => {
  if (errorCallback) errorCallback(message);
  else showError(message);
};

const showError = (message) => {
  alert("ERR: " + message);
};
