import PropTypes from "prop-types";
import { useSelector } from "react-redux";
// @mui
import {
  Avatar,
  Button,
  Card,
  CardContent,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { CoinImage } from "../../settings/constants";

// component

// icon
import { IconCurrencyDollar } from "@tabler/icons";
import { fCurrency } from "../../utils/format";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import { useResponsive } from "../../hooks";

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  alignSelf: "center",
  borderRadius: theme.shape.paperRadius,
  background: theme.palette.background.paper,
  maxWidth: "600px",
  width: "100%",
  // boxShadow: theme.shadows[24],
  margin: "0px auto",
  padding: "1.5rem",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "none",
    background: "none",
    boxShadow: "none",
    padding: 0,
  },
}));

const CardHeaderButton = styled(Button)(({ theme }) => ({
  color: theme.palette.warning.dark,
  padding: "5px 20px",
  "&.active": {
    color: "#fff",
    background: theme.palette.warning.dark,
  },
}));

const FundItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: "5px 0px",
  padding: "0px",
  "&:hover": {
    ".MuiTypography-root": {
      color: "#fff",
    }
  },
}));

// ----------------------------------------------------------------------

FundOverview.propTypes = {
  data: PropTypes.object,
};

export default function FundOverview({ data, onSuccess, ...other }) {
  const { user, app } = useSelector((state) => state);
  const { funding } = user ? user : {};
  const isMobile = useResponsive('down', 'sm')
  const { langLibrary } = app;
  const theme = useTheme()

  return (
    <>
      <CardStyle {...other}>
        <CardContent sx={{ padding: isMobile ? "1rem !important" : "0px !important", background: isMobile ? theme.palette.background.paper : "" }}>
          <Stack>
            {funding.map((option) => (
              <FundItem key={option.asset} sx={{ height: 45 }}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  width={"100%"}
                >
                  <Avatar
                    src={CoinImage[option.asset]}
                    alt="Remy Sharp"
                    sx={{ width: 25, height: 25 }}
                  >
                    <IconCurrencyDollar stroke={2} size="1.2rem" />
                  </Avatar>
                  <Typography variant="button">{option.asset}</Typography>
                  <Box sx={{ flexGrow: 1, height: "100%" }} />
                  <Typography variant="body2">
                    {fCurrency(option.amount)}
                  </Typography>
                </Stack>
              </FundItem>
            ))}
          </Stack>
          <Stack direction="row" mt={3} spacing={1}>
            <Deposit />
            <Withdraw onSuccess={(msg) => onSuccess(msg)} />
          </Stack>
        </CardContent>
      </CardStyle>
    </>
  );
}
