import Iconify from "../components/Iconify";

export const PROFILE_NAV_CONFIG = [
  {
    items: [
      {
        title: "key_189",
        value: "dashboard",
        path: "/profile/dashboard",
        icon: <Iconify icon={"carbon:dashboard"} />,
      }, //Dashboard
      {
        title: "key_192", //wallet
        value: "wallet",
        path: "/profile/wallet",
        icon: <Iconify icon={"carbon:wallet"} />,
      }, //Wallet
      {
        title: "key_193",
        value: "packages",
        path: "/profile/packages",
        icon: <Iconify icon={"iconoir:packages"} />,
      }, //Staking
      {
        title: "key_42",
        value: "staking",
        path: "/profile/staking",
        icon: <Iconify icon={"game-icons:profit"} />,
      }, //Package
      // {
      //   title: "key_194",
      //   value: "my-package",
      //   path: "/profile/my-package",
      //   icon: <Iconify icon={"carbon:inventory-management"} />,
      // }, //My packages
      {
        title: "key_335",
        value: "my-bots",
        path: "/profile/my-bots",
        icon: <Iconify icon={"uil:robot"} />,
      }, //My bots
      {
        title: "key_556",
        value: "guideline",
        path: "/profile/guideline",
        icon: <Iconify icon={"ic:outline-help-outline"} />,
        outLine: true
      },
      // GUIDELINE
      // {
      //   title: "key_28",
      //   value: "team",
      //   path: "/profile/team",
      //   icon: <Iconify icon={"carbon:group-presentation"} />,
      // }, //Team
      {
        title: "key_317",
        value: "setting",
        path: "/profile/setting",
        icon: <Iconify icon={"ep:setting"} />,
      }, //Setting
    ],
  },
];


