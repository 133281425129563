import { useEffect, useState } from "react";

// @mui
import {
  Chip,
  Grid,
  TableCell,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// components
import Label from "../../components/Label";
import SearchNotFound from "../../components/SearchNotFound";

// icon
//
import CustomTable from "../../components/CustomTable";
import useResponsive from "../../hooks/useResponsive";
import { MemberLevel, Ranks } from "../../settings/constants";
import { post } from "../../utils/api";
import { fDate, fDateTime } from "../../utils/format";
import { formatRankName } from "../../utils/utils";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const TABLE_HEAD_DESKTOP = [
  { id: "id", label: "key_127" }, //ID
  { id: "email", label: "key_128" }, //Email
  {
    id: "level",
    label: "key_108", //Level
    render: (row) => <Label color="default">{`F${row.level}`}</Label>,
  },
  {
    id: "createdTime",
    label: "key_107", //Created Time
    sx: { textAlign: "right" },
    render: (row) => `${fDateTime(row.createdTime)}`,
  },
];
const TABLE_HEAD_MOBILE = [
  {
    render: (row) => (
      <>
        #{row.id}
        <span style={{ margin: "0px 0.5rem", opacity: 0.3 }}>|</span>
        <span style={{ opacity: 0.6 }}>F{row.level}</span>
      </>
    ),
  },
  {
    sx: { textAlign: "right" },
    render: (row) => <> </>,
  },
  {
    render: (row) => <span style={{ opacity: 0.6 }}>{row.email}</span>,
  },
  {
    sx: { textAlign: "right" },
    render: (row) => (
      <span style={{ opacity: 0.6 }}>{fDate(row.createdTime)}</span>
    ),
  },
];

// ----------------------------------------------------------------------

export default function MemberDownLine({ userId, open, ...other }) {
  const isDesktop = useResponsive("up", "sm");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(7);
  const [isFirst, setIsFirst] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [levelSelected, setLevelSelected] = useState("All");
  const [rankSelected, setRankSelected] = useState("All");
  const { user, app } = useSelector((state) => state);
  const { langLibrary } = app;

  const handleGetList = () => {
    let params = {
      page: page + 1,
      pageSize: pageSize,
      search: "",
      orderBy: "",
      isDesc: true,
      returnStatistic: false,
      filters: {
        DownlineId: userId,
        Level: parseInt(
          levelSelected === "All" ? "" : levelSelected.slice(1, 10)
        ),
        Rank: rankSelected === "All" ? "" : rankSelected.split(" ").join("_"),
      },
    };
    setLoading(true);
    post(
      `/affiliate/list-downlines`,
      params,
      (result) => {
        setData(result);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleGetList();
    setTimeout(() => {
      setIsFirst(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isFirst === false) {
      handleGetList();
    }
  }, [page]);

  useEffect(() => {
    if (isFirst === false && page > 0) {
      setPage(0);
    } else {
      handleGetList();
    }
  }, [pageSize, levelSelected, rankSelected]);

  const TABLE_HEAD = isDesktop ? TABLE_HEAD_DESKTOP : TABLE_HEAD_MOBILE;

  return (
    <ThemeProvider>
      <Grid container padding="1rem" spacing={{ xs: 1, md: 10 }}>
        {/* <Grid item sm={12} lg={6}>
          <Chip
            sx={{ margin: "3px", cursor: "pointer" }}
            onClick={() => setLevelSelected("All")}
            color={levelSelected === "All" ? "primary" : "default"}
            label={"All"}
          />
          {MemberLevel.map((item) => (
            <Chip
              onClick={() => setLevelSelected(item)}
              sx={{ margin: "3px", cursor: "pointer" }}
              color={levelSelected === item ? "primary" : "default"}
              key={item}
              label={item}
            />
          ))}
        </Grid> */}

        <Grid item xs={12}>
          <CustomTable
            maxHeight={"calc(100vh - 250px)"}
            loading={loading}
            tableHead={
              <TableRow>
                {TABLE_HEAD.map((item, index) => (
                  <TableCell key={index} sx={item.sx}>
                    {langLibrary[item.label]}
                  </TableCell>
                ))}
              </TableRow>
            }
            tableBody={
              <>
                {data.items &&
                  data.items.map((item, index) => (
                    <TableRow key={index}>
                      {TABLE_HEAD.map(
                        (e, j) =>
                          j < 5 && (
                            <TableCell key={j} sx={e.sx}>
                              {e.render ? e.render(item) : item[e.id]}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  ))}
                {!loading && data.items && data.items.length === 0 && (
                  <TableRow sx={{ '&.MuiTableRow-root': { display: !isDesktop ? "flex" : "", justifyContent: "center" } }}>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                )}
              </>
            }
            tablePagination={
              data && (
                <TablePagination
                  rowsPerPageOptions={[-1]}
                  component="div"
                  labelRowsPerPage={langLibrary.key_304}
                  count={data.itemCount ? data.itemCount : 0}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={(e, newPage) => {
                    setPage(newPage);
                  }}
                  onRowsPerPageChange={(e) => setPageSize(e.target.value)}
                />
              )
            }
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
