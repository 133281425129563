import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Logo from '../components/Logo';
import { Stack, useTheme } from '@mui/system';
import { APP_BAR_DESKTOP } from '../layouts/app';
import AccountPopover from '../layouts/profile/AccountPopover';
import LanguagePopover from '../layouts/LanguagePopover';
import Banner from '../sections/about-us/Banner';
import Service from '../sections/about-us/Service';
import Commit from '../sections/about-us/Commit';
import Benefit from '../sections/about-us/Benefit';
import Market from '../sections/about-us/Market';
import Education from '../sections/about-us/Education';
import Difference from '../sections/about-us/Difference';
import MainMenu from '../layouts/header/MainMenu';
import { useSelector } from 'react-redux';
import { isLoggedIn } from '../utils/auth';
import { Button, Hidden } from '@mui/material';
import { Link as RouterLink, useLocation } from "react-router-dom";
import FooterBanner from '../sections/homepage/FooterBanner';
import { Roadmap } from '../sections/about-us/Roadmap';
import { useResponsive } from '../hooks';


function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function HideAppBar(props) {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const isMobile = useResponsive("down", "md")
  const theme = useTheme()
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar sx={{ background: 'none', zIndex: 1000, background: theme.palette.background.default, boxShadow: theme.customShadows.z8, padding: 1, "& *": { fontFamily: "conthrax !important" } }}>
          <Stack px={{ sm: 0, lg: 6 }} py={1} direction={'row'} alignItems="center" justifyContent={'space-between'}>
            <Hidden lgDown>
              <Box sx={{ flexGrow: 1 }} />
            </Hidden>
            <Stack direction={'row'} alignItems='center' width={'100%'} justifyContent='center'>
              <Logo onDark={false} />
              <Hidden lgUp>
                <Box sx={{ flexGrow: 1 }} />
              </Hidden>
              <MainMenu />
              {
                !isLoggedIn() ?
                  <Stack direction="row" alignItems="center" spacing={2}>

                    <Button
                      to="/auth/login"
                      sx={{ fontSize: '1rem', background: "#fff !important" }}
                      variant="contained"
                      component={RouterLink}
                    >
                      {langLibrary.key_7}
                    </Button>
                    <Button
                      to="/auth/register"
                      sx={{ fontSize: '1rem' }}
                      color="primary"
                      variant="contained"
                      component={RouterLink}

                    >
                      {langLibrary.key_6}
                    </Button>
                  </Stack>
                  : <AccountPopover />
              }
              <LanguagePopover />
            </Stack>
            <Hidden lgDown>
              <Box sx={{ flexGrow: 1 }} />
            </Hidden>
          </Stack>
        </AppBar>
      </HideOnScroll>
      <Container sx={{ "& *": { fontFamily: 'conthrax !important' } }}>
        <Stack pt={8}>
          <Banner />
          <Service />
          <Difference />
          {/* <Benefit /> */}
          <Benefit />
          <Roadmap />
          <Stack mt={20} />
          <FooterBanner />
        </Stack>
      </Container>
    </React.Fragment>
  );
}