import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';



// ----------------------------------------------------------------------

export default function Title({ isVisible, title, ...other }) {
    const [renderCount, setRenderCount] = useState(0)
    useEffect(() => {
        if (isVisible) setRenderCount(renderCount + 1)
    }, [isVisible])

    return (
        renderCount > 0 && <RenderTitle variant='h3' color='primary' textAlign='center' width={'fit-content'} margin="auto">{title}</RenderTitle>
    );
}

// ----------------------------------------------------------------------
const RenderTitle = styled(Typography)(({ theme }) => ({
    position: "relative",
    color: "#fff",
    '&:before': {
        content: '""',
        width: "100px",
        height: "6px",
        background: "linear-gradient(90deg,rgba(0,0,0,0),#F85482)",
        top: "calc(50% - 3px)",
        left: 'calc(-100px - 20px)',
        position: 'absolute',
        borderRadius: '0 0 50px 0',
        animation: 'title-animation 1.5s ease-in-out '
    },
    '&:after': {
        content: '""',
        width: "100px",
        height: "6px",
        background: "linear-gradient(90deg,#42E5F3,rgba(0,0,0,0))",
        top: "calc(50% - 3px)",
        right: 'calc(-100px - 20px)',
        position: 'absolute',
        borderRadius: '0 0 0 50px',
        animation: 'title-animation 1.5s ease-in-out '
    }
}));