import { Button, Card, Container, Grid, Hidden, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as robotLottieData from '../../lotties/robot.json'
import Image from '../../components/Image';
import { alpha, Box, useTheme } from '@mui/system';
import { Link as RouterLink, useLocation } from "react-router-dom";


// ----------------------------------------------------------------------

export default function Benefit({ ...other }) {
    const { app } = useSelector(state => state)
    const theme = useTheme()
    const { langLibrary } = app ? app : {}
    return (<ContainerStyle {...other}>
        <BenifitContent container>
            {/* <Grid item xs={12} p={2}>
                <Typography textAlign={'center'} variant='h2'>{langLibrary.key_597}</Typography>
            </Grid>
            <Grid item xs={12} p={2} mb={5}>
                <Typography textAlign={'center'} variant='body3'>{langLibrary.key_598}</Typography>
            </Grid>
            <Grid item xs={12} p={2}>
                <Stack direction='row' alignItems={'center'} justifyContent='space-between' spacing={{ xs: 2, md: 10 }} px={{ xs: 0, md: 0 }}>
                    <Image src="/images/image33.png" sx={{ paddingLeft: { xs: '0.5rem', md: '2rem' }, borderLeft: "1px solid rgba(255,255,255,0.5)" }} />
                    <Image src="/images/image35.png" sx={{ paddingLeft: { xs: '0.5rem', md: '2rem' }, borderLeft: "1px solid rgba(255,255,255,0.5)" }} />
                </Stack>
            </Grid>
            <Grid item xs={12} p={2}>
                <Stack direction='row' alignItems={'center'} justifyContent='space-between' spacing={{ xs: 2, md: 10 }} px={{ xs: 0, md: 0 }}>
                    <Image src="/images/image31.png" sx={{ paddingLeft: { xs: '0.5rem', md: '2rem' }, borderLeft: "1px solid rgba(255,255,255,0.5)" }} />
                    <Image src="/images/image34.png" sx={{ paddingLeft: { xs: '0.5rem', md: '2rem' }, borderLeft: "1px solid rgba(255,255,255,0.5)" }} />
                </Stack>
            </Grid> */}
            <Grid item md={3} sm={12} p={2} mt={10}>
                <Stack direction={'row'} width="100%" height="100%" alignItems='center' >
                    <Typography variant='h3'>{langLibrary.key_208}</Typography>
                </Stack>
            </Grid>
            <Grid item md={9} sm={12} p={2} mt={{ sm: 0, md: 10 }}>
                <Typography variant='body3'>{langLibrary.key_599}</Typography>
            </Grid>
        </BenifitContent>
        <Button component={RouterLink} to="/auth/register" variant='contained' color='primary' sx={{ minWidth: 200 }}>{langLibrary.key_600}</Button>
    </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    alignItems: 'center',
    paddingTop: "50px",
    paddingBottom: '50px',
    minHeight: '50vh',
}));
const BenifitContent = styled(Grid)(({ theme }) => ({
    padding: '3rem',
    position: "relative",
    height: 'fit-content',
    zIndex: 10,
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
        padding: '1rem 0px',
    }
}));
const BenifitItem = styled(Stack)(({ theme }) => ({
    background: theme.palette.background.neutral,
    padding: '1.5rem',
    height: '100%',
    borderRadius: '1rem'
}));