// @mui
import { styled } from "@mui/material/styles";
import { Link, Container, Typography, Grid, Stack } from "@mui/material";
// hooks
// components
import Page from "../components/Page";
// sections
import { LoginForm } from "../sections/auth/login";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  maxHeight: `calc(100vh - ${60}px)`,
  padding: theme.spacing(12, 0),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(22, 0),
  },
}));

const CustomBox = styled(Stack)(({ theme }) => ({
  padding: "2rem",
  textAlign: "left",
  borderRadius: theme.shape.borderRadius,
  // boxShadow: theme.customShadows.z24,
  alignSelf: "center",
  maxWidth: "500px",
  margin: "0px auto",
  background: "#202020",
  border: '1px solid #43E9F6',
  [theme.breakpoints.down("md")]: {
    // padding: "0px",
    textAlign: "center",
    boxShadow: "none",
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;

  return (
    <Page title={langLibrary.key_32}>
      <RootStyle>
        <Container>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} md={5} lg={5}>
              <CustomBox>
                <div style={{ marginBottom: "20px" }}>
                  <Typography variant="h3" paragraph>
                    {langLibrary.key_32}
                  </Typography>
                  <Typography variant="body2">
                    {langLibrary.key_33}
                    <RouterLink to="/auth/register">
                      <Link variant="subtitle2" color="primary">
                        {""} {langLibrary.key_34}
                      </Link>
                    </RouterLink>
                  </Typography>
                </div>
                <LoginForm />
              </CustomBox>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
}
