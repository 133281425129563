import { Button, Card, Container, Hidden, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/system';
import { isLoggedIn } from '../../utils/auth';

// ----------------------------------------------------------------------

export default function Banner({ ...other }) {
    const { app } = useSelector(state => state)
    const { langLibrary } = app ? app : {}
    const theme = useTheme()
    const animation = `@keyframes jum {
        0%   { transform:translateY(-30px)}
        50% {transform:translateY(30px)}
        100% {transform:translateY(-30px)}
      } 
      @keyframes jum1 {
        0%   { transform:scale(1) translateY(50px)}
        50% {transform:scale(0.7) translateY(50px)}
        100% {transform:scale(1) translateY(50px)}
      }`;
    return (<ContainerStyle {...other}>
        <style children={animation} />
        <Content direction={'row'} >
            <IntroText>
                <Stack maxWidth={'md'} >
                    <Typography sx={{ fontSize: '2.5rem', borderBottom: '1px solid #fff', width: 'fit-content', lineHeight: { xs: '3rem', sm: '6rem' } }}><span style={{ color: theme.palette.text.default }}>{langLibrary.key_572}</span> {langLibrary.key_332}</Typography>
                    <Typography my={5}>{langLibrary.key_245}</Typography>
                    <Button component={RouterLink} to={isLoggedIn() ? "/profile/packages" : "/auth/register"} color='primary' variant='contained' sx={{ maxWidth: 200 }}>{isLoggedIn() ? langLibrary.key_658 : langLibrary.key_6}</Button>
                </Stack>
            </IntroText>
            <IntroImage >
                <img src="/images/Group430.png" style={{ width: '70%', margin: 'auto', animation: `jum 2s ease-in-out infinite` }} />
                <img src="/images/Ellipse18.png" style={{ width: '30%', height: '30px', marginLeft: '-5%', animation: `jum1 2s ease-in-out infinite` }} />
            </IntroImage>
        </Content>
    </ContainerStyle>
    );
}

// ----------------------------------------------------------------------

const ContainerStyle = styled(Stack)(({ theme }) => ({
    backgroundSize: 'cover',
    position: 'relative',
    minHeight: 'calc(100vh + 16px)',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        marginTop: "13rem",
        minHeight: 'unset',

    },
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        marginTop: "10rem",
        minHeight: 'unset',

    }
}));
const Content = styled(Stack)(({ theme }) => ({
    backgroundSize: 'calc(100% - 4rem)',
    position: 'relative',
    zIndex: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
        position: 'initial !important',
        flexWrap: 'wrap',
        justifyContent: 'center'
    }
}));
const IntroText = styled(Stack)(({ theme }) => ({
    width: '50%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 2rem',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginTop: '-10%',
    },
}));
const IntroImage = styled(Stack)(({ theme }) => ({
    height: '100%',
    position: 'relative',
    width: '500px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    "& img": {
        zIndex: 10,
        position: 'relative'
    },
    [theme.breakpoints.down('lg')]: {
        position: 'relative',
        bottom: 0,
        left: 0,
        width: '400px',
        height: '400px'
    },
    [theme.breakpoints.down('sm')]: {
        width: '250px',
        height: '250px',
        margin: 'auto'
    },
    "&:after": {
        content: '""',
        width: "30%",
        height: '30%',
        position: 'absolute',
        top: "35%",
        left: "32%",
        boxShadow: `0px 0px 200px ${theme.palette.text.default}, 0px 0px 200px ${theme.palette.text.default}`,
        borderRadius: '50%',
        zIndex: 0
    }
}));