import PropTypes from "prop-types";
import { useState } from "react";
// icons
import contentDeliveryNetwork from "@iconify/icons-carbon/content-delivery-network";
// @mui
import { MenuItem, Box, Popover, useTheme } from "@mui/material";
// components
import Iconify from "../components/Iconify";
import { IconButtonAnimate } from "../components/animate";
import { useDispatch, useSelector } from "react-redux";
import { SWITCH_LANGUAGE } from "../store/constants";

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: "key_11",
    value: "en",
  },
  {
    label: "key_12",
    value: "ko",
  },
  {
    label: "key_697",
    value: "vi",
  },
];

// ----------------------------------------------------------------------

LanguagePopover.propTypes = {
  sx: PropTypes.object,
};

export default function LanguagePopover({ sx, lightMode }) {
  const { app } = useSelector((state) => state);
  const { langLibrary } = app;
  const [currentLang, setCurrentLang] = useState("en");
  const dispatch = useDispatch();
  const theme = useTheme()

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    handleClose();
    setCurrentLang(newLang);
    dispatch({ type: SWITCH_LANGUAGE, payload: newLang });
    localStorage.setItem("langCode", newLang);
  };

  return (
    <>
      <IconButtonAnimate color="inherit" onClick={handleOpen} sx={sx}>
        <Iconify
          icon={contentDeliveryNetwork}
          sx={{ width: 25, height: 25, fontWeight: "bold", color: lightMode ? theme.palette.text.secondary : "#fff" }}
        />
      </IconButtonAnimate>

      <Popover
        open={Boolean(open)}
        onClose={handleClose}
        anchorEl={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { px: 1, width: 220 },
        }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang}
            onClick={() => handleChangeLang(option.value)}
            sx={{ my: 1 }}
          >
            <Box
              component="img"
              alt={option.label}
              src={`/images/flags/${option.value}.png`}
              sx={{
                width: 28,
                height: 28,
                objectFit: "cover",
                mr: 1,
              }}
            />

            {langLibrary[option.label]}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
